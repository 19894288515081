export const hi = {
  next: "अगला",
  youWontBeCharged:
    "जब तक आप सत्र विवरण की पुष्टि नहीं करते तब तक आपसे शुल्क नहीं लिया जाएगा",
  home: "घर",
  chats: "चैट",
  ratings: "रेटिंग्स",
  settings: "समायोजन",
  moreSettings: "अधिक सेटिंग",
  settingsNotify: "सेटिंग्स | Waylight",
  logOut: "लॉग आउट",
  changePassword: "पासवर्ड बदलें",
  receiveResetPassword:
    "पासवर्ड रीसेट लिंक प्राप्त करने के लिए नीचे 'रीसेट' पर क्लिक करें।",
  enterEmailForResetPassword:
    "पासवर्ड रीसेट लिंक प्राप्त करने के लिए अपना ईमेल दर्ज करें।",
  successResetPassword: "पासवर्ड रीसेट ईमेल भेजा गया!",
  failResetPassword: "पासवर्ड रीसेट करने में विफल",
  reset: "रीसेट",
  signOut: "प्रस्थान करें",
  availableBooking: "बुकिंग के लिए उपलब्ध है",
  unavailableBooking: "बुकिंग के लिए उपलब्ध नहीं है",
  shareProfile: "प्रोफ़ाइल साझा करें",
  requests: "अनुरोध",
  hours: "घंटे",
  totalSessions: "कुल सत्र",
  totalEarned: "कुल कमाई",
  totalHours: "कुल घंटे",
  yourSession: "आपका सत्र",
  runningNow: "अब चल रहा है",
  notHaveUpcomingSessions: "आपका अभी तक कोई आगामी सत्र नहीं है",
  notHavePastSessions: "अभी तक आपका कोई पिछला सत्र नहीं है",
  notHaveQuickSessions: "आपने अभी तक कोई त्वरित सत्र प्रारंभ नहीं किया है",
  upcomingSessions: "आगामी सत्र",
  offer: "प्रस्ताव",
  gallery: "गेलरी",
  photo: "तस्वीर",
  session: "सत्र",
  sessionWith: "के साथ सत्र",
  occupation: "व्यवसाय",
  skills: "कौशल",
  experts: "विशेषज्ञों",
  noInformation: "कोई सूचना नहीं है",
  speaks: "बोलता हे",
  socialLinks: "सामाजिक कड़ियाँ",
  requestSession: "अनुरोध सत्र",
  contacts: "संपर्क",
  noOffersYet: "कोई ऑफ़र उपलब्ध नहीं है",
  all: "सभी",
  requested: "का अनुरोध किया",
  confirmed: "की पुष्टि की",
  passed: "उत्तीर्ण",
  joined: "में शामिल हो गए",
  ongoing: "चल रहे",
  canceled: "रद्द",
  rejected: "ठुकरा दिया",
  approved: "स्वीकृत",
  calendar: "पंचांग",
  favorites: "पीछा किया",
  removeAll: "सभी हटाएं",
  viewProfile: "प्रोफ़ाइल देखें",
  today: "आज",
  upcoming: "आगामी",
  attachments: "संलग्नक",
  goToSession: "सत्र में जाओ",
  prepare: "तैयार करना",
  sessions: "सत्र",
  prerequisites: "आवश्यक शर्तें",
  download: "डाउनलोड",
  past: "भूतकाल",
  decline: "मना करना",
  accept: "स्वीकार करें",
  declineSession: "अस्वीकार सत्र",
  wantDeclineSession: "क्या आप वाकई इस सत्र को अस्वीकार करना चाहते हैं?",
  wantDeclinePaidSession:
    "क्या आप वाकई अनुरोध अस्वीकार करना चाहते हैं? आपके ग्राहक का भुगतान वापस कर दिया जाएगा।",
  wantDeclineAfterApprove:
    "क्या आप वाकई इसे रद्द करना चाहते हैं? आपके ग्राहक का भुगतान वापस कर दिया जाएगा।",
  videoCall: "स्ट्रीम प्रारंभ करें",
  endSession: "सत्र समाप्त",
  areYouSureYouWantEndSession: "क्या आप वाकई इस सत्र को समाप्त करना चाहते हैं?",
  findExpert: "विशेषज्ञ खोजें",
  selectAnExpert: "एक विशेषज्ञ का चयन करें",
  inviteClients: "अपने ग्राहकों को आमंत्रित करें",
  selectAChat: "संदेश सेवा प्रारंभ करने के लिए चैट चुनें",
  noChatsYet: "अभी तक कोई चैट नहीं है",
  notHaveMessages: "आपके पास अभी तक कोई संदेश नहीं है",
  shareProfileToClient: "क्लाइंट को प्रोफ़ाइल साझा करें",
  remove: "हटाना",
  time: "समय",
  timeSlots: "समय प्रकोष्ठ",
  deleteOffer: "ऑफ़र हटाएं",
  myOffers: "ऑफर",
  aboutOffer: "सत्र के बारे में",
  createNewOffer: "नया प्रस्ताव बनाएं",
  editOffer: "प्रस्ताव संपादित करें",
  successfulyBooked: "सत्र सफलतापूर्वक बुक किया गया",
  close: "बंद करना",
  account: "खाता",
  price: "कीमत",
  priceAndLength: "मूल्य और लंबाई",
  aboutTheOffer: "सत्र के बारे में",
  uploadAFile: "एक फ़ाइल अपलोड करें",
  uploadAFileError: "फ़ाइल अपलोड नहीं की गई थी",
  confirm: "पुष्टि करें",
  IDLogin: "आपका ईमेल",
  myProfile: "ग्राहक प्रोफाइल",
  transactions: "लेनदेन",
  transactionHistory: "लेनदेन का इतिहास",
  noTransactions: "आपने अभी तक कोई लेन-देन नहीं किया है",
  noTransactionsDescription:
    "एक बार जब आप सफलतापूर्वक भुगतान सत्र प्राप्त कर लेंगे तो वे दिखाई देंगे",
  typicalSchedule: "विशिष्ट अनुसूची",
  amount: "मात्रा",
  status: "दर्जा",
  paid: "बाहर का भुगतान किया",
  action: "गतिविधि",
  description: "विवरण",
  billing: "बिलिंग",
  card: "कार्ड",
  clients: "ग्राहकों",
  sessionsPassed: "सत्र बीत गए",
  payout: "भुगतान",
  firstName: "संतोष",
  lastName: "उपनाम",
  skip: "छोडना",
  connect: "जोड़ना",
  about: "के बारे में",
  title: "शीर्षक",
  languages: "बोली",
  currency: "मुद्रा",
  addYourOffer: "अपना प्रस्ताव जोड़ें",
  join: "जोड़ना",
  joinIn: "में शामिल हों",
  joinOn: "ज्वाइन करें",
  message: "संदेश",
  client: "ग्राहक",
  expert: "विशेषज्ञ",
  Expert: "विशेषज्ञ",
  noMoreOptions: "और अधिक विकल्प नहीं",
  male: "नर",
  female: "मादा",
  custom: "रिवाज़",
  copyYourLink: "अपना लिंक कॉपी करें",
  done: "पूर्ण",
  search: "खोज",
  searchWaylight: "अपना Waylight खोजें",
  suggested: "सुझाव दिया",
  upcomingSession: "आगामी सत्र",
  startASession: "त्वरित सत्र",
  quickSession: "त्वरित सत्र",
  rate: "दर",
  perHour: "घंटे से",
  packages: "संकुल",
  timezone: "समय क्षेत्र",
  book: "पुस्तक",
  favorite: "पसंदीदा",
  lastSession: "अंतिम बातचीत",
  ago: "पहले",
  gender: "लिंग",
  loading: "लोड हो रहा है",
  min: "मिनट",
  hour: "घंटा",
  online: "ऑनलाइन",
  offline: "ऑफलाइन",
  date: "तारीख",
  displayName: "प्रदर्शित होने वाला नाम",
  closePanel: "बंद पैनल",
  goToStripe: "अपना पेआउट डैशबोर्ड खोलें",
  completeStripeOnBoarding: "पूर्ण स्ट्राइप एक्सप्रेस ऑनबोर्डिंग",
  stripeOnBoarding: "स्ट्राइप ऑनबोर्डिंग",
  completed: "पूरा किया हुआ",
  notCompleted: "पूरा नहीं हुआ",
  myClients: "मेरे ग्राहक",
  findClients: "हमें ग्राहक नहीं मिल रहे हैं",
  clientDisplayedHere:
    "जब आपको क्लाइंट मिल जाते हैं, तो वे यहां प्रदर्शित होंगे",
  writeSomething: "कुछ इनपुट करें और आइटम बनाने के लिए एंटर दबाएं",
  bookSession: "पुस्तक सत्र",
  registerAs: "इस रूप में पंजीकरण करें:",
  addedCards: "आपके कार्ड",
  cardType: "कार्ड के प्रकार",
  billingHistory: "बिलिंग इतिहास",
  deleteCard: "कार्ड हटाएं",
  wantToDeleteCard: "क्या आप वाकई कार्ड हटाना चाहते हैं?",
  selectPaymentMethod: "भुगतान का तरीका चुनें",
  addNewCard: "नया कार्ड जोड़ें",
  emailAddress: "सार्वजनिक ईमेल",
  phoneNumber: "फ़ोन नंबर",
  publicPhoneNumber: "सार्वजनिक फोन नंबर",
  username: "उपयोगकर्ता नाम",
  socialProfilesOrLinks: "सामाजिक प्रोफ़ाइल / अन्य लिंक",
  newOffer: "नया प्रस्ताव",
  myExperts: "मेरे विशेषज्ञ",
  myClientProfile: "मेरा ग्राहक प्रोफ़ाइल",
  noExpertsFound: "कोई विशेषज्ञ नहीं मिला",
  openMenu: "मेनू खोलें",
  becomeAnExpert: "Waylight बिजनेस ज्वाइन करें",
  switchToExpert: "व्यावसायिक दृश्य पर स्विच करें",
  switchToClient: "क्लाइंट व्यू पर स्विच करें",
  notHaveExperts: "आपके पास अभी तक कोई विशेषज्ञ नहीं है",
  endCall: "स्ट्रीम बंद करो",
  endSharing: "सांझा करना बंद करो",
  lastOnline: "अंतिम ऑनलाइन",
  clientSince: "ग्राहक के बाद से",
  welcome: "स्वागत",
  messageError: "आवश्यक क्षेत्र",
  support: "सहारा",
  profile: "प्रोफ़ाइल",
  portfolio: "विभाग",
  publicId: "सार्वजनिक आईडी",
  files: "फ़ाइलें",
  reason: "कारण",
  declineReason: "अपने ग्राहक को बताएं कि आपको क्यों अस्वीकार करना है",
  rejectionReason: "अस्वीकृति कारण",
  business: "व्यवसाय",
  passwordResetInvalidEmail: "ईमेल अमान्य है",
  logIn: "साइन इन करें",
  logInSubtitle: "कोई भी कार्य करने के लिए, कृपया साइन इन या रजिस्टर करें।",
  enterEmail: "ईमेल दर्ज करें",
  enterPassword: "कुंजिका",
  capsLockIsOn: "कैप्स लॉक ऑन है",
  numLockIsOn: "न्यूम लॉक चालू है",
  forgotPassword: "अपना कूट शब्द भूल गए?",
  forgotPasswordEmail:
    "पासवर्ड रीसेट लिंक प्राप्त करने के लिए अपना ईमेल पता दर्ज करें।",
  requestPasswordReset: "पासवर्ड रीसेट का अनुरोध करें",
  requestResetLink: "लिंक दोबारा भेजें",
  in: "में",
  sec: "सेकंड",
  passwordResetRequestReceived: "पासवर्ड रीसेट अनुरोध प्राप्त हुआ",
  passwordResetIfAccountExist:
    "यदि कोई खाता मौजूद है, तो आपको शीघ्र ही एक लिंक के साथ एक ईमेल प्राप्त होगा।",
  resetPassword: "पासवर्ड रीसेट",
  passwordChangeSuccessful: "पासवर्ड सफलतापूर्वक बदला गया",
  passwordChangeSuccessfulSubtitle:
    "अब आप अपने खाते में साइन इन करने के लिए अपने नए पासवर्ड का उपयोग कर सकते हैं।",
  setNewPassword: "अपने खाते में साइन इन करने के लिए नया पासवर्ड सेट करें",
  passwordChange: "पासवर्ड बदलें",
  passwordOld: "पुराना पासवर्ड",
  passwordNew: "नया पासवर्ड",
  dontHaveAccount: "क्या आपके पास खाता नहीं है?",
  alreadyRegistered: "क्या आपके पास पहले से खाता है?",
  tokenExpired: "टोकन समाप्त हो गया",
  tokenExpiredDescription:
    "यह टोकन या तो समाप्त हो गया है या पहले इस्तेमाल किया जा चुका है",
  validLength: "8 या अधिक वर्ण",
  upperCase: "बड़े अक्षर (AZ)",
  lowerCase: "छोटे अक्षर (az)",
  numbersOrSymbols: "संख्याएं या प्रतीक (0-9!@#$%^&*)",
  joinFree: "मुफ्त में जुडो",
  businessTitle: "Waylight | लिंक इन बायो उपकरण जो आपको कमाई में मदद करता है!",
  businessDescription:
    "सोशल मीडिया रेवेन्यू पर 10X कमाएं! सिर्फ 2 मिनट में लॉन्च करें!",

  spotlightYourExperience:
    "आपकी सामग्री, विशेषज्ञ और सामुदायिक राजस्व धाराओं के लिए व्यापक उपकरण",
  monetizationPlatform: "ऑनलाइन बिजनेस स्टूडियो",
  createAccount: "खाता बनाएं",
  watchVideo: "वीडियो देखेंा",
  bestToolsAvailable:
    "हम चाहते हैं कि आपके पास सर्वोत्तम उपकरण उपलब्ध हों और हम उनका उपयोग करने के लिए आपसे शुल्क नहीं लेंगे।",
  schedulingManagement: "शेड्यूलिंग प्रबंधन।",
  chooseConvenientTimeSlots:
    "काम के लिए सुविधाजनक समय स्लॉट चुनें और बुकिंग प्राप्त करें।",
  expertLandingPayouts: "भुगतान।",
  simpleOnboarding: "आपके वित्तीय खातों की सरल ऑनबोर्डिंग।",
  industryStandard: "उद्योग मानक कॉल गुणवत्ता और उपलब्धता।",
  lead: "प्रमुख",
  engage: "काम पर लगाना",
  frequentlyAskedQuestions: "सामान्यतःपूछे जाने वाले प्रश्न",
  contactUs: "संपर्क करें:",
  accordionLabel0: "यदि सेवा नि:शुल्क है, तो Waylight कैसे चलती है?",
  accordionText0:
    "हम विशेषज्ञ शुल्कों पर वॉल्यूम-आधारित कमीशन ले रहे हैं और डिस्कवरी और चैरिटी कॉल हम पर हैं!",
  accordionLabel1: "क्या आप अनेक प्रकार के ऑफ़र का समर्थन करते हैं?",
  accordionText1:
    "निश्चित रूप से, आप प्रत्येक ऑफ़र की कीमत, अवधि और विवरण निर्दिष्ट करते हुए जितने चाहें उतने ऑफ़र बना सकते हैं।",
  accordionLabel2: "मैं भुगतान कैसे पाऊं?",
  accordionText2:
    "हम पेआउट को संभालने के लिए स्ट्राइप और अन्य गेटवे का उपयोग कर रहे हैं। आपके बैंक खाते या भुगतान विधि को जोड़ने के लिए एक साधारण ऑनबोर्डिंग की आवश्यकता होती है, जो 55 से अधिक देशों में काम करता है। कई और भुगतान विधियां और लचीली पेआउट सेटिंग्स तैयार की जा रही हैं।",
  accordionLabel3: "क्या मैं ज़ूम, मीट या स्काइप का उपयोग कर सकता हूँ?",
  accordionText3:
    "हमारे पास पूरी तरह से एकीकृत वीडियो समाधान है, जिसे दोनों पक्षों के लिए सुरक्षित प्रवाह की गारंटी के लिए बनाया गया है। कम सुरक्षा लेकिन अधिक लचीलेपन वाले अन्य वीडियो समाधानों का उपयोग करना भविष्य में एक विकल्प होगा।",
  accordionLabel4: "क्या मैं जैपियर, कैलेंडली आदि का उपयोग कर सकता हूं?",
  accordionText4:
    "हम उन एकीकरणों के बैकलॉग पर सक्रिय रूप से काम कर रहे हैं जो हमारे उपयोगकर्ता सबसे अधिक चाहते हैं। इस मामले में आपके विचार जानकर हमें खुशी होगी। पर एक त्वरित ईमेल छोड़ेंsupport@waylight.me . शुक्रिया!",
  freeAudience: "हम विकास करते हैं - आप बनाते हैं!",
  businessProfile: "व्यापार प्रोफ़ाइल।",
  onlineBusiness:
    "मिनटों में ऑनलाइन व्यवसाय बनाएं, आप जो सबसे अच्छा करते हैं उसे स्पॉटलाइट करें।",
  paidCalls: "भुगतान ऑडियो और वीडियो कॉल।",
  paidChatsAndBlog: "भुगतान चैट और ब्लॉग।",
  setUpSubscription: "चैटिंग और व्यक्तिगत ब्लॉग के लिए सदस्यता सेट करें।",
  web3: "Web3 रेटिंग और समीक्षाएं।",
  collectRealRatings:
    "अपना व्यक्तिगत ब्रांड बनाने के लिए वास्तविक रेटिंग और समीक्षाएं एकत्र करें।",
  getStartedToday: "एक नई राजस्व धारा के तीन सरल चरण:",
  createProfile: "1. अपना प्रोफाइल बनाएं",
  fillTemplate:
    "अपनी प्रोफ़ाइल, शेड्यूल और ऑफ़र का एक पूर्व-निर्मित टेम्प्लेट भरें, सशुल्क चैट और ब्लॉग के लिए मूल्य निर्धारित करें और मिनटों में आप ऑर्डर और सब्सक्राइबर स्वीकार करने के लिए तैयार होंगे।",
  shareIt: "2. इसे दुनिया के साथ साझा करें",
  linkYourProfile:
    "अपनी प्रोफ़ाइल को अपने सभी चैनलों से लिंक करें - आपका सोशल मीडिया, विज्ञापन, ईमेल, और बहुत कुछ। पोस्ट, कहानियों, वीडियो, व्यक्तिगत संदेशों आदि में अपने ऑनलाइन व्यवसाय के बारे में जानकारी साझा करें।",
  growYourBusiness: "3. अपना व्यवसाय बढ़ाएँ",
  shareYourExpertise:
    "अपनी विशेषज्ञता और ज्ञान साझा करें, अपना ब्लॉग अपडेट करें, बुकिंग और चैट का जवाब दें, ऑनलाइन वीडियो परामर्श करें, और अपनी सार्वजनिक वेब3 रेटिंग और समीक्षाएं प्राप्त करें।",
  businessflow: "प्रवाह",
  businessflowStep1Title: "बायो में लिंक जोड़ें",
  businessflowStep1:
    "सामाजिक नेटवर्क BIO (Instagram, Facebook, YouTube, LinkedIn, Twitter, TikTok, आदि) में अपना Waylight Business प्रोफ़ाइल लिंक जोड़ें और इसके बारे में कहानियों और पोस्ट के माध्यम से जानकारी साझा करें।",
  businessflowStep2Title: "1:1 वीडियो कॉल, 1:1 चैट, निजी ब्लॉग",
  businessflowStep2:
    "अपने ग्राहकों और ग्राहकों के साथ 1:1 वीडियो कॉल, 1:1 चैट के माध्यम से कमाई करें। ट्यूटोरियल, लेख, वीडियो सलाह, बैकस्टेज, व्यक्तिगत जीवन के अनुभव, पाठ, संगीत आदि के साथ अपने व्यक्तिगत ब्लॉग में केवल-सदस्य सामग्री बनाएं।",
  businessflowStep3Title: "भुगतान प्राप्त करना",
  businessflowStep3:
    "अपने व्यक्तिगत ब्लॉग में अपने वीडियो कॉल, चैट, और केवल-सदस्य सदस्यता के लिए भुगतान प्राप्त करें। अपने Waylight के बारे में अधिक जानकारी साझा करके अपना मुद्रीकरण बढ़ाएँ!",
  bookOnboardingCall: "निःशुल्क ऑनबोर्डिंग कॉल बुक करें",
  talkToFounders: "संस्थापकों को बताएं कि Waylight से कमाई कैसे शुरू करें।",
  orScanQR: "या क्यूआर स्कैन करें",
  yourFreeTools: "आपके निःशुल्क उपकरण",
  features: "विशेषताएं",
  personalLink: "व्यक्तिगत यूआरएल",
  personalQR: "व्यक्तिगत क्यूआर",
  scheduling: "निर्धारण",
  bookings: "बुकिंग के",
  paidChats: "भुगतान 1:1 चैट",
  personalPaidBlog: "व्यक्तिगत सशुल्क ब्लॉग",
  soon: "जल्दी",
  subscriptions: "सदस्यता",
  payments: "100+ देशों में भुगतान",
  payouts: "58+ देशों में भुगतान",
  cryptoPayments: "क्रिप्टो भुगतान",
  coinsAwarding: "पुरस्कार देने वाले सिक्के",
  web3Ratings: "Web3 रेटिंग, समीक्षाएं",
  clientsManagement: "ग्राहक प्रबंधन",
  sessionsManagement: "सत्र प्रबंधन",
  dashboard: "डैशबोर्ड",
  businessCalculator: "Waylight बिजनेस इनकम कैलकुलेटर",
  howMuchMoney: "पता करें कि ग्राहक आपके व्यवसाय में कितना पैसा लाएंगे।",
  estimateNumber: "1. ग्राहकों और ग्राहकों की अनुमानित संख्या",
  howMuchYouCharge:
    "2. आप वीडियो कॉल या सब्सक्रिप्शन के लिए कितना चार्ज कर सकते हैं?",
  monthlyIncome: "मासिक आय",
  newToday: "नई आज वी",
  hadNoSessions: "इस सप्ताह कोई सत्र नहीं",
  atThisWeek: "इस सप्ताह",
  welcomeToWaylight: "वायलाइट में आपका स्वागत है!",
  welcomeToWaylightBusiness: "Waylight बिजनेस में आपका स्वागत है!",
  linkToVideo: "परिचयात्मक वीडियो का लिंक",
  writeSentences: "अपने बारे में कुछ वाक्य लिखिए।",
  emptyExpertTitle: "ऐसा लगता है कि यह पृष्ठ मौजूद नहीं है",
  emptyExpertText:
    "लिंक टूटा हुआ हो सकता है, या पृष्ठ अक्षम या हटा दिया गया है।",
  profileData: "प्रोफ़ाइल डेटा",
  headerImageTooltip:
    "सुझाई गई शीर्षलेख छवि आयाम लगभग 10:1 हैं। आप 1900x190px या 1280x128px जैसा कुछ आज़मा सकते हैं।",
  emailTooltip:
    "यह सार्वजनिक प्रदर्शन के लिए एक ईमेल पता है (आपका लॉगिन नहीं)। आप अपनी पसंद का कोई भी ईमेल निर्दिष्ट कर सकते हैं या उसे पूरी तरह से हटा सकते हैं, यह वैकल्पिक है!",
  timezoneTooltip:
    "अगर आपको अपना शहर नहीं मिल रहा है, तो 'पैसिफ़िक डेलाइट टाइम' या 'पूर्वी यूरोपीय समय' जैसे क्षेत्र खोजने की कोशिश करें।",
  linkToVideoPresentation: "एक बाहरी लिंक जोड़ें",
  expertProfileVideoPresentation: "या फ़ाइल अपलोड करें",
  linkToVideoPresentationTooltip:
    "अपने विवरण का हिस्सा बनने के लिए किसी बाहरी वीडियो का लिंक सेट करें। अधिकांश मौजूदा वीडियो होस्टिंग सेवाएं समर्थित हैं। यदि आप एक वीडियो अपलोड करते हैं - इसे इस लिंक पर प्राथमिकता दी जाएगी।",
  linkToVideoOfferTooltip:
    "ऑफ़र विवरण का हिस्सा बनने के लिए किसी बाहरी वीडियो का लिंक सेट करें। अधिकांश मौजूदा वीडियो होस्टिंग सेवाएं समर्थित हैं। यदि आप एक वीडियो अपलोड करते हैं - इसे इस लिंक पर प्राथमिकता दी जाएगी।",
  phoneTooltip: "यह सार्वजनिक प्रदर्शन के लिए एक फोन नंबर है। यह वैकल्पिक है!",
  socialProfilesTooltip:
    "YouTube, Facebook, Linkedin, Instagram या Tiktok जैसी अपनी सामाजिक प्रोफ़ाइल और साइटों से लिंक जोड़ें।",
  videoPresentationTooltip:
    "अपने विवरण में प्रदर्शित होने के लिए एक वीडियो अपलोड करें। यह उपरोक्त वीडियो लिंक को ओवरराइड करेगा।",
  videoOfferTooltip:
    "ऑफ़र विवरण में प्रदर्शित होने के लिए एक वीडियो अपलोड करें। यह उपरोक्त वीडियो लिंक को ओवरराइड करेगा।",
  occupationTooltip:
    "अपने व्यवसाय के बारे में दुनिया को बताएं, जैसे 'एआर सलाहकार' या 'टॉप टिकटॉक इन्फ्लुएंसर'।",
  skillsTooltip:
    "अपने कौशल जोड़ें ताकि आपके ग्राहक जान सकें कि आप किसमें अच्छे हैं, उदाहरण के लिए 'उद्यमिता' या 'पालन-पोषण'।",
  aboutTooltip:
    "अपने ग्राहकों का स्वागत महसूस करने और अपने व्यक्तिगत ब्रांड की सराहना करने के लिए एक व्यक्तिगत विवरण जोड़ें।",
  speaksTooltip: "आपके द्वारा बोली जाने वाली भाषाओं को निर्दिष्ट करें।",
  cropPhoto: "चित्र काटो",
  updatePhoto: "प्रोफाइल फोटो अपडेट करें",
  crop: "फसल",
  bookASession: "जब आप एक सत्र बुक करते हैं तो यह यहां प्रदर्शित होगा",
  videoAudioSettings: "वीडियो और ऑडियो सेटिंग्स",
  videoAudioSettingsDescription:
    "वीडियो और ऑडियो को समायोजित करने के लिए इन सेटिंग्स का उपयोग करें",
  cameras: "कैमरा",
  microphones: "माइक्रोफ़ोन",
  output: "वक्ताओं",
  bgSettings: "एक वीडियो पृष्ठभूमि चुनें",
  toStartAChat: "चैट शुरू करने के लिए,",
  startAChat: "और चैट शुरू करें।",
  messageToAnExpert: "किसी विशेषज्ञ को अपना पहला संदेश भेजें",
  messageToAClient: "क्लाइंट को अपना पहला संदेश भेजें",
  chatWindowGetToKnow: "1. अपने संभावित ग्राहकों और उनकी ज़रूरतों को जानें",
  requestInformation: "2. यदि आवश्यक हो तो अतिरिक्त जानकारी का अनुरोध करें",
  notGettingAReply:
    "3. यदि आपको उत्तर नहीं मिल रहा है, तो आप विनम्रतापूर्वक अनुसरण करने का प्रयास कर सकते हैं",
  knowEachOther: "4. एक दूसरे को जानें और अपने सत्रों की योजना बनाएं",
  chatWindowProvideABrief:
    "1. आप जो खोज रहे हैं उसका संक्षिप्त विवरण प्रदान करें",
  chatWindowAddAttachments: "2. यदि आवश्यक हो तो संलग्नक जोड़ें",
  describeYourIssue:
    "कृपया अपने अनुरोध का वर्णन करें और बताएं कि आप क्या सीखना चाहते हैं या इसके लिए आपको सहायता चाहिए। यदि आवश्यक हो तो कोई अतिरिक्त फाइल संलग्न करें।",
  getAConfirmation: "आपको शीघ्र ही विशेषज्ञ से पुष्टि मिल जाएगी।",
  describeTheDetails:
    "अपने प्रस्ताव के लिए विवरण, मूल्य और पूर्वापेक्षाओं का वर्णन करें।",
  areYouSure: "क्या आप वाकई इस ऑफ़र को हटाना चाहते हैं?",
  offerAvailable: "प्रस्ताव उपलब्ध है",
  offerUnavailable: "प्रस्ताव उपलब्ध नहीं है",
  notHaveOffers: "आपके पास अभी तक कोई प्रस्ताव नहीं है",
  createOne: "अपने ग्राहकों के साथ काम करना शुरू करने के लिए एक बनाएं।",
  getPaid: "आपको निश्चित समय के लिए निर्दिष्ट मूल्य का भुगतान मिलता है।",
  describeYourOffer: "अपने प्रस्ताव का संक्षेप में वर्णन करें।",
  describeRequirements:
    "सत्र में शामिल होने से पहले ग्राहकों के लिए आवश्यकताओं का वर्णन करें: संदेश या अनुलग्नक।",
  priceLimit: "मूल्य $0 हो सकता है या $2 से $10000 तक हो सकता है।",
  priceLimitForUA: "मूल्य ₴0 हो सकता है या ₴83 से ₴412496 तक हो सकता है।",
  offerNameDescription:
    "डिस्कवरी कॉल' या 'बेसिक ग्राफ़िक डिज़ाइन' जैसा कुछ आज़माएँ, यदि वह आपकी चीज़ है।",
  clickingConfirm: "'पुष्टि करें' पर क्लिक करके मैं इससे सहमत हूं ...",
  termsAndConditions: "नियम एवं शर्तें",
  preCharge: "आपके सत्र का अनुरोध करने के लिए एक प्री-चार्ज रखा जाएगा।",
  paymentFeesMayAlter: "भुगतान प्रणाली शुल्क भिन्न हो सकते हैं।.",
  notBeCharged:
    "आपका खाता विशेष पुष्टिकरण के बिना कभी भी चार्ज नहीं किया जाएगा।",
  last: "अंतिम 4",
  lastDigits: "आखिरी 4 अक्षर",
  billingPageName: "नाम",
  gateway: "द्वार",
  prepareForTheMeeting: "बैठक की तैयारी करें",
  prepareForSession: "के लिए तैयार",
  fewRecomendations: "अपने सत्र को सहज और उत्पादक बनाने के लिए कुछ सुझाव।",
  findAQuiet: "एक शांत जगह खोजें जहाँ कोई आपको परेशान न करे",
  makeSure: "सुनिश्चित करें कि आपके पास एक अच्छा इंटरनेट कनेक्शन है",
  weRecommendUsing: "हम हेडफ़ोन का उपयोग करने की सलाह देते हैं",
  prepareYourQuestions: "अपने प्रश्न तैयार करें",
  enjoyTheSession: "सत्र का आनंद लें!",
  initialization: "प्रारंभ कर रहा है...",
  beginOnboardingAsExpert:
    "Waylight Business के विशेषज्ञ के रूप में ऑनबोर्डिंग शुरू करें",
  connectStripeAccount:
    "अपने ग्राहकों से धन प्राप्त करने के लिए, आपको भुगतान विधि बनानी होगी।",
  provideABrief: "1. आप जो खोज रहे हैं उसका संक्षिप्त विवरण प्रदान करें",
  addAttachments: "2. यदि आवश्यक हो तो संलग्नक जोड़ें",
  gettingAReply:
    "3. यदि आपको उत्तर नहीं मिल रहा है, तो आप विनम्रतापूर्वक अनुसरण करने का प्रयास कर सकते हैं",
  getToKnow: "4. एक-दूसरे को जानें और साथ में अपने सत्रों की योजना बनाएं",
  fileType: "फ़ाइल प्रकार समर्थित नहीं है",
  orDragAndDrop: "या खींचें और छोड़ें",
  upTo: "तक 1 जीबी (यदि आपके पास बड़ी फ़ाइल की आकार है, तो समर्थन से संपर्क करें)",
  headerImage: "प्रवेशिका प्रतिमा",
  inviteClientsModalInviteClients: "ग्राहकों को आमंत्रित करें",
  shareYourLink:
    "अपने ग्राहकों को आमंत्रित करने के लिए अपना व्यक्तिगत लिंक साझा करें!",
  addDays: "अपना नियमित शेड्यूल सेट करें",
  publiclyInformation: "सप्ताह के प्रति दिन कई बार स्लॉट जोड़े जा सकते हैं।",
  availableTimeslot: "उपलब्ध समय",
  AddTimeslot: "समयावधि जोड़ें",
  AddToSchedule: "अनुसूची में जोड़ें",
  signUpAs: "के रूप में साइन अप करें",
  registerAsClient: "मैं कुछ नया सीखना चाहता हूं",
  registerAsExpert: "मैं अपना अनुभव साझा करना चाहता हूं",
  approveSession: "सत्र स्वीकृत करें",
  approveThisSession:
    "क्या आप वाकई इस सत्र को स्वीकार करना चाहते हैं? स्वीकृति देकर आप निर्दिष्ट समय पर शामिल होने के लिए सहमत होते हैं।",
  noTimeslots:
    "कोई समयावधि उपलब्ध नहीं है। कोई अन्य तिथि चुनें या शेड्यूल के बारे में विशेषज्ञ से पूछें।",
  toastSavedSuccessfully: "सफलतापूर्वक बचाया",
  toastDeletedSuccessfully: "सफलतापूर्वक मिटाया गया",
  toastEmailCopied: "ईमेल कॉपी किया गया",
  toastProfileCopied: "प्रोफ़ाइल कॉपी की गई",
  toastBookingAvalable: "अब आप बुकिंग के लिए उपलब्ध हैं",
  toastBookingUnavalable: "आप बुकिंग के लिए उपलब्ध नहीं हैं",
  toastQrCopied: "क्यूआर कोड कॉपी किया गया",
  toastPaymentMethodAdded: "भुगतान विधि सफलतापूर्वक जोड़ी गई",
  toastPaymentMethodDeleted: "भुगतान विधि सफलतापूर्वक हटाई गई",
  toastUrlCopied: "निजी लिंक कॉपी किया गया",
  toastOfferUrlCopied: "ऑफ़र लिंक कॉपी किया गया",
  toastSessionCanceled: "समय की कमी के कारण यह सत्र रद्द कर दिया गया है",
  toastSessionWithYourself: "आप अपने आप से सत्र बुक नहीं कर सकते!",
  toastPayoutMethodAdded: "भुगतान विधि सफलतापूर्वक जोड़ी गई",
  toastPayoutMethodDeleted: "भुगतान विधि सफलतापूर्वक हटाई गई",
  toastPrimaryCardError: "प्राथमिक कार्ड सेट नहीं किया जा सका",
  toastPrimaryCardAdded: "प्राथमिक कार्ड सफलतापूर्वक जोड़ा गया",
  toastTooLargeFile: "फ़ाइल का आकार बहुत बड़ा है",
  toastOfferNotAdded: "प्रस्ताव नहीं जोड़ा गया था",
  toastOfferNotEdited: "प्रस्ताव संपादित नहीं किया गया है",
  toastSessionLinkCopied: "सत्र लिंक कॉपी किया गया",
  toastMaxParticipantsReached: "सहभागियों की अधिकतम संख्या पोहोचली",
  toastNotMayJoin:
    "दुर्भाग्य से, आप इस सत्र में शामिल नहीं हो सकते क्योंकि यह अपनी क्षमता तक पहुँच चुका है।",
  toastLinkExpired: "यह लिंक पहले ही समाप्त हो चुका है।",
  toastCannotStartSession: "अभी त्वरित सत्र प्रारंभ नहीं किया जा सकता",
  toastSessionExtendedSuccessfully: "सत्र सफलतापूर्वक बढ़ाया गया था",
  toastTimeslotStartAndEnd:
    "आप ऐसा टाइम स्लॉट नहीं जोड़ सकते जो एक ही समय पर शुरू और समाप्त हो",
  toastTimeslotEndsInPast:
    "आप अतीत में समाप्त होने वाली समयावधि नहीं जोड़ सकते हैं",
  toastTimeslotDuplicate: "आप डुप्लीकेट समयावधि नहीं जोड़ सकते हैं",
  toastSkillNameTooLong:
    "आपका कौशल नाम बहुत लंबा है, कोई छोटा नाम चुनने का प्रयास करें",
  buttonSave: "सहेजें",
  buttonCancel: "रद्द करना",
  buttonApprove: "मंज़ूरी देना",
  buttonDelete: "मिटाना",
  buttonChat: "बात करना",
  buttonOpenChat: "बात करना",
  buttonFiles: "फ़ाइलें",
  buttonNotNow: "अभी नहीं",
  buttonSubmit: "प्रस्तुत",
  buttonNo: "नहीं",
  buttonEnd: "अंत",
  buttonSignUp: "साइन अप करें",
  buttonYes: "हां",
  buttonEdit: "संपादन करना",
  buttonCopy: "प्रतिलिपि",
  buttonReply: "जवाब",
  buttonAdd: "जोड़ना",
  buttonSend: "भेजना",
  buttonRequest: "समर्थन का अनुरोध करें",
  buttonSet: "सेट",
  buttonEditPhoto: "फ़ोटो संपादित करें",
  buttonStartAnyway: "वैसे भी शुरू करो",
  buttonWait: "इंतज़ार",
  buttonCopyLink: "लिंक की प्रतिलिपि करें",
  buttonLeave: "छुट्टी",
  buttonExtraMinutes: "अतिरिक्त 10 मिनट",
  buttonViewOffer: "विवरण देखें",
  buttonMoreDetails: "अधिक जानकारी",
  buttonContinue: "जारी रखना",
  buttonBookACall: "कॉल बुक करें",
  notificationSetup: "स्थापित करना",
  notificationFillProfile: "प्रोफ़ाइल भरें",
  notificationHi: "नमस्ते!",
  notificationAddYourName:
    "संचार को जीवंत बनाने के लिए अपना नाम और फोटो जोड़ें, यदि आप ऐसा महसूस करते हैं। कोई दबाव नहीं :)",
  notificationSetupBilling: "सेटअप बिलिंग",
  notificationAddPaymentMethod:
    "सत्र बुक करने में सक्षम होने के लिए भुगतान विधि जोड़ें।",
  notificationGoToBilling: "बिलिंग > पर जाएं",
  notificationWeAreSureYouHaveALotToSay:
    "हमें यकीन है कि आपके पास अपने बारे में कहने के लिए बहुत कुछ है। अपना प्रोफ़ाइल भरें और साझा करना प्रारंभ करें!",
  notificationAddAnOffer: "एक प्रस्ताव जोड़ें",
  notificationAddYourOffers: "अपने ऑफ़र जोड़ें",
  notificationSetupVariousKindsOfSessions:
    "अपने ग्राहकों के लिए विभिन्न प्रकार के सत्र निर्धारित करें, जो आपकी प्रोफ़ाइल पर बुकिंग के लिए उपलब्ध होंगे।",
  notificationGoToMyOffers: "ऑफ़र > पर जाएं",
  notificationConnectStripe: "सेटअप भुगतान",
  notificationSetupPayouts: "पेआउट ऑनबोर्डिंग",
  notificationInOrderToReceiveMoney:
    "अपने ग्राहकों से धन प्राप्त करने के लिए, आपको भुगतान विधि बनानी होगी।",
  notificationGoToPayouts: "भुगतान> पर जाएं",
  notificationSetYourSchedule: "अपना शेड्यूल सेट करें",
  notificationWorkAtConvenientHours: "सुविधाजनक समय पर काम करें",
  notificationSetupSchedule:
    "एक शेड्यूल सेट करें और आपके ग्राहक केवल उसी के अनुसार सत्र बुक कर पाएंगे।",
  accountOn: "पर खाता",
  accountOff: "खाता बंद",
  cancelSession: "सत्र रद्द करें",
  paymentFailed: "भुगतान विफल रही",
  paymentFailedDescription:
    "आपका भुगतान पूरा नहीं हुआ है और हम नए सत्र का अनुरोध करने में असमर्थ हैं।",
  areYouSureCancel: "क्या आप वाकई इस सत्र को रद्द करना चाहते हैं?",
  areYouSureCancelPaidSession:
    "क्या आप वाकई इसे रद्द करना चाहते हैं? आपका भुगतान वापस कर दिया जाएगा।",
  areYouSureCancelLessThanADay:
    "क्या आप वाकई 24 घंटे से कम समय के साथ रद्द करना चाहते हैं? आपका भुगतान वापस नहीं किया जाएगा।",
  link: "संपर्क",
  view: "देखना",
  requires_action: "कार्रवाई की आवश्यकता है",
  succeeded: "सफल हुए",
  signUpTitle: "साइन अप करें",
  profileTitleNotify: "प्रोफ़ाइल | Waylight",
  dashboardTitleNotify: "डैशबोर्ड | Waylight",
  chatTitleNotify: "चैट | Waylight",
  with: "साथ",
  expertsTitle: "विशेषज्ञों",
  expertsTitleNotify: "एक्सप्लोर | Waylight",
  signUpTitleNotify: "साइन अप | Waylight",
  chatTitle: "बात करना",
  clientsTitle: "ग्राहकों",
  clientsTitleNotify: "ग्राहक | Waylight",
  clientTitle: "ग्राहक",
  fixedPrice: "एक ही दाम",
  internetConnectionLost: "इंटरनेट कनेक्शन टूट गया",
  fiveMinutesLeft: "5 मिनट बचे हैं",
  summarizeMeeting: "कृपया अपनी बैठक का सारांश दें और अपने इंप्रेशन साझा करें",
  oopsConnectionLost: "ओह... पार्टी कनेक्शन टूट गया",
  partyTemporaryLostConnection:
    "ऐसा लगता है कि आपकी पार्टी का मीटिंग से कनेक्शन अस्थायी रूप से टूट गया है। असुविधा के लिए खेद है।",
  leaveAReviewFor: "के लिए एक समीक्षा छोड़ दें",
  leaveAReview: "समीक्षा लिखें",
  review: "समीक्षा",
  followers: "समर्थक",
  rating: "रेटिंग",
  hitStarRate: "सत्र को नीचे रेट करें!",
  reviewsEmpty: "कोई समीक्षा के लिए अभी तक कर रहे हैं",
  reviews: "समीक्षा",
  sessionEnded: "सत्र सफलतापूर्वक समाप्त हुआ",
  thankYouForLighting: "किसी का मार्ग प्रशस्त करने के लिए धन्यवाद!",
  sessionNotStarted: "सत्र शुरू नहीं हुआ है",
  devicePermissionTittle:
    "Waylight को अपना माइक्रोफ़ोन और कैमरा एक्सेस करने दें। अगर आपके पास वीडियो नहीं है तो पेज को रिफ्रेश करें।",
  networkConnection: "आपके इंटरनेट कनेक्शन की गुणवत्ता है",
  unknown: "अनजान",
  low: "कम",
  average: "औसत",
  good: "अच्छा",
  expertDidNotCome:
    "दुर्भाग्य से, आपका विशेषज्ञ नहीं दिखा। आपसे शुल्क नहीं लिया जाएगा और धनवापसी प्राप्त होगी। असुविधा के लिए हमें खेद है।",
  noFilesAdded: "कोई फाइल नहीं जोड़ी गई।",
  enterYourEmail: "अपना ईमेल पता दर्ज करें",
  required: "क्षेत्र की आवश्यकता है",
  emailOrPassword: "गलत ई - मेल और पासवर्ड",
  emailInUse: "यह ईमेल पहले से प्रयोग में है",
  businessIsNotAvailableTitle:
    "Waylight Business आपके देश में उपलब्ध नहीं है। यह कब लॉन्च होगा, यह जानने वाले पहले व्यक्ति बनें।",
  beInTouch: "संपर्क में रहें",
  businessIsNotAvailableEmailAddress: "ईमेल पता",
  pleaseTryAgain:
    "हम आपका ईमेल पता सहेजने में असमर्थ रहे। कृपया पुन: प्रयास करें।",
  thankYou: "धन्यवाद, जैसे ही यह रोल आउट होगा हम संपर्क में रहेंगे।",
  wereNoChatMessages: "इस सत्र में कोई चैट संदेश नहीं थे",
  yourCountry: "आपके बैंक का देश",
  open: "खुला",
  offerName: "प्रस्ताव का नाम",
  gatewayCountryWarning:
    "चेतावनी: यह आपके बैंक संस्थान का देश होना चाहिए और इसे बाद में बदला नहीं जा सकता, जो पेआउट प्राप्त करने की आपकी क्षमता को प्रभावित कर सकता है।",
  addAttachment: "संलग्न करें",
  you: "आप",
  explore: "खोजना",
  stats: "आँकड़े",
  more: "और देखें",
  noPrice: "मुफ्त ऑफर",
  theyWillAppear: "जब आप इंटरैक्ट करना शुरू करेंगे तो वे यहां दिखाई देंगे",
  noExpertFoundUsingFilter:
    "इस फ़िल्टर का उपयोग करने वाला कोई विशेषज्ञ नहीं मिला।",
  tryAnotherInput: "कोई दूसरा टैग या इनपुट आज़माएं.",
  noFavoriteExpertsFound: "कोई पसंदीदा विशेषज्ञ नहीं मिला।",
  tryAddingSomeone: "किसी को पसंदीदा में जोड़ने का प्रयास करें।",
  agoraDeniedError:
    "Waylight का उपयोग करने के लिए कृपया सेटिंग में माइक्रोफ़ोन और कैमरे तक अपनी पहुंच सक्षम करें!",
  editCover: "कवर संपादित करें",
  language: "भाषा",
  invalid: "अमान्य",
  scrollPastHistory: "ऊपर अपने पिछले सत्र के इतिहास में स्क्रॉल करें",
  earnedFromLastSession: "पिछले सत्र से अर्जित किया",
  typeHere: "यहाँ टाइप करें",
  schedule: "अनुसूची",
  offers: "ऑफर",
  qrSharing: "अपना क्यूआर कोड डाउनलोड करें और साझा करें।",
  qrSharingMobile:
    "कॉपी करने और साझा करने के लिए क्यूआर कोड पर लंबे समय तक दबाएं।",
  preview: "पूर्वावलोकन",
  back: "पीछे",
  love: "Waylight द्वारा प्यार से बनाया गया",
  allRightsReserved: "© 2023 Waylight। सर्वाधिकार सुरक्षित।",
  allRightsReservedSecond: "© 2022 Arceon Consulting OÜ। सर्वाधिकार सुरक्षित",
  termsOfService: "सेवा की शर्तें",
  privacyPolicy: "गोपनीयता नीति",
  sessionSharingpopupTitle: "स्क्रीन साझाकरण प्रारंभ नहीं कर सकता!",
  sessionSharingpopupDescription:
    "जांचें कि क्या आपके पास अपने ब्राउज़र का नवीनतम संस्करण है और यदि उसके पास स्क्रीन साझा करने के लिए सिस्टम अनुमतियां हैं। अंत में, आप पृष्ठ को ताज़ा करने का प्रयास कर सकते हैं। यदि समस्या बनी रहती है, तो कृपया समर्थन से संपर्क करें!",
  sessionSharingpopupSafariDescription:
    "आपके ब्राउज़र में स्क्रीन शेयरिंग समर्थित नहीं है। लोकप्रिय ब्राउज़रों के नवीनतम संस्करण इस फ़ंक्शन का समर्थन करते हैं।",
  copy: "प्रतिलिपि",
  expertWithoutOffers: "इस विशेषज्ञ ने अभी तक कोई सशुल्क ऑफ़र नहीं जोड़ा है।",
  errorViewHeader: "ओह! कुछ गलत हो गया है!",
  errorViewDescription:
    "आपको एक त्रुटि का सामना करना पड़ा है। यह लिंक टूटा हुआ हो सकता है, या आपके पास किसी आइटम तक पहुंच नहीं है।",
  returnToHome: "घर लौटना",
  invalidURL:
    "असामान्य यूआरएल। सुनिश्चित करें कि यह 'https://' या 'http://' से शुरू होता है '",
  invalidVideoExternalURL:
    "अमान्य वीडियो URL. कोई Youtube या Vimeo लिंक जोड़ें।",
  repeatedURL: "यह लिंक पहले ही जोड़ा जा चुका है",
  tooLongUrl:
    "यह लिंक बहुत लंबा है। कृपया, 128 वर्णों से अधिक लंबे लिंक का उपयोग न करें।",
  edited: "संपादित",
  emptyCards: "आपने अभी तक कोई कार्ड नहीं जोड़ा है",
  emptyBillingHistory: "आपने अभी तक कोई लेन-देन नहीं किया है",
  emptyBillingHistoryDescription:
    "जब आप बुकिंग सत्र प्रारंभ करेंगे तो वे यहां दिखाई देंगे",
  addNewSkill: "नया कौशल जोड़ें",
  confirmationAddSkill: "क्या आप वाकई नया कौशल जोड़ना चाहते हैं?",
  addPaymentMethod: "भुगतान विधि जोड़ें",
  stripeDescription: "50+ देशों के विशेषज्ञों के साथ लेन-देन के लिए",
  fondyDescription: "यूक्रेन के विशेषज्ञों के साथ लेनदेन के लिए",
  fondyAddCardDescription:
    "आगे बढ़ने के लिए भुगतान विधि जोड़ें। एक परीक्षण शुल्क की पुष्टि करने का प्रयास किया जाएगा।",
  timeZoneChangedHeader: "समयक्षेत्र बदल गया",
  timeZoneSChangedDescription:
    "हमें पता चला है कि आपका समयक्षेत्र बदल गया है। क्या आप अपना प्रोफ़ाइल समयक्षेत्र अपडेट करना चाहेंगे?",
  updateButton: "अपडेट करना",
  and: "और",
  moreFiles: "अधिक फ़ाइलें",
  addPayout: "पेआउट प्राप्त करने के लिए कम से कम एक विधि जोड़ें",
  testChargeWarning: "एक परीक्षण शुल्क की पुष्टि करने का प्रयास किया जाएगा।",
  payoutMethods: "आपके भुगतान के तरीके",
  identifier: "पहचानकर्ता",
  stopWar: "युद्ध बंद करो",
  yourAreOnlyOneHere: "आप यहाँ अकेले हैं",
  yourAreOnlyOneHereSingle:
    "आप यहाँ अकेले हैं। दूसरों के शामिल होने के लिए नीचे लिंक साझा करें",
  clientView: "ग्राहक",
  businessView: "व्यवसाय",
  mainPage: "मुख्य पृष्ठ",
  signUpCompleted: "साइन अप पूरा हुआ",
  selectPaymentInfo:
    "उपलब्ध भुगतान विधियां आपके विशेषज्ञ के गेटवे पर निर्भर करती हैं",
  payment: "भुगतान",
  paymentWasDeclined: "भुगतान अस्वीकार कर दिया गया था।",
  payoutWasDeclined: "भुगतान अस्वीकार कर दिया गया था।",
  primary: "प्राथमिक",
  notUsed: "उपयोग नहीं किया",
  releaseBannerTitle: "Waylight को एक नए संस्करण में अपडेट किया गया है।",
  refresh: "ताज़ा करना",
  country: "देश",
  viewAll: "सभी देखें",
  videoPresentation: "वीडियो प्रस्तुति",
  bookFor: "के लिए बुक करें",
  bookForFree: "निःशुल्क बुक करें",
  follow: "का पालन करें",
  followed: "पीछा किया",
  emptyPortfolio: "ऐसा लगता है कि इस पोर्टफ़ोलियो में कोई फ़ाइल नहीं है",
  media: "मीडिया",
  other: "फ़ाइलें",
  emptyReview: "ऐसा लगता है कि कोई समीक्षा नहीं है",
  newest: "नवीनतम",
  oldest: "सबसे पुराना",
  viewProfileInfo: "प्रोफ़ाइल जानकारी देखें",
  emptyTransactions: "ऐसा लगता है कि कोई लेन-देन नहीं है",
  emptyOffers: "दुर्भाग्य से, इस विशेषज्ञ के पास अभी तक कोई प्रस्ताव नहीं है।",
  searchExperts: "खोज विशेषज्ञ",
  modifySchedule: "शेड्यूल संशोधित करें",
  editMode: "संपादन मोड",
  finishEditing: "संपादन समाप्त करें",
  editPersonalInfo: "व्यक्तिगत जानकारी संपादित करें",
  editPortfolio: "पोर्टफोलियो संपादित करें",
  editOffers: "ऑफ़र संपादित करें",
  profileId: "व्यक्तिगत सार्वजनिक आईडी",
  profileSettings: "पार्श्वचित्र समायोजन",
  editPublicID: "सार्वजनिक आईडी संपादित करें",
  removeTimeslot: "टाइमस्लॉट हटाएं",
  areYouSureRemoveTimeslot: "क्या आप वाकई टाइमस्लॉट हटाना चाहते हैं?",
  qrCode: "क्यू आर संहिता",
  communication: "संचार",
  editCoverDescription: "अपनी कवर छवि को अनुकूलित करें",
  editProfileData: "प्रोफ़ाइल डेटा संपादित करें",
  editProfileDataDescription:
    "अपने और अपनी पृष्ठभूमि के बारे में जानकारी जोड़ें",
  editPublicIDDescription:
    "अपनी प्रोफ़ाइल के लिए एक अद्वितीय सार्वजनिक आईडी चुनें",
  profilePhoto: "प्रोफ़ाइल तस्वीर",
  publicIDDescription: "यह एक ऑटो-जनरेटेड आईडी है!",
  publicIDWarning:
    "संपादन मोड में इसे 'सुपरगर्ल' जैसे कुछ कूल में बदलना न भूलें।",
  followWarning: "'अनुसरण करें' बटन सभी उपयोगकर्ताओं के लिए काम करेगा।",
  openChatWarning:
    "आप अपने आप से चैट नहीं कर पाएंगे, लेकिन आपके ग्राहक करेंगे।",
  bookingOfferWarning: "उपयोगकर्ता यहां क्लिक करके एक प्रस्ताव खरीद सकते हैं।",
  shareOffer: "लिंक की प्रतिलिपि करें",
  turnOffAccount: "खाता बंद करें",
  turnOnAccount: "खाता चालू करें",
  areYouSureYouWantTurnOff:
    "क्या आप वाकई अपना खाता पूरी तरह से बंद करना चाहते हैं?",
  areYouSureYouWantTurnOn: "क्या आप वाकई अपना खाता वापस चालू करना चाहते हैं?",
  disableFreeChat: "निःशुल्क चैट अक्षम करें",
  enableFreeChat: "निःशुल्क चैट सक्षम करें",
  areYouSureYOuWantDisableFreeChat:
    "क्या आप वाकई निःशुल्क चैट अक्षम करना चाहते हैं?",
  areYouSureYOuWantEnableFreeChat:
    "क्या आप वाकई निःशुल्क चैट सक्षम करना चाहते हैं?",
  cancelEditing: "संपादन रद्द करें",
  descriptionCancelEditing: "क्या आप वाकई संपादन रद्द करना चाहते हैं?",
  takenPublicID: "यह सार्वजनिक आईडी पहले ही ली जा चुकी है",
  charactersMatch: "2-64 अक्षर",
  charactersBetweenMatch: "फ़ील्ड में 1 से 64 वर्ण होने चाहिए",
  charactersMaxMatch: "अधिकतम फ़ील्ड लंबाई 64 वर्ण है",
  deletePhoto: "फोटो हटाएं",
  descriptionDeletePhoto: "क्या आप वाकई अपनी तस्वीर हटाना चाहते हैं?",
  accountOnDescription:
    "जब खाता बंद हो जाता है, तो यह उपलब्ध नहीं होता है और किसी भी तरह से दिखाई नहीं देता है (प्रत्यक्ष लिंक, खोज परिणाम आदि)",
  freechat: "मुक्त चैट",
  freechatDescription:
    "जब मुफ़्त चैट अक्षम हो जाती है, तो उपयोगकर्ता इस माध्यम से आपके साथ संचार प्रारंभ नहीं कर पाएंगे। हालाँकि, आप अपने ग्राहकों के साथ ऐसा करने में सक्षम होंगे।",
  freeChatWarning:
    "इस खाते ने निःशुल्क चैट अक्षम कर दी है। आप संवाद करने के लिए एक सत्र का अनुरोध कर सकते हैं।",
  editMessage: "संदेश संपादित करें",
  replyTo: "को उत्तर",
  file: "फ़ाइल",
  unableToJoinSession: "सत्र में शामिल होने में असमर्थ",
  backgroundWarning:
    "चेतावनी: सफ़ारी और फ़ायरफ़ॉक्स ब्राउज़र पर पृष्ठभूमि पूरी तरह से समर्थित नहीं है और काम नहीं कर सकती है।",
  single: "शीघ्र",
  scheduleSession: "किसी विशेषज्ञ या क्रिएटर के साथ सेशन शेड्यूल करें.",
  startSessionImmediately:
    "फ्री सेशन तुरंत शुरू करें। दूसरों को सीधे लिंक के साथ आमंत्रित करें।",
  quickSessionWarningTittle: "क्या आप वाकई सत्र प्रारंभ करना चाहते हैं?",
  quickSessionWarningDescription:
    "आप जिस त्वरित सत्र को शुरू करने का प्रयास कर रहे हैं, उसके साथ आपका एक निर्धारित सत्र ओवरलैप हो रहा है।",
  connectIn: "में कनेक्ट करें",
  connectOn: "कनेक्ट करें",
  sessionDecisionTittle: "आपकी पार्टी नहीं आई है।",
  expertSessionDecision:
    "क्या आप सत्र समाप्त करना चाहते हैं या अधिक प्रतीक्षा करना चाहते हैं? सत्र समाप्त होने पर आपके खाते में भुगतान स्थानांतरित हो जाएगा।",
  clientSessionDecision:
    "क्या आप सत्र समाप्त करना चाहते हैं या अधिक प्रतीक्षा करना चाहते हैं? सत्र समाप्त होने पर आपका भुगतान वापस कर दिया जाएगा।",
  cancelledSessionTittle: "यह सत्र रद्द कर दिया गया है",
  cancelledSessionExpertDescription:
    "भुगतान आपके खाते में स्थानांतरित कर दिया गया है। असुविधा के लिए हमें खेद है।",
  cancelledSessionClientDescription:
    "आपसे शुल्क नहीं लिया जाएगा और धनवापसी प्राप्त होगी। असुविधा के लिए हमें खेद है।",
  areYouSureYouWantLeaveSession: "क्या आप वाकई सत्र छोड़ना चाहते हैं?",
  areYouSureYouWantLeaveSessionDescription:
    "सत्र समाप्त होने तक आप सत्र में वापस आ सकेंगे।",
  addExtraMinutes: "अतिरिक्त 10 मिनट जोड़ें",
  addExtraMinutesDescription:
    "क्या आप वाकई इस सत्र में अतिरिक्त 10 मिनट जोड़ना चाहते हैं?",
  unableAddExtraMinutes: "10 मिनट और नहीं जोड़े जा सके",
  unableAddExtraMinutesDescription:
    "दुर्भाग्य से, आपका एक निर्धारित सत्र 10 मिनट से भी कम समय में आ रहा है।",
  monday: "सोमवार",
  tuesday: "मंगलवार",
  wednesday: "बुधवार",
  thursday: "गुरुवार",
  friday: "शुक्रवार",
  saturday: "शनिवार",
  sunday: "रविवार",
  h: "एच",
  sharingSetting: "साझाकरण सेटिंग",
  call: "बुलाना",
  public: "जनता",
  byLinkOnly: "लिंक द्वारा ही",
  offerInfo: "ऑफर की जानकारी",
  coverVideo: "कवर / वीडियो",
  imageVideo: "छवि / वीडियो",
  chooseCountry: "देश चुनें",
  passwordChangedSuccessfully: "पासवर्ड सफलतापूर्वक बदला गया",
  connected: "जुड़े हुए",
  passedALot: "उत्तीर्ण",
  chooseCountryBank: "अपने बैंक या वित्तीय सेवा का देश चुनें।",
  createAccountContinue: "जारी रखने के लिए खाता बनाएँ",
  metasignInTitle: "साइन-इन | Waylight",
  metasignUpTitle: "साइन-अप | Waylight",
  metapasswordForgotTitle: "पासवर्ड भूल गए | Waylight",
  metapasswordResetTitle: "पासवर्ड रीसेट करें | Waylight",
  clientflowStep1Title: "बायो या Waylight सर्च में लिंक ढूंढें",
  clientflowStep1:
    "सोशल नेटवर्क्स (Instagram, Facebook, YouTube, LinkedIn, आदि) में अपने निर्माता, प्रभावित करने वाले, विशेषज्ञ, या ट्यूटर का Waylight लिंक ढूंढें या Waylight सर्च में नाम या उपनाम टाइप करें।",
  clientflowStep2Title:
    "वीडियो कॉल बुक करें या ब्लॉग या चैट के लिए सदस्यता लें",
  clientflowStep2:
    "अपने निर्माता, प्रभावित करने वाले, विशेषज्ञ या ट्यूटर की व्यावसायिक प्रोफ़ाइल खोलें और एक्सप्लोर करें। 1:1 वीडियो कॉल के लिए एक दिलचस्प प्रस्ताव खोजें, इसे बुक करें और निर्धारित समय पर इस पर कूदें। लंबी अवधि में अधिक जानकारी प्राप्त करने के लिए पहले या बाद में चैट करने का प्रयास करें। ट्यूटोरियल, लेख, वीडियो सलाह, बैकस्टेज, व्यक्तिगत जीवन के अनुभव, पाठ, संगीत आदि के साथ केवल-व्यक्तिगत सदस्य ब्लॉग की सदस्यता लें।",
  clientflowStep3Title: "विकेंद्रीकृत रेटिंग और समीक्षाओं की जाँच करें",
  clientflowStep3:
    "विकेंद्रीकृत रेटिंग रचनाकारों और विशेषज्ञों के लिए एक ट्रैक रिकॉर्ड बनाती है, जिसे बेचा या नकली नहीं बनाया जा सकता है और ग्राहकों और ग्राहकों के लिए विश्वास, पारदर्शिता, विशेषज्ञता के प्रमाण और सामग्री की मांग का निर्माण करती है। वीडियो कॉल, चैट और ब्लॉग में केवल-सदस्य पोस्ट के तहत अपनी वास्तविक रेटिंग दें। Waylight समुदाय को सबसे अच्छी समीक्षाओं को दिखाने में मदद करने के लिए अपनी समीक्षाएं साझा करें।",
  confirmBooking: "चयन की पुष्टि करें",
  free: "नि: शुल्क",
  unableVerifyEmail: "हम आपका ईमेल सत्यापित करने में असमर्थ थे।",
  thankYouForVerifiedEmail: "अपना ईमेल सत्यापित करने के लिए धन्यवाद!",
  reSend: "पुन: भेजें",
  signInToContinue: "जारी रखने के लिए साइन इन करें",
  switchingToClientView: "क्लाइंट व्यू पर स्विच करना",
  switchingToExpertView: "व्यावसायिक दृश्य पर स्विच करना",
  cookiePermission:
    "हम आपकी गोपनीयता की सराहना करते हैं। हम आपके ब्राउज़िंग अनुभव को बढ़ाने, वैयक्तिकृत सामग्री परोसने और अपने ट्रैफ़िक का विश्लेषण करने के लिए कुकीज़ का उपयोग करते हैं। 'स्वीकार करें' पर क्लिक करके, आप कुकीज़ के हमारे उपयोग के लिए सहमति देते हैं।",
  readMore: "अधिक पढ़ें",
  notReadableDevicesWarning:
    "चेतावनी: आपका कैमरा और/या माइक्रोफ़ोन या तो वर्तमान में उपयोग में है या उस तक पहुँचा नहीं जा सकता!",
  howPayoutsWork: "पेआउट कैसे काम करते हैं?",
  payoutsOnboarding1: "पेआउट सक्षम करने के लिए ऑनबोर्डिंग पूर्ण करें।",
  payoutsOnboarding2:
    "जब तक आप जहाज पर नहीं चढ़ते, हम आपके पैसे को सुरक्षित रखते हैं।",
  payoutsOnboarding3:
    "आपको अपने देश के आधार पर अपने बैंक विवरण या कार्ड विवरण की आवश्यकता होगी।",
  payoutsOnboarding4: "भुगतान प्रत्येक सोमवार को होता है।",
  payoutsOnboarding5: "आप लेन-देन में पेआउट स्थिति की जांच कर सकते हैं।",
  payoutsOnboarding6: "पेमेंट गेटवे शुल्क 0.25% + $0.25 प्रति पेआउट।",
  payoutsOnboardingEcommpay6:
    "भुगतान गेटवे कार्डों के लिए 0.5% और व्यापारियों के लिए 0% शुल्क लेता है यदि क्लाइंट शुल्क सक्षम हैं।",
  payoutsOnboarding7:
    "यदि आप पहली बार पेआउट प्राप्त कर रहे हैं, तो गेटवे की सीमाओं के कारण इसे आने में 7-14 कार्यदिवस लग सकते हैं।",
  payoutsOnboarding8: "आम तौर पर भुगतान आने में लगभग 3 कार्यदिवस लगते हैं।",
  payoutsOnboarding9:
    "वर्तमान में देश को बदलना संभव नहीं है। यदि आपको इसकी आवश्यकता है तो हमसे संपर्क करें और हम मामले के आधार पर समाधान करने का प्रयास करेंगे।",
  payoutsOnboarding10: "Waylight आपकी योजना के आधार पर चर्ज चालू करता है।",
  payoutsOnboarding11: "यदि आपके पास कोई सवाल हो तो संपर्क करें",
  supportAlternative: "सहारा",
  freeSessionDecision:
    "क्या आप सत्र समाप्त करना चाहते हैं या अधिक प्रतीक्षा करना चाहते हैं?",
  cancelledFreeSessionDescription:
    "आप ही हैं जो दिखाई दिए। असुविधा के लिए हमें खेद है।",
  m: "m",
  cityNotFound: "कोई विकल्प नहीं मिला। निकटतम बड़ा शहर चुनें।",
  notifications: "सूचनाएं",
  telegramBot: "टेलीग्राम बॉट",
  notConnected: "कनेक्टेड नहीं",
  connectTelegramBot: "कनेक्ट टेलीग्राम बॉट",
  disconnectTelegramBot: "टेलीग्राम बॉट डिस्कनेक्ट करें",
  telegramConnected: "कनेक्टेड",
  bgSettingsDescription:
    "अपने वीडियो स्ट्रीम के लिए पृष्ठभूमि समायोजित करने के लिए इन सेटिंग्स का उपयोग करें",
  selectBackground: "पृष्ठभूमि का चयन करें",
  bookingDelay: "बुकिंग विलंब सेट करें",
  bookingDelayDescription:
    "निर्दिष्ट करें कि आपको कितना अग्रिम बुक किया जा सकता है.",
  processingFee: "भुगतान गेटवे शुल्क",
  payoutsOnboardingEcommpay1:
    "व्यक्तिगत क्रेडिट कार्ड या निजी उद्यमिता आउटपुट विधि का चयन करें।",
  payoutsOnboardingEcommpay3:
    "आपको कम से कम एक व्यक्तिगत क्रेडिट कार्ड + अपना व्यक्तिगत कर कोड जोड़ना होगा या फिर लेनदेन काम करने के लिए फोंडी व्यापारी ऑनबोर्डिंग पूरा करना होगा!",
  payoutsOnboardingEcommpay4: "वित्तीय लेनदेन के बाद भुगतान होते हैं।",
  payoutsOnboardingEcommpay7: "भुगतान अगले व्यापारिक दिन से पहले होते हैं।",
  tutorials: "उत्पाद",
  tutorialsAndCourses: "उत्पाद खरीदें",
  noOffers: "लगता है यहां अब तक कोई प्रस्तावना नहीं है",
  newSessionOffer: "नई सत्र प्रस्तावना",
  newSessionOfferDescription: "एक भुगतान करने या मुफ्त वीडियो सत्र बनाएं",
  newTutorialOffer: "नई उत्पाद प्रस्तावना",
  newTutorialOfferDescription: "एक भुगतान करने या मुफ्त उत्पाद बनाएं",
  sessionName: "सत्र का नाम",
  tutorialName: "उत्पाद का नाम",
  tutorialNameDescription:
    "अपने उत्पाद के लिए एक नाम चुनें, जैसे “नृत्य कक्षा #1”",
  cover: "कवर",
  tutorialCoverTooltip:
    "उत्पाद विवरण में प्रदर्शित करने के लिए एक छवि या वीडियो अपलोड करें।",
  getTutorialPaid: "इस उत्पाद की हर खरीद पर आपको भुगतान किया जाएगा।",
  unlockedAfterPurchase: "खरीदी के बाद अनलॉक किया जाएगा",
  unlockedAfterPurchaseDescription:
    "उन्हें अनलॉक करने के लिए अपनी ग्राहकों को सामग्री जोड़ें।",
  tutorialDetailsDescription: "यहां टिप्पणियाँ, विवरण या निर्देश जोड़ें",
  removeSection: "खंड हटाएं",
  addAnotherSection: "एक और खंड जोड़ें",
  details: "विवरण",
  toastEmptyUnlockedContent: "अनलॉक किया गया सामग्री खाली नहीं हो सकता",
  toastTutorialNotEdited: "इस उत्पाद का संपादन नहीं किया गया है",
  removeSectionDescription:
    "क्या आप इस खंड को हटाने के लिए पूर्वनिर्धारित हैं?",
  buyFor: "के लिए खरीदें",
  buttonOpenChatAlt: "चैट खोलें",
  buttonShare: "साझा करें",
  tutorial: "उत्पाद",
  toastBuyingYourOwnTutorial: "आप अपना खुद का उत्पाद नहीं खरीद सकते हैं!",
  purchasingInProgress: "लेन-देन प्रक्रिया की प्रगति",
  byClickingConfirm: "पर क्लिक करके",
  iAgreeWith: "मैं सहमत हूँ",
  allowWaylightToCharge:
    "और Waylight को मेरे कार्ड से अब और भविष्य में सदस्यता की शर्तों के अनुसार पैसे वसूलने की अनुमति दें, जब तक मैं नहीं रद्द करता।",
  paymentProcessingFees: "भुगतान प्रसंस्करण शुल्क लग सकता है।",
  bookingTutorialWarning: "उपयोगकर्ता इस जगह क्लिक करके एक उत्पाद खरीद सकेंगे।",
  editTutorials: "उत्पाद संपादित करें",
  topReview: "शीर्ष समीक्षा",
  addForFree: "मुफ्त में जोड़ें",
  tutorialPaymentFailed:
    "आपका भुगतान नहीं हुआ और आप इस उत्पाद को नहीं खरीद सके।",
  buy: "खरीदें",
  tutorialWith: "के द्वारा उत्पाद",
  purchased: "खरीदा गया",
  noTutorialsFound: "कोई उत्पाद नहीं मिला",
  noTutorialsFoundDescription:
    "जब आप उत्पाद खरीदते हैं या जोड़ते हैं, तो वे यहां दिखाए जाएंगे",
  tags: "टैग",
  createSessionOffer: "सत्र प्रस्तावना बनाएं",
  createSessionOfferDescription: "भुगतान करने या मुफ्त वीडियो सत्र जोड़ें।",
  createTutorial: "उत्पाद प्रस्तावना बनाएं",
  createTutorialDescription: "भुगतान करने या मुफ्त उत्पाद जोड़ें।",
  requires_transfer: "प्रशिक्षण की आवश्यकता है",
  pending: "लेन-देन बेकार में है",
  requires_payout: "लेन-देन बेकार में है",
  requires_capture: "ध्यान में",
  reschedule: "फिर से नियोजित करें",
  rescheduleDelay: "पुनर्नियोजन देर सेट करें",
  rescheduleDelayDescription:
    "सत्र की पुनर्नियोजन कितने दिन पहले की जा सकती है, यह स्पष्ट करें।",
  rescheduleSession: "सत्र को पुनर्नियोजित करें",
  rescheduleSessionDescription:
    "क्या आप वाकई सत्र को पुनर्नियोजित करना चाहते हैं?",
  buttonChange: "पुनर्नियोजित करें",
  timeslots: "समय स्लॉट",
  specifyReason: "तारीख और समय बदलने के कारण को स्पष्ट करें।",
  cannotRescheduleSession: "सत्र को पुनर्नियोजित नहीं किया जा सकता",
  rescheduleReason: "पुनर्नियोजन का कारण",
  optionLink: "एक बाह्य लिंक जोड़ें",
  optionUpload: "या एक फ़ाइल अपलोड करें",
  tutorialLinkDescription: "अपने उत्पाद के लिए लिंक जोड़ें।",
  tutorialUploadDescription:
    "यहां किसी भी फ़ाइल को अपलोड करें। आप प्रत्येक खंड में एक फ़ाइल जोड़ सकते हैं।",
  bgNone: "कोई नहीं",
  bgLowBlur: "कम धुंधलाप",
  bgMediumBlur: "मध्यम धुंधलाप",
  bgHighBlur: "उच्च धुंधलाप",
  bgSolidColour: "ठोस रंग",
  bgCustomImage: "कस्टम छवि",
  telegramNotifications: "टेलीग्राम सूचनाएँ",
  connectYourTelegramAccount:
    "अपना टेलीग्राम खाता जोड़ें ताकि Waylight से सूचनाएँ प्राप्त कर सकें।",
  buttonConnectTelegram: "टेलीग्राम कनेक्ट करें",
  notificationGoToMyProfile: "मेरे प्रोफ़ाइल पर जाएं",
  setupSchedule: "समय सारणी सेटअप",
  aboutTheTutorial: "उत्पाद के बारे में",
  added: "जोड़ा गया",
  orderingChangeInProgress: "क्रम परिवर्तन जारी है...",
  gloryToUkraine: "यूक्रेन को श्रेष्ठि!",
  terroristStates:
    "Waylight उन देशों के उपयोगकर्ताओं के लिए काम नहीं करता है जिनमें निरंकुश और आतंकी शासन है: रूस, ईरान, उत्तर कोरिया, सीरिया, क्यूबा, वेनेजुएला, अफगानिस्तान।",
  never: "कोई सीमा नहीं",
  expiration: "समय सीमा",
  expirationDescription:
    "ग्राहकों को इस उत्पाद का उपयोग करने के लिए कुछ दिनों के लिए पहुँच होगी",
  daysLeft: "बचे हुए दिन",
  tutorialExpired: "आपका इस उत्पाद तक पहुँचना समाप्त हो गया है।",
  days: "दिन",
  tips: "टिप्स",
  newTipOffer: "नई टिप्स ऑफर",
  newTipOfferDescription: "टिप्स बनाएं",
  tipName: "टिप्स का नाम",
  tipNameDescription: "अपनी टिप्स के लिए एक नाम चुनें",
  tipCoverTooltip:
    "टिप्स की विवरण में दिखाए जाने वाली एक छवि या वीडियो अपलोड करें।",
  aboutTheTip: "टिप्स के बारे में",
  describeYourTip:
    "टिप्स का विवरण जोड़ें: दान जुटाना, एक चैरिटी चलाना या कॉफ़ी ख़रीदना?",
  tipAmounts: "तीन टिप्स राशियाँ जोड़ें",
  tipAmountsDescription:
    "अगर वह चाहें, तो आपके ग्राहक अपनी खुद की राशि डाल सकेंगे।",
  bonusTipper: "(वैकल्पिक) ग्राहक को बोनस भेजें",
  bonusTipperDescription:
    "टिप्स करने के बाद आपके ग्राहकों को एक बोनस के रूप में सामग्री या संदेश जोड़ें।",
  tipLinkDescription: "अपने बोनस के लिए लिंक जोड़ें।",
  tipMessageDescription: "यहां अपना पाठ संदेश जोड़ें।",
  toastTipNotEdited: "यह टिप्स संपादित नहीं की गई है",
  leaveATip: "टिप्स छोड़ें",
  bookingTipWarning: "उपयोगकर्ता यहां क्लिक करके टिप्स छोड़ सकते हैं।",
  includesAReward: "एक बोनस शामिल है",
  selectTip: "आप कितनी टिप्स देना चाहेंगे, चुनें",
  selectTipAmount: "टिप्स के लिए एक राशि का चयन करें",
  toastBuyingYourOwnTip: "आप अपनी खुद की टिप्स नहीं खरीद सकते!",
  tipSuccessfullyBought: "टिप्स सफलतापूर्वक खरीदी गई",
  tip: "टिप्स",
  tipPaymentFailed:
    "आपका भुगतान संपन्न नहीं हुआ और आप इस टिप्स को खरीदने में असमर्थ रहे।",
  purchasedOffers: "खरीदें",
  noTipsFound: "कोई टिप्स नहीं मिली",
  noTipsFoundDescription: "जब आप टिप्स खरीदेंगे तो वे यहां दिखाई देंगी",
  thankYouExclamation: "धन्यवाद!",
  editTips: "दान संपादित करें",
  tipPriceLimit: "मूल्य $2 से $10000 तक हो सकता है।",
  tipPriceLimitForUA: "मूल्य ₴83 से ₴412496 तक हो सकता है।",
  cardAuthorization: "क्रेडिट कार्ड प्राधिकरण",
  failed: "विफल",
  communityName: "समुदाय का नाम",
  communityNameDescription:
    "अपने समुदाय के लिए एक नाम चुनें, जैसे “मेरा सदस्य-केवल Telegram चैनल“",
  getCommunityPaid: "सदस्यता कीमत",
  Period: "अवधि*",
  expirationCommunityDescription: "सदस्यता अवधि सेट करें",
  aboutTheCommunityOffer: "ऑफर के बारे में",
  TelegramChannelName: "Telegram चैनल/समूह का नाम*",
  communityTelegramNameDescription:
    "अपने चैनल या समूह का नाम चुनें। अगर खाली है, तो कृपया निर्देशों के लिए जानकारी आइकन पर क्लिक करें।",
  communities: "समुदाय",
  community: "समुदाय",
  subscribeFor: "के लिए सदस्यता लें",
  subscribed: "सदस्यता ली हुई",
  unsubscribe: "सदस्यता रद्द करें",
  unsubscribeDescription:
    "क्या आप सदस्यता रद्द करना चाहते हैं?\nआपका पहुंचने का समय समाप्त हो जाएगा",
  subscribeDescription: "{{community}}।",
  usubscribed: "सदस्यता रद्द की गई",
  unlimited: "असीमित",
  subscribeAgain: "फिर से सदस्यता लें",
  unsubscribeDescriptionModal:
    "क्या आप फिर से सदस्यता लेना चाहते हैं?\nआपका अगले अवधि के लिए चार्ज किया जाएगा",
  subscribe: "सदस्यता लें",
  currentlyMember: "आप Telegram में {{title}} के सदस्य हैं।",
  notCurrentlyMember: "आप Telegram में {{title}} के सदस्य नहीं हैं।",
  newCommunityOffer: "नया समुदाय ऑफर",
  newCommunityOfferDescription: "समुदाय बनाएं या प्रबंधित करें",
  editCommunities: "समुदायों को संपादित करें",
  joinCommunities: "समुदायों में शामिल हों",
  noCommunitiesAdded: "कोई समुदाय नहीं जोड़ी गई",
  TheyWillAppear: "जब आप अपने प्रस्तावनाएँ बनाएंगे, तो यहां दिखाई देंगे।",
  createCommunity: "समुदाय बनाएं",
  noAccessToData: "डेटा तक पहुंच नहीं है! जोड़ें",
  noAccessToDataAsAnAdministrator:
    "डेटा तक पहुंच प्रदान करने के लिए अपने समूह में एक व्यवस्थापक के रूप में जोड़ें।",
  retryPayment: "भुगतान पुनः प्रयास करें",
  areYouSureYouWantToSetCard:
    "क्या आप वाकई कार्ड *{{last4}} को प्राथमिक बनाना चाहते हैं?",
  thingsToTry: "कुछ आजमाएं:",
  checkIfYouHaveSufficient:
    "जांचें कि आपके खाते में पर्याप्त धन है या इंटरनेट भुगतान सक्षम हैं।",
  selectDifferentPaymentMethod: "एक अलग भुगतान विधि का चयन करें।",
  set: "सेट करें",
  primaryCard: "प्राथमिक",
  setPrimary: "प्राथमिक बनाएं",
  inviteMember: "सदस्य को आमंत्रित करें",
  copyLink: "लिंक कॉपी करें",
  members: "सदस्य",
  cancel: "रद्द करें",
  actions: "कार्रवाइयाँ",
  subscribedUntil: "सदस्यता लागू है",
  waylightClients: "WAYLIGHT क्लाइंट",
  nameMembers: "नाम",
  admin: "व्यवस्थापक",
  external: "बाहरी",
  subscriber: "सदस्य",
  unsubscribed: "अनसब्सक्राइब्ड",
  kicked: "किक किया गया",
  owner: "मालिक",
  save: "सहेजें",
  kickAndBan: "किक और",
  areYouSureYouWantToRemove:
    "क्या आप वाकई अपने चैनल से “{{name}}” को स्थायी रूप से हटाना चाहते हैं?",
  WeHaveEncounteredAnIssue: "हमें आपके चक्रीय भुगतान में समस्या आई है!",
  yourPaymentHasNotGone:
    "आपका भुगतान सफल नहीं हुआ है और हम आपकी सदस्यता के लिए चार्ज को पुनः प्रयास नहीं कर सके।",
  gatewayFee: "क्लाइंट्स द्वारा गेटवे शुल्क",
  gatewayFeeDescription:
    "इस सेटिंग को चालू करने पर, भुगतान गेटवे शुल्क को क्लाइंट बिल में जोड़ा जाएगा। जब यह बंद हो जाएगा, शुल्क व्यापारिक भुगतान से कटेगा।",
  enableGatewayFee: "क्लाइंट गेटवे शुल्क सक्षम करें?",
  areYouSureYOuWantEnableGatewayFee:
    "क्या आप वाकई क्लाइंट गेटवे शुल्क को सक्षम करना चाहते हैं?",
  disableGatewayFee: "क्लाइंट गेटवे शुल्क अक्षम करें?",
  areYouSureYOuWantDisableGatewayFee:
    "क्या आप वाकई क्लाइंट गेटवे शुल्क को अक्षम करना चाहते हैं? शुल्क व्यापारिक भुगतान से कटेगा!",
  communityTooltip:
    "सुनिश्चित करें कि आपने Waylight बॉट को अपने समूह/चैनल में एक व्यवस्थापक के रूप में जोड़ा है, अपना टेलीग्राम खाता Waylight से कनेक्ट किया है और यह समूह/चैनल के लिए यही प्रस्ताव है!",
  communityCoverTooltip:
    "समुदाय विवरण में प्रदर्शित करने के लिए एक छवि या वीडियो अपलोड करें।",
  setCard: "सेट करें",
  expirationDateMustBeInFuture: "समाप्ति तिथि भविष्य में होनी चाहिए",
  accessGranted: "पहुँच दी गई",
  noAccess: "पहुँच नहीं है",
  manageCommunity: "समुदाय का प्रबंधन करें",
  manage: "प्रबंधित करें",
  January: "जनवरी",
  February: "फ़रवरी",
  March: "मार्च",
  April: "अप्रैल",
  May: "मई",
  June: "जून",
  July: "जुलाई",
  August: "अगस्त",
  September: "सितंबर",
  October: "अक्टूबर",
  November: "नवंबर",
  December: "दिसंबर",
  toastBuyingYourOwnCommunity: "आप अपनी खुद की समुदाय की सदस्यता नहीं ले सकते!",
  priceLimitCommunity: "मूल्य $5 से $10000 तक हो सकता है।",
  priceLimitCommunityForUA: "मूल्य ₴198 से ₴195000 तक हो सकता है।",
  toastRetryPaymentSuccess: "आपका भुगतान पुन: प्रयास सफल रहा।",
  toastCommunityLinkCopied: "समुदाय लिंक कॉपी किया गया",
  communityPaymentFailed: "समुदाय पेमेंट असफल हुआ",
  AddExpirationDateManually: "समय सीमा दिनांक स्वयं जोड़ें:",
  SetTimeFormat: "समय प्रारूप सेट करें",
  events: "घटनाएँ",
  newEventOffer: "नई घटना प्रस्तावना",
  newEventOfferDescription: "एक समयित घटना बनाएं",
  eventName: "घटना का नाम",
  eventNameDescription:
    "अपनी घटना के लिए एक नाम चुनें, जैसे “एसईओ मास्टरक्लास”",
  eventCoverTooltip: "घटना कवर टूलटिप",
  getEventPaid: "आपको इस घटना में सीटों की प्रत्येक खरीद के लिए भुगतान होता है",
  aboutTheEventOffer: "घटना के बारे में",
  dateAndTime: "तिथि और समय",
  clickToSetDateAndTime: "तिथि और समय निर्धारित करने के लिए क्लिक करें",
  DescribeYourEvent: "अपनी घटना की संक्षिप्त विवरण करें।",
  onlineEvent: "ऑनलाइन घटना",
  offlineEvent: "ऑफ़लाइन घटना",
  eventLinkDescription: "घटना की स्थान की जानकारी जैसे कि जूम या मीट जोड़ें",
  uploadOptional: "अपलोड (वैकल्पिक)",
  uploadEventDetails: "वैकल्पिक घटना विवरण के साथ कोई भी फ़ाइल यहाँ अपलोड करें",
  addInformationAboutLocation:
    "घटना के स्थान के बारे में जानकारी या मानचित्र लिंक जोड़ें",
  location: "स्थान",
  address: "पता",
  addressOptional: "पता (वैकल्पिक)",
  addAddressEvent: "घटना का पता जोड़ें",
  AllDayEvent: "यह पूरे दिन की घटना है।",
  toastBuyingYourOwnEvent: "You can not buy your own event!",
  event: "घटना",
  timeToEvent: "घटना के लिए समय",
  hoursEvent: "घंटे",
  seatsLeft: "बचे हुए सीटें",
  follower: "अनुयायी",
  priceLimitEventForUA: "मूल्य ₴198 से ₴195000 तक हो सकता है।",
  soldOut: "बिक गया!",
  joinEvents: "कार्यक्रमों में भाग लें",
  noEventFound: "कोई कार्यक्रम नहीं मिला",
  noEventsFoundDescription:
    "ये यहाँ पर दिखाए जाएंगे जब आप कम से कम एक कार्यक्रम में भाग लेंगे",
  editEvents: "कार्यक्रम संपादित करें",
  attendForfree: "मुफ़्त में भाग लें",
  disabled: "अक्षम",
  enabled: "सक्षम",
  personalCreditcard: "व्यक्तिगत (क्रेडिट कार्ड)",
  privateEntrepreneur: "निजी व्यवसायी (IBAN)",
  selectPayoutMethod: "भुगतान विधि का चयन करें",
  enterYourPersonalTaxCode: "अपना व्यक्तिगत कर कोड दर्ज करें*",
  createFondyMerchantAccount:
    "एक फोंडी व्यापारी खाता बनाएं और पूरी प्रक्रिया पूरी करें:",
  create: "बनाएं",
  participantsMin: "न्यूनतम प्रतिभागी संख्या 1 है",
  personalTaxCodeError: "व्यक्तिगत कर कोड का एक मान्य संख्या होनी चाहिए!",
  participantsError: "प्रतिभागी संख्या का एक मान्य संख्या होनी चाहिए!",
  peName: "व्यक्तिगत कर कोड नाम",
  url: "वेबसाइट",
  okpo: "OKPO",
  phone: "फ़ोन",
  peNameTooltip:
    "आपके व्यक्तिगत उद्यम का आधिकारिक नाम: 'अंतिम नाम प्रथम नाम पत्रोनाम'.",
  urlTooltip:
    "आपके व्यवसाय का URL। यह आपकी वेबसाइट, Facebook, Instagram आदि हो सकता है।",
  okpoTooltip: "आपके व्यक्तिगत उद्यम का ओक्पो कोड।",
  phonePayoutTooltip: "आपका फ़ोन नंबर।",
  paymentsTipStripe:
    "Stripe उपयोगकर्ताओं के लिए हमेशा भुगतान सक्षम होते हैं। पंजीकरण पूरा करने के बाद वित्तपोषण उपलब्ध होता है।",
  paymentsTipFondy1:
    "निजी व्यवसायी: व्यापारी बनाने के बाद भुगतान सक्षम होते हैं। पंजीकरण पूरा करने के बाद वित्तपोषण उपलब्ध होता है।",
  paymentsTipFondy2:
    "क्रेडिट कार्ड: भुगतान और वित्तपोषण केवल तब सक्षम होते हैं जब व्यक्तिगत क्रेडिट कार्ड और व्यक्तिगत कर कोड की एक संयोजना जोड़ी जाती है।",
  phoneError: "फ़ोन नंबर को एक मान्य संख्या होनी चाहिए!",
  toastTaxCodeAdded: "आपका व्यक्तिगत कर कोड सफलतापूर्वक जोड़ा गया।",
  toastTaxCodeError:
    "आपके व्यक्तिगत कर कोड जोड़ने की कोशिश करते समय एक त्रुटि आई!",
  toastPECodeAdded:
    "आपका नया फोंडी विक्रेता सफलतापूर्वक बनाया गया। आगे की प्रक्रिया शुरू करने के लिए अपने ईमेल की जाँच करें!",
  toastPECodeError:
    "आपके नए फोंडी विक्रेता बनाने की कोशिश करते समय एक त्रुटि आई!",
  onboarding: "ऑनबोर्डिंग",
  notCompletedOnboarding: "पूरा नहीं हुआ",
  completedOnboarding: "पूरा हुआ",
  completeOnboarding: "ऑनबोर्डिंग पूरा करें",
  onboardingTooltip:
    "Fondy डैशबोर्ड में ऑनबोर्डिंग पूरा करें। एक पासवर्ड बनाने के लिए आमंत्रण के लिए अपने ईमेल की जाँच करें, Fondy डैशबोर्ड में साइन इन करें, आवश्यक दस्तावेज़ अपलोड करें और एक डिजिटल हस्ताक्षर जोड़ें। मंजूरी प्राप्त होने पर आपका खाता सक्रिय हो जाएगा।",
  fondyMerchantAccountDetails: "फोंडी व्यापारी खाता विवरण",
  eventIsLife: "घटना लाइव है",
  created: "प्रारंभिक",
  processing: "प्रोसेसिंग",
  goToFondyDashboard: "अपना फॉन्डी डैशबोर्ड खोलें",
  youMustCompleteFondy:
    "आपको फॉन्डी पर बोर्डिंग पूरी करनी होगी, जिसके लिए आपको निम्नलिखित ईमेल आमंत्रण पर जाना होगा:",
  chooseEventType: "घटना का प्रकार चुनें",
  addException: "अपवाद जोड़ें",
  manageScheduleExceptions: "समय-सारणी में अपवाद प्रबंधित करें",
  addRemoveSpecificTime:
    "स्पष्ट समय जोड़ें या हटाएं जब आप उपलब्ध हैं या उपलब्ध नहीं हैं।",
  exceptionType: "अपवाद का प्रकार:",
  available: "उपलब्ध",
  notAvailable: "उपलब्ध नहीं",
  yes: "हाँ",
  no: "नहीं",
  removeException: "अपवाद हटाएं",
  removeExceptionDesc:
    "क्या आप {{time}} को हटाने के लिए पूर्णत: सुनिश्चित हैं?",
  NoExceptionsAdded: "कोई अपवाद नहीं जोड़े गए हैं",
  availableAllDay: "मैं पूरे दिन उपलब्ध रहूँगा।",
  notAvailableAllDay: "मैं पूरे दिन उपलब्ध नहीं रहूँगा।",
  exceptionDeleteSuccessfully: "अपवाद सफलतापूर्वक हटाया गया",
  exceptionDeleteError: "आपका अपवाद हटाया नहीं जा सका",
  exceptionSavedSuccessfully: "अपवाद सफलतापूर्वक सहेजा गया",
  exceptionSavedError: "आपका अपवाद सहेजा नहीं जा सका",
  addJoininstructions: "जुड़ने के निर्देश जोड़ें",
  addJoininstructionsDesc:
    "वह सामग्री या संदेश जोड़ें जिसे आपके ग्राहक खरीदने के बाद देखेंगे जब वे अब तक चैनल से जुड़े नहीं हैं",
  joinChannel: "चैनल में शामिल हों",
  communityUploadDescription: "छवि या वीडियो अपलोड करें।",
  detailsOptional: "विवरण (वैकल्पिक)",
  type: "प्रकार",
  youCannotAddPast:
    "आप उस समयस्पष्ट को नहीं जोड़ सकते जो गति में समाप्त होता है",
  Sun: "रवि",
  Mon: "सोम",
  Tue: "मंगल",
  Wed: "बुध",
  Thu: "गुरु",
  Fri: "शुक्र",
  Sat: "शनि",
  toastEventNotEdited:
    "आपकी घटना को संपादित करने की कोशिश करते समय एक त्रुटि आई!",
  refunded: "वापस किया गया",
  declined: "अस्वीकृत",
  verified: "सत्यापित",
  transactionSuccessful: "लेन-देन सफल",
  myTabs: "मेरे टैब्स",
  tabStatus: "{{tabName}} {{status}} हैं",
  controlOfferTabs:
    "वे प्रकार की प्रस्ताव जो आपके मुख्य पृष्ठ पर दिख सकते हैं:",
  onlyEnglishLetters: "केवल अंग्रेजी अक्षर और अंक अनुमत हैं",
  notificationCompleteOnboarding: "पूरा करें Onboarding",
  notificationYourPaidOffersAreUnavailable:
    "आपके पेड ऑफ़र आपके ग्राहकों के लिए उपलब्ध नहीं हैं।",
  products: "उत्पाद",
  bookingAvailable: "बुकिंग उपलब्धता",
  bookingAvailableDescription:
    "यह स्पष्ट करें कि आप सामान्य रूप से बुकिंग के लिए उपलब्ध हैं या नहीं।",
  availableKey: "उपलब्ध",
  notAvailableKey: "उपलब्ध नहीं",
  paymentOccurs: "प्रत्येक बार पेमेंट देना है",
  paymentOccursOneTime: "एक बार का पेमेंट।",
  eventIsOver: "आयोजन समाप्त हो गया है",
  integrations: "एकीकरण",
  externalAnalytics: "बाह्य विश्लेषण",
  externalAnalyticsDescription:
    "नीचे अपने बाह्य विश्लेषण खातों की आईडी सेट करें।",
  participantsManage: "प्रतियोगी",
  manageEvent: "इवेंट प्रबंधित करें",
  noEventsAdded: "कोई इवेंट जोड़ा नहीं गया",
  createEvent: "इवेंट बनाएँ",
  shareEvent: "इवेंट साझा करें",
  waylightProfile: "वेलाइट प्रोफाइल",
  tickedId: "टिकट आईडी",
  checkIn: "Check-in",
  checkedIn: "चेक-इन किया गया",
  notCheckedin: "चेक-इन नहीं किया गया",
  codeIsValid: "कोड मान्य है! चेक-इन किया गया!",
  codeisInvalid: "कोड अमान्य है! कृपया पुनः प्रयास करें।",
  codeIsUsed: "यह कोड पहले से ही उपयोग किया गया है!",
  buyMore: "और खरीदें",
  noParticipantsYet: "अब तक कोई प्रतियोगी नहीं हैं",
  emptyDeteilsBuysTickets:
    "ये यहाँ दिखाए जाएंगे जब कोई आपके इवेंट के टिकट खरीदेगा।",
  connectYourTelegram: "चैनल तक पहुंचन के लिए अपना Telegram खाता कनेक्ट करें!",
  change: "परिवर्तन",
  numberOfTickets: "टिकटों की संख्या:",
  selectPaymentMethodTitle: "भुगतान का तरीका चुनें:",
  or: "- या -",
  addACard: "कार्ड जोड़ें",
  subscriptionLevels: "सदस्यता स्तर",
  subscriptionLevelsTooltip:
    "अपने समुदाय सदस्यता को 3 स्तरों तक जोड़ें। उदाहरण के लिए: मासिक, तिमाही और वार्षिक।",
  levelName: "स्तर का नाम",
  starter: "स्टार्टर",
  pro: "पेशेवर",
  levelDiscription: "ग्राहकों को दिखाए जाने वाले स्तर का नाम",
  period: "काल",
  periodDiscription:
    "ग्राहकों को इस अंतराल में सदस्यता बनाए रखने के लिए धन वसूला जाएगा",
  priceDiscription: "आपको निर्दिष्ट अंतरालों में भुगतान मिलता है",
  removeLevel: "स्तर हटाएं",
  addAnotherLevel: "और एक स्तर जोड़ें",
  noTrial: "कोई परीक्षण नहीं",
  trialDiscription:
    "आपके ग्राहकों को मुफ्त पहुंच के लिए दिए जाने वाले दिनों की संख्या",
  trialPeriod: "परीक्षण अवधि:",
  selectSubscription: "सदस्यता का चयन करें:",
  subscription: "सदस्यता",
  removeLevelDescription: "क्या आप स्तर हटाना चाहते हैं?",
  tryForFree: "मुफ्त प्रयास करें",
  startTrial: "प्रारंभ करें परीक्षण",
  subscribedSuccessfully: "सफलतापूर्वक सब्सक्राइब किया गया",
  edit: "संपादित करें",
  expirationColumName: "समाप्ति",
  kick: "निकालें",
  trial: "परीक्षण",
  notJoined: "साझा नहीं किया गया",
  telegramProfile: "टेलीग्राम प्रोफ़ाइल",
  notAuthorized: "अनधिकृत",
  noMembersFoundFilter: "निर्धारित फ़िल्टर के अनुसार कोई सदस्य नहीं मिला।",
  tryADifferentFilter: "कोई अलग फ़िल्टर या खोज इनपुट का प्रयास करें।",
  "not joined": "साझा नहीं किया गया",
  "not authorized": "अनधिकृत",
  membership: "सदस्यता",
  priceDiscriptionEvents:
    "आपको इस घड़ी की हर सीट की खरीद के लिए भुगतान मिलता है",
  fanZone: "फैन जोन",
  vipZone: "VIP जोन",
  selectTickets: "टिकट चुनें:",
  attendance: "उपस्थिति",
  eventLevels: "घड़ी के स्तर",
  noTicketsSelected: "कोई टिकट चयन नहीं किए गए",
  SetMaximumNumberOfSeats:
    "इस स्तर के लिए अधिकतम सीटों की संख्या निर्धारित करें",
  participants: "सीटों की संख्या",
  import: "आयात",
  export: "निर्यात",
  labelNameDescription: "अपने आयातित डेटा के लिए एक लेबल चुनें",
  hidden: "छुपा हुआ",
  upload: "अपलोड",
  labelName: "लेबल का नाम",
  fileIsRequired: "आपको एक फ़ाइल अपलोड करनी चाहिए!",
  code: "कोड",
  level: "स्तर",
  label: "लेबल",
  exportFileError: "निर्यात विफल!",
  exportFileSuccess: "फ़ाइल सफलतापूर्वक निर्यात की गई।",
  importFileSuccess: "फ़ाइल सफलतापूर्वक आयात की गई।",
  importFileError: "आयात विफल!",
  statsSuccess: "चेक-इन आंकड़े सफलतापूर्वक लोड हो गए हैं।",
  statsError: "चेक-इन आंकड़ों को लोड करने के दौरान कुछ त्रुटि हुई थी!",
  uploadAText: "सामान्य पाठ या सीएसवी फ़ाइल तक 50 एमबी",
  onlyTextFilesAllowed: "केवल सामान्य पाठ या सीएसवी फ़ाइलें अनुमत हैं!",
  toastCommunityNotEdited: "समुदाय को संपादित करते समय एक त्रुटि हुई थी",
  toastYouCanSelectOnlyImage: "आप हेडर के लिए केवल एक छवि का चयन कर सकते हैं",
  sections: "खंड",
  logInOrSignup: "Waylight में लॉग इन करें या साइनअप करें",
  continue: "जारी रखें",
  invalidEmail: "अवैध ईमेल",
  enterCode: "कोड दर्ज करें",
  haveSentCodeTo: "हमने कोड भेजा है",
  paste: "पेस्ट करें",
  resendCodeIn: "कोड पुनः भेजें... (में {{time}} सेकंड)",
  resendCode: "कोड पुनः भेजें",
  waylight: "Waylight",
  pleaseChooseCountry: "कृपया अपने बैंक या वित्तीय सेवा का देश चुनें।",
  buyAccessFor: "एक्सेस खरीदें अनुभव के लिए",
  thankYouForFollowing: "फॉलो करने के लिए धन्यवाद!",
  incorrectCode: "कोड गलत है या समाप्त हो गया है!",
  enterEmailToContinue: "जारी रखने के लिए ईमेल दर्ज करें",
  city: "शहर",
  social: "सामाजिक",
  earned: "कुल",
  priceLimitARS:
    "मूल्य ARS$ 0 हो सकता है, या ARS$ 1879.31 से ARS$ 9396554.46 तक हो सकता है।",
  priceLimitAUD:
    "मूल्य A$ 0 हो सकता है, या A$ 3.01 से A$ 15097.1 तक हो सकता है।",
  priceLimitEUR: "मूल्य €0 हो सकता है, या €1.81 से €9070 तक हो सकता है।",
  priceLimitBOB:
    "मूल्य Bs. 0 हो सकता है, या Bs. 13.81 से Bs. 69119.48 तक हो सकता है।",
  priceLimitBGN:
    "मूल्य лв 0 हो सकता है, या лв 3.55 से лв 17750.81 तक हो सकता है।",
  priceLimitCAD:
    "मूल्य CA$ 0 हो सकता है, या CA$ 2.74 से CA$ 13718.98 तक हो सकता है।",
  priceLimitCLP:
    "मूल्य CL$ 0 हो सकता है, या CL$ 1864.56 से CL$ 9321644.2 तक हो सकता है।",
  priceLimitCOP:
    "मूल्य COL$ 0 हो सकता है, या COL$ 8072.11 से COL$ 40360576.84 तक हो सकता है।",
  priceLimitCRC:
    "मूल्य ₡0 हो सकता है, या ₡1055.02 से ₡5277582.56 तक हो सकता है।",
  priceLimitHRK:
    "मूल्य kn 0 हो सकता है, या kn 35.24 से kn 70472 तक हो सकता है।",
  priceLimitCZK:
    "मूल्य Kč 0 हो सकता है, या Kč 45.71 से Kč 228558 तक हो सकता है।",
  priceLimitDKK:
    "मूल्य kr 0 हो सकता है, या kr 13.54 से kr 67719 तक हो सकता है।",
  priceLimitDOP:
    "मूल्य RD$ 0 हो सकता है, या RD$ 294.46 से RD$ 588928 तक हो सकता है।",
  priceLimitHKD:
    "मूल्य HK$ 0 हो सकता है, या HK$ 15.58 से HK$ 77915 तक हो सकता है।",
  priceLimitEGP:
    "मूल्य EGP 0 हो सकता है, या EGP 154.75 से EGP 309501 तक हो सकता है।",
  priceLimitHUF:
    "मूल्य Ft 0 हो सकता है, या Ft 716 से Ft 3579217 तक हो सकता है।",
  priceLimitISK:
    "मूल्य kr 0 हो सकता है, या kr 684 से kr 1369300 तक हो सकता है।",
  priceLimitINR: "मूल्य ₹0 हो सकता है, या ₹167.89 से ₹839435 तक हो सकता है।",
  priceLimitIDR:
    "मूल्य Rp 0 हो सकता है, या Rp 78852 से Rp 157703833 तक हो सकता है।",
  priceLimitILS: "मूल्य ₪0 हो सकता है, या ₪19 से ₪36657 तक हो सकता है।",
  priceLimitUYU: "मूल्य $U 0 हो सकता है, या $U 81 से $U 402925 तक हो सकता है।",
  priceLimitGBP: "मूल्य £0 हो सकता है, या £1.56 से £7779.95 तक हो सकता है।",
  priceLimitTRY: "मूल्य ₺0 हो सकता है, या ₺67.06 से ₺335289.19 तक हो सकता है।",
  priceLimitTTD:
    "मूल्य TT$ 0 हो सकता है, या TT$ 13.58 से TT$ 67917.76 तक हो सकता है।",
  priceLimitTHB: "मूल्य ฿0 हो सकता है, या ฿69.82 से ฿349109.6 तक हो सकता है।",
  priceLimitCHF:
    "मूल्य CHF 0 हो सकता है, या CHF 1.73 से CHF 8647 तक हो सकता है।",
  priceLimitSEK:
    "मूल्य kr 0 हो सकता है, या kr 20.82 से kr 104092.39 तक हो सकता है।",
  priceLimitZAR: "मूल्य R 0 हो सकता है, या R 94 से R 186359 तक हो सकता है।",
  priceLimitSGD:
    "मूल्य SGD$ 0 हो सकता है, या SGD$ 2.63 से SGD$ 13146.69 तक हो सकता है।",
  priceLimitSAR:
    "मूल्य SAR 0 हो सकता है, या SAR 19 से SAR 37496 तक हो सकता है।",
  priceLimitRON:
    "मूल्य lei 0 हो सकता है, या lei 9.04 से lei 45196.94 तक हो सकता है।",
  priceLimitPLN:
    "मूल्य zł 0 हो सकता है, या zł 7.8 से zł 39008.41 तक हो सकता है।",
  priceLimitPHP: "मूल्य ₱ 0 हो सकता है, या ₱ 281 से ₱ 560255 तक हो सकता है।",
  priceLimitPEN:
    "मूल्य S/. 0 हो सकता है, या S/. 19 से S/. 38040 तक हो सकता है।",
  priceLimitPYG:
    "मूल्य ₲ 0 हो सकता है, या ₲ 36387 से ₲ 72772680 तक हो सकता है।",
  priceLimitNOK:
    "मूल्य kr 0 हो सकता है, या kr 21.38 से kr 106903 तक हो सकता है।",
  priceLimitNZD:
    "मूल्य NZ$ 0 हो सकता है, या NZ$ 3.33 से NZ$ 16646 तक हो सकता है।",
  priceLimitMXN:
    "मूल्य MX$ 0 हो सकता है, या MX$ 37.92 से MX$ 189595 तक हो सकता है।",
  priceLimitKES:
    "मूल्य KSh 0 हो सकता है, या KSh 803 से KSh 1605000 तक हो सकता है।",

  linkInBIOtool: "Link in BIO टूल",
  thatHelpsYouEarn: "जो आपको कमाने में मदद करता है!",
  earnMore: "और कमाओ",
  withWaylight: "वे लाइट के साथ:",
  descriptionEarn: "अपने सोशल मीडिया राजस्व में 10X कमाएं!",
  descriptionEarnPart: "केवल 2 मिनट में लॉन्च करें!",
  earnOnPaidCommunities: "पेड कम्युनिटी पर कमाएं।",
  createAndManage:
    "टेलीग्राम में अपनी सदस्यता आधारित कम्युनिटी बनाएं और प्रबंधित करें।",
  EarnPaidDigital: "पेड डिजिटल प्रोडक्ट पर कमाएं।",
  hostAndManage:
    "अपने कोर्स, ट्यूटोरियल, ई-बुक्स, गाइड्स, फ़ाइलें, आदि को होस्ट और प्रबंधित करें।",
  earnOnline: "ऑनलाइन और इन-पर्सन इवेंट्स पर कमाएं।",
  sellTicketsAndManage:
    "अपने ऑनलाइन और इन-पर्सन समूह इवेंट्स के लिए टिकट बेचें और प्रबंधित करें।",
  EarnServices: "पेड सेवाओं पर कमाएं।",
  sellManage: "अपनी सेवा की प्रवाह को बेचें और प्रबंधित करें।",
  earnOnlineVideoSessions: "पेड ऑनलाइन वीडियो सत्रों पर कमाएं।",
  ScheduleBookingsOnlineVideo:
    "एक अनुसूची सेट करें और अपने ग्राहकों के साथ ऑनलाइन वीडियो सत्रों के लिए बुकिंग प्राप्त करें।",
  EarnAppointments: "पेड इन-पर्सन अपॉइंटमेंट पर कमाएं।",
  ScheduleSignups:
    "एक अनुसूची सेट करें और अपने 1-on-1 या समूह अपॉइंटमेंट्स के लिए साइनअप्स प्राप्त करें।",
  earnTipping: "टिपिंग पर कमाएं।",
  getTippingSupport:
    "अपने चैनल्स के समर्थन में या सोशल नेटवर्क्स पर साझा करने के लिए टिपिंग प्राप्त करें।",
  youWilGet: "आपको मिलेगा",
  tools: "1. टूल्स",
  sales: "2. बिक्री",
  clientsGet: "3. ग्राहक",
  howItWorks: "यह कैसे काम करता है",
  GetClients: "नए बिक्री और ग्राहक प्राप्त करें",
  useWaylightGrowth:
    "वेलाइट विकास इंजन टूल्स और सलाह का उपयोग करके अधिक बिक्री और नए ग्राहकों को सोशल नेटवर्क्स से प्राप्त करें।",
  createWaylight: "वेलाइट बनाएं",
  creatorsExperts: "निर्माता और विशेषज्ञों",
  interactionsMonthly: "मासिक परस्पर क्रिया",
  monthOverMonth: "माह-ओ-माह वृद्धि",
  forWhom: "किसके लिए:",
  pricing: "मूल्य निर्धारण:",
  chooseYourPlan: "अपनी योजना चुनें",
  saveWhenChoosingYearlyPlan: "सालाना योजना चुनते समय अधिक बचाएं!",
  monthlyBilling: "मासिक बिलिंग",
  annuallyBilling: "वार्षिक बिलिंग",
  freePlan: "मुफ्त",
  startLowestCosts: "न्यूनतम लागत से शुरू करें और वेलाइट की खोज करें",
  mo: "/माह",
  signUp: "साइन अप करें",
  impressionMo: "{{comission}}% लेन-देन शुल्क।",
  basicToolsIncluded: "मौलिक टूल्स शामिल हैं!",
  basic: "मौलिक",
  getEverythingBasic:
    "कम लेनदेन शुल्क स्तर के साथ मौलिक में सब कुछ प्राप्त करें",
  contactSales: "बिक्री से संपर्क करें",
  transactionFees: "2% लेन-देन शुल्क।",
  publishingMarketplace: "मार्केटप्लेस खोज पर प्रकाशन।",
  saveBadge: "{{percent}}% बचाएं",
  bestValue: "सर्वश्रेष्ठ मूल्य",
  GetMoreSales:
    "कोई लेन-देन शुल्क के साथ अपने सोशल नेटवर्क्स से अधिक बिक्री प्राप्त करें",
  ziroTransactionFees: "0% लेन-देन शुल्क।",
  proToolsIncluded: "पेशेवर टूल्स शामिल हैं!",
  customizations: "कस्टमाइजेशन।",
  verifiedSign: "सत्यापित साइन।",
  prioritySupport: "प्राथमिकता समर्थन।",
  launchExpert: "लॉन्च एक्सपर्ट संलग्न है।",
  askTelegram: "टेलीग्राम पर पूछें",
  askWhatsApp: "व्हाट्सएप पर पूछें",
  askEmail: "ईमेल के माध्यम से पूछें",
  MakeTenX: "10X कमाएं",
  onYourSocialMedia: "अपने सोशल मीडिया पर",
  revenue: "राजस्व! 🎉",
  launchJust: "केवल लॉन्च",
  twoMinutes: "2 मिनट!",
  product: "उत्पाद:",
  customersDataManagement: "ग्राहकों का डेटा और प्रबंधन",
  reviewsProduct: "समीक्षाएँ",
  portfolioProduct: "पोर्टफोलियो",
  businessWebsite: "व्यापार वेबसाइट",
  securePayments: "सुरक्षित भुगतान",
  tipping: "टिपिंग",
  paidAppointments: "पेड इन-पर्सन अपॉइंटमेंट्स",
  paidVideoSessions: "पेड ऑनलाइन वीडियो सत्र",
  paidServices: "पेड सेवाएं",
  ticketsOnlineEvents: "ऑनलाइन घटनाओं के लिए टिकटें",
  ticketsScanner: "टिकट स्कैनर",
  ticketsPersonEvents: "इन-पर्सन घटनाओं के लिए टिकटें",
  paidAccessCourses: "कोर्सेस के लिए पेड पहुँच",
  paidAccessDigitalProducts: "डिजिटल प्रोडक्ट्स के लिए पेड पहुँच",
  paidGroupChannelAccessTelegram: "टेलीग्राम पर पेड समूह और चैनल एक्सेस",
  paidSubscriptionCommunitiesTelegram:
    "टेलीग्राम पर पेड सदस्यता आधारित कम्युनिटियाँ",
  productMenuItem: "उत्पाद",
  PricingMenuItem: "मूल्य निर्धारण",
  Creator: "निर्माता",
  Freelancer: "फ्रीलांसर",
  Tutor: "ट्यूटर",
  Coach: "कोच",
  Influencer: "प्रभावकारी",
  ask: "पूछें",
  anyQuestions: "कोई भी सवाल यहाँ:",
  how: "कैसे",
  itWorks: "यह कैसे काम करता है",
  businessflowStep1new:
    "अपने Waylight लिंक को सोशल मीडिया BIO में जोड़ें और इसके बारे में कहानियों और पोस्ट्स साझा करके अपने दर्शक और ग्राहकों से संवाद करना शुरू करें।",
  businessflowStep2new:
    "वे लाइट ग्रोथ इंजन उपकरण और सलाह का उपयोग करें और सोशल मीडिया से अधिक बिक्री करें और नए ग्राहकों को प्राप्त करें।",
  businessflowStep3new:
    "अपने समुदाय, डिजिटल उत्पादों, ऑनलाइन और ऑफलाइन घटनाओं, टिपिंग, व्यक्तिगत और वीडियो सत्रों, आदि के लिए भुगतान प्राप्त करें।",
  businessCalculatorNew: "Waylight आय कैलकुलेटर",
  howMuchMoneyNew:
    "जानें कि सब्सक्राइबर्स आपके व्यापार में कितने पैसे ला सकते हैं।",
  estimateNumberNew: "1. ग्राहकों और सब्सक्राइबर्स की संख्या का आकलन करें",
  howMuchYouChargeNew: "2. आप अपने प्रस्ताव के लिए कितना शुल्क ले सकते हैं",
  for: "के लिए",
  whom: "किसके लिए",
  getEverythingBasicNew: "कम लेनदेन शुल्क स्तर के साथ सभी कुछ प्राप्त करें",
  startLowestCostsNew:
    "कोई आगे के लागत के साथ शुरू करें और Waylight का अन्वेषण करें",
  GetMoreSalesNew:
    "अपने सोशल मीडिया से कोई लेन-देन शुल्क नहीं मिलकर अधिक बिक्री प्राप्त करें",
  onYourSocialMediaNew: "आपके सोशल मीडिया राजस्व पर! 🎉",
  accordionv2label0: "किस देशों में आप काम करते हैं?",
  accordionv2text0:
    "Waylight Business 5 महाद्वीपों पर 55+ देशों में काम करता है। आप अपने देश के बारे में हमसे संपर्क कर सकते हैं या बस साइन अप करके इसे जांचने का प्रयास कर सकते हैं। व्यापारी दुनिया के 150+ देशों से आपकी पेशकशों के लिए भुगतान कर सकते हैं।",
  accordionv2label1: "मुझे कैसे भुगतान मिलेगा?",
  accordionv2text1:
    "आपको प्रति सोमवार अपने बैंक खाते में पेआउट्स मिलेगा। अपने बैंक खाते को Waylight Business से कनेक्ट करने में कम से कम 1 मिनट लगता है।",
  accordionv2label2: "मूल्य में कौन-कौन से उपकरण शामिल हैं?",
  accordionv2text2:
    "यह आपके द्वारा चयन किए गए योजना पर निर्भर करता है, लेकिन किसी भी मामले में आपको पूरी तरह से Waylight मोनेटाइजेशन व्यापार फ्लोज का पहुंच मिलेगा, जैसे कि टेलीग्राम में पेड कम्यूनिटीज, टिकट्स, डिजिटल उत्पाद, कोर्सेस, अपॉइंटमेंट्स, वीडियो सत्र, टिपिंग, बिजनेस वेबसाइट, क्लायंट डेटा, सेल्स इंजन, मोनेटाइजेशन एकेडमी इत्यादि।",
  accordionv2label3: "क्या आपके पास Apple Pay, Google Pay, PayPal हैं?",
  accordionv2text3:
    "हाँ, हमारे पास हैं। हम इसे 'स्मूथ चेकआउट' कह रहे हैं। Waylight उच्च कन्वर्जन दरों के साथ बिक्री फ्लोज को स्वचालित करने पर काम कर रहा है, इसलिए हमारे पास 1 क्लिक चेकआउट्स (PayPal के साथ) हैं।",
  accordionv2label4: "Waylight मुझे 10X कैसे मदद करेगा?",
  accordionv2text4:
    "आपको बिक्री विकास इंजन और मोनेटाइजेशन एकेडमी का पहुंच मिलेगा, जो आपको अपने सोशल मीडिया से और अधिक ग्राहकों को बेचने में मदद करेगा। वैसे ही, Waylight ने पहले ही ऐसे स्वचालित फ्लोज और उपकरण बनाए हैं जो आपको उच्च कन्वर्जन दरें प्रदान करते हैं।",
  accordionv2label5: "मुझे शुरू होने के लिए क्या चाहिए?",
  accordionv2text5:
    "यह आसान है 👌 साइन अप करें, अपना बैंक खाता जोड़ें, पेशकशें बनाएं, मोनेटाइजेशन एकेडमी में शामिल हों, सोशल मीडिया पर लिंक जोड़ें, और और ज्यादा-और ज्यादा पैसा कमाना शुरू करें।",
  connectWaylight: "Waylight पर कनेक्ट करें!",
  toastTooLargeFileTwilio:
    "यह फ़ाइल बहुत बड़ी है। अधिकतम फ़ाइल का आकार 150Mb है।",
  enableSold: "क्या आप ऑफर बिक्री संकेतक्षेत्र सक्षम करना चाहते हैं?",
  areYouSureYOuWantEnableSold:
    "क्या आप सुनिश्चित हैं कि आप ऑफर बिक्री संकेतक्षेत्र सक्षम करना चाहते हैं? बिक्री मूल्य सभी उपयोगकर्ताओं के लिए दृश्यमान होंगे।",
  disableSold: "क्या आप ऑफर बिक्री संकेतक्षेत्र अक्षम करना चाहते हैं?",
  areYouSureYOuWantDisableSold:
    "क्या आप सुनिश्चित हैं कि आप ऑफर बिक्री संकेतक्षेत्र अक्षम करना चाहते हैं?",
  soldEnabled: "ऑफर बिक्री संकेतक्षेत्र सक्षम हैं",
  soldEnabledDescription:
    "जब यह सेटिंग सक्षम होती है, तो सभी उपयोगकर्ताओं के लिए बिक्री मूल्य दिखाई देते हैं।",
  tipOrCommunitypriceLimitARS:
    "मूल्य ARS$ 1879.31 से ARS$ 9396554.46 तक हो सकता है।",
  tipOrCommunitypriceLimitAUD: "मूल्य A$ 3.01 से A$ 15097.1 तक हो सकता है।",
  tipOrCommunitypriceLimitEUR: "मूल्य €1.81 से €9070 तक हो सकता है।",
  tipOrCommunitypriceLimitBOB: "मूल्य Bs. 13.81 से Bs. 69119.48 तक हो सकता है।",
  tipOrCommunitypriceLimitBGN: "मूल्य лв 3.55 से лв 17750.81 तक हो सकता है।",
  tipOrCommunitypriceLimitCAD: "मूल्य CA$ 2.74 से CA$ 13718.98 तक हो सकता है।",
  tipOrCommunitypriceLimitCLP:
    "मूल्य CL$ 1864.56 से CL$ 9321644.2 तक हो सकता है।",
  tipOrCommunitypriceLimitCOP:
    "मूल्य COL$ 8072.11 से COL$ 40360576.84 तक हो सकता है।",
  tipOrCommunitypriceLimitCRC: "मूल्य ₡1055.02 से ₡5277582.56 तक हो सकता है।",
  tipOrCommunitypriceLimitHRK: "मूल्य kn 36 से kn 70472 तक हो सकता है।",
  tipOrCommunitypriceLimitCZK: "मूल्य Kč 45.71 से Kč 228558 तक हो सकता है।",
  tipOrCommunitypriceLimitDKK: "मूल्य kr 13.54 से kr 67719 तक हो सकता है।",
  tipOrCommunitypriceLimitDOP: "मूल्य RD$ 295 से RD$ 588928 तक हो सकता है।",
  tipOrCommunitypriceLimitHKD: "मूल्य HK$ 15.58 से HK$ 77915 तक हो सकता है।",
  tipOrCommunitypriceLimitEGP: "मूल्य EGP 154.75 से EGP 309501 तक हो सकता है।",
  tipOrCommunitypriceLimitHUF: "मूल्य Ft 716 से Ft 3579217 तक हो सकता है।",
  tipOrCommunitypriceLimitISK: "मूल्य kr 684 से kr 1369300 तक हो सकता है।",
  tipOrCommunitypriceLimitINR: "मूल्य ₹167.89 से ₹839435 तक हो सकता है।",
  tipOrCommunitypriceLimitIDR: "मूल्य Rp 78852 से Rp 157703833 तक हो सकता है।",
  tipOrCommunitypriceLimitILS: "मूल्य ₪19 से ₪36657 तक हो सकता है।",
  tipOrCommunitypriceLimitUYU: "मूल्य $U 81 से $U 402925 तक हो सकता है।",
  tipOrCommunitypriceLimitGBP: "मूल्य £1.56 से £7779.95 तक हो सकता है।",
  tipOrCommunitypriceLimitTRY: "मूल्य ₺67.06 से ₺335289.19 तक हो सकता है।",
  tipOrCommunitypriceLimitTTD: "मूल्य TT$ 13.58 से TT$ 67917.76 तक हो सकता है।",
  tipOrCommunitypriceLimitTHB: "मूल्य ฿69.82 से ฿349109.6 तक हो सकता है।",
  tipOrCommunitypriceLimitCHF: "मूल्य CHF 1.73 से CHF 8647 तक हो सकता है।",
  tipOrCommunitypriceLimitSEK: "मूल्य kr 20.82 से kr 104092.39 तक हो सकता है।",
  tipOrCommunitypriceLimitZAR: "मूल्य R 94 से R 186359 तक हो सकता है।",
  tipOrCommunitypriceLimitSGD:
    "मूल्य SGD$ 2.63 से SGD$ 13146.69 तक हो सकता है।",
  tipOrCommunitypriceLimitSAR: "मूल्य SAR 19 से SAR 37496 तक हो सकता है।",
  tipOrCommunitypriceLimitRON: "मूल्य lei 9.04 से lei 45196.94 तक हो सकता है।",
  tipOrCommunitypriceLimitPLN: "मूल्य zł 7.8 से zł 39008.41 तक हो सकता है।",
  tipOrCommunitypriceLimitPHP: "मूल्य ₱ 281 से ₱ 560255 तक हो सकता है।",
  tipOrCommunitypriceLimitPEN: "मूल्य S/. 19 से S/. 38040 तक हो सकता है।",
  tipOrCommunitypriceLimitPYG: "मूल्य ₲ 36387 से ₲ 72772680 तक हो सकता है।",
  tipOrCommunitypriceLimitNOK: "मूल्य kr 21.38 से kr 106903 तक हो सकता है।",
  tipOrCommunitypriceLimitNZD: "मूल्य NZ$ 3.33 से NZ$ 16646 तक हो सकता है।",
  tipOrCommunitypriceLimitMXN: "मूल्य MX$ 37.92 से MX$ 189595 तक हो सकता है।",
  tipOrCommunitypriceLimitKES: "मूल्य KSh 803 से KSh 1605000 तक हो सकता है।",

  authorizeToAccess: "आपको पहुंचने के लिए अधिकृत करना होगा",
  noCommunitiesFound: "कोई समुदाय नहीं मिला",
  noCommunitiesFoundDescription:
    "जब आप एक समुदाय खरीदते हैं या जोड़ते हैं, तो वे यहां दिखाई देंगे",
  following: "फॉलोइंग",
  appointments: "अपॉइंटमेंट्स",
  share: "साझा करें",
  addOffer: "ऑफर जोड़ें",
  aboutMe: "मेरे बारे में",
  estimatedAmount:
    "आपके स्ट्राइप खाते की डिफ़ॉल्ट मुद्रा में वर्तमान मुद्रा परिवर्तन दरों के अनुसार आंशिक राशि।",
  ConnectYourTelegram: "अपना टेलीग्राम कनेक्ट करें:",
  ConnectYourTelegramAccount:
    "अपने टेलीग्राम खाते को कनेक्ट करें ताकि आप उन निर्माताओं से सूचनाएँ प्राप्त कर सकें जिनका आप पीछा कर रहे हैं।",
  connectTelegram: "टेलीग्राम कनेक्ट करें",
  buyAgain: "फिर से खरीदें",
  purchases: "खरीदा गया",
  videoGuide: "वीडियो गाइड",
  businessBilling: "व्यापार बिलिंग",
  noPaymentMethodsAdded: "कोई भुगतान के तरीके नहीं जोड़े गए",
  yourPaymentMethods: "आपके भुगतान के तरीके",
  yourPlan: "आपकी योजना",
  yearlyBilling: "सालाना बिलिंग",
  selectFree: "मुफ्त का चयन करें",
  whatIncluded: "क्या शामिल है",
  selectStarter: "मूल चयन करें",
  selectPro: "प्रो चयन करें",
  enterPromoCode: "प्रोमो कोड दर्ज करें:",
  apply: "लागू करें",
  selected: "चयनित",
  waylightSubscription: "वेलाइट सदस्यता",
  cashbackRefferals: "संदर्भ",
  referrals: "संदर्भ",
  shareLink: "लिंक साझा करें",
  howReferralsWork: "संदर्भ कैसे काम करते हैं",
  cashback: "कैशबैक",
  yourCashbackCode: "आपका कैशबैक कोड",
  yourCustomPartnerReferralCode: "आपका विशेष भागीदार संदर्भ कोड",
  howCashbackWork: "कैशबैक कैसे काम करता है",
  toastPromoCodeSuccess: "आपका प्रोमो कोड सफलतापूर्वक लागू हुआ!",
  toastPromoCodeError: "प्रोमो कोड लागू करते समय एक त्रुटि आई थी!",
  enterPromoCodeSignUp: "प्रोमो कोड दर्ज करें (वैकल्पिक):",
  enterPromoCodeBtn: "+ प्रोमो कोड दर्ज करें",
  billingBusinessTooltip: "नीचे अपनी योजना का चयन करें।",
  referralTooltip: "यह आपका अद्वितीय कैशबैक कोड है।",
  cashbackTooltip: "यह आपका अद्वितीय संदर्भ कोड है।",
  currentPlan: "वर्तमान योजना:",
  neverExpires: "कभी नहीं",
  expires: "समाप्त होता है:",
  emptyBillingBusinessHistoryDescription: "खाली बिलिंग व्यापार इतिहास विवरण",
  notificationGoToBillingBusiness:
    "हमें आपके सदस्यता भुगतान के साथ समस्या हो गई है!",
  WeHaveEncounteredAnIssueSubscription:
    "हमें आपके सदस्यता भुगतान के साथ समस्या हो गई है",
  checkIfYouHaveSufficientSubscription:
    "अपने धन की पर्याप्तता या इंटरनेट भुगतान सक्षम हैं की जाँच करें।",
  selectDifferentPaymentMethodSubscription:
    "एक विभिन्न भुगतान विधि का चयन करें।",
  referralsDescriptions1: "हमारे मूल्यवान संदर्भण साथी होने के लिए धन्यवाद!",
  referralsDescriptions2:
    "संदर्भणों को प्राप्त करने के लिए पूर्ण बोर्डिंग करें। हम आपके पैसे को सुरक्षित रखते हैं जब तक आप बोर्डिंग करते हैं।",
  referralsDescriptions3:
    "आपको अपने देश के अनुसार अपने बैंक विवरण या कार्ड विवरण की आवश्यकता होगी।",
  referralsDescriptions4:
    "भुगतान प्रत्येक सोमवार को होते हैं। आप Transactions में भुगतान स्थिति की जांच कर सकते हैं।",
  referralsDescriptions5:
    "आपका संदर्भ कोड Waylight सदस्यताओं के लिए अतिरिक्त छूट प्रदान करता है।",
  referralsDescriptions6:
    "आपकी छूट और आय साझा करने की दरें हमारी सहमति के आधार पर कस्टमाइज़ की जाती हैं।",
  referralsDescriptions7:
    "यदि यह आपके लिए पहली बार है कि आप भुगतान प्राप्त कर रहे हैं, तो यह गेटवे सीमाओं के कारण पहुँचने में 7-14 व्यावसायिक दिन लग सकते हैं। सामान्य रूप से भुगतानों में लगभग 3 व्यावसायिक दिन लगते हैं।",
  cashbackDescriptions1: "हमारे कैशबैक कार्यक्रम के सदस्य बनने के लिए धन्यवाद!",
  cashbackDescriptions2:
    "संदर्भणों को प्राप्त करने के लिए पूर्ण बोर्डिंग करें। हम आपके पैसे को सुरक्षित रखते हैं जब तक आप बोर्डिंग करते हैं।",
  cashbackDescriptions3:
    "आपको अपने देश के अनुसार अपने बैंक विवरण या कार्ड विवरण की आवश्यकता होगी।",
  cashbackDescriptions4:
    "भुगतान प्रत्येक सोमवार को होते हैं। आप Transactions में भुगतान स्थिति की जांच कर सकते हैं।",
  cashbackDescriptions5:
    "अपना लिंक साझा करें और नए ग्राहकों से राजस्व साझा करें।",
  cashbackDescriptions6:
    "आपका कैशबैक 1 साल के लिए सभी वेलाइट शुल्कों का 20-30% है (केवल प्रो वार्षिक सदस्यताओं से 30%)।",
  cashbackDescriptions7:
    "यदि यह आपके लिए पहली बार है कि आप भुगतान प्राप्त कर रहे हैं, तो यह गेटवे सीमाओं के कारण पहुँचने में 7-14 व्यावसायिक दिन लग सकते हैं। सामान्य रूप से भुगतानों में लगभग 3 व्यावसायिक दिन लगते हैं।",
  invalidGTMiD:
    "अमान्य जीटीएम आईडी। केवल अपनी आईडी दर्ज करें, कोड नहीं, उदाहरण के लिए: जीटीएम-एए11बीबी2।",
  invalidMetaPixelID:
    "अमान्य मेटा पिक्सेल आईडी। केवल अपनी आईडी दर्ज करें, कोड नहीं, उदाहरण के लिए: 123456789012345।",
  invalidHotjarID:
    "अमान्य हॉटजार आईडी। केवल अपनी आईडी दर्ज करें, कोड नहीं, उदाहरण के लिए: 1112223।",
  accountCreated: "खाता बनाया गया",
  toastOUrlCopied: "संदर्भ लिंक सफलतापूर्वक कॉपी किया गया!",
  YouAreOnFreePlan: "आप वर्तमान में मुफ्त योजना पर हैं",
  referralCodeCanNotBeUsed:
    "यह संदर्भ कोड आपके वर्तमान खाता देश के लिए उपयोग नहीं किया जा सकता: {{country}}",
  createWaylightBusiness: "वेलाइट व्यवसाय बनाएं",
  pleaseChooseCountryBank:
    "कृपया अपने बैंक या वित्तीय सेवा के देश का चयन करें:",
  referralsDescriptionsUA4:
    "प्रत्येक लेन-देन के बाद भुगतान होता है। आप लेन-देन में भुगतान की स्थिति की जांच कर सकते हैं।",
  referralsDescriptionsUA7:
    "भुगतान अगले व्यावसायिक दिन से लेकर देर नहीं होता है।",
  cashbackDescriptionsUA4:
    "प्रत्येक लेन-देन के बाद भुगतान होता है। आप लेन-देन में भुगतान की स्थिति की जांच कर सकते हैं।",
  cashbackDescriptionsUA7:
    "भुगतान अगले व्यावसायिक दिन से लेकर देर नहीं होता है।",
  issueSubscriptionPayment: "हमें आपके सदस्यता भुगतान में समस्या आई है!",
  revenueSharhingSelectPE:
    "रेफरल प्रोग्राम केवल यूक्रेन में सक्रिय पीई खातों के लिए उपलब्ध है।",
  youCanChangeYourAccount: "आप सेटिंग में अपने खाते का प्रकार बदल सकते हैं।",
  yourReferralCodeWillAppear:
    "आपका रेफरल कोड यहाँ दिखाई देगा जब आप पीई ऑनबोर्डिंग पूरी करेंगे।",
  goToPayoutSettings: "भुगतान सेटिंग्स पर जाएं",
  commission: "कमीशन",
  youAreOnSamePlan:
    "आप वर्तमान में उसी योजना पर हैं जिसे आप चुनने का प्रयास कर रहे हैं! कृपया कोई अन्य योजना चुनें।",
  onlyCyrillicLettersAllowed: "केवल सिरिलिक अक्षरों की अनुमति है",
  dragToChangePosition: "स्थिति बदलने के लिए खींचें",
  eventsQrcode: "घटना छोटे कोड",
  eventsQrcodeDescription:
    "जब यह सेटिंग चालू होती है, तो आपके घटना टिकट छोटे 4-अंकीय पिन्स और क्यूआर कोड के साथ उत्पन्न किए जाएंगे। जब बंद होता है, तो अधिक सुरक्षित 8-अंकीय पिन्स के साथ बारकोड उत्पन्न किए जाएंगे।",
  areYouSureYOuWantEnableQrcode:
    "क्या आप वाकई इवेंट छोटे कोड पिन्स को क्यूआर कोड के साथ सक्षम करना चाहते हैं?",
  areYouSureYOuWantDisableQrcode:
    "क्या आप वाकई इवेंट छोटे पिन्स को अक्षम करना चाहते हैं और उनके बजाय बारकोड उत्पन्न करना चाहते हैं?",
  enableEventsQrcode: "इवेंट छोटे कोड सक्षम करें",
  disableEventsQrcode: "छोटे कोड अक्षम करें और बारकोड पर स्विच करें",
  authorizeScanner: "स्कैनर को अधिकृत करें",
  toastScannerUrlCopied: "स्कैनर URL कॉपी किया गया",
  total: "कुल:",
  gtmIdDescription: "इस प्रस्ताव के लिए स्थानीय GTM।",
  metaPixelIdDescription: "इस प्रस्ताव के लिए स्थानीय मेटा पिक्सेल।",
  tutorialBoughtPreview: "यह आपके उत्पाद के खरीदे गए स्थिति का पूर्वावलोकन है।",
  tutorialNotBoughtPreview:
    "यह उत्पाद के ग्राहक कैसे देखते हैं जब यह नहीं खरीदा जाता है।",
  authorizeToAccessPurchases:
    "कृपया अपनी खरीदारियों तक पहुँचने के लिए अधिकृत करें!",
  myPromotions: "मेरा प्रोमो कोड",

  describeTheDetailsPromotions:
    "ऑफ़र चुनें, नाम निर्दिष्ट करें, URL चुनें और नीचे प्रमोशन विवरण जोड़ें",
  promotionDropdownDescription:
    "सूची से अपना ऑफ़र चुनें। इस प्रमोशन के लिए आप इसे बाद में बदल नहीं सकेंगे।",
  offerNamePromotions: "ऑफ़र",
  promotionsTooltip:
    "उस ऑफ़र को चुनें जिसके लिए यह प्रमोशन लागू होगा। आप इस ऑफ़र के लिए जितने चाहें उतने अन्य प्रमोशन बना सकते हैं।",
  offerNameDescriptionPromotions:
    "प्रमोशन का नाम दर्ज करें। यह केवल आपके आंतरिक उपयोग के लिए है।",
  codePromotionsDescription:
    "अंग्रेजी अक्षरों और संख्याओं का उपयोग करके एक अद्वितीय प्रमो कोड दर्ज करें। यदि आप चाहें तो जादुई उत्पन्न बटन आज़माएं!",
  expirationPromotions: "समाप्ति",
  name: "नाम",
  codePromotions: "कोड",
  usersNumDescription: "यह प्रमो कोड निर्दिष्ट बार उपयोग किया जा सकता है।",
  max_uses: "उपयोग की संख्या",
  pricingPromotionsDiscription: "pricingPromotionsDiscription",
  discount: "छूट",
  discountTitle: "छूट(एं):",
  selectOfferDiscounts:
    "इस ऑफ़र के लिए छूट प्रबंधित करने के लिए ऊपर ऑफ़र चुनें।",
  promoNameCanNotBeEmpty: "प्रमो नाम खाली नहीं हो सकता",
  urlIsAlreadyTaken: "यह URL पहले से ही लिया गया है!",
  promoCodeValidation:
    "प्रमो कोड में केवल बड़े और छोटे अंग्रेजी अक्षर और 0 से 9 तक की संख्याएँ हो सकती हैं!",
  promotions: "प्रमोशन्स",
  enterPromoCodePromotions: "प्रमो कोड दर्ज करें",
  promoCodeIncorrectExpired: "यह प्रमो कोड गलत है या समाप्त हो गया है!",
  selectedPromoCodeNotAvailable: "चयनित प्रमो कोड उपलब्ध नहीं है!",
  weWereUnableToProcessYourTransaction:
    "समाप्ति, उपयोग सीमा या अनुपलब्धता के कारण चयनित प्रमो कोड के साथ आपका लेन-देन संसाधित करने में असमर्थ रहे। कृपया अपनी खरीदारी पुनः आरंभ करें।",
  promotionsEmptyStateHeader:
    "सेट किए गए फ़िल्टर के अनुसार कोई प्रमोशन नहीं मिला।",
  promotionsEmptyStateDescription:
    "प्रमोशन बनाएं, एक अलग फ़िल्टर या खोज इनपुट आज़माएं।",
  toastPromotionUrlCopied: "प्रमोशन URL कॉपी किया गया!",
  toastPromotionCodeCopied: "प्रमो कोड कॉपी किया गया!",
  managePromotionsForYourOffers: "अपने ऑफ़र्स के लिए प्रमोशन्स प्रबंधित करें",
  offerPromotions: "ऑफ़र प्रमोशन्स",
  deletePromotionDescription: "क्या आप वाकई प्रमोशन हटाना चाहते हैं?",
  deletePromotionTitle: "प्रमोशन हटाएं",
  addPromotion: "प्रमोशन जोड़ें",
  toastPromotionNotEditedEnabled: "हम आपकी प्रमोशन को संशोधित नहीं कर सके!",
  toastPromotionNotEdited: "हम आपकी प्रमोशन को संशोधित नहीं कर सके!",
  usage: "उपयोग किया गया",
  discountedPriceError: "छूट वाली कीमत 1 USD के बराबर से कम नहीं हो सकती!",
  priceWithDiscount: "छूट के साथ मूल्य",
  promoCodeAddedToast: "प्रमो कोड सफलतापूर्वक लागू किया गया!",
  toastPromotionSavedSuccessfully: "प्रमो कोड सफलतापूर्वक बनाया गया",
  toastPromotiomNotAdded: "आपकी प्रमोशन बनाने में त्रुटि हुई!",
  myPromotionsMenu: "मेरे प्रोमोशन्स",
  setSubscriptionlevelManually: "सदस्यता स्तर को मैन्युअली सेट करें:",
  settingLevelManuallyNotifyClientAutomatically:
    "स्तर को मैन्युअली सेट करने से ग्राहक को स्वचालित रूप से सूचित किया जाएगा और यह अगली चार्ज के साथ लागू होगा {{expiration}}",
  subscribeForFree: "नि:शुल्क सदस्यता लें",
  toastSavedSuccessfullyUpdatedLevelCommunity:
    "सामुदायिक स्तर को सफलतापूर्वक सहेजा और अपडेट किया गया।",
  toastCommunityLevelNotUpdated: "सामुदायिक स्तर को अपडेट नहीं किया गया।",
  addNewCardStripe: "नया कार्ड जोड़ें (Stripe):",
  creditCard: "क्रेडिट कार्ड",
  promocode: "Promocode",
  nonFollowers: "अनुयायी नहीं",
  dueOn: "नियत तिथि",
  exportMyClientsSuccess: "ग्राहक निर्यात सफलतापूर्वक पूरा हुआ।",
  exportMyClientsError: "ग्राहक निर्यात के दौरान त्रुटि।",
  eventTelegramDescription:
    "अपने चैनल या समूह का नाम चुनें। यदि आपको यहाँ कुछ भी नहीं दिख रहा है, तो सुनिश्चित करें कि आपने Waylight Bot को अपने समूह या चैनल में एक व्यवस्थापक के रूप में जोड़ा है और यह इस चैनल के लिए एकमात्र प्रस्ताव है!",
  onlineTelegramEvent: "ऑनलाइन टेलीग्राम कार्यक्रम",
  joinEvent: "कार्यक्रम में शामिल हों",
  levels: "स्तर",
  paidAccess: "भुगतान किया गया एक्सेस",
  clientWillPayOnceAccess:
    "एक्सेस प्राप्त करने के लिए ग्राहक एक बार भुगतान करेगा",
  noOffersFound: "कोई ऑफर नहीं मिला",
  noOffersFoundDescription:
    "वे यहां दिखाई देंगे जब आप कोई ऑफर खरीदते हैं या जोड़ते हैं",
  youCanNotManageEnabled:
    "आपके पास कोई भी ऑफ़र सक्षम नहीं है जिसे आप प्रबंधित कर सकते हैं।",
  enableOffersForManagement:
    "उन्हें यहां प्रबंधित करने के लिए सत्र, घटनाएँ या समुदाय सक्षम करें।",
  specifyQuickSessionPriceDuration:
    "त्वरित सत्र की कीमत और अवधि निर्दिष्ट करें",
  youGetPaidSpecifiedPriceForFixedAmountTime:
    "आपको एक निश्चित समय के लिए निर्दिष्ट मूल्य का भुगतान किया जाता है।",
  videoCallQuick: "वीडियो कॉल",
  tabs: "टैब्स",
  pleaseEnterEmailAccessQuickSession:
    "त्वरित सत्र का उपयोग करने के लिए कृपया अपना ईमेल दर्ज करें",
  disconnectWhatsAppBot: "WhatsApp-बॉट को डिस्कनेक्ट करें",
  connectWhatsAppBot: "WhatsApp-बॉट कनेक्ट करें",
  WhatsAppBot: "WhatsApp-बॉट",
  videoCallSession: "वीडियो कॉल",
  copyProfileLink: "प्रोफ़ाइल लिंक कॉपी करें",
  profileQRCode: "मेरे प्रोफ़ाइल का QR कोड",
  copyCommunitiesLink: "समुदायों का लिंक",
  copyEventsLink: "घटनाओं का लिंक",
  copyProductsLink: "उत्पादों का लिंक",
  copySessionsLink: "सत्रों का लिंक",
  copyTipsLink: "टिप्स का लिंक",
  copyPortfolioLink: "पोर्टफोलियो का लिंक",
  copyReviewsLink: "समीक्षाओं का लिंक",
  leaveReviewForMe: "मेरे लिए समीक्षा छोड़ें",
  shareYourWaylight: "अपना Waylight साझा करें",
  myProfileQRCode: "मेरे प्रोफ़ाइल का QR कोड",
  menu: "मेन्यू",
  selectOfferExportData: "डेटा निर्यात करने के लिए एक प्रस्ताव चुनें",
};
