export const fr = {
  next: "Suivant",
  youWontBeCharged:
    "Vous ne serez pas facturé tant que vous n'aurez pas confirmé les détails de la session.",
  home: "Accueil",
  chats: "Chats",
  ratings: "Notations",
  settings: "Paramètres",
  moreSettings: "Plus de paramètres",
  settingsNotify: "Paramètres | Waylight",
  logOut: "Déconnexion",
  changePassword: "Modifier le mot de passe",
  receiveResetPassword:
    "Cliquez sur 'Réinitialiser' ci-dessous pour recevoir un lien de réinitialisation du mot de passe.",
  enterEmailForResetPassword:
    "Saisissez votre adresse électronique pour obtenir un lien de réinitialisation du mot de passe.",
  successResetPassword:
    "L'email de réinitialisation du mot de passe a été envoyé !",
  failResetPassword: "Échec de la réinitialisation du mot de passe",
  reset: "Réinitialiser",
  signOut: "Se déconnecter",
  availableBooking: "Disponible pour la réservation",
  unavailableBooking: "Non disponible pour la réservation",
  shareProfile: "Profil de partage",
  requests: "Demandes",
  hours: "Heures",
  totalSessions: "Total des sessions",
  totalEarned: "Total gagné",
  totalHours: "Total des heures",
  yourSession: "Votre session",
  runningNow: "est en cours d'exécution maintenant",
  notHaveUpcomingSessions: "Vous n'avez pas encore de sessions à venir",
  notHavePastSessions: "Vous n'avez pas encore de sessions passées",
  notHaveQuickSessions: "Vous n'avez pas encore lancé de sessions rapides",
  upcomingSessions: "Sessions à venir",
  offer: "Offre",
  gallery: "Galerie",
  photo: "Photo",
  session: "Session",
  sessionWith: "session avec",
  occupation: "Profession",
  skills: "Compétences",
  experts: "Experts",
  noInformation: "Aucune information",
  speaks: "Parle de",
  socialLinks: "Liens sociaux",
  requestSession: "Demande de session",
  contacts: "Contacts",
  noOffersYet: "Aucune offre disponible",
  all: "Tous",
  requested: "Demandé",
  confirmed: "Confirmé",
  passed: "Adopté",
  joined: "Rejoint",
  ongoing: "En cours",
  canceled: "Annulé",
  rejected: "Rejeté",
  approved: "Approuvé",
  calendar: "Calendrier",
  favorites: "Suivi de",
  removeAll: "Supprimer tout",
  viewProfile: "Voir le profil",
  today: "Aujourd'hui",
  upcoming: "Prochainement :",
  attachments: "Pièces jointes",
  goToSession: "Aller à la session",
  prepare: "Préparer",
  sessions: "Sessions",
  prerequisites: "Conditions préalables",
  download: "Télécharger",
  past: "Passé",
  decline: "Déclin",
  accept: "Accepter",
  declineSession: "Session de déclin",
  wantDeclineSession: "Êtes-vous sûr de vouloir refuser cette session?",
  wantDeclinePaidSession:
    "Êtes-vous sûr de vouloir rejeter la demande? Le paiement de votre client sera remboursé.",
  wantDeclineAfterApprove:
    "Vous êtes sûr de vouloir annuler? Le paiement de votre client sera remboursé.",
  videoCall: "Début du flux",
  endSession: "Fin de la session",
  areYouSureYouWantEndSession:
    "Êtes-vous sûr de vouloir mettre fin à cette session?",
  findExpert: "Trouver un expert",
  selectAnExpert: "sélectionner un expert",
  inviteClients: "invitez vos clients",
  selectAChat: "Sélectionnez un chat pour commencer à échanger des messages",
  noChatsYet: "Aucune discussion pour le moment",
  notHaveMessages: "Vous n'avez pas encore de messages",
  shareProfileToClient: "Partager le profil avec le client",
  remove: "Retirer",
  time: "Temps",
  timeSlots: "Créneaux horaires",
  deleteOffer: "Supprimer l'offre",
  myOffers: "Offres",
  aboutOffer: "À propos de la session",
  createNewOffer: "Créer une nouvelle offre",
  editOffer: "Modifier l'offre",
  successfulyBooked: "Session réservée avec succès",
  close: "Fermer",
  account: "Compte",
  price: "Prix",
  priceAndLength: "Prix et durée",
  aboutTheOffer: "À propos de la session",
  uploadAFile: "Télécharger un fichier",
  uploadAFileError: "Le fichier n'a pas été téléchargé",
  confirm: "Confirmer",
  IDLogin: "Votre email",
  myProfile: "Profil du client",
  transactions: "Transactions",
  transactionHistory: "Historique des transactions",
  noTransactions: "Vous n'avez pas encore de transactions",
  noTransactionsDescription:
    "Ils apparaîtront une fois que vous aurez réussi à payer vos sessions",
  typicalSchedule: "Horaire type",
  amount: "Montant",
  status: "Statut",
  paid: "Payé",
  action: "Action",
  description: "Description",
  billing: "Facturation",
  card: "Carte",
  clients: "Clients",
  sessionsPassed: "Sessions passées",
  payout: "Paiements",
  firstName: "Prénom",
  lastName: "Nom de famille",
  skip: "Skip",
  connect: "Connectez-vous à",
  about: "À propos de",
  title: "Titre",
  languages: "Langues",
  currency: "Monnaie",
  addYourOffer: "Ajoutez votre offre",
  join: "Rejoignez",
  joinIn: "Participez",
  joinOn: "Rejoignez-nous",
  message: "Message",
  client: "Client",
  expert: "Expert",
  Expert: "Expert",
  noMoreOptions: "Plus d'options",
  male: "Homme",
  female: "Femme",
  custom: "Personnalisé",
  copyYourLink: "Copiez votre lien",
  done: "Terminé",
  search: "Recherche",
  searchWaylight: "Trouvez votre Waylight",
  suggested: "Suggestion :",
  upcomingSession: "Session à venir",
  startASession: "Session rapide",
  quickSession: "Session rapide",
  rate: "Taux",
  perHour: "par heure",
  packages: "Packages",
  timezone: "Fuseau horaire",
  book: "Livre",
  favorite: "Favoris",
  lastSession: "Dernière interaction",
  ago: "Ago",
  gender: "Genre",
  loading: "Chargement",
  min: "Min",
  hour: "Heure",
  online: "En ligne",
  offline: "Hors ligne",
  date: "Date",
  displayName: "Nom d'affichage",
  closePanel: "Fermer le panneau",
  goToStripe: "Ouvrez votre tableau de bord des paiements",
  completeStripeOnBoarding: "Intégration complète de Stripe Express",
  stripeOnBoarding: "Stripe Onboarding",
  completed: "terminé",
  notCompleted: "pas terminé",
  myClients: "Mes clients",
  findClients: "Nous ne pouvons pas trouver de clients",
  clientDisplayedHere:
    "Lorsque vous trouverez des clients, ils seront affichés ici.",
  writeSomething:
    "Saisissez quelque chose et appuyez sur la touche Entrée pour créer un élément",
  bookSession: "Séance d'enregistrement",
  registerAs: "Enregistrez-vous en tant que :",
  addedCards: "Vos cartes",
  cardType: "Type de carte",
  billingHistory: "Historique de facturation",
  deleteCard: "Supprimer la carte",
  wantToDeleteCard: "Vous êtes sûr de vouloir supprimer la carte?",
  selectPaymentMethod: "Sélectionnez le mode de paiement",
  addNewCard: "Ajouter une nouvelle carte",
  emailAddress: "Courriel public",
  phoneNumber: "Numéro de téléphone",
  publicPhoneNumber: "Numéro de téléphone public",
  username: "Nom d'utilisateur :",
  socialProfilesOrLinks: "Profils sociaux / Autres liens",
  newOffer: "Nouvelle offre",
  myExperts: "Mes experts",
  myClientProfile: "Le profil de mon client",
  noExpertsFound: "Aucun expert trouvé",
  openMenu: "Ouvrir le menu",
  becomeAnExpert: "Rejoindre Waylight Business",
  switchToExpert: "Passer à la vue d'entreprise",
  switchToClient: "Passer à la vue client",
  notHaveExperts: "Vous n'avez pas encore d'experts",
  endCall: "Arrêt du flux",
  endSharing: "Arrêtez de partager",
  lastOnline: "Dernière mise en ligne",
  clientSince: "Client depuis",
  welcome: "Bienvenue sur",
  messageError: "Champ obligatoire",
  support: "Soutien",
  profile: "Profil",
  portfolio: "Portefeuille",
  publicId: "ID public",
  files: "Fichiers",
  reason: "Raison",
  declineReason: "Faites savoir à votre client pourquoi vous devez refuser",
  rejectionReason: "Motif du rejet",
  business: "Entreprise",
  passwordResetInvalidEmail: "L'email n'est pas valide",
  logIn: "Se connecter",
  logInSubtitle:
    "Pour effectuer une quelconque action, veuillez vous connecter ou vous enregistrer.",
  enterEmail: "Entrez l'adresse email",
  enterPassword: "Mot de passe",
  capsLockIsOn: "Le verrouillage des majuscules est activé",
  numLockIsOn: "Le verrouillage numérique est activé",
  forgotPassword: "Vous avez oublié votre mot de passe?",
  forgotPasswordEmail:
    "Saisissez votre adresse électronique pour obtenir un lien de réinitialisation du mot de passe.",
  requestPasswordReset: "Demander la réinitialisation du mot de passe",
  requestResetLink: "Renvoyer le lien",
  in: "Sur",
  sec: "Sec",
  passwordResetRequestReceived:
    "Demande de réinitialisation du mot de passe reçue",
  passwordResetIfAccountExist:
    "Si un compte existe, vous recevrez bientôt un courriel contenant un lien.",
  resetPassword: "Réinitialiser le mot de passe",
  passwordChangeSuccessful: "Le mot de passe a été modifié avec succès",
  passwordChangeSuccessfulSubtitle:
    "Vous pouvez maintenant utiliser votre nouveau mot de passe pour vous connecter à votre compte.",
  setNewPassword:
    "Définissez un nouveau mot de passe pour vous connecter à votre compte",
  passwordChange: "Modifier le mot de passe",
  passwordOld: "Ancien mot de passe",
  passwordNew: "Nouveau mot de passe",
  dontHaveAccount: "Vous n 'avez pas de compte?",
  alreadyRegistered: "Avez-vous déjà eu un compte?",
  tokenExpired: "Token expiré",
  tokenExpiredDescription:
    "Ce jeton a soit expiré, soit été utilisé auparavant.",
  validLength: "8 caractères ou plus",
  upperCase: "Lettres majuscules (A-Z)",
  lowerCase: "Lettres minuscules (a-z)",
  numbersOrSymbols: "Chiffres ou symboles (0-9!@#$%^&*)",
  joinFree: "S'inscrire gratuitement",
  businessTitle: "Waylight | Outil Link in BIO qui vous aide à gagner!",
  businessDescription:
    "Gagnez 10 fois plus sur vos réseaux sociaux! Lancez-vous en seulement 2 minutes!",

  spotlightYourExperience:
    "Des outils complets pour vos flux de revenus liés au contenu, aux experts et à la communauté",
  monetizationPlatform: "Studio D'affaires en Ligne",
  createAccount: "Créer un compte",
  watchVideo: "Regarder la vidéo",
  bestToolsAvailable:
    "Nous voulons que vous disposiez des meilleurs outils disponibles et nous ne vous ferons pas payer pour les utiliser.",
  schedulingManagement: "Gestion de l'ordonnancement.",
  chooseConvenientTimeSlots:
    "Choisissez des créneaux horaires qui vous conviennent pour travailler et obtenez des réservations.",
  expertLandingPayouts: "Les paiements.",
  simpleOnboarding: "Intégration simple de vos comptes financiers.",
  industryStandard:
    "Qualité d'appel et disponibilité conformes aux normes du secteur.",
  lead: "Chef de file",
  engage: "Engagez",
  frequentlyAskedQuestions: "Questions fréquemment posées",
  contactUs: "Nous contacter :",
  accordionLabel0: "Si le service est gratuit, comment fonctionne Waylight?",
  accordionText0:
    "Nous prenons une commission basée sur le volume sur les frais d'expertise et les appels de découverte et de charité sont pour nous !",
  accordionLabel1: "Prenez-vous en charge plusieurs types d'offres?",
  accordionText1:
    "Absolument, vous pouvez créer autant d'offres que vous le souhaitez, en spécifiant le prix, la durée et les détails de chaque offre.",
  accordionLabel2: "Comment suis-je payé?",
  accordionText2:
    "Nous utilisons Stripe et d'autres passerelles pour gérer les paiements. Un simple onboarding est nécessaire pour connecter votre compte bancaire ou votre méthode de paiement, qui fonctionne dans plus de 55 pays. De nombreuses autres méthodes de paiement et des paramètres de paiement flexibles sont en cours d'élaboration.",
  accordionLabel3: "Puis-je utiliser Zoom, Meet ou Skype?",
  accordionText3:
    "Nous disposons d'une solution vidéo entièrement intégrée, conçue pour garantir un flux sécurisé pour les deux parties. L'utilisation d'autres solutions vidéo moins sécurisées mais plus flexibles sera une option à l'avenir.",
  accordionLabel4: "Puis-je utiliser Zapier, Calendly, etc.",
  accordionText4:
    "Nous travaillons activement sur un backlog pour les intégrations que nos utilisateurs souhaitent le plus. Nous serions heureux d'entendre vos idées à ce sujet. Envoyez un email rapide à support@waylight.me. Nous vous remercions.",
  freeAudience: "Nous développons - vous créez !",
  businessProfile: "Profil de l'entreprise.",
  onlineBusiness:
    "Créez une activité en ligne en quelques minutes, en mettant en avant ce que vous faites le mieux.",
  paidCalls: "Appels audio et vidéo payants.",
  paidChatsAndBlog: "Discussions payantes et blog.",
  setUpSubscription:
    "Mise en place d'un abonnement pour le chat et le blog personnel.",
  web3: "Web3 évaluations et critiques.",
  collectRealRatings:
    "Recueillez des évaluations et des critiques réelles pour construire votre marque personnelle.",
  getStartedToday:
    "Trois étapes simples pour créer une nouvelle source de revenus :",
  createProfile: "1. Créez votre profil",
  fillTemplate:
    "Remplissez un modèle préétabli de votre profil, de votre calendrier et de vos offres, fixez les prix pour le chat payant et le blog et en quelques minutes, vous serez prêt à accepter des commandes et des abonnés.",
  shareIt: "2. Partagez-le avec le monde entier",
  linkYourProfile:
    "Reliez votre profil à tous vos canaux - vos médias sociaux, vos annonces, vos emails, etc. Partagez des informations sur votre activité en ligne dans des messages, des histoires, des vidéos, des messages personnels, etc.",
  growYourBusiness: "3. Développer votre activité",
  shareYourExpertise:
    "Partagez votre expertise et vos connaissances, mettez à jour votre blog, répondez aux réservations et aux chats, faites de la consultation vidéo en ligne, et obtenez vos évaluations et critiques publiques sur Web3.",
  businessflow: "Débit",
  businessflowStep1Title: "Ajouter un lien dans BIO",
  businessflowStep1:
    "Ajoutez le lien du profil de votre entreprise Waylight dans les réseaux sociaux BIO (Instagram, Facebook, YouTube, LinkedIn, Twitter, TikTok, etc.) et partagez des informations par le biais de récits et de posts à son sujet.",
  businessflowStep2Title: "Appels vidéo 1:1, chats 1:1, blog personnel",
  businessflowStep2:
    "Monétisez grâce à des appels vidéo 1:1, des chats 1:1 avec vos abonnés et clients. Créez du contenu réservé aux membres sur votre blog personnel avec des tutoriels, des articles, des conseils vidéo, des coulisses, des expériences de vie personnelles, des leçons, de la musique, etc.",
  businessflowStep3Title: "Soyez payé",
  businessflowStep3:
    "Soyez payé pour vos appels vidéo, vos chats et vos abonnements réservés aux membres sur votre blog personnel. Développez votre monétisation en partageant plus d'informations sur votre Waylight !",
  bookOnboardingCall: "Réservez un appel gratuit sur l'intégration",
  talkToFounders:
    "Dites aux fondateurs comment commencer à monétiser avec Waylight.",
  orScanQR: "Ou scannez QR",
  yourFreeTools: "Vos outils gratuits",
  features: "Caractéristiques",
  personalLink: "URL personnel",
  personalQR: "QR personnel",
  scheduling: "Programmation",
  bookings: "Réservations",
  paidChats: "Discussions payées 1:1",
  personalPaidBlog: "Blog personnel payant",
  soon: "Bientôt",
  subscriptions: "Abonnements",
  payments: "Paiements dans plus de 100 pays",
  payouts: "Paiements dans plus de 58 pays",
  cryptoPayments: "Paiements en crypto-monnaie",
  coinsAwarding: "Attribution de pièces",
  web3Ratings: "Web3 : évaluations, critiques",
  clientsManagement: "Gestion des clients",
  sessionsManagement: "Gestion des sessions",
  dashboard: "Tableau de bord",
  businessCalculator: "Calculateur de revenu d'entreprise Waylight",
  howMuchMoney:
    "Déterminez combien d'argent les abonnés apporteraient à votre entreprise.",
  estimateNumber: "1. Estimer le nombre de clients et d'abonnés",
  howMuchYouCharge:
    "2. Combien pouvez-vous facturer pour un appel vidéo ou un abonnement?",
  monthlyIncome: "Revenu mensuel",
  newToday: "Nouveau aujourd'hui",
  hadNoSessions: "Pas de sessions cette semaine",
  atThisWeek: "Cette semaine",
  welcomeToWaylight: "Bienvenue à Waylight !",
  welcomeToWaylightBusiness: "Bienvenue à Waylight Business !",
  linkToVideo: "Lien vers la vidéo d'introduction",
  writeSentences: "Écrivez quelques phrases sur vous-même.",
  emptyExpertTitle: "Il semble que cette page n'existe pas",
  emptyExpertText:
    "Le lien est peut-être rompu, ou la page a été désactivée ou supprimée.",
  profileData: "Données de profil",
  headerImageTooltip:
    "Les dimensions suggérées pour l'image d'en-tête sont d'environ 10:1. Vous pouvez essayer quelque chose comme 1900x190px ou 1280x128px.",
  emailTooltip:
    "Il s'agit d'une adresse électronique destinée à être affichée publiquement (pas votre identifiant). Vous pouvez spécifier l'adresse électronique de votre choix ou l'enlever complètement, c'est facultatif !",
  timezoneTooltip:
    "Si vous ne trouvez pas votre ville, essayez de chercher la zone, comme 'Heure d'été du Pacifique' ou 'Heure d'Europe de l'Est'.",
  linkToVideoPresentation: "Ajouter un lien externe",
  expertProfileVideoPresentation: "Ou télécharger un fichier",
  linkToVideoPresentationTooltip:
    "Définissez un lien vers une vidéo externe qui fera partie de vos informations. La plupart des services d'hébergement vidéo existants sont pris en charge. Si vous téléchargez une vidéo, elle sera privilégiée par rapport à ce lien.",
  linkToVideoOfferTooltip:
    "Définissez un lien vers une vidéo externe qui fera partie des détails de l'offre. La plupart des services d'hébergement vidéo existants sont pris en charge. Si vous téléchargez une vidéo, elle sera privilégiée par rapport à ce lien.",
  phoneTooltip:
    "Il s'agit d'un numéro de téléphone à afficher publiquement. C'est facultatif !",
  socialProfilesTooltip:
    "Ajoutez des liens vers vos profils et sites sociaux, comme Youtube, Facebook, Linkedin, Instagram ou Tiktok.",
  videoPresentationTooltip:
    "Téléchargez une vidéo à afficher dans vos détails. Elle remplacera le lien vidéo ci-dessus.",
  videoOfferTooltip:
    "Téléchargez une vidéo à afficher dans les détails de l'offre. Elle remplacera le lien vidéo ci-dessus.",
  occupationTooltip:
    "Indiquez au monde votre profession, par exemple 'Consultant en RA' ou 'Top Tiktok Influencer'.",
  skillsTooltip:
    "Ajoutez vos compétences afin que vos clients sachent ce que vous savez faire, par exemple 'Entrepreneuriat' ou 'Parentalité'.",
  aboutTooltip:
    "Ajoutez une description personnelle pour que vos clients se sentent bienvenus et apprécient votre marque personnelle.",
  speaksTooltip: "Indiquez les langues que vous parlez.",
  cropPhoto: "Recadrage de la photo",
  updatePhoto: "Mise à jour de la photo de profil",
  crop: "Culture",
  bookASession: "Lorsque vous réservez une session, elle s'affiche ici",
  videoAudioSettings: "Paramètres vidéo et audio",
  videoAudioSettingsDescription:
    "Utilisez ces paramètres pour régler la vidéo et l'audio",
  cameras: "Appareil photo",
  microphones: "Microphone",
  output: "Haut-parleurs",
  bgSettings: "Choisissez un arrière-plan vidéo",
  toStartAChat: "Pour commencer une discussion,",
  startAChat: "Et commencez une discussion.",
  messageToAnExpert: "Envoyez votre premier message à un expert",
  messageToAClient: "Envoyez votre premier message à un client",
  chatWindowGetToKnow:
    "1. Apprenez à connaître vos clients potentiels et leurs besoins",
  requestInformation:
    "2. Demander des informations supplémentaires si nécessaire",
  notGettingAReply:
    "3. Si vous n'obtenez pas de réponse, vous pouvez essayer de relancer poliment la conversation.",
  knowEachOther: "4. Apprenez à vous connaître et planifiez vos sessions",
  chatWindowProvideABrief:
    "1. Donnez une brève description de ce que vous recherchez",
  chatWindowAddAttachments: "2. Ajoutez des pièces jointes, si nécessaire",
  describeYourIssue:
    "Veuillez décrire votre demande et indiquer ce que vous voulez apprendre ou ce pour quoi vous avez besoin d'aide. Joignez tout fichier supplémentaire si nécessaire.",
  getAConfirmation: "Vous recevrez rapidement une confirmation de l'expert.",
  describeTheDetails:
    "Décrivez les détails, le prix et les conditions préalables de votre offre.",
  areYouSure: "Êtes-vous sûr de vouloir supprimer cette offre?",
  offerAvailable: "Offre disponible",
  offerUnavailable: "Offre non disponible",
  notHaveOffers: "Vous n'avez pas encore d'offres",
  createOne: "Créez-en un pour commencer à travailler avec vos clients.",
  getPaid: "Vous êtes payé au prix indiqué pour une durée déterminée.",
  describeYourOffer: "Décrivez brièvement votre offre.",
  describeRequirements:
    "Décrire les exigences des clients avant de se joindre aux sessions : message ou pièces jointes.",
  priceLimit: "Le prix peut être $0 ou de $2 à $10000.",
  priceLimitForUA: "Le prix peut être ₴0 ou de ₴83 à ₴412496.",
  offerNameDescription:
    "Essayez quelque chose comme 'Discovery Call' ou 'Basic Graphic Design' si c'est votre truc.",
  clickingConfirm: "En cliquant sur 'Confirmer', je suis d'accord avec ...",
  termsAndConditions: "Termes et conditions",
  preCharge: "Un prépaiement sera effectué pour demander votre session.",
  paymentFeesMayAlter: "Les frais du système de paiement peuvent varier.",
  notBeCharged:
    "Vous ne serez jamais facturé sans confirmation supplémentaire.",
  last: "Les 4 derniers",
  lastDigits: "4 derniers chiffres",
  billingPageName: "Nom",
  gateway: "Passerelle",
  prepareForTheMeeting: "Préparer la réunion",
  prepareForSession: "Préparez-vous à",
  fewRecomendations:
    "Quelques recommandations pour rendre votre session confortable et productive.",
  findAQuiet: "Trouvez un endroit calme où personne ne vous dérangera.",
  makeSure: "Assurez-vous que vous disposez d'une bonne connexion Internet",
  weRecommendUsing: "Nous recommandons d'utiliser des écouteurs",
  prepareYourQuestions: "Préparez vos questions",
  enjoyTheSession: "Profitez de la session !",
  initialization: "Initialisation...",
  beginOnboardingAsExpert:
    "Commencez à vous former en tant qu'expert sur Waylight Business.",
  connectStripeAccount:
    "Afin de recevoir de l'argent de vos clients, vous devez créer une méthode de paiement.",
  provideABrief: "1. Donnez une brève description de ce que vous recherchez",
  addAttachments: "2. Ajoutez des pièces jointes, si nécessaire",
  gettingAReply:
    "3. Si vous n'obtenez pas de réponse, vous pouvez essayer de relancer poliment la conversation.",
  getToKnow: "4. Apprenez à vous connaître et planifiez vos sessions ensemble.",
  fileType: "Le type de fichier n'est pas pris en charge",
  orDragAndDrop: "Ou glisser-déposer",
  upTo: "jusqu'à 1 Go (contactez le support si vous avez des tailles de fichier plus grandes)",
  headerImage: "Image d'en-tête",
  inviteClientsModalInviteClients: "Inviter les clients",
  shareYourLink: "Partagez votre lien personnel pour inviter vos clients !",
  addDays: "Établissez votre horaire régulier",
  publiclyInformation:
    "Il est possible d'ajouter plusieurs créneaux horaires pour un même jour de la semaine.",
  availableTimeslot: "Créneau horaire disponible",
  AddTimeslot: "Ajouter un créneau horaire",
  AddToSchedule: "Ajouter à l'agenda",
  signUpAs: "Inscrivez-vous en tant que",
  registerAsClient: "Je veux apprendre quelque chose de nouveau",
  registerAsExpert: "Je veux partager mon expérience",
  approveSession: "Approuver la session",
  approveThisSession:
    "Êtes-vous sûr de vouloir accepter cette session? En approuvant, vous acceptez de vous joindre à nous à l'heure indiquée.",
  noTimeslots:
    "Aucun créneau horaire disponible. Choisissez une autre date ou demandez à l'expert les horaires.",
  toastSavedSuccessfully: "Sauvegardé avec succès",
  toastDeletedSuccessfully: "Supprimé avec succès",
  toastEmailCopied: "Courriel copié",
  toastProfileCopied: "Profil copié",
  toastBookingAvalable: "Vous êtes maintenant disponible pour la réservation",
  toastBookingUnavalable: "Vous n'êtes pas disponible pour une réservation",
  toastQrCopied: "Code QR copié",
  toastPaymentMethodAdded: "Méthode de paiement ajoutée avec succès",
  toastPaymentMethodDeleted: "Méthode de paiement supprimée avec succès",
  toastUrlCopied: "Lien personnel copié",
  toastOfferUrlCopied: "Le lien de l'offre a été copié",
  toastSessionCanceled:
    "Cette session a été annulée pour des raisons de temps.",
  toastSessionWithYourself:
    "Vous ne pouvez pas réserver une session tout seul !",
  toastPayoutMethodAdded: "Méthode de paiement ajoutée avec succès",
  toastPayoutMethodDeleted: "Méthode de paiement supprimée avec succès",
  toastPrimaryCardError: "Impossible de définir la carte principale",
  toastPrimaryCardAdded: "Carte primaire ajoutée avec succès",
  toastTooLargeFile: "La taille du fichier est trop importante",
  toastOfferNotAdded: "L'offre n'a pas été ajoutée",
  toastOfferNotEdited: "L'offre n'a pas été modifiée",
  toastSessionLinkCopied: "Lien de session copié",
  toastMaxParticipantsReached: "Nombre maximal de participants atteint",
  toastNotMayJoin:
    "Malheureusement, vous ne pouvez pas vous joindre à cette session car elle a atteint sa capacité.",
  toastLinkExpired: "Ce lien a déjà expiré.",
  toastCannotStartSession:
    "Impossible de démarrer la session rapide maintenant",
  toastSessionExtendedSuccessfully: "La session a été prolongée avec succès",
  toastTimeslotStartAndEnd:
    "Vous ne pouvez pas ajouter une plage horaire qui commence et se termine à la même heure.",
  toastTimeslotEndsInPast:
    "Vous ne pouvez pas ajouter un créneau horaire qui se termine dans le passé.",
  toastTimeslotDuplicate:
    "Vous ne pouvez pas ajouter un créneau horaire en double",
  toastSkillNameTooLong:
    "Le nom de votre compétence est trop long, essayez de choisir un nom plus court.",
  buttonSave: "Sauvez",
  buttonCancel: "Annuler",
  buttonApprove: "Approuver",
  buttonDelete: "Supprimer",
  buttonChat: "Chat",
  buttonOpenChat: "Chat",
  buttonFiles: "Fichiers",
  buttonNotNow: "Pas maintenant.",
  buttonSubmit: "Soumettre",
  buttonNo: "Non",
  buttonEnd: "Fin",
  buttonSignUp: "S'inscrire",
  buttonYes: "Oui",
  buttonEdit: "Modifier",
  buttonCopy: "Copie",
  buttonReply: "Réponse",
  buttonAdd: "Ajouter",
  buttonSend: "Envoyer",
  buttonRequest: "Demande de soutien",
  buttonSet: "Définir",
  buttonEditPhoto: "Modifier la photo",
  buttonStartAnyway: "Commencez quand même",
  buttonWait: "Attendez",
  buttonCopyLink: "Copier le lien",
  buttonLeave: "Laissez",
  buttonExtraMinutes: "10 minutes supplémentaires",
  buttonViewOffer: "Voir les détails",
  buttonMoreDetails: "Plus de détails",
  buttonContinue: "Continuer",
  buttonBookACall: "Réserver un appel",
  notificationSetup: "Configuration",
  notificationFillProfile: "Profil de remplissage",
  notificationHi: "Salut !",
  notificationAddYourName:
    "Ajoutez votre nom et votre photo pour agrémenter la communication, si vous en avez envie. Pas de pression :)",
  notificationSetupBilling: "Configurer la facturation",
  notificationAddPaymentMethod:
    "Ajoutez une méthode de paiement pour pouvoir réserver des sessions.",
  notificationGoToBilling: "Allez à Facturation",
  notificationWeAreSureYouHaveALotToSay:
    "Nous sommes sûrs que vous avez beaucoup de choses à dire sur vous. Remplissez votre profil et commencez à partager !",
  notificationAddAnOffer: "Ajouter une offre",
  notificationAddYourOffers: "Ajoutez vos offres",
  notificationSetupVariousKindsOfSessions:
    "Mettez en place différents types de sessions pour vos clients, qui pourront être réservées sur votre profil.",
  notificationGoToMyOffers: "Accéder aux offres",
  notificationConnectStripe: "Configurer les paiements",
  notificationSetupPayouts: "L'onboarding des paiements",
  notificationInOrderToReceiveMoney:
    "Afin de recevoir de l'argent de vos clients, vous devez créer une méthode de paiement.",
  notificationGoToPayouts: "Allez dans Paiements",
  notificationSetYourSchedule: "Définissez votre horaire",
  notificationWorkAtConvenientHours:
    "Travailler aux heures qui vous conviennent",
  notificationSetupSchedule:
    "Définissez un calendrier et vos clients ne pourront réserver des séances qu'en fonction de celui-ci.",
  accountOn: "Compte sur",
  accountOff: "Compte désactivé",
  cancelSession: "Annuler la session",
  paymentFailed: "Échec du paiement",
  paymentFailedDescription:
    "Votre paiement n'a pas été effectué et nous n'avons pas pu demander une nouvelle session.",
  areYouSureCancel: "Êtes-vous sûr de vouloir annuler cette session?",
  areYouSureCancelPaidSession:
    "Vous êtes sûr de vouloir annuler? Votre paiement sera remboursé.",
  areYouSureCancelLessThanADay:
    "Êtes-vous sûr de vouloir annuler alors qu'il reste moins de 24 heures? Votre paiement ne sera PAS remboursé.",
  link: "Lien",
  view: "Voir",
  requires_action: "Nécessite une action",
  succeeded: "Succède à",
  signUpTitle: "S'inscrire",
  profileTitleNotify: "Profil | Waylight",
  dashboardTitleNotify: "Tableau de bord | Waylight",
  chatTitleNotify: "Chat | Waylight",
  with: "avec",
  expertsTitle: "Experts",
  expertsTitleNotify: "Explorer | Waylight",
  signUpTitleNotify: "S'inscrire | Waylight",
  chatTitle: "Chat",
  clientsTitle: "Clients",
  clientsTitleNotify: "Clients | Waylight",
  clientTitle: "Client",
  fixedPrice: "Prix fixe",
  internetConnectionLost: "Perte de la connexion Internet",
  fiveMinutesLeft: "5 minutes restantes",
  summarizeMeeting:
    "Veuillez résumer votre réunion et partager vos impressions",
  oopsConnectionLost: "Oups... Connexion à la partie perdue",
  partyTemporaryLostConnection:
    "Il semble que votre groupe ait temporairement perdu la connexion à la réunion. Désolé pour ce désagrément.",
  leaveAReviewFor: "Laissez un commentaire sur",
  leaveAReview: "Laissez un commentaire",
  review: "Examen",
  followers: "Suiveurs",
  rating: "Classement",
  hitStarRate: "Notez la session ci-dessous !",
  reviewsEmpty: "Il n'y a pas encore de commentaires",
  reviews: "Critiques",
  sessionEnded: "La session s'est terminée avec succès",
  thankYouForLighting: "Merci d'avoir éclairé le chemin de quelqu'un !",
  sessionNotStarted: "La session n'a pas commencé",
  devicePermissionTittle:
    "Autorisez Waylight à accéder à votre microphone et à votre caméra. Rafraîchissez la page si vous n'avez pas de vidéo.",
  networkConnection: "La qualité de votre connexion internet est",
  unknown: "Inconnu",
  low: "Faible",
  average: "Moyenne",
  good: "Bon",
  expertDidNotCome:
    "Malheureusement, votre expert ne s'est pas présenté. Vous ne serez pas facturé et serez remboursé. Nous nous excusons pour ce désagrément.",
  noFilesAdded: "Aucun fichier ajouté.",
  enterYourEmail: "Entrez votre adresse email",
  required: "Le champ est obligatoire",
  emailOrPassword: "Courriel ou mot de passe incorrect",
  emailInUse: "Ce courriel est déjà utilisé",
  businessIsNotAvailableTitle:
    "Waylight Business n'est pas disponible dans votre pays. Soyez le premier à être informé de son lancement.",
  beInTouch: "Restez en contact",
  businessIsNotAvailableEmailAddress: "Adresse électronique",
  pleaseTryAgain:
    "Nous n'avons pas pu enregistrer votre adresse électronique. Veuillez réessayer.",
  thankYou: "Merci, nous vous contacterons dès que cela sera mis en place.",
  wereNoChatMessages: "Il n'y a pas eu de messages de chat dans cette session",
  yourCountry: "Pays de votre banque",
  open: "Ouvrir",
  offerName: "Nom de l'offre",
  gatewayCountryWarning:
    "Attention : il doit s'agir du pays de votre institution bancaire et ne peut PAS être modifié ultérieurement, ce qui pourrait affecter votre capacité à recevoir des paiements.",
  addAttachment: "Ajouter une pièce jointe",
  you: "Vous",
  explore: "Explorez",
  stats: "Stats",
  more: "Voir plus",
  noPrice: "Offres gratuites",
  theyWillAppear: "Ils apparaîtront ici lorsque vous commencerez à interagir",
  noExpertFoundUsingFilter:
    "Aucun expert n'a été trouvé en utilisant ce filtre.",
  tryAnotherInput: "Essayez une autre balise ou une autre entrée.",
  noFavoriteExpertsFound: "Aucun expert préféré n'a été trouvé.",
  tryAddingSomeone: "Essayez d'ajouter quelqu'un aux favoris.",
  agoraDeniedError:
    "Veuillez activer votre accès au microphone et à la caméra dans les paramètres afin d'utiliser Waylight !",
  editCover: "Couverture de l'édition",
  language: "Langue",
  invalid: "Invalide",
  scrollPastHistory:
    "Faites défiler l'historique de vos sessions précédentes ci-dessus.",
  earnedFromLastSession: "Gain de la dernière session",
  typeHere: "Tapez ici",
  schedule: "Programme",
  offers: "Offres",
  qrSharing: "Téléchargez et partagez votre code QR.",
  qrSharingMobile:
    "Appuyez longuement sur le code QR pour le copier et le partager.",
  preview: "Prévisualisation",
  back: "Dos",
  love: "Fabriqué avec amour par Waylight",
  allRightsReserved: "© 2024 Waylight Inc. Tous droits réservés.",
  allRightsReservedSecond: "© 2022 Arceon Consulting OÜ. Tous droits réservés",
  termsOfService: "Conditions d'utilisation",
  privacyPolicy: "Politique de confidentialité",
  sessionSharingpopupTitle: "Impossible de lancer le partage d'écran !",
  sessionSharingpopupDescription:
    "Vérifiez si vous disposez d'une version récente de votre navigateur et si celui-ci dispose des autorisations système pour le partage d'écran. Enfin, vous pouvez essayer de rafraîchir la page. Si le problème persiste, veuillez contacter le support technique !",
  sessionSharingpopupSafariDescription:
    "Le partage d'écran n'est pas pris en charge par votre navigateur. Les dernières versions des navigateurs populaires prennent en charge cette fonction.",
  copy: "Copie",
  expertWithoutOffers: "Cet expert n'a pas encore ajouté d'offres payantes.",
  errorViewHeader: "Oups, quelque chose a mal tourné !",
  errorViewDescription:
    "Vous avez rencontré une erreur. Ce lien est peut-être rompu ou vous n'avez pas accès à un article.",
  returnToHome: "Retour à la maison",
  invalidURL:
    "URL non valide. Assurez-vous qu'elle commence par 'https://' ou 'http://'.",
  invalidVideoExternalURL:
    "URL de la vidéo non valide. Ajoutez un lien Youtube ou Vimeo.",
  repeatedURL: "Ce lien a déjà été ajouté",
  tooLongUrl:
    "Ce lien est trop long. Veuillez ne pas utiliser les liens de plus de 128 caractères.",
  edited: "Modifié",
  emptyCards: "Vous n'avez pas encore ajouté de cartes",
  emptyBillingHistory: "Vous n'avez pas encore de transactions",
  emptyBillingHistoryDescription:
    "Ils apparaîtront ici lorsque vous commencerez à réserver des sessions",
  addNewSkill: "Ajouter une nouvelle compétence",
  confirmationAddSkill:
    "Êtes-vous sûr de vouloir ajouter une nouvelle compétence?",
  addPaymentMethod: "Ajouter une méthode de paiement",
  stripeDescription:
    "Pour des transactions avec des experts de plus de 50 pays",
  fondyDescription: "Pour les transactions avec des experts de l'Ukraine",
  fondyAddCardDescription:
    "Ajoutez un mode de paiement pour continuer. Un test de charge sera tenté pour confirmer.",
  timeZoneChangedHeader: "Changement de fuseau horaire",
  timeZoneSChangedDescription:
    "Nous avons détecté que votre fuseau horaire a changé. Voulez-vous mettre à jour le fuseau horaire de votre profil?",
  updateButton: "Mise à jour",
  and: "et",
  moreFiles: "plus de fichiers",
  addPayout: "Ajoutez au moins une méthode pour recevoir les paiements",
  testChargeWarning: "Une charge d'essai sera tentée pour confirmer.",
  payoutMethods: "Vos méthodes de paiement",
  identifier: "Identifiant",
  stopWar: "Stop à la guerre",
  yourAreOnlyOneHere: "Vous êtes le seul ici",
  yourAreOnlyOneHereSingle:
    "Vous êtes le seul ici. Partagez le lien ci-dessous pour que d'autres puissent vous rejoindre",
  clientView: "Client",
  businessView: "Entreprise",
  mainPage: "Page principale",
  signUpCompleted: "Inscription terminée",
  selectPaymentInfo:
    "Les méthodes de paiement disponibles dépendent de la passerelle de votre expert.",
  payment: "Paiements",
  paymentWasDeclined: "Le paiement a été refusé.",
  payoutWasDeclined: "Le paiement a été refusé.",
  primary: "Primaire",
  notUsed: "Non utilisé",
  releaseBannerTitle: "Waylight a été mis à jour vers une nouvelle version.",
  refresh: "Rafraîchir",
  country: "Pays",
  viewAll: "Voir tous",
  videoPresentation: "Présentation vidéo",
  bookFor: "Livre pour",
  bookForFree: "Réservez gratuitement",
  follow: "Suivez",
  followed: "Suivi de",
  emptyPortfolio: "Il semble qu'il n'y ait aucun fichier dans ce portefeuille.",
  media: "Médias",
  other: "Dossiers",
  emptyReview: "On dirait qu'il n'y a pas de critiques",
  newest: "Le plus récent",
  oldest: "La plus ancienne",
  viewProfileInfo: "Afficher les informations du profil",
  emptyTransactions: "Il semble qu'il n'y ait pas de transactions",
  emptyOffers: "Malheureusement, cet expert n'a pas encore d'offres.",
  searchExperts: "Recherche d'experts",
  modifySchedule: "Modifier le calendrier",
  editMode: "Mode d'édition",
  finishEditing: "Terminer l'édition",
  editPersonalInfo: "Modifier les informations personnelles",
  editPortfolio: "Modifier le portefeuille",
  editOffers: "Modifier les offres",
  profileId: "ID personnel et public",
  profileSettings: "Paramètres du profil",
  editPublicID: "Modifier l'ID public",
  removeTimeslot: "Supprimer un créneau horaire",
  areYouSureRemoveTimeslot: "Etes-vous sûr de vouloir supprimer le timeslot?",
  qrCode: "Code QR",
  communication: "Communication",
  editCoverDescription: "Personnalisez votre image de couverture",
  editProfileData: "Modifier les données du profil",
  editProfileDataDescription:
    "Ajoutez des informations sur vous-même et votre parcours",
  editPublicIDDescription:
    "Choisissez un identifiant public unique pour votre profil",
  profilePhoto: "Photo de profil",
  publicIDDescription: "Il s'agit d'un identifiant généré automatiquement !",
  publicIDWarning:
    "N'oublie pas de le changer en quelque chose de cool en mode édition, comme 'supergirl'.",
  followWarning: "Le bouton 'Suivre' fonctionnera pour tous les utilisateurs.",
  openChatWarning:
    "Vous ne pourrez pas discuter avec vous-même, mais vos clients le pourront.",
  bookingOfferWarning:
    "Les utilisateurs pourront acheter une offre en cliquant ici.",
  shareOffer: "Copier le lien",
  turnOffAccount: "Désactiver le compte",
  turnOnAccount: "Activer le compte",
  areYouSureYouWantTurnOff:
    "Êtes-vous sûr de vouloir désactiver complètement votre compte?",
  areYouSureYouWantTurnOn: "Vous êtes sûr de vouloir réactiver votre compte?",
  disableFreeChat: "Désactiver le chat gratuit",
  enableFreeChat: "Activer le chat gratuit",
  areYouSureYOuWantDisableFreeChat:
    "Êtes-vous sûr de vouloir désactiver le chat gratuit?",
  areYouSureYOuWantEnableFreeChat:
    "Êtes-vous sûr de vouloir activer le chat gratuit?",
  cancelEditing: "Annuler l'édition",
  descriptionCancelEditing: "Êtes-vous sûr de vouloir annuler l'édition?",
  takenPublicID: "Cet identifiant public est déjà pris",
  charactersMatch: "2-64 caractères",
  charactersBetweenMatch: "Le champ doit comporter de 1 à 64 caractères",
  charactersMaxMatch: "La longueur maximale du champ est de 64 caractères",
  deletePhoto: "Supprimer la photo",
  descriptionDeletePhoto: "Vous êtes sûr de vouloir supprimer votre photo?",
  accountOnDescription:
    "Lorsque le compte est désactivé, il n'est pas disponible et n'est visible par aucun moyen (liens directs, résultats de recherche, etc.).",
  freechat: "Chat gratuit",
  freechatDescription:
    "Lorsque le chat gratuit est désactivé, les utilisateurs ne pourront pas engager la communication avec vous par ce moyen. En revanche, vous pourrez le faire avec vos clients.",
  freeChatWarning:
    "Ce compte a désactivé le chat gratuit. Vous pouvez demander une session pour communiquer.",
  editMessage: "Modifier le message",
  replyTo: "Répondre à",
  file: "Fichier",
  unableToJoinSession: "Impossible de rejoindre la session",
  backgroundWarning:
    "Avertissement : les arrière-plans ne sont pas entièrement pris en charge par les navigateurs Safari et Firefox et peuvent ne pas fonctionner.",
  single: "Rapidement",
  scheduleSession: "Programmez une session avec un expert ou un créateur.",
  startSessionImmediately:
    "Commencez immédiatement la session gratuite. Invitez d'autres personnes directement avec un lien.",
  quickSessionWarningTittle: "Vous êtes sûr de vouloir commencer la session?",
  quickSessionWarningDescription:
    "Vous avez une session programmée qui chevauche la session rapide que vous essayez de démarrer.",
  connectIn: "Connecter en",
  connectOn: "Connectez-vous sur",
  sessionDecisionTittle: "Votre parti ne s'est pas montré.",
  expertSessionDecision:
    "Souhaitez-vous mettre fin à la session ou attendre plus longtemps? La fin de la session entraînera le transfert du paiement sur votre compte.",
  clientSessionDecision:
    "Souhaitez-vous mettre fin à la session ou attendre plus longtemps? Si vous mettez fin à la session, votre paiement vous sera remboursé.",
  cancelledSessionTittle: "Cette session a été annulée",
  cancelledSessionExpertDescription:
    "Le paiement a été transféré sur votre compte. Nous nous excusons pour le désagrément.",
  cancelledSessionClientDescription:
    "Vous ne serez pas facturé et serez remboursé. Nous nous excusons pour ce désagrément.",
  areYouSureYouWantLeaveSession: "Tu es sûr de vouloir quitter la session?",
  areYouSureYouWantLeaveSessionDescription:
    "Vous pourrez revenir à la session jusqu'à ce qu'elle se termine.",
  addExtraMinutes: "Ajouter 10 minutes supplémentaires",
  addExtraMinutesDescription:
    "Êtes-vous sûr de vouloir ajouter 10 minutes supplémentaires à cette session?",
  unableAddExtraMinutes: "Impossible d'ajouter 10 minutes supplémentaires",
  unableAddExtraMinutesDescription:
    "Malheureusement, vous avez une session programmée dans moins de 10 minutes.",
  monday: "Lundi",
  tuesday: "Mardi",
  wednesday: "Mercredi",
  thursday: "Jeudi",
  friday: "Vendredi",
  saturday: "Samedi",
  sunday: "Dimanche",
  h: "h",
  sharingSetting: "Paramètres de partage",
  call: "Appelez",
  public: "Public",
  byLinkOnly: "Par lien uniquement",
  offerInfo: "Informations sur l'offre",
  coverVideo: "Couverture / vidéo",
  imageVideo: "Image / vidéo",
  chooseCountry: "Choisir le pays",
  passwordChangedSuccessfully: "Le mot de passe a été modifié avec succès",
  connected: "Connecté",
  passedALot: "Adopté",
  chooseCountryBank:
    "Choisissez le pays de votre banque ou de votre service financier.",
  createAccountContinue: "Créer un compte pour continuer",
  metasignInTitle: "Connexion | Waylight",
  metasignUpTitle: "S'inscrire | Waylight",
  metapasswordForgotTitle: "Mot de passe oublié | Waylight",
  metapasswordResetTitle: "Réinitialiser le mot de passe | Waylight",
  clientflowStep1Title: "Trouver le lien dans la recherche BIO ou Waylight",
  clientflowStep1:
    "Trouvez le lien Waylight de votre créateur, influenceur, expert ou tuteur dans les réseaux sociaux (Instagram, Facebook, YouTube, LinkedIn, etc.) ou tapez le nom ou le surnom dans la recherche Waylight.",
  clientflowStep2Title:
    "Réserver un appel vidéo ou s'abonner à un blog ou à un chat",
  clientflowStep2:
    "Ouvrez et explorez le profil professionnel de votre créateur, influenceur, expert ou tuteur. Trouvez une offre intéressante pour un appel vidéo 1:1, réservez-la et sautez dessus à l'heure prévue. Essayez de chatter après ou avant pour obtenir plus d'informations sur le long terme. Abonnez-vous au blog personnel réservé aux membres avec des tutoriels, des articles, des conseils vidéo, des coulisses, des expériences de vie personnelles, des leçons, de la musique, etc.",
  clientflowStep3Title:
    "Vérifier les évaluations et les critiques décentralisées",
  clientflowStep3:
    "Les évaluations décentralisées constituent un historique pour les créateurs et les experts, ne peuvent pas être vendues ou falsifiées et créent un sentiment de confiance, de transparence, de preuve d'expertise et de demande de contenu pour les clients et les abonnés. Laissez vos évaluations réelles après les appels vidéo, les chats et sous les articles réservés aux membres dans les blogs. Partagez vos évaluations pour aider la communauté Waylight à mettre en lumière les meilleures.",
  confirmBooking: "Confirmer la sélection",
  free: "Gratuit",
  unableVerifyEmail: "Nous n'avons pas pu vérifier votre email.",
  thankYouForVerifiedEmail: "Merci de vérifier votre email !",
  reSend: "Renvoyer",
  signInToContinue: "Se connecter pour continuer",
  switchingToClientView: "Passage à la vue client",
  switchingToExpertView: "Passer à la vue d'entreprise",
  cookiePermission:
    "Nous apprécions la protection de votre vie privée. Nous utilisons des cookies pour améliorer votre expérience de navigation, servir un contenu personnalisé et analyser notre trafic. En cliquant sur 'Accepter', vous consentez à notre utilisation des cookies.",
  readMore: "Lire la suite",
  notReadableDevicesWarning:
    "Attention : votre caméra et/ou votre microphone sont soit en cours d'utilisation, soit inaccessibles !",
  howPayoutsWork: "Comment fonctionnent les versements?",
  payoutsOnboarding1: "Compléter l'onboarding pour permettre les paiements.",
  payoutsOnboarding2:
    "Nous gardons votre argent en sécurité jusqu'à ce que vous montiez à bord.",
  payoutsOnboarding3:
    "Vous aurez besoin de vos coordonnées bancaires ou de votre carte, selon votre pays.",
  payoutsOnboarding4: "Les paiements sont effectués chaque lundi.",
  payoutsOnboarding5:
    "Vous pouvez vérifier le statut du paiement dans Transactions.",
  payoutsOnboarding6:
    "La passerelle de paiement facture 0,25% + 0,25$ par paiement.",
  payoutsOnboardingEcommpay6:
    "La passerelle de paiement facture 0,5% pour les cartes et 0% pour les marchands si les frais clients sont activés.",
  payoutsOnboarding7:
    "Si c'est la première fois que vous recevez le paiement, il peut prendre de 7 à 14 jours ouvrables pour arriver en raison des limitations de la passerelle.",
  payoutsOnboarding8:
    "Normalement, les paiements prennent environ 3 jours ouvrables pour arriver.",
  payoutsOnboarding9:
    "Il n'est actuellement pas possible de modifier le pays. Contactez-nous si vous en avez besoin et nous essaierons de résoudre le problème au cas par cas.",
  payoutsOnboarding10:
    "Waylight facture une commission variable en fonction de votre plan.",
  payoutsOnboarding11: "Si vous avez des questions, contactez",
  supportAlternative: "Soutien",
  freeSessionDecision:
    "Voulez-vous mettre fin à la session ou attendre plus longtemps?",
  cancelledFreeSessionDescription:
    "Vous êtes le seul à vous être présenté. Nous nous excusons pour le dérangement.",
  m: "m",
  cityNotFound:
    "Aucune option trouvée. Choisissez la grande ville la plus proche.",
  notifications: "Notifications",
  telegramBot: "Telegram Bot",
  notConnected: "non connecté",
  connectTelegramBot: "Connecter le robot Telegram",
  disconnectTelegramBot: "Déconnecter le robot Telegram",
  telegramConnected: "connecté",
  bgSettingsDescription:
    "Utilisez ces paramètres pour régler l'arrière-plan de votre flux vidéo",
  selectBackground: "Sélectionner l'arrière-plan",
  bookingDelay: "Définir un délai de réservation",
  bookingDelayDescription:
    "Indiquez combien de temps à l'avance vous pouvez être réservé.",
  processingFee: "Frais de système de paiement",
  payoutsOnboardingEcommpay1:
    "Sélectionnez la méthode de paiement par carte de crédit personnelle ou l'entrepreneuriat privé.",
  payoutsOnboardingEcommpay3:
    "Vous devez ajouter au moins une carte de crédit personnelle + votre code fiscal individuel OU terminer l'enregistrement du marchand Fondy pour que les transactions fonctionnent !",
  payoutsOnboardingEcommpay4:
    "Les paiements sont effectués après chaque transaction.",
  payoutsOnboardingEcommpay7:
    "Les paiements sont effectués au plus tard le jour ouvré suivant.",
  tutorials: "Produits",
  tutorialsAndCourses: "Acheter des produits",
  noOffers: "Il semble qu'il n'y ait pas encore d'offres ici",
  newSessionOffer: "Nouvelle offre de session",
  newSessionOfferDescription: "Créez une session vidéo payante ou gratuite",
  newTutorialOffer: "Nouvelle offre de produit",
  newTutorialOfferDescription: "Créez un produit payant ou gratuit",
  sessionName: "Nom de la session",
  tutorialName: "Nom du produit",
  tutorialNameDescription:
    "Choisissez un nom pour votre produit, par exemple, « Cours de danse n°1 »",
  cover: "Couverture",
  tutorialCoverTooltip:
    "Téléchargez une image ou une vidéo à afficher dans la description du produit.",
  getTutorialPaid: "Vous êtes payé à chaque achat de ce produit.",
  unlockedAfterPurchase: "Débloqué après l'achat",
  unlockedAfterPurchaseDescription:
    "Ajoutez du contenu que vos clients pourront débloquer.",
  tutorialDetailsDescription:
    "Ajoutez des commentaires, des détails ou des instructions ici",
  removeSection: "Supprimer la section",
  addAnotherSection: "Ajouter une autre section",
  details: "Détails",
  toastEmptyUnlockedContent: "Le contenu verrouillé ne peut pas être vide",
  toastTutorialNotEdited: "Ce produit n'a pas été édité",
  removeSectionDescription: "Êtes-vous sûr de vouloir supprimer cette section?",
  buyFor: "Acheter pour",
  buttonOpenChatAlt: "Ouvrir le chat",
  buttonShare: "Partager",
  tutorial: "Produit",
  toastBuyingYourOwnTutorial:
    "Vous ne pouvez pas acheter votre propre produit !",
  purchasingInProgress: "Transaction en cours",
  byClickingConfirm: "En cliquant",
  iAgreeWith: "j'accepte les",
  allowWaylightToCharge:
    "et autoriser Waylight à débiter ma carte maintenant et à l'avenir conformément aux conditions d'abonnement jusqu'à ce que j'annule.",
  paymentProcessingFees: "Des frais de traitement peuvent être facturés.",
  bookingTutorialWarning:
    "Les utilisateurs pourront acheter un produit en cliquant ici.",
  editTutorials: "Modifier les produits",
  topReview: "Meilleure critique",
  addForFree: "Ajouter gratuitement",
  tutorialPaymentFailed:
    "Votre paiement n'a pas été effectué et vous n'avez pas pu acheter ce produit.",
  buy: "Acheter",
  tutorialWith: "produit par",
  purchased: "Acheté",
  noTutorialsFound: "Aucun produit trouvé",
  noTutorialsFoundDescription:
    "Ils apparaîtront ici lorsque vous achèterez ou ajouterez un produit",
  tags: "Balises",
  createSessionOffer: "Créer une offre de session",
  createSessionOfferDescription:
    "Ajoutez une session vidéo payante ou gratuite.",
  createTutorial: "Créer une offre de produit",
  createTutorialDescription: "Ajoutez un produit payant ou gratuit.",
  requires_transfer: "Nécessite une formation",
  pending: "En attente de paiement",
  requires_payout: "En attente de paiement",
  requires_capture: "En attente",
  reschedule: "Replanifier",
  rescheduleDelay: "Définir un délai de report",
  rescheduleDelayDescription:
    "Précisez combien de jours avant la session vous pouvez la replanifier.",
  rescheduleSession: "Replanifier la session",
  rescheduleSessionDescription:
    "Êtes-vous sûr de vouloir replanifier cette session?",
  buttonChange: "Replanifier",
  timeslots: "Créneaux horaires",
  specifyReason: "Précisez la raison du changement de date et d'heure.",
  cannotRescheduleSession: "Impossible de replanifier la session",
  rescheduleReason: "Raison de la replanification",
  optionLink: "Ajouter un lien externe",
  optionUpload: "Ou téléchargez un fichier",
  tutorialLinkDescription: "Ajoutez le lien vers votre produit.",
  tutorialUploadDescription:
    "Téléchargez n'importe quel fichier ici. Vous pouvez ajouter un fichier par section.",
  bgNone: "Aucun",
  bgLowBlur: "Flou faible",
  bgMediumBlur: "Flou moyen",
  bgHighBlur: "Flou élevé",
  bgSolidColour: "Couleur unie",
  bgCustomImage: "Image personnalisée",
  telegramNotifications: "Notifications Telegram",
  connectYourTelegramAccount:
    "Connectez votre compte Telegram pour recevoir des notifications de Waylight.",
  buttonConnectTelegram: "Connecter Telegram",
  notificationGoToMyProfile: "Aller à mon profil",
  setupSchedule: "Configurer le calendrier",
  aboutTheTutorial: "À propos du produit",
  added: "Ajouté",
  orderingChangeInProgress: "Changement de commande en cours...",
  gloryToUkraine: "Gloire à l'Ukraine !",
  terroristStates:
    "Waylight ne fonctionne pas pour les utilisateurs des pays sanctionnés par des régimes dictatoriaux et terroristes : Russie, Iran, Corée du Nord, Syrie, Cuba, Venezuela, Afghanistan.",
  never: "Pas de limite",
  expiration: "Limite de temps",
  expirationDescription:
    "Les clients auront accès à ce produit pendant un certain nombre de jours",
  daysLeft: "jours restants",
  tutorialExpired: "Votre accès à ce produit a expiré.",
  days: "jours",
  tips: "Dons",
  newTipOffer: "Nouvelle offre de don",
  newTipOfferDescription: "Créer un don",
  tipName: "Nom du don",
  tipNameDescription: "Choisissez un nom pour votre don",
  tipCoverTooltip:
    "Téléchargez une image ou une vidéo à afficher dans la description du don.",
  aboutTheTip: "À propos du don",
  describeYourTip:
    "Ajoutez une description du don : collecte de dons, organisation d'une action caritative ou achat d'un café?",
  tipAmounts: "Ajoutez trois montants de don",
  tipAmountsDescription:
    "Vos clients pourront également saisir leur propre montant s'ils le souhaitent.",
  bonusTipper: "(Facultatif) Envoyer un bonus au client",
  bonusTipperDescription:
    "Ajoutez du contenu ou un message que vos clients recevront en bonus après avoir fait un don.",
  tipLinkDescription: "Ajoutez le lien vers votre bonus.",
  tipMessageDescription: "Ajoutez votre message texte ici.",
  toastTipNotEdited: "Ce don n'a pas été édité",
  leaveATip: "Laisser un don",
  bookingTipWarning:
    "Les utilisateurs pourront laisser un don en cliquant ici.",
  includesAReward: "Inclut un bonus",
  selectTip: "Sélectionnez combien vous souhaitez donner",
  selectTipAmount: "Sélectionnez un montant à donner",
  toastBuyingYourOwnTip: "Vous ne pouvez pas acheter votre propre don !",
  tipSuccessfullyBought: "Don acheté avec succès",
  tip: "Don",
  tipPaymentFailed:
    "Votre paiement n'a pas été effectué et vous n'avez pas pu acheter ce don.",
  purchasedOffers: "Achats",
  noTipsFound: "Aucun don trouvé",
  noTipsFoundDescription: "Ils apparaîtront ici lorsque vous achèterez un don",
  thankYouExclamation: "Merci!",
  editTips: "Modifier les dons",
  tipPriceLimit: "Le prix peut être de $2 à $10000.",
  tipPriceLimitForUA: "Le prix peut être de ₴83 à ₴412496.",
  cardAuthorization: "Autorisation de carte de crédit",
  failed: "Échec",
  communityName: "Nom de la communauté",
  communityNameDescription:
    "Choisissez un nom pour votre communauté, comme « Mon canal Telegram exclusif réservé aux abonnés »",
  getCommunityPaid: "Prix d'abonnement",
  Period: "Période*",
  expirationCommunityDescription: "Définissez la durée de l'abonnement",
  aboutTheCommunityOffer: "À propos de l'offre",
  TelegramChannelName: "Nom du canal/groupe Telegram*",
  communityTelegramNameDescription:
    "Sélectionnez le nom de votre canal ou groupe. S'il est vide, veuillez cliquer sur l'icône d'information pour obtenir des instructions.",
  communities: "Communautés",
  community: "Communauté",
  subscribeFor: "Abonnez-vous pour",
  subscribed: "Abonné",
  unsubscribe: "Se désabonner",
  unsubscribeDescription:
    "Êtes-vous sûr de vouloir vous désabonner?\nVotre accès expirera le",
  subscribeDescription: "{{community}}.",
  usubscribed: "Désabonné",
  unlimited: "Illimité",
  subscribeAgain: "S'abonner à nouveau",
  unsubscribeDescriptionModal:
    "Êtes-vous sûr de vouloir vous réabonner?\nVous serez facturé pour la prochaine période le",
  subscribe: "S'abonner",
  currentlyMember: "Vous êtes actuellement membre de {{title}} sur Telegram.",
  notCurrentlyMember:
    "Vous n'êtes actuellement pas membre de {{title}} sur Telegram.",
  newCommunityOffer: "Nouvelle offre de communauté",
  newCommunityOfferDescription: "Créer ou gérer une communauté",
  editCommunities: "Modifier les communautés",
  joinCommunities: "Rejoindre les communautés",
  noCommunitiesAdded: "Aucune communauté ajoutée",
  TheyWillAppear: "Ils apparaîtront ici lorsque vous créerez vos offres.",
  createCommunity: "Créer une communauté",
  noAccessToData: "Pas d'accès aux données ! Ajoutez",
  noAccessToDataAsAnAdministrator:
    "en tant qu'administrateur à votre groupe pour fournir l'accès.",
  retryPayment: "Réessayer le paiement",
  areYouSureYouWantToSetCard:
    "Êtes-vous sûr de vouloir définir la carte *{{last4}} comme principale?",
  thingsToTry: "Choses à essayer :",
  checkIfYouHaveSufficient:
    "Vérifiez si vous avez suffisamment de fonds sur votre solde ou si les paiements Internet sont activés.",
  selectDifferentPaymentMethod:
    "Sélectionnez une méthode de paiement différente.",
  set: "Définir",
  primaryCard: "Principale",
  setPrimary: "Définir comme principale",
  inviteMember: "Inviter un membre",
  copyLink: "Copier le lien",
  members: "Membres",
  cancel: "Annuler",
  actions: "ACTIONS",
  subscribedUntil: "ABONNÉ JUSQU'AU",
  waylightClients: "Client WAYLIGHT",
  nameMembers: "NOM",
  admin: "Admin",
  external: "Externe",
  subscriber: "Abonné",
  unsubscribed: "Désabonné",
  kicked: "Expulsé",
  owner: "Propriétaire",
  save: "Enregistrer",
  kickAndBan: "Expulser",
  areYouSureYouWantToRemove:
    "Êtes-vous sûr de vouloir supprimer « {{name}} » de votre canal de manière?",
  WeHaveEncounteredAnIssue:
    "Nous avons rencontré un problème avec votre paiement récurrent !",
  yourPaymentHasNotGone:
    "Votre paiement n'a pas été effectué et nous n'avons pas pu réessayer la charge pour votre abonnement.",
  gatewayFee: "Les clients paient les frais de passerelle",
  gatewayFeeDescription:
    "Avec ce paramètre activé, les frais de passerelle de paiement seront ajoutés aux factures des clients. Lorsqu'il est désactivé, les frais sont déduits des paiements de l'entreprise.",
  enableGatewayFee: "Activer les frais de passerelle pour les clients?",
  areYouSureYOuWantEnableGatewayFee:
    "Êtes-vous sûr de vouloir activer les frais de passerelle pour les clients?",
  disableGatewayFee: "Désactiver les frais de passerelle pour les clients?",
  areYouSureYOuWantDisableGatewayFee:
    "Êtes-vous sûr de vouloir désactiver les frais de passerelle pour les clients? Les frais seront déduits de vos paiements !",
  communityTooltip:
    "Assurez-vous d'avoir ajouté Waylight Bot à votre groupe/canal en tant qu'administrateur, connecté votre compte Telegram à Waylight et que c'est la seule offre pour ce canal !",
  communityCoverTooltip:
    "Téléchargez une image ou une vidéo à afficher dans la description de la communauté.",
  setCard: "Définir",
  expirationDateMustBeInFuture: "La date d'expiration doit être dans le futur",
  accessGranted: "Accès accordé",
  noAccess: "Pas d'accès",
  manageCommunity: "Gérer la communauté",
  manage: "Gérer",
  January: "Janvier",
  February: "Février",
  March: "Mars",
  April: "Avril",
  May: "Mai",
  June: "Juin",
  July: "Juillet",
  August: "Août",
  September: "Septembre",
  October: "Octobre",
  November: "Novembre",
  December: "Décembre",
  toastBuyingYourOwnCommunity:
    "Vous ne pouvez pas vous abonner à votre propre communauté !",
  priceLimitCommunity: "Le prix peut être de $5 à $10000.",
  priceLimitCommunityForUA: "Le prix peut être de ₴198 à ₴195000.",
  toastRetryPaymentSuccess: "Votre nouvelle tentative de paiement a réussi.",
  toastCommunityLinkCopied: "Lien de la communauté copié",
  communityPaymentFailed: "Paiement de la communauté échoué",
  AddExpirationDateManually: "Ajouter la date d'expiration manuellement:",
  SetTimeFormat: "Définir le format de l'heure",
  events: "Événements",
  newEventOffer: "Nouvelle offre d'événement",
  newEventOfferDescription: "Créez un événement planifié",
  eventName: "Nom de l'événement",
  eventNameDescription:
    "Choisissez un nom pour votre événement, comme « Masterclass SEO »",
  eventCoverTooltip: "Conseil de couverture d'événement",
  getEventPaid:
    "Vous êtes rémunéré pour chaque achat de places dans cet événement",
  aboutTheEventOffer: "À propos de l'événement",
  dateAndTime: "Date et heure",
  clickToSetDateAndTime: "Cliquez pour définir une date et une heure",
  DescribeYourEvent: "Décrivez brièvement votre événement.",
  onlineEvent: "Événement en ligne",
  offlineEvent: "Événement hors ligne",
  eventLinkDescription:
    "Ajoutez des informations sur le lieu de l'événement, comme Zoom ou Meet",
  uploadOptional: "Télécharger (facultatif)",
  uploadEventDetails:
    "Téléchargez ici tout fichier avec des détails facultatifs sur l'événement",
  addInformationAboutLocation:
    "Ajoutez des informations sur le lieu de l'événement ou un lien vers une carte",
  location: "Lieu",
  address: "Adresse",
  addressOptional: "Adresse (facultatif)",
  addAddressEvent: "Ajoutez l'adresse de l'événement",
  AllDayEvent: "Ceci est un événement qui dure toute la journée.",
  toastBuyingYourOwnEvent: "You can not buy your own event!",
  event: "Événement",
  timeToEvent: "Temps jusqu'à l'événement",
  hoursEvent: "heures",
  seatsLeft: "places disponibles",
  follower: "Abonné",
  priceLimitEventForUA: "Le prix peut être de ₴198 à ₴195000.",
  soldOut: "Épuisé !",
  joinEvents: "Participer aux événements",
  noEventFound: "Aucun événement trouvé",
  noEventsFoundDescription:
    "Ils apparaîtront ici lorsque vous aurez assisté à au moins un événement",
  editEvents: "Modifier les événements",
  attendForfree: "Participer gratuitement",
  disabled: "Désactivé",
  enabled: "Activé",
  personalCreditcard: "Personnel (Carte de crédit)",
  privateEntrepreneur: "Entrepreneur privé (IBAN)",
  selectPayoutMethod: "Sélectionner le mode de paiement",
  enterYourPersonalTaxCode: "Entrez votre code fiscal personnel*",
  createFondyMerchantAccount:
    "Créez un compte marchand Fondy et finalisez l'intégration :",
  create: "Créer",
  participantsMin: "Le nombre minimum de participants est 1",
  personalTaxCodeError:
    "Le code fiscal individuel doit être un numéro valide !",
  participantsError: "Le nombre de participants doit être un numéro valide !",
  peName: "Nom de l'AE",
  url: "Site web",
  okpo: "OKPO",
  phone: "Téléphone",
  peNameTooltip:
    "Le nom officiel de votre entreprise individuelle : 'Nom Prénom Patronyme'.",
  urlTooltip:
    "L'URL de votre entreprise. Il peut s'agir de votre site web, Facebook, Instagram, etc.",
  okpoTooltip: "Code OKPO de votre entreprise individuelle.",
  phonePayoutTooltip: "Votre numéro de téléphone.",
  paymentsTipStripe:
    "Les paiements sont toujours activés pour les utilisateurs de Stripe. Les paiements sont disponibles après avoir terminé l'enregistrement.",
  paymentsTipFondy1:
    "Entrepreneurs privés : les paiements sont activés après la création du marchand. Les paiements sont disponibles après avoir terminé l'enregistrement.",
  paymentsTipFondy2:
    "Cartes de crédit : les paiements et les transferts sont activés après avoir ajouté une combinaison de carte de crédit privée et de code fiscal individuel.",
  phoneError: "Le téléphone doit être un numéro valide!",
  toastTaxCodeAdded: "Votre code fiscal individuel a été ajouté avec succès.",
  toastTaxCodeError:
    "Une erreur s'est produite lors de la tentative d'ajout de votre code fiscal individuel !",
  toastPECodeAdded:
    "Votre nouveau commerçant Fondy a été créé avec succès. Vérifiez votre email pour commencer l'intégration !",
  toastPECodeError:
    "Une erreur s'est produite lors de la tentative de création de votre nouveau commerçant Fondy !",
  onboarding: "Onboarding",
  notCompletedOnboarding: "Non terminé",
  completedOnboarding: "Terminé",
  completeOnboarding: "Terminer l'onboarding",
  onboardingTooltip:
    "Terminez l'onboarding dans le tableau de bord Fondy. Vérifiez votre email pour une invitation à créer un mot de passe, connectez-vous au tableau de bord Fondy, téléchargez les documents requis et ajoutez une signature numérique. Une fois approuvé, votre compte sera activé.",
  fondyMerchantAccountDetails: "Détails du compte marchand Fondy",
  eventIsLife: "L'événement est en direct",
  created: "Initié",
  processing: "En cours de traitement",
  goToFondyDashboard: "Ouvrez votre tableau de bord Fondy",
  youMustCompleteFondy:
    "Vous devez compléter l'intégration à Fondy via l'invitation par email que vous avez reçue à l'adresse suivante :",
  chooseEventType: "Choisissez le type d'événement",
  addException: "Ajouter une exception",
  manageScheduleExceptions: "Gérer les exceptions dans l'emploi du temps",
  addRemoveSpecificTime:
    "Ajoutez ou supprimez un moment précis où vous êtes disponible ou non disponible.",
  exceptionType: "Type d'exception :",
  available: "Disponible",
  notAvailable: "Non disponible",
  yes: "Oui",
  no: "Non",
  removeException: "Supprimer l'exception",
  removeExceptionDesc:
    "Êtes-vous sûr de vouloir supprimer l'exception {{time}}?",
  NoExceptionsAdded: "Aucune exception n'a été ajoutée",
  availableAllDay: "Je serai disponible toute la journée.",
  notAvailableAllDay: "Je ne serai pas disponible toute la journée.",
  exceptionDeleteSuccessfully: "Exception supprimée avec succès",
  exceptionDeleteError: "Votre exception n'a pas pu être supprimée",
  exceptionSavedSuccessfully: "Exception enregistrée avec succès",
  exceptionSavedError: "Votre exception n'a pas pu être enregistrée",
  addJoininstructions: "Ajouter des instructions pour rejoindre",
  addJoininstructionsDesc:
    "Ajoutez du contenu ou un message que vos clients verront après l'achat s'ils ne se sont pas encore joints au canal",
  joinChannel: "Rejoindre le canal",
  communityUploadDescription: "Télécharger une image ou une vidéo.",
  detailsOptional: "Détails (facultatif)",
  type: "Type",
  youCannotAddPast:
    "Vous ne pouvez pas ajouter un créneau horaire qui se termine dans le passé",
  Sun: "Dim",
  Mon: "Lun",
  Tue: "Mar",
  Wed: "Mer",
  Thu: "Jeu",
  Fri: "Ven",
  Sat: "Sam",
  toastEventNotEdited:
    "Une erreur s'est produite lors de la tentative de modification de votre événement!",
  refunded: "remboursé",
  declined: "refusé",
  verified: "vérifié",
  transactionSuccessful: "Transaction réussie",
  myTabs: "Mes onglets",
  tabStatus: "{{tabName}} sont {{status}}",
  controlOfferTabs:
    "Types d'offres que les clients peuvent voir sur votre page principale:",
  onlyEnglishLetters:
    "Seules les lettres et les chiffres anglais sont autorisés",
  notificationCompleteOnboarding: "Terminez l'intégration",
  notificationYourPaidOffersAreUnavailable:
    "Vos offres payantes ne sont pas disponibles pour vos clients.",
  products: "Produits",
  bookingAvailable: "Disponibilité des réservations",
  bookingAvailableDescription:
    "Indiquez si vous êtes disponible pour les réservations en général.",
  availableKey: "Disponible",
  notAvailableKey: "Non disponible",
  paymentOccurs: "Paiement dû chaque",
  paymentOccursOneTime: "Paiement unique.",
  eventIsOver: "L'événement est terminé",
  integrations: "Intégrations",
  externalAnalytics: "Analyse externe",
  externalAnalyticsDescription:
    "Définissez vos identifiants de comptes d'analyse externe ci-dessous.",
  participantsManage: "Participants",
  manageEvent: "Gérer l'événement",
  noEventsAdded: "Aucun événement ajouté",
  createEvent: "Créer un événement",
  shareEvent: "Partager l'événement",
  waylightProfile: "Profil Waylight",
  tickedId: "ID du billet",
  checkIn: "Check-in",
  checkedIn: "Enregistré",
  notCheckedin: "Non enregistré",
  codeIsValid: "Le code est valide ! Enregistré !",
  codeisInvalid: "Code invalide ! Veuillez réessayer.",
  codeIsUsed: "Ce code est déjà utilisé !",
  buyMore: "Acheter plus",
  noParticipantsYet: "Aucun participant pour le moment",
  emptyDeteilsBuysTickets:
    "Ils apparaîtront ici quand quelqu'un achètera des billets pour votre événement.",
  connectYourTelegram:
    "Connectez votre compte Telegram pour accéder au canal !",
  change: "Changement",
  numberOfTickets: "Nombre de billets:",
  selectPaymentMethodTitle: "Sélectionner le moyen de paiement :",
  or: "- ou -",
  addACard: "Ajouter une carte",
  subscriptionLevels: "Niveaux d'abonnement",
  subscriptionLevelsTooltip:
    "Ajoutez jusqu'à 3 niveaux à votre abonnement communautaire. Par exemple : mensuel, trimestriel et annuel.",
  levelName: "Nom du niveau",
  starter: "Débutant",
  pro: "Professionnel",
  levelDiscription: "Nom du niveau affiché aux clients",
  period: "Période",
  periodDiscription:
    "Les clients seront facturés à cet intervalle pour rester abonnés",
  priceDiscription: "Vous êtes payé à des intervalles spécifiés",
  removeLevel: "Supprimer le niveau",
  addAnotherLevel: "Ajouter un autre niveau",
  noTrial: "Pas d'essai",
  trialDiscription:
    "Nombre de jours d'accès gratuit que vos clients obtiendront",
  trialPeriod: "Période d'essai :",
  selectSubscription: "Sélectionner l'abonnement :",
  subscription: "Abonnement",
  removeLevelDescription: "Êtes-vous sûr de vouloir supprimer le niveau ?",
  tryForFree: "Essai gratuit",
  startTrial: "Commencer l'essai",
  subscribedSuccessfully: "Abonnement réussi",
  edit: "Éditer",
  expirationColumName: "expiration",
  kick: "Exclure",
  trial: "Essai",
  notJoined: "Non rejoint",
  telegramProfile: "Profil Telegram",
  notAuthorized: "Non autorisé",
  noMembersFoundFilter: "Aucun membre trouvé selon le filtre défini.",
  tryADifferentFilter:
    "Essayez un filtre différent ou une recherche différente.",
  "not joined": "Non rejoint",
  "not authorized": "Non autorisé",
  membership: "Adhésion",
  priceDiscriptionEvents:
    "Vous êtes rémunéré pour chaque achat de places à cet événement",
  fanZone: "Zone des fans",
  vipZone: "Zone VIP",
  selectTickets: "Sélectionner des billets :",
  attendance: "Fréquentation",
  eventLevels: "Niveaux de l'événement",
  noTicketsSelected: "Aucun billet sélectionné",
  SetMaximumNumberOfSeats: "Définir le nombre maximum de places pour ce niveau",
  participants: "Nombre de places",
  import: "Import",
  export: "Export",
  labelNameDescription: "Choisissez une étiquette pour vos données importées",
  hidden: "caché",
  upload: "Télécharger",
  labelName: "Nom de l'étiquette",
  fileIsRequired: "Vous devez télécharger un fichier!",
  code: "code",
  level: "Niveau",
  label: "Étiquette",
  exportFileError: "Échec de l'exportation!",
  exportFileSuccess: "Fichier exporté avec succès.",
  importFileSuccess: "Fichier importé avec succès.",
  importFileError: "Échec de l'importation!",
  statsSuccess: "Statistiques d'enregistrement chargées avec succès.",
  statsError:
    "Une erreur s'est produite lors du chargement des statistiques d'enregistrement!",
  uploadAText: "Texte brut ou fichier CSV jusqu'à 50 Mo",
  onlyTextFilesAllowed: "Seuls les fichiers texte ou CSV sont autorisés!",
  toastCommunityNotEdited:
    "Une erreur s'est produite lors de la modification de la communauté",
  toastYouCanSelectOnlyImage:
    "Vous ne pouvez sélectionner qu’une seule image pour l’en-tête",
  sections: "Sections",
  logInOrSignup: "Connectez-vous ou inscrivez-vous à Waylight ci-dessous",
  continue: "Continuer",
  invalidEmail: "Adresse email invalide",
  enterCode: "Entrer le code",
  haveSentCodeTo: "Nous avons envoyé un code à",
  paste: "Coller",
  resendCodeIn: "Renvoyer le code... (dans {{time}} secondes)",
  resendCode: "Renvoyer le code",
  waylight: "Waylight",
  pleaseChooseCountry:
    "Veuillez choisir le pays de votre banque ou service financier.",
  buyAccessFor: "Acheter l'accès pour",
  thankYouForFollowing: "Merci de nous suivre !",
  incorrectCode: "Le code est incorrect ou a expiré !",
  enterEmailToContinue: "Entrez l'adresse email pour continuer",
  city: "Ville",
  social: "Social",
  earned: "total",
  priceLimitARS:
    "Le prix peut être de ARS$ 0, ou de ARS$ 1879.31 à ARS$ 9396554.46.",
  priceLimitAUD: "Le prix peut être de A$ 0, ou de A$ 3.01 à A$ 15097.1 .",
  priceLimitEUR: "Le prix peut être de €0, ou de €1.81 à €9070.",
  priceLimitBOB: "Le prix peut être de Bs. 0, ou de Bs. 13.81 à Bs. 69119.48.",
  priceLimitBGN: "Le prix peut être de лв 0, ou de лв 3.55 à лв 17750.81.",
  priceLimitCAD: "Le prix peut être de CA$ 0, ou de CA$ 2.74 à CA$ 13718.98.",
  priceLimitCLP:
    "Le prix peut être de CL$ 0, ou de CL$ 1864.56 à CL$ 9321644.2.",
  priceLimitCOP:
    "Le prix peut être de COL$ 0, ou de COL$ 8072.11 à COL$ 40360576.84.",
  priceLimitCRC: "Le prix peut être de ₡0, ou de ₡1055.02 à ₡5277582.56.",
  priceLimitHRK: "Le prix peut être de kn 0, ou de kn 35.24 à kn 70472.",
  priceLimitCZK: "Le prix peut être de Kč 0, ou de Kč 45.71 à Kč 228558.",
  priceLimitDKK: "Le prix peut être de kr 0, ou de kr 13.54 à kr 67719.",
  priceLimitDOP: "Le prix peut être de RD$ 0, ou de RD$ 294.46 à RD$ 588928.",
  priceLimitHKD: "Le prix peut être de HK$ 0, ou de HK$ 15.58 à HK$ 77915.",
  priceLimitEGP: "Le prix peut être de EGP 0, ou de EGP 154.75 à EGP 309501.",
  priceLimitHUF: "Le prix peut être de Ft 0, ou de Ft 716 à Ft 3579217.",
  priceLimitISK: "Le prix peut être de kr 0, ou de kr 684 à kr 1369300.",
  priceLimitINR: "Le prix peut être de ₹0, ou de ₹167.89 à ₹839435.",
  priceLimitIDR: "Le prix peut être de Rp 0, ou de Rp 78852 à Rp 157703833.",
  priceLimitILS: "Le prix peut être de ₪0, ou de ₪19 à ₪36657.",
  priceLimitUYU: "Le prix peut être de $U 0, ou de $U 81 à $U 402925.",
  priceLimitGBP: "Le prix peut être de £0, ou de £1.56 à £7779.95.",
  priceLimitTRY: "Le prix peut être de ₺0, ou de ₺67.06 à ₺335289.19.",
  priceLimitTTD: "Le prix peut être de TT$ 0, ou de TT$ 13.58 à TT$ 67917.76.",
  priceLimitTHB: "Le prix peut être de ฿0, ou de ฿69.82 à ฿349109.6.",
  priceLimitCHF: "Le prix peut être de CHF 0, ou de CHF 1.73 à CHF 8647.",
  priceLimitSEK: "Le prix peut être de kr 0, ou de kr 20.82 à kr 104092.39.",
  priceLimitZAR: "Le prix peut être de R 0, ou de R 94 à R 186359.",
  priceLimitSGD:
    "Le prix peut être de SGD$ 0, ou de SGD$ 2.63 à SGD$ 13146.69.",
  priceLimitSAR: "Le prix peut être de SAR 0, ou de SAR 19 à SAR 37496.",
  priceLimitRON: "Le prix peut être de lei 0, ou de lei 9.04 à lei 45196.94.",
  priceLimitPLN: "Le prix peut être de zł 0, ou de zł 7.8 à zł 39008.41.",
  priceLimitPHP: "Le prix peut être de ₱ 0, ou de ₱ 281 à ₱ 560255.",
  priceLimitPEN: "Le prix peut être de S/. 0, ou de S/. 19 à S/. 38040.",
  priceLimitPYG: "Le prix peut être de ₲ 0, ou de ₲ 36387 à ₲ 72772680.",
  priceLimitNOK: "Le prix peut être de kr 0, ou de kr 21.38 à kr 106903.",
  priceLimitNZD: "Le prix peut être de NZ$ 0, ou de NZ$ 3.33 à NZ$ 16646.",
  priceLimitMXN: "Le prix peut être de MX$ 0, ou de MX$ 37.92 à MX$ 189595.",
  priceLimitKES: "Le prix peut être de KSh 0, ou de KSh 803 à KSh 1605000.",

  linkInBIOtool: "Outil Lien dans la BIO",
  thatHelpsYouEarn: "qui vous aide à gagner!",
  earnMore: "Gagnez plus",
  withWaylight: "avec Waylight :",
  descriptionEarn: "Faites 10 fois plus de revenus sur vos réseaux sociaux !",
  descriptionEarnPart: "Lancez-vous en seulement 2 minutes !",
  earnOnPaidCommunities: "Gagnez sur les communautés payantes.",
  createAndManage:
    "Créez et gérez vos communautés basées sur l'abonnement sur Telegram.",
  EarnPaidDigital: "Gagnez sur les produits numériques payants.",
  hostAndManage:
    "Hébergez et gérez vos cours, tutoriels, livres électroniques, guides, fichiers, etc.",
  earnOnline: "Gagnez sur les événements en ligne et en personne.",
  sellTicketsAndManage:
    "Vendez des billets et gérez vos événements de groupe en ligne et en personne.",
  EarnServices: "Gagnez sur les services payants.",
  sellManage: "Vendez et gérez le flux de vos services.",
  earnOnlineVideoSessions: "Gagnez sur les sessions vidéo en ligne payantes.",
  ScheduleBookingsOnlineVideo:
    "Établissez un calendrier et obtenez des réservations pour des sessions vidéo en ligne avec vos clients.",
  EarnAppointments: "Gagnez sur les rendez-vous en personne payants.",
  ScheduleSignups:
    "Établissez un calendrier et obtenez des inscriptions pour vos rendez-vous individuels ou de groupe.",
  earnTipping: "Gagnez sur les pourboires.",
  getTippingSupport:
    "Obtenez des pourboires en soutien à vos chaînes ou pour le partage de contenu sur les réseaux sociaux.",
  youWilGet: "Vous obtiendrez",
  tools: "1. Outils",
  sales: "2. Ventes",
  clientsGet: "3. Clients",
  howItWorks: "Comment ça marche",
  GetClients: "Obtenez de nouvelles ventes et clients",
  useWaylightGrowth:
    "Utilisez les outils et conseils du moteur de croissance de Waylight pour vendre plus et obtenir de nouveaux clients sur les réseaux sociaux. ",
  createWaylight: "Créer Waylight",
  creatorsExperts: "Créateurs et experts",
  interactionsMonthly: "Interactions mensuelles",
  monthOverMonth: "Croissance mois après mois",
  forWhom: "Pour qui :",
  pricing: "Tarification :",
  chooseYourPlan: "Choisissez votre plan",
  saveWhenChoosingYearlyPlan: "Économisez plus en choisissant un plan annuel !",
  monthlyBilling: "Facturation mensuelle",
  annuallyBilling: "Facturation annuelle",
  freePlan: "Gratuit",
  startLowestCosts:
    "Commencez avec les coûts les plus bas et explorez Waylight",
  mo: "/mois",
  signUp: "S'inscrire",
  impressionMo: "{{comission}}% de frais de transaction.",
  basicToolsIncluded: "Les outils de base sont inclus!",
  basic: "Basique",
  getEverythingBasic:
    "Obtenez tout en gratuit avec un niveau de frais de transaction inférieur",
  contactSales: "Contacter les ventes",
  transactionFees: "Frais de transaction de 2%.",
  publishingMarketplace: "Publication sur la recherche de marché.",
  saveBadge: "Économisez {{percent}}%",
  bestValue: "Meilleur rapport qualité-prix",
  GetMoreSales:
    "Obtenez plus de ventes de vos réseaux sociaux sans frais de transaction",
  ziroTransactionFees: "0% de frais de transaction.",
  proToolsIncluded: "Les outils Pro sont inclus!",
  customizations: "Personnalisations.",
  verifiedSign: "Signe vérifié.",
  prioritySupport: "Support prioritaire.",
  launchExpert: "Un expert en lancement est attaché.",
  askTelegram: "Demander sur Telegram",
  askWhatsApp: "Demander sur WhatsApp",
  askEmail: "Demander par e-mail",
  MakeTenX: "Faites 10X",
  onYourSocialMedia: "sur vos médias sociaux",
  revenue: "revenus ! 🎉",
  launchJust: "Lancez-vous en seulement",
  twoMinutes: "2 minutes!",
  product: "Produit :",
  customersDataManagement: "Gestion des données des clients",
  reviewsProduct: "Avis",
  portfolioProduct: "Portfolio",
  businessWebsite: "Site web d'entreprise",
  securePayments: "Paiements sécurisés",
  tipping: "Pourboires",
  paidAppointments: "Rendez-vous en personne payants",
  paidVideoSessions: "Sessions vidéo en ligne payantes",
  paidServices: "Services payants",
  ticketsOnlineEvents: "Billets pour les événements en ligne",
  ticketsScanner: "Scanner de billets",
  ticketsPersonEvents: "Billets pour les événements en personne",
  paidAccessCourses: "Accès payant aux cours",
  paidAccessDigitalProducts: "Accès payant aux produits numériques",
  paidGroupChannelAccessTelegram:
    "Accès payant aux groupes et aux canaux sur Telegram",
  paidSubscriptionCommunitiesTelegram:
    "Communautés payantes par abonnement sur Telegram",
  productMenuItem: "Produit",
  PricingMenuItem: "Tarification",
  Creator: "Créateur",
  Freelancer: "Freelancer",
  Tutor: "Tuteur",
  Coach: "Entraîneur",
  Influencer: "Influenceur",
  ask: "Demander",
  anyQuestions: "toutes les questions ici:",
  how: "Comment",
  itWorks: "ça fonctionne",
  businessflowStep1new:
    "Ajoutez votre lien Waylight à la BIO de vos réseaux sociaux et commencez à impliquer votre public et vos clients en partageant des histoires et des publications à ce sujet.",
  businessflowStep2new:
    "Utilisez les outils et conseils du moteur de croissance Waylight pour vendre plus et obtenir de nouveaux clients sur les réseaux sociaux.",
  businessflowStep3new:
    "Recevez un paiement pour vos communautés, produits numériques, événements en ligne et hors ligne, pourboires, séances en personne et vidéo, etc.",
  businessCalculatorNew: "Calculateur de revenus Waylight",
  howMuchMoneyNew:
    "Découvrez combien d'argent les abonnés apporteraient à votre entreprise.",
  estimateNumberNew: "1. Estimez le nombre de clients et d'abonnés",
  howMuchYouChargeNew: "2. Combien pouvez-vous facturer pour une offre",
  for: "Pour",
  whom: "à qui",
  getEverythingBasicNew:
    "Obtenez tout dans le forfait de base avec un niveau inférieur de frais de transaction",
  startLowestCostsNew: "Commencez sans frais initiaux et explorez Waylight",
  GetMoreSalesNew:
    "Obtenez plus de ventes de vos réseaux sociaux sans frais de transaction",
  onYourSocialMediaNew: "sur vos revenus des réseaux sociaux ! 🎉",
  accordionv2label0: "Dans quels pays travaillez-vous ?",
  accordionv2text0:
    "Waylight Business opère dans plus de 55 pays à travers 5 continents. Vous pouvez contacter notre support concernant votre pays ou simplement essayer de vous inscrire et le vérifier. Les clients peuvent payer pour vos offres depuis plus de 150 pays du monde entier.",
  accordionv2label1: "Comment suis-je payé ?",
  accordionv2text1:
    "Vous recevrez des paiements sur votre compte bancaire chaque lundi. Il faut moins d'une minute pour connecter votre compte bancaire à Waylight Business.",
  accordionv2label2: "Quels outils sont inclus dans le prix ?",
  accordionv2text2:
    "Cela dépend du plan que vous choisissez, mais dans tous les cas, vous aurez accès à tous les flux de monétisation complets de Waylight, comme les communautés payantes sur Telegram, les billets, les produits numériques, les cours, les rendez-vous, les sessions vidéo, les pourboires, le site web professionnel, les données clients, les moteurs de vente, l'académie de monétisation, etc.",
  accordionv2label3: "Avez-vous Apple Pay, Google Pay, PayPal ?",
  accordionv2text3:
    "Oui, nous avons. Nous l'appelons 'Smooth Checkout'. Waylight travaille à automatiser les flux de ventes avec des taux de conversion plus élevés, nous avons donc des paiements en un clic (avec PayPal en cours).",
  accordionv2label4: "Comment Waylight m'aidera à faire 10 fois plus ?",
  accordionv2text4:
    "Vous aurez accès au moteur de croissance des ventes et à l'académie de monétisation, qui vous aideront à vendre plus et à obtenir plus de clients de vos médias sociaux. De plus, Waylight a déjà créé des flux automatisés et des outils qui vous offrent des taux de conversion plus élevés.",
  accordionv2label5: "De quoi ai-je besoin pour commencer ?",
  accordionv2text5:
    "C'est facile 👌 Inscrivez-vous, ajoutez votre compte bancaire, créez des offres, rejoignez l'académie de monétisation, ajoutez des liens sur les réseaux sociaux et commencez à gagner de plus en plus d'argent.",
  connectWaylight: "Connectez-vous sur Waylight !",
  toastTooLargeFileTwilio:
    "Ce fichier est trop volumineux. La taille maximale du fichier est de 150 Mo.",
  enableSold: "Activer les indicateurs de ventes d'offres ?",
  areYouSureYOuWantEnableSold:
    "Êtes-vous sûr de vouloir activer les indicateurs de ventes d'offres ? Les valeurs de vente seront visibles pour tous les utilisateurs.",
  disableSold: "Désactiver les indicateurs de ventes d'offres ?",
  areYouSureYOuWantDisableSold:
    "Êtes-vous sûr de vouloir désactiver les indicateurs de ventes d'offres ?",
  soldEnabled: "Les indicateurs de ventes d'offres sont activés",
  soldEnabledDescription:
    "Lorsque ce paramètre est activé, les valeurs de vente sont visibles pour tous les utilisateurs.",
  tipOrCommunitypriceLimitARS:
    "Le prix peut être de ARS$ 1879.31 à ARS$ 9396554.46.",
  tipOrCommunitypriceLimitAUD: "Le prix peut être de A$ 3.01 à A$ 15097.1 .",
  tipOrCommunitypriceLimitEUR: "Le prix peut être de €1.81 à €9070.",
  tipOrCommunitypriceLimitBOB: "Le prix peut être de Bs. 13.81 à Bs. 69119.48.",
  tipOrCommunitypriceLimitBGN: "Le prix peut être de лв 3.55 à лв 17750.81.",
  tipOrCommunitypriceLimitCAD: "Le prix peut être de CA$ 2.74 à CA$ 13718.98.",
  tipOrCommunitypriceLimitCLP:
    "Le prix peut être de CL$ 1864.56 à CL$ 9321644.2.",
  tipOrCommunitypriceLimitCOP:
    "Le prix peut être de COL$ 8072.11 à COL$ 40360576.84.",
  tipOrCommunitypriceLimitCRC: "Le prix peut être de ₡1055.02 à ₡5277582.56.",
  tipOrCommunitypriceLimitHRK: "Le prix peut être de kn 36 à kn 70472.",
  tipOrCommunitypriceLimitCZK: "Le prix peut être de Kč 45.71 à Kč 228558.",
  tipOrCommunitypriceLimitDKK: "Le prix peut être de kr 13.54 à kr 67719.",
  tipOrCommunitypriceLimitDOP: "Le prix peut être de RD$ 295 à RD$ 588928.",
  tipOrCommunitypriceLimitHKD: "Le prix peut être de HK$ 15.58 à HK$ 77915.",
  tipOrCommunitypriceLimitEGP: "Le prix peut être de EGP 154.75 à EGP 309501.",
  tipOrCommunitypriceLimitHUF: "Le prix peut être de Ft 716 à Ft 3579217.",
  tipOrCommunitypriceLimitISK: "Le prix peut être de kr 684 à kr 1369300.",
  tipOrCommunitypriceLimitINR: "Le prix peut être de ₹167.89 à ₹839435.",
  tipOrCommunitypriceLimitIDR: "Le prix peut être de Rp 78852 à Rp 157703833.",
  tipOrCommunitypriceLimitILS: "Le prix peut être de ₪19 à ₪36657.",
  tipOrCommunitypriceLimitUYU: "Le prix peut être de $U 81 à $U 402925.",
  tipOrCommunitypriceLimitGBP: "Le prix peut être de £1.56 à £7779.95.",
  tipOrCommunitypriceLimitTRY: "Le prix peut être de ₺67.06 à ₺335289.19.",
  tipOrCommunitypriceLimitTTD: "Le prix peut être de TT$ 13.58 à TT$ 67917.76.",
  tipOrCommunitypriceLimitTHB: "Le prix peut être de ฿69.82 à ฿349109.6.",
  tipOrCommunitypriceLimitCHF: "Le prix peut être de CHF 1.73 à CHF 8647.",
  tipOrCommunitypriceLimitSEK: "Le prix peut être de kr 20.82 à kr 104092.39.",
  tipOrCommunitypriceLimitZAR: "Le prix peut être de R 94 à R 186359.",
  tipOrCommunitypriceLimitSGD:
    "Le prix peut être de SGD$ 2.63 à SGD$ 104092.39.",
  tipOrCommunitypriceLimitSAR: "Le prix peut être de SAR 19 à SAR 37496.",
  tipOrCommunitypriceLimitRON: "Le prix peut être de lei 9.04 à lei 45196.94.",
  tipOrCommunitypriceLimitPLN: "Le prix peut être de zł 7.8 à zł 39008.41.",
  tipOrCommunitypriceLimitPHP: "Le prix peut être de ₱ 281 à ₱ 560255.",
  tipOrCommunitypriceLimitPEN: "Le prix peut être de S/. 19 à S/. 38040.",
  tipOrCommunitypriceLimitPYG: "Le prix peut être de ₲ 36387 à ₲ 72772680.",
  tipOrCommunitypriceLimitNOK: "Le prix peut être de kr 21.38 à kr 106903.",
  tipOrCommunitypriceLimitNZD: "Le prix peut être de NZ$ 3.33 à NZ$ 16646.",
  tipOrCommunitypriceLimitMXN: "Le prix peut être de MX$ 37.92 à MX$ 189595.",
  tipOrCommunitypriceLimitKES: "Le prix peut être de KSh 803 à KSh 1605000.",

  authorizeToAccess: "Vous devez autoriser l'accès",
  noCommunitiesFound: "Aucune communauté trouvée",
  noCommunitiesFoundDescription:
    "Elles apparaîtront ici lorsque vous achetez ou ajoutez une communauté",
  following: "Suivant",
  appointments: "Rendez-vous",
  share: "Partager",
  addOffer: "Ajouter une offre",
  aboutMe: "À propos de moi",
  estimatedAmount:
    "Montant estimé selon les taux de conversion de devise actuels dans la devise par défaut de votre compte Stripe.",
  ConnectYourTelegram: "Connectez votre Telegram :",
  ConnectYourTelegramAccount:
    "Connectez votre compte Telegram pour recevoir des notifications des créateurs que vous suivez.",
  connectTelegram: "Connecter Telegram",
  buyAgain: "Acheter à nouveau",
  purchases: "Acheté",
  videoGuide: "Guide vidéo",
  businessBilling: "Facturation entreprise",
  noPaymentMethodsAdded: "Aucune méthode de paiement ajoutée",
  yourPaymentMethods: "Vos méthodes de paiement",
  yourPlan: "Votre plan",
  yearlyBilling: "Facturation annuelle",
  selectFree: "Sélectionner gratuit",
  whatIncluded: "Ce qui est inclus",
  selectStarter: "Sélectionner de base",
  selectPro: "Sélectionner Pro",
  enterPromoCode: "Saisissez le code promotionnel :",
  apply: "Appliquer",
  selected: "Sélectionné",
  waylightSubscription: "Abonnement Waylight",
  cashbackRefferals: "Références",
  referrals: "Affiliés",
  shareLink: "Partager le lien",
  howReferralsWork: "Comment fonctionnent les références",
  cashback: "Cashback",
  yourCashbackCode: "Votre code de cashback",
  yourCustomPartnerReferralCode:
    "Votre code de référence partenaire personnalisé",
  howCashbackWork: "Comment fonctionne le cashback",
  toastPromoCodeSuccess: "Votre code promo a été appliqué avec succès !",
  toastPromoCodeError:
    "Une erreur s'est produite lors de l'application de votre code promo !",
  enterPromoCodeSignUp: "Saisissez le code promo (facultatif) :",
  enterPromoCodeBtn: "+ Saisir le code promo",
  billingBusinessTooltip: "Sélectionnez votre plan ci-dessous.",
  referralTooltip: "Ceci est votre code de cashback unique.",
  cashbackTooltip: "Ceci est votre code d'affiliation unique.",
  currentPlan: "Plan actuel :",
  neverExpires: "Jamais",
  expires: "Expire :",
  emptyBillingBusinessHistoryDescription:
    "Description vide de l'historique de facturation entreprise",
  notificationGoToBillingBusiness:
    "Nous avons rencontré un problème avec le paiement de votre abonnement ! ",
  WeHaveEncounteredAnIssueSubscription:
    "Nous avons rencontré un problème avec le paiement de votre abonnement",
  checkIfYouHaveSufficientSubscription:
    "Vérifiez si vous avez suffisamment de fonds sur votre solde ou si les paiements sur internet sont activés.",
  selectDifferentPaymentMethodSubscription:
    "Sélectionnez une méthode de paiement différente.",
  referralsDescriptions1: "Merci d'être notre précieux partenaire affilié !",
  referralsDescriptions2:
    "Terminez l'intégration pour recevoir vos paiements d'affiliation. Nous gardons votre argent en sécurité jusqu'à ce que vous soyez intégré.",
  referralsDescriptions3:
    "Vous aurez besoin de vos coordonnées bancaires ou de carte en fonction de votre pays.",
  referralsDescriptions4:
    "Les paiements ont lieu chaque lundi. Vous pouvez vérifier l'état des paiements dans Transactions.",
  referralsDescriptions5:
    "Votre code d'affiliation offre des réductions supplémentaires pour les abonnements Waylight.",
  referralsDescriptions6:
    "Vos réductions et taux de partage des revenus sont personnalisés en fonction de notre accord.",
  referralsDescriptions7:
    "Si c'est la première fois que vous recevez le paiement, il peut mettre 7 à 14 jours ouvrables à arriver en raison de limitations de passerelle. Normalement, les paiements prennent environ 3 jours ouvrables à arriver.",
  cashbackDescriptions1: "Merci d'être membre de notre programme de cashback !",
  cashbackDescriptions2:
    "Terminez l'intégration pour recevoir vos paiements d'affiliation. Nous gardons votre argent en sécurité jusqu'à ce que vous soyez intégré.",
  cashbackDescriptions3:
    "Vous aurez besoin de vos coordonnées bancaires ou de carte en fonction de votre pays.",
  cashbackDescriptions4:
    "Les paiements ont lieu chaque lundi. Vous pouvez vérifier l'état des paiements dans Transactions.",
  cashbackDescriptions5:
    "Partagez votre lien et obtenez une part des revenus des nouveaux clients.",
  cashbackDescriptions6:
    "Votre cashback est de 20 à 30% de tous les frais Waylight pendant 1 an (seulement 30% des abonnements annuels Pro).",
  cashbackDescriptions7:
    "Si c'est la première fois que vous recevez le paiement, il peut mettre 7 à 14 jours ouvrables à arriver en raison de limitations de passerelle. Normalement, les paiements prennent environ 3 jours ouvrables à arriver.",
  invalidGTMiD:
    "ID GTM non valide. Entrez uniquement votre ID, pas le code, par exemple : GTM-AA11BB2.",
  invalidMetaPixelID:
    "ID Meta Pixel non valide. Entrez uniquement votre ID, pas le code, par exemple : 123456789012345.",
  invalidHotjarID:
    "ID Hotjar non valide. Entrez uniquement votre ID, pas le code, par exemple : 1112223.",
  accountCreated: "Compte créé",
  toastOUrlCopied: "Lien de référence copié avec succès !",
  YouAreOnFreePlan: "Vous êtes actuellement déjà sur le plan gratuit",
  referralCodeCanNotBeUsed:
    "Ce code de référence ne peut pas être utilisé pour le pays de votre compte actuel : {{country}}",
  createWaylightBusiness: "Créer une entreprise Waylight",
  pleaseChooseCountryBank:
    "Veuillez choisir le pays de votre banque ou de votre service financier :",
  referralsDescriptionsUA4:
    "Les paiements ont lieu après chaque transaction. Vous pouvez vérifier l'état des paiements dans Transactions.",
  referralsDescriptionsUA7:
    "Les paiements ont lieu au plus tard le jour ouvrable suivant.",
  cashbackDescriptionsUA4:
    "Les paiements ont lieu après chaque transaction. Vous pouvez vérifier l'état des paiements dans Transactions.",
  cashbackDescriptionsUA7:
    "Les paiements ont lieu au plus tard le jour ouvrable suivant.",
  issueSubscriptionPayment:
    "Nous avons rencontré un problème avec le paiement de votre abonnement !",
  revenueSharhingSelectPE:
    "Le programme de parrainage n'est disponible que pour les comptes PE activés en Ukraine.",
  youCanChangeYourAccount:
    "Vous pouvez changer le type de votre compte dans les paramètres.",
  yourReferralCodeWillAppear:
    "Votre code de référence apparaîtra ici une fois que vous aurez terminé l'intégration PE.",
  goToPayoutSettings: "Aller aux paramètres de paiement",
  commission: "Frais",
  youAreOnSamePlan:
    "Vous êtes actuellement sur le même plan que vous essayez de choisir ! Veuillez choisir un autre plan.",
  onlyCyrillicLettersAllowed: "Seules les lettres cyrilliques sont autorisées",
  dragToChangePosition: "Faites glisser pour changer la position",
  eventsQrcode: "Codes courts d'événements",
  eventsQrcodeDescription:
    "Quand ce paramètre est activé, vos billets d'événement seront générés avec des PINs courts à 4 chiffres et des codes QR. Quand il est désactivé, des PINs plus sécurisés à 8 chiffres avec des codes-barres seront générés.",
  areYouSureYOuWantEnableQrcode:
    "Êtes-vous sûr de vouloir activer les PINs courts d'événements avec des codes QR ?",
  areYouSureYOuWantDisableQrcode:
    "Êtes-vous sûr de vouloir désactiver les PINs courts d'événements et générer des codes-barres à la place ?",
  enableEventsQrcode: "Activer les codes courts d'événements",
  disableEventsQrcode: "Désactiver les codes courts et passer aux codes-barres",
  authorizeScanner: "Autoriser le scanner",
  toastScannerUrlCopied: "URL du scanner copiée",
  total: "Total :",
  gtmIdDescription: "GTM local pour cette offre.",
  metaPixelIdDescription: "Meta Pixel local pour cette offre.",
  tutorialBoughtPreview: "Ceci est l'aperçu de l'état acheté de votre produit.",
  tutorialNotBoughtPreview:
    "Voici comment les clients voient votre produit lorsqu'il n'est pas acheté.",
  authorizeToAccessPurchases: "Veuillez autoriser l'accès à vos achats !",
  myPromotions: "Mon code promo",

  describeTheDetailsPromotions:
    "Sélectionnez l'offre, spécifiez le nom, choisissez une URL et ajoutez les détails de la promotion ci-dessous",
  promotionDropdownDescription:
    "Sélectionnez votre offre dans la liste. Vous ne pourrez plus la modifier pour cette promotion.",
  offerNamePromotions: "Offre",
  promotionsTooltip:
    "Sélectionnez une offre pour laquelle cette promotion s'appliquera. Vous pouvez créer autant d'autres promotions pour cette offre que vous le souhaitez.",
  offerNameDescriptionPromotions:
    "Entrez le nom de la promotion. Ceci est uniquement à des fins internes.",
  codePromotionsDescription:
    "Entrez un code promo unique en utilisant des lettres et des chiffres anglais. Essayez le bouton magique de génération, si vous le souhaitez!",
  expirationPromotions: "Expiration",
  name: "Nom",
  codePromotions: "Code",
  usersNumDescription:
    "Ce code promo peut être utilisé un nombre spécifié de fois.",
  max_uses: "Nombre d'utilisations",
  pricingPromotionsDiscription: "pricingPromotionsDiscription",
  discount: "Remise",
  discountTitle: "Remise(s):",
  selectOfferDiscounts:
    "Sélectionnez l'offre ci-dessus pour commencer à gérer les remises pour celle-ci.",
  promoNameCanNotBeEmpty: "Le nom de la promotion ne peut pas être vide",
  urlIsAlreadyTaken: "Cette URL est déjà prise!",
  promoCodeValidation:
    "Le code promo ne peut contenir que des lettres majuscules et minuscules anglaises et des chiffres de 0 à 9!",
  promotions: "Promotions",
  enterPromoCodePromotions: "Entrez le code promo",
  promoCodeIncorrectExpired: "Ce code promo est incorrect ou expiré!",
  selectedPromoCodeNotAvailable:
    "Le code promo sélectionné n'est pas disponible!",
  weWereUnableToProcessYourTransaction:
    "Nous n'avons pas pu traiter votre transaction avec le code promo sélectionné en raison de son expiration, de sa limite d'utilisation ou de son indisponibilité. Veuillez recommencer votre achat.",
  promotionsEmptyStateHeader:
    "Aucune promotion trouvée selon le filtre défini.",
  promotionsEmptyStateDescription:
    "Créez des promotions, essayez un autre filtre ou une recherche différente.",
  toastPromotionUrlCopied: "URL de la promotion copiée!",
  toastPromotionCodeCopied: "Code promo copié!",
  managePromotionsForYourOffers: "Gérez les promotions pour vos offres",
  offerPromotions: "Promotions d'offre",
  deletePromotionDescription:
    "Êtes-vous sûr de vouloir supprimer la promotion?",
  deletePromotionTitle: "Supprimer la promotion",
  addPromotion: "Ajouter une promotion",
  toastPromotionNotEditedEnabled:
    "Nous n'avons pas pu modifier votre promotion!",
  toastPromotionNotEdited: "Nous n'avons pas pu modifier votre promotion!",
  usage: "Utilisé",
  discountedPriceError:
    "Le prix réduit ne peut pas être inférieur à l'équivalent de 1 USD !",
  priceWithDiscount: "Prix avec réduction",
  promoCodeAddedToast: "Code promo appliqué avec succès !",
  toastPromotionSavedSuccessfully: "Code promo créé avec succès",
  toastPromotiomNotAdded:
    "Une erreur s'est produite lors de la création de votre promotion !",
  myPromotionsMenu: "Mes promotions",
  setSubscriptionlevelManually: "Définir le niveau d'abonnement manuellement:",
  settingLevelManuallyNotifyClientAutomatically:
    "Définir un niveau manuellement notifiera automatiquement le client et s'appliquera à la prochaine charge le {{expiration}}",
  subscribeForFree: "S'abonner gratuitement",
  toastSavedSuccessfullyUpdatedLevelCommunity:
    "Niveau de communauté enregistré et mis à jour avec succès.",
  toastCommunityLevelNotUpdated: "Niveau de communauté non mis à jour.",
  addNewCardStripe: "Ajouter une nouvelle carte (Stripe):",
  creditCard: "Carte de crédit",
  promocode: "Promocode",
  nonFollowers: "Non-abonnés",
  dueOn: "Dû le",
  exportMyClientsSuccess: "Exportation des clients terminée avec succès.",
  exportMyClientsError: "Erreur lors de l'exportation des clients.",
  eventTelegramDescription:
    "Sélectionnez le nom de votre chaîne ou de votre groupe. Si vous ne voyez rien ici, assurez-vous d'avoir ajouté Waylight Bot à votre groupe ou chaîne en tant qu'administrateur et c'est la seule offre pour cette chaîne!",
  onlineTelegramEvent: "Événement télégramme en ligne",
  joinEvent: "Rejoindre l'événement",
  levels: "Niveaux",
  paidAccess: "Accès payant",
  clientWillPayOnceAccess: "Le client paiera une fois pour obtenir l'accès",
  noOffersFound: "Aucune offre trouvée",
  noOffersFoundDescription:
    "Elles apparaîtront ici lorsque vous achèterez ou ajouterez une offre",
  youCanNotManageEnabled:
    "Vous n'avez aucune offre activée que vous pouvez gérer.",
  enableOffersForManagement:
    "Activez des sessions, des événements ou des communautés pour les gérer ici.",
  specifyQuickSessionPriceDuration:
    "Précisez le prix et la durée de la session rapide",
  youGetPaidSpecifiedPriceForFixedAmountTime:
    "Vous êtes payé le prix spécifié pour un temps fixe.",
  videoCallQuick: "Appel vidéo",
  tabs: "Onglets",
  pleaseEnterEmailAccessQuickSession:
    "Veuillez entrer votre e-mail pour accéder à la session rapide",
  disconnectWhatsAppBot: "Déconnecter le WhatsApp-bot",
  connectWhatsAppBot: "Connecter le WhatsApp-bot",
  WhatsAppBot: "WhatsApp-bot",
  videoCallSession: "Appel vidéo",
  copyProfileLink: "Copier le lien du profil",
  profileQRCode: "Mon code QR de profil",
  copyCommunitiesLink: "Lien des communautés",
  copyEventsLink: "Lien des événements",
  copyProductsLink: "Lien des produits",
  copySessionsLink: "Lien des sessions",
  copyTipsLink: "Lien des conseils",
  copyPortfolioLink: "Lien du portfolio",
  copyReviewsLink: "Lien des avis",
  leaveReviewForMe: "Laisser un avis pour moi",
  shareYourWaylight: "Partagez votre Waylight",
  myProfileQRCode: "Mon code QR de profil",
  menu: "Menu",
  selectOfferExportData: "Sélectionnez une offre pour exporter des données",
};
