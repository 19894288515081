export const ua = {
  next: "Далі",
  youWontBeCharged:
    "Гроші не будуть заблоковані до підтвердження деталей сесії.",
  home: "Домашня",
  chats: "Чати",
  settings: "Налаштування",
  moreSettings: "Більше налаштувань",
  settingsNotify: "Налаштування | Waylight",
  logOut: "Вийти",
  changePassword: "Змінити пароль",
  receiveResetPassword:
    "Натисніть “Скинути”, щоб отримати посилання для зміни пароля.",
  enterEmailForResetPassword:
    "Введіть вашу email адресу, щоб отримати посилання для зміни пароля.",
  successResetPassword: "Email відправлено!",
  failResetPassword: "Не вдалося скинути пароль.",
  reset: "Скинути",
  signOut: "Вийти",
  availableBooking: "Бронювання доступне",
  unavailableBooking: "Бронювання недоступне",
  shareProfile: "Поділитися профілем",
  requests: "Запити",
  hours: "Години",
  totalSessions: "Усього сесій",
  totalEarned: "Усього оплат",
  totalHours: "Усього годин",
  yourSession: "Ваша сесія",
  runningNow: "почалася",
  notHaveUpcomingSessions: "У вас ще немає жодних запланованих сесій",
  notHavePastSessions: "У вас ще немає минулих сесій",
  notHaveQuickSessions: "Ви ще не починали швидкі сесії",
  upcomingSessions: "Майбутні сесії",
  offer: "Пропозиція",
  gallery: "Галерея",
  photo: "Фото",
  session: "Сесія",
  sessionWith: "сесію з",
  occupation: "Посада",
  skills: "Теги",
  experts: "Експерти",
  noInformation: "Немає інформації",
  speaks: "Мови",
  socialLinks: "Соціальні профілі",
  requestSession: "Відео сесії",
  contacts: "Контакти",
  noOffersYet: "Немає доступних пропозицій",
  all: "Всі",
  requested: "Запитана",
  confirmed: "Підтверджена",
  passed: "Завершена",
  joined: "Приєднано",
  ongoing: "Активна",
  canceled: "Скасована",
  rejected: "Відхилена",
  approved: "Підтверджена",
  calendar: "Календар",
  favorites: "Обрані",
  removeAll: "Видалити все",
  viewProfile: "Відкрити профіль",
  today: "Сьогодні",
  upcoming: "Майбутні",
  attachments: "Вкладення",
  goToSession: "Перейти до сесії",
  prepare: "Підготуватися",
  sessions: "Сесії",
  prerequisites: "Передумови",
  download: "Завантажити",
  past: "Минулі",
  decline: "Відхилити",
  accept: "Підтвердити",
  declineSession: "Відмовитися від сесії",
  wantDeclineSession: "Ви впевнені, що хочете відмовитися від цієї сесії?",
  wantDeclinePaidSession:
    "Ви впевнені, що бажаєте відхилити запит? Платіж вашого клієнта буде повернено.",
  wantDeclineAfterApprove:
    "Ви впевнені, що бажаєте скасувати? Платіж вашого клієнта буде повернено.",
  videoCall: "Ввімкнути відео",
  endSession: "Завершити сесію",
  areYouSureYouWantEndSession: "Ви упевнені, що хочете завершити цю сесію?",
  findExpert: "Знайти експерта",
  selectAnExpert: "обрати експерта",
  inviteClients: "запросіть ваших клієнтів",
  selectAChat: "Оберіть чат, щоб почати розмову",
  noChatsYet: "Ще жодних чатів",
  notHaveMessages: "У вас ще немає повідомлень",
  shareProfileToClient: "Поділитися профілем з клієнтом",
  remove: "Видалити",
  time: "Час",
  timeSlots: "Слоти",
  deleteOffer: "Видалити пропозицію",
  myOffers: "Мої пропозиції",
  aboutOffer: "Про сесію",
  createNewOffer: "Створити нову пропозицію",
  editOffer: "Редагувати пропозицію",
  successfulyBooked: "Запит на сесію успішно відправлено",
  close: "Закрити",
  account: "Акаунт",
  price: "Вартість",
  priceAndLength: "Вартість і тривалість",
  aboutTheOffer: "Про сесію",
  uploadAFile: "Завантажити файл",
  uploadAFileError: "Файл не завантажено",
  confirm: "Підтвердити",
  IDLogin: "Ваш логін",
  myProfile: "Мій профіль",
  transactions: "Транзакції",
  transactionHistory: "Історія транзакцій",
  noTransactions: "У вас ще немає транзакцій",
  noTransactionsDescription:
    "Вони з'являться одразу після того, як ви проведете успішні платні сесії",
  typicalSchedule: "Типовий розклад",
  amount: "Сума",
  status: "Статус",
  paid: "Виплачена",
  action: "Дія",
  description: "Опис",
  billing: "Оплати",
  card: "Карта",
  clients: "Клієнти",
  sessionsPassed: "Минулі сесії",
  payout: "Виплати",
  firstName: "Ім'я",
  lastName: "Прізвище",
  skip: "Пропустити",
  connect: "Під'єднатися",
  about: "Мій досвід",
  title: "Назва",
  languages: "Мови",
  currency: "Валюта",
  addYourOffer: "Додати вашу пропозицію",
  join: "Ввійти",
  joinIn: "Ввійти через",
  joinOn: "Ввійти",
  message: "Повідомлення",
  client: "Клієнт",
  expert: "Експерт",
  Expert: "Експерт",
  noMoreOptions: "Більше немає опцій",
  male: "Чоловік",
  female: "Жінка",
  custom: "Custom",
  copyYourLink: "Скопіювати ваше посилання",
  done: "Гаразд",
  search: "Пошук",
  searchWaylight: "Знайдіть свій Waylight",
  suggested: "Популярні",
  upcomingSession: "Майбутня сесія",
  startASession: "Швидка сесія",
  quickSession: "Швидка сесія",
  rate: "Вартість",
  perHour: "за годину",
  packages: "Пакети",
  timezone: "Часовий пояс",
  book: "Бронювати",
  favorite: "Улюблений",
  lastSession: "Остання взаємодія",
  ago: "тому",
  gender: "Стать",
  loading: "Завантаження",
  min: "хв",
  hour: "година",
  online: "Онлайн",
  offline: "Офлайн",
  date: "Дата",
  displayName: "Ім'я",
  closePanel: "Закрити панель",
  goToStripe: "Відкрити вашу панель виплат",
  completeStripeOnBoarding: "Завершити Stripe Express онбордінг",
  stripeOnBoarding: "Stripe Онбордінг",
  completed: "завершено",
  notCompleted: "не завершено",
  myClients: "Мої клієнти",
  findClients: "Ми не можемо знайти клієнтів",
  clientDisplayedHere: "Коли ви знайдете клієнтів, їх буде відображено тут",
  writeSomething: "Уведіть дані та натисніть enter, щоб створити item",
  bookSession: "Забронювати сесію",
  registerAs: "Зареєструватись як:",
  addedCards: "Ваші картки",
  cardType: "Тип картки",
  billingHistory: "Історія оплат",
  deleteCard: "Видалити картку",
  wantToDeleteCard: "Ви впевнені, що хочете видалити картку?",
  selectPaymentMethod: "Оберіть спосіб оплати",
  addNewCard: "Додати нову картку",
  emailAddress: "Публічна email адреса",
  publicPhoneNumber: "Загальнодоступний номер телефону",
  phoneNumber: "Номер телефону",
  username: "Username",
  socialProfilesOrLinks: "Соціальні профілі / посилання",
  newOffer: "Нова пропозиція",
  myExperts: "Мої експерти",
  myClientProfile: "Мій клієнтський профіль",
  noExpertsFound: "Експертів не знайдено",
  openMenu: "Відкрити меню",
  becomeAnExpert: "Ввійти в Waylight Business",
  switchToExpert: "Переключитися на сторінку бізнесу",
  switchToClient: "Переключитися на сторінку клієнта",
  notHaveExperts: "У вас ще немає експертів",
  endCall: "Зупинити відео",
  endSharing: "Зупинити демонстрацію",
  lastOnline: "Востаннє онлайн",
  clientSince: "Клієнт з",
  welcome: "Ласкаво просимо",
  messageError: "Обов'язкове поле",
  support: "Підтримка",
  profile: "Профіль",
  portfolio: "Портфоліо",
  publicId: "Public ID",
  files: "Файли",
  reason: "Причина",
  declineReason: "Повідомте клієнта про причину відмови",
  rejectionReason: "Причина відмови",
  business: "Бізнес",
  passwordResetInvalidEmail: "Невірний Email",
  logIn: "Ввійти",
  logInSubtitle: "Для виконання будь-яких дій ввійдіть або зареєструйтеся.",
  enterEmail: "Введіть email",
  enterPassword: "Пароль",
  capsLockIsOn: "Caps Lock ввімкнено",
  numLockIsOn: "Num Lock ввімкнено",
  forgotPassword: "Забули свій пароль?",
  forgotPasswordEmail:
    "Введіть адресу електронної пошти, щоб отримати посилання для зміни пароля.",
  requestPasswordReset: "Скинути пароль",
  requestResetLink: "Повторно надіслати посилання",
  in: "через",
  sec: "сек",
  passwordResetRequestReceived: "Запит на скидання пароля",
  passwordResetIfAccountExist:
    "Якщо обліковий запис існує, ви незабаром отримаєте електронний лист із посиланням.",
  resetPassword: "Скинути пароль",
  setNewPassword: "Встановіть новий пароль для входу в обліковий запис",
  dontHaveAccount: "У вас немає облікового запису?",
  passwordChange: "Змінити пароль",
  passwordChangeSuccessful: "Пароль успішно змінений",
  passwordChangeSuccessfulSubtitle:
    "Тепер ви можете використовувати свій новий пароль для входу в обліковий запис.",
  passwordOld: "Старий пароль",
  passwordNew: "Новий пароль",
  alreadyRegistered: "У вас є обліковий запис?",
  tokenExpired: "Застарілий токен",
  tokenExpiredDescription: "Цей токен застарів або був використаний раніше",
  validLength: "8 і більше символів",
  upperCase: "Верхній регістр (A-Z)",
  lowerCase: "Нижній регістр (a-z)",
  numbersOrSymbols: "Цифри або символи (0-9!@#$%^&*)",
  joinFree: "Приєднатись зараз",
  businessTitle: "Waylight | Інструмент Link in BIO, що допомагає заробляти!",
  businessDescription:
    "Заробляйте в 10 разів більше зі своїх соціальних мереж! Запускайтеся всього за 2 хвилини!",
  spotlightYourExperience:
    "Ефективні засоби для ваших джерел доходів: контенту, експертизи і спільнот",
  monetizationPlatform: "Студія Онлайн Бізнесу",
  createAccount: "Створити акаунт",
  watchVideo: "Дивитись відео",
  bestToolsAvailable:
    "Ми хочемо, щоб ви мали найкращі інструменти і не повинні були сплачувати щомісяця за їх використання.",
  schedulingManagement: "Управління розкладом.",
  chooseConvenientTimeSlots:
    "Обирайте зручні таймслоти для роботи та отримуйте бронювання.",
  expertLandingPayouts: "Виплати.",
  simpleOnboarding: "Простий онбордінг ваших фінансових сервісів.",
  industryStandard: "Стандарти індустрії з якості й доступності.",
  lead: "Ведіть",
  engage: "Залучайте",
  frequentlyAskedQuestions: "Розповсюджені запитання",
  contactUs: "Контакти:",
  accordionLabel0: "Якщо сервіс безкоштовний, як працює Waylight?",
  accordionText0:
    "Ми отримуємо комісію відповідно до об'єму успішних платежів, а discovery і благодійні дзвінки - за наш рахунок!",
  accordionLabel1: "Ви підтримуєте багато типів пропозицій?",
  accordionText1:
    "Звичайно, ви можете створити стільки пропозицій, скільки забажаєте, зазначаючи вартість, тривалість і деталі кожної",
  accordionLabel2: "Як я отримаю платежі?",
  accordionText2:
    "Вам необхідно завершити простий onboarding, щоб під'єднати ваш фінансовий метод. Додаткові методи будут додані в близькому майбутньому.",
  accordionLabel3: "Я можу використовувати Zoom, Meet чи Skype?",
  accordionText3:
    "Ми інтегрували дзвінки в платформу, щоб гарантувати безпеку всіх взаємодій. Менш безпечні та більш гнучкі методи будуть додані в майбутньому.",
  accordionLabel4: "Я можу використовувати Zapier, Calendly і т.д.?",
  accordionText4:
    "Ми активно працюємо над інтеграціями і були б раді почути ваші думки з цього приводу. Напишіть нам на support@waylight.me. Дякуємо!",
  freeAudience: "Ми розробляємо - ви створюєте!",
  businessProfile: "Бізнес профіль.",
  onlineBusiness:
    "Почніть онлайн бізнес за хвилини, висвітлюючи те, що робите найкраще.",
  paidCalls: "Платні відео та аудіо дзвінки.",
  paidChatsAndBlog: "Платні чати та блог.",
  setUpSubscription: "Створіть підписку на чат та персональний блог.",
  web3: "Web3 рейтинги та відгуки.",
  collectRealRatings:
    "Отримуйте реальні рейтинги та відгуки, щоб створити персональний бренд.",
  getStartedToday: "Три простих кроки до нового джерела доходу:",
  createProfile: "1. Створіть Ваш Профіль",
  fillTemplate:
    "Заповніть ваш профіль, розклад, створіть пропозиції, вкажіть вартості платних чатів, блогу та за лічені хвилини будьте готові отримувати замовлення та підписки.",
  shareIt: "2. Поділіться зі світом",
  linkYourProfile:
    "Зв'яжіть ваш Профіль з усіма каналами - вашими соціальними мережами, рекламою, email. Поділіться інформацією про ваш онлайн бізнес в постах, відео, персональній комунікації.",
  growYourBusiness: "3. Розвивайте ваш бізнес",
  shareYourExpertise:
    "Діліться вашою експертизою і знаннями, ведіть ваш блог, відповідайте на запити і чати, консультуйте онлайн по відео і отримуйте публічні рейтинги та відгуки за допомогою Web3 технологій.",
  businessflow: "Флоу",
  businessflowStep1Title: "Додайте посилання в БІО",
  businessflowStep1:
    "Додайте посилання на ваш профіль Waylight Business в БІО в соціальних мережах (Instagram, Facebook, YouTube, LinkedIn, Twitter, TikTok, і т.д.) і поділіться про це в сторіз і постах",
  businessflowStep2Title: "1:1 відео дзвінки, 1:1 чати, Персональний блог",
  businessflowStep2:
    "Монетизуйтесь через 1:1 відео дзвінки, 1:1 чати з підписниками і клієнтами. Створюйте закриті пости для підписників: освітній контент, статті, відео-поради, бекстейдж і досвід з особистого життя.",
  businessflowStep3Title: "Отримуйте гроші",
  businessflowStep3:
    "Отримуйте гроші за відео дзвінки, чати і підписки на персональний блог. Збільшуйте монетизацію, розповсюджуючи інформацію про ваш Waylight!",
  bookOnboardingCall: "Забронюуйте безкоштовний онбордінг",
  talkToFounders:
    "Поспілкуйтеся з засновниками про те, як почати монетизуватись з Waylight.",
  orScanQR: "або сканувати QR",
  yourFreeTools: "Ваші безкоштовні засоби",
  features: "Можливості",
  personalLink: "Персональний лінк (URL)",
  personalQR: "Персональний QR",
  scheduling: "Календар",
  bookings: "Бронювання",
  paidChats: "Платні 1:1 чати",
  personalPaidBlog: "Персональний платний блог",
  soon: "скоро",
  subscriptions: "Підписки",
  payments: "Платежі в 100+ країнах",
  payouts: "Виплати в 58+ країнах",
  cryptoPayments: "Крипто платежі",
  coinsAwarding: "Coin винагороди",
  web3Ratings: "Web3 рейтинги, відгуки",
  clientsManagement: "Управління клієнтами",
  sessionsManagement: "Управління сесіями",
  dashboard: "Дешборд",
  businessCalculator: "Waylight Business Калькулятор Доходу",
  howMuchMoney: "Дізнайтеся, скільки ваш бізнес заробить на підписках.",
  estimateNumber: "1. Оцініть кількість клієнтів та підписників.",
  howMuchYouCharge: "2. Скільки коштує відео сесія чи підписка",
  monthlyIncome: "Місячний дохід",
  newToday: " нових сьогодні",
  hadNoSessions: "Цього тижня без сесій",
  atThisWeek: "цього тижня",
  welcomeToWaylight: "Ласкаво просимо до Waylight!",
  welcomeToWaylightBusiness: "Ласкаво просимо до Waylight Business!",
  linkToVideo: "Посилання на основне відео",
  writeSentences: "Додайте кілька слів про себе.",
  emptyExpertTitle: "За нашими даними, такої сторінки не існує",
  emptyExpertText:
    "Посилання може бути неправильним, або сторінку відключено чи видалено.",
  profileData: "Дані профілю",
  headerImageTooltip:
    "Рекомендовані виміри зображення для заголовку -  10:1. Спробуйте щось близьке до 1900x190px або 1280x128px.",
  emailTooltip:
    "Ця email адреса буде відображена в вашому профілі (це не ваш логін). Ви можете вказати будь-яку адресу, або не вказати жодної взагалі. Це поле є опціональним!",
  timezoneTooltip:
    "Якщо ви не можете знайти ваше місто, спробуйте пошукати зону, таку як 'Pacific Daylight Time' або 'Eastern European Time'.",
  linkToVideoPresentation: "Додати зовнішнє посилання",
  linkToVideoPresentationTooltip:
    "Вкажіть посилання на відео для деталей профілю. Більшість відео хостингів спрацює. Якщо ви завантажите відео - воно матиме пріорітет, в порівнянні з цим посиланням.",
  linkToVideoOfferTooltip:
    "Вкажіть посилання на відео для деталей пропозиції. Більшість відео хостингів спрацює. Якщо ви завантажите відео - воно матиме пріорітет, в порівнянні з цим посиланням.",
  expertProfileVideoPresentation: "Або завантажити файл",
  phoneTooltip:
    "Цей телефонний номер буде публічно відображено в вашому профілі. Це поле є опціональним!",
  socialProfilesTooltip:
    "Додайте посилання на ваші соціальні мережі та сайти, наприклад Youtube, Facebook, Linkedin, Instagram або Tiktok.",
  videoPresentationTooltip:
    "Завантажте відео для деталей профілю. Воно матиме пріорітет, в порівнянні з зображенням або посиланням на відео.",
  videoOfferTooltip:
    "Завантажте зображення чи  відео для деталей пропозиції. Воно матиме пріорітет, в порівнянні з зображенням або посиланням на відео.",
  occupationTooltip:
    "Розкажіть світові про вашу зайнятість, наприклад 'AR Consultant' або 'Top Tiktok Influencer'.",
  skillsTooltip:
    "Додайте ваші вміння, щоб клієнти розуміли, на чому ви знаєтесь, наприклад 'Entrepreneurship' або 'Parenting'.",
  aboutTooltip:
    "Додайте кілька слів про себе, щоб клієнти відчували вашу привітність та цінували ваш персональний бренд.",
  speaksTooltip: "Вкажіть якими мовами розмовляєте.",
  cropPhoto: "Відрізати фото",
  updatePhoto: "Завантажити фото для профілю",
  crop: "Відрізати",
  bookASession: "Коли ви забронюєте сесію, вона буде відображена тут",
  videoAudioSettings: "Відео та аудіо налаштування",
  videoAudioSettingsDescription:
    "Використовуйте це меню, щоб налаштувати відео та аудіо",
  cameras: "Камери",
  microphones: "Мікрофони",
  output: "Аудіо виводи",
  bgSettings: "Оберіть відео бекграунд",
  toStartAChat: "Щоб почати чат,",
  startAChat: "і почніть чат.",
  messageToAnExpert: "Відправте перше повідомлення експерту",
  messageToAClient: "Відправте перше повідомлення клієнту",
  chatWindowGetToKnow:
    "1. Познайомтеся з потенційними клієнтами та їх потребами",
  requestInformation: "2. Запитайте додаткову інформацію, якщо потрібно",
  notGettingAReply:
    "3. Якщо ви не отримуєте відповідь, спробуйте ввічливо ще раз",
  knowEachOther: "4. Познайомтеся та сплануйте ваші сесії",
  chatWindowProvideABrief: "1. Розкажіть, що ви шукаєте",
  chatWindowAddAttachments: "2. Додайте вкладення, якщо необхідно",
  describeYourIssue:
    "Будь-ласка опишіть ваш запит, з чим вам потрібна допомога або чого ви хочете навчитися. Додайте файли, якщо необхідно.",
  getAConfirmation: "Ви незабаром отримаєте підтвердження від експерта.",
  describeTheDetails:
    "Опишіть деталі, вартість та передумови вашої пропозиції.",
  areYouSure: "Ви впевнені, що хочете видалити цю пропозицію?",
  offerAvailable: "Пропозиція доступна",
  offerUnavailable: "Пропозиція недоступна",
  notHaveOffers: "У вас ще немає пропозицій",
  createOne: " Створіть пропозицію, щоб почати працювати з вашими клієнтами.",
  getPaid: "Ви отримуєте фіксовану оплату за фіксований час.",
  describeYourOffer: "Стисло опишіть вашу пропозицію.",
  describeRequirements:
    "Вкажіть вимоги до клієнтів, які бронюватимуть сесії; наприклад: описання або файли.",
  priceLimit: "Ціна може бути $0, або від $2 до $10000.",
  priceLimitForUA: "Ціна може бути ₴0, або від ₴83 до ₴412496.",
  offerNameDescription:
    "Спробуйте щось накшталт ‘Discovery Call’ або ‘Basic Graphic Design’ якщо ви на цьому знаєтесь.",
  clickingConfirm: "Натискаючи “Пітдвердити”, я погоджуюсь з",
  termsAndConditions: "Умовами Користування",
  preCharge: "Необхідну суму буде заблоковано, щоб відправити запит на сесію.",
  paymentFeesMayAlter: "Комісія платіжної системи може варіюватися.",
  notBeCharged: "Кошти ніколи не буде стягнуто без додаткового підтвердження.",
  last: "Останні 4",
  lastDigits: "Останні 4 цифри",
  billingPageName: "Ім'я",
  gateway: "Платіжна система",
  prepareForTheMeeting: "Підготуйтесь до сесії",
  prepareForSession: "Підготуйтесь до",
  fewRecomendations:
    "Кілька порад, щоб зробити сесію комфортною та продуктивною",
  findAQuiet: "Знайдіть тихе місце, де вам ніхто не заважатиме",
  makeSure: "Впевніться, що маєте якісний інтернет",
  weRecommendUsing: "Ми рекомендуємо використовувати навушники",
  prepareYourQuestions: "Підготуйте питання",
  enjoyTheSession: "Насолоджуйтесь сесією!",
  initialization: "Завантаження...",
  beginOnboardingAsExpert:
    "Почніть процес реєстрації, як експерт в Waylight Business",
  connectStripeAccount:
    "Щоб отримати гроші від ваших клієнтів, ви маєте створити метод виплат.",
  provideABrief: "1. Стисло вкажіть на те, з чим вам потрібна допомога",
  addAttachments: "2. Додайте файли, якщо необхідно",
  gettingAReply: "3. Якщо ви не отримуєте відповідь, спробуйте ввічливо ще раз",
  getToKnow: "4. Познайомтеся та сплануйте ваші сесії",
  fileType: "тип файлів не підтримується",
  orDragAndDrop: " або перетягнути",
  upTo: "до 1 Гб (зверніться до служби підтримки, якщо у вас більші розміри файлів)",
  headerImage: "Зображення заголовку",
  inviteClientsModalInviteClients: "Запросіть клієнтів",
  shareYourLink:
    "Поділіться вашим персональним посиланням, щоб запросити ваших клієнтів.",
  addDays: "Налаштуйте ваш регулярний розклад",
  publiclyInformation:
    "В кожному дні тижня може бути вказано декілька таймслотів.",
  availableTimeslot: "Доступний таймслот",
  AddTimeslot: "Додати таймслот",
  AddToSchedule: "Додати до Розкладу",
  signUpAs: "Зареєструватися як",
  registerAsClient: "Я хочу навчитись новому",
  registerAsExpert: "Я хочу поділитися досвідом",
  approveSession: "Підтвердити сесію",
  approveThisSession:
    "Ви впевнені, що хочете підтвердити сесію? Підтверджуючи, ви погоджуєтесь приєднатись в вказаний час.",
  noTimeslots:
    "Немає доступних таймслотів. Оберіть іншу дату, або запитайте в експерта щодо розкладу.",
  toastSavedSuccessfully: "Успішно збережено",
  toastDeletedSuccessfully: "Успішно видалено",
  toastProfileCopied: "Посилання скопійовано",
  toastEmailCopied: "Електронна пошта скопійована",
  toastBookingAvalable: "Ваш профіль доступний для бронювання",
  toastBookingUnavalable: "Ваш профіль недоступний для бронювання",
  toastQrCopied: "QR-код скопійовано",
  toastPaymentMethodAdded: "Спосіб оплати успішно додано",
  toastPaymentMethodDeleted: "Спосіб оплати успішно видалено",
  toastUrlCopied: "Персональне посилання скопійовано",
  toastOfferUrlCopied: "Посилання на пропозицію скопійовано",
  toastSessionCanceled:
    "Цю сесію було відмінено у зв'язку з часовим обмеженням",
  toastSessionWithYourself: "Ви не можете забронювати сесію самі з собою!",
  toastPayoutMethodAdded: "Спосіб виплати додано успішно",
  toastPayoutMethodDeleted: "Спосіб виплати успішно видалено",
  toastpayoutMethodNotAdded:
    "Нам не вдалося додати ваш спосіб виплати. Будь ласка, спробуйте пізніше!",
  toastPrimaryCardError: "Не вдалося встановити основну карту",
  toastPrimaryCardAdded: "Основну карту успішно додано",
  toastTooLargeFile: "Розмір файлу занадто великий",
  toastOfferNotAdded: "Пропозиція не додана",
  toastOfferNotEdited: "Пропозиція не відредагована",
  toastSessionLinkCopied: "Посиланя на сесію скопійовано",
  toastMaxParticipantsReached: "Максимальна кількість учасників досягнута",
  toastNotMayJoin:
    "Нажаль, ви не можете приєднатися до цієї сесії, тому що вона досягла ліміту учасників.",
  toastLinkExpired: "Термін дії посилання вичерпано.",
  toastCannotStartSession: "Неможливо почати швидку сесію",
  toastSessionExtendedSuccessfully: "Сесію було успішно продовжено",
  toastTimeslotStartAndEnd:
    "Ви не можете додати таймслот, який починається і закінчується в той самий час",
  toastTimeslotEndsInPast:
    "Ви не можете додати таймслот, який закінчується в минулому",
  toastTimeslotDuplicate: "Ви не можете додати дублюючий таймслот",
  toastSkillNameTooLong:
    "Назва тега занадто довга, сробуйте обрати більш коротку назву",
  buttonSave: "Зберегти",
  buttonCancel: "Відмінити",
  buttonApprove: "Підтвердити",
  buttonDelete: "Видалити",
  buttonChat: "Чат",
  buttonOpenChat: "Чат",
  buttonFiles: "Файли",
  buttonNotNow: "Не наразі",
  buttonSubmit: "Відправити",
  buttonNo: "Ні",
  buttonEnd: "Завершити",
  buttonSignUp: "Зареєструватися",
  buttonYes: "Так",
  buttonEdit: "Редагувати",
  buttonCopy: "Копіювати",
  buttonReply: "Відповісти",
  buttonAdd: "Додати",
  buttonSend: "Надіслати",
  buttonRequest: "Запросити підтримку",
  buttonSet: "Встановити",
  buttonEditPhoto: "Редагувати фото",
  buttonStartAnyway: "Підтвердити запуск",
  buttonWait: "Почекати",
  buttonCopyLink: "Копіювати посилання",
  buttonLeave: "Залишити",
  buttonExtraMinutes: "Додати 10 хвилин",
  buttonViewOffer: "Переглянути деталі",
  buttonMoreDetails: "Додаткова інформація",
  buttonContinue: "Продовжити",
  buttonBookACall: "Відео сесії",
  notificationSetup: "Setup",
  notificationFillProfile: "Заповніть профіль",
  notificationHi: "Вітання!",
  notificationAddYourName:
    "Додайте ваше ім'я та фото, щоб оживити спілкування, якщо забажаєте. Не наполягаємо :)",
  notificationSetupBilling: "Налаштування оплат",
  notificationAddPaymentMethod:
    "Додайте метод оплати, щоб змогти бронювати сесії.",
  notificationGoToBilling: "Перейти в Оплати",
  notificationWeAreSureYouHaveALotToSay:
    "Ми впевнені, вам є що про себе розказати. Заповніть профіль та почніть ділитися знаннями!",
  notificationAddAnOffer: "Додайте пропозицію",
  notificationAddYourOffers: "Додайте ваші пропозиції",
  notificationSetupVariousKindsOfSessions:
    "Створіть різні типи пропозицій для ваших клієнтів, які будуть доступні для бронювання у вашому профілі.",
  notificationGoToMyOffers: "Перейти в Мої Пропозиції",
  notificationConnectStripe: "Налаштування Виплат",
  notificationSetupPayouts: "Онбордiнг виплат",
  notificationInOrderToReceiveMoney:
    "Щоб отримати гроші від ваших клієнтів, ви маєте створити метод виплат.",
  notificationGoToPayouts: "Перейти в Виплати",
  notificationSetYourSchedule: "Налаштуйте ваш розклад",
  notificationWorkAtConvenientHours: "Працюйте в зручний час",
  notificationSetupSchedule:
    "Налаштуйте розклад і ваші клієнти зможуть бронювати сесії лише в зручний вам час.",
  accountOn: "Акаунт ввімкнено",
  accountOff: "Акаунт вимкнено",
  cancelSession: "Відмінити сесію",
  paymentFailed: "Невдала спроба оплати",
  paymentFailedDescription:
    "Ваш платіж не здійснено, і ми не змогли створити запит на сесію.",
  areYouSureCancel: "Ви впевнені, що ви хочете відмінити сесію?",
  areYouSureCancelPaidSession:
    "Ви впевнені, що бажаєте скасувати сесію? Ваш платіж буде повернено.",
  areYouSureCancelLessThanADay:
    "Ви впевнені, що бажаєте скасувати, якщо залишилося менше 24 годин? Ваш платіж НЕ буде повернено.",
  link: "Посилання",
  view: "Відкрити",
  requires_action: "Потребує 3DS",
  succeeded: "Успішна",
  signUpTitle: "Зареєструватися",
  profileTitleNotify: "Профіль | Waylight",
  dashboardTitleNotify: "Дешборд | Waylight",
  chatTitleNotify: "Чат | Waylight",
  with: "з",
  expertsTitle: "Експерти",
  expertsTitleNotify: "Домашня | Waylight",
  signUpTitleNotify: "Реєстрація | Waylight",
  chatTitle: "Чат",
  clientsTitle: "Клієнти",
  clientsTitleNotify: "Клієнти | Waylight",
  clientTitle: "Клієнт",
  fixedPrice: "Фіксована",
  internetConnectionLost: "Інтернет з'єднання відсутнє",
  fiveMinutesLeft: "Залишилось 5 хвилин",
  summarizeMeeting: "Підсумуйте зустріч і поділіться вашими враженнями",
  oopsConnectionLost: "Отакої... Інша сторона втратила зв'язок",
  partyTemporaryLostConnection:
    "Здається ви залишилися на самоті, через втрату зв'язку на іншій стороні. Вибачте за незручності.",
  leaveAReviewFor: "Залиште відгук про ",
  leaveAReview: "Залишити відгук",
  review: "Відгук",
  followers: "Підписники",
  rating: "Рейтинг",
  ratings: "рейтинги(ів)",
  reviews: "Відгуки",
  hitStarRate: "Поділіться вашими враженнями нижче!",
  reviewsEmpty: "Експерт ще не має відгуків",
  sessionEnded: "Сесію успішно завершено",
  thankYouForLighting: "Дякуємо, що освітили шлях іншим!",
  devicePermissionTittle:
    "Дозвольте Waylight доступ до вашого мікрофона та камери. Перезавантажте сторінку, якщо у вас немає відео.",
  networkConnection: "Рівень з'єднання",
  unknown: "невідомий",
  low: "низький",
  average: "середній",
  good: "стабільний",
  noFilesAdded: "Файли ще не було додано.",
  enterYourEmail: "Вкажіть вашу email адресу",
  required: "Обов'язкове поле",
  emailOrPassword: "Невірний email або пароль",
  emailInUse: "Цей email уже використовується",
  businessIsNotAvailableTitle:
    "Waylight Business не доступний в вашій країні. Будьте першими, хто дізнається про запуск.",
  beInTouch: "Будьте на зв'язку",
  businessIsNotAvailableEmailAddress: "Email Адреса",
  pleaseTryAgain:
    "Ми не змогли зберегти вашу email адресу. Будь-ласка, спробуйте ще раз.",
  thankYou:
    "Дякуємо, ми зв'яжемося з вами одразу після запуску підтримки вашої країни.",
  wereNoChatMessages:
    "Протягом сесії не було відправлено жодного текстового повідомлення",
  yourCountry: "Країна вашого банку",
  open: "Відкрити",
  offerName: "Назва пропозиції",
  gatewayCountryWarning:
    "Увага: це має бути країна вашої банківської установи і НЕ може бути змінена у майбутньому, що може вплинути на вашу можливість отримувати виплати.",
  addAttachment: "Додати файл",
  you: "Ви",
  explore: "Домашня",
  stats: "Статистика",
  more: "Дивитись ще",
  noPrice: "Безкоштовні пропозиції",
  theyWillAppear: "Вони будуть відображені тут, коли ви почнете взаємодію",
  noExpertFoundUsingFilter: "За цим запитом експертів не знайдено.",
  tryAnotherInput: "Спробуйте інший тег або запит",
  noFavoriteExpertsFound: "Улюблених експертів не знайдено.",
  tryAddingSomeone: "Спробуйте додати когось до улюблених експертів.",
  agoraDeniedError:
    "Будь-ласка, дозвольте використання вашого мікрофону та камери в налаштуваннях, щоб користуватись Waylight!",
  editCover: "Редагувати обкладинку",
  language: "Мова",
  invalid: "Невалідна",
  scrollPastHistory: "Проскрольте історію минулої сесії вище",
  earnedFromLastSession: "отримано за останню сесію",
  typeHere: "Введіть повідомлення",
  schedule: "Розклад",
  offers: "Пропозиції",
  copy: "Скопіювати",
  qrSharing: "Завантажте та поділіться своїм QR-кодом.",
  qrSharingMobile:
    "Натисніть та утримуйте QR-код, щоб скопіювати та поділитися ним.",
  preview: "Попередній перегляд",
  back: "Назад",
  love: "Створено з любов'ю в Waylight",
  allRightsReserved: "© 2024 Waylight Inc. Всі права захищені.",
  allRightsReservedSecond: "© 2022 Arceon Consulting OÜ. Всі права захищені",
  termsOfService: "Умови Користування",
  privacyPolicy: "Політика Конфіденційності",
  sessionSharingpopupTitle: "Неможливо почати демонстрацію екрану!",
  sessionSharingpopupDescription:
    "Перевірте, чи в вас остання версія браузера та чи є системний дозвіл на демонстрацію екрану. Також, спробуйте перезавантажити сторінку. Якщо проблема лишається, зв'яжіться з підтримкою!",
  sessionSharingpopupSafariDescription:
    "Демонстрація екрану не підтримується вашим браузером. Останні версії популярних браузерів підтримують цю функцію.",
  expertWithoutOffers: "В цього експерта ще нема жодної платної пропозиції",
  errorViewHeader: "Щось пішло не так!",
  errorViewDescription:
    "Сталася помилка. Можливо, це посилання не працює, або ви не маєте доступу до елемента.",
  returnToHome: "Повернутися на домашню",
  invalidURL:
    "Недійсна URL-адреса. Переконайтеся, що вона починається з 'https://' або 'http://'.",
  invalidVideoExternalURL:
    "Недійсна URL-адреса відео. Додайте посилання на Youtube або Vimeo.",
  tooLongUrl:
    "Це посилання занадто довге. Будь-ласка, не використовуйте посилання, довші, ніж 128 символів.",
  repeatedURL: "Це посилання вже додано",
  edited: "Відредаговано",
  emptyCards: "Ви ще не додали жодної картки",
  emptyBillingHistory: "У вас ще немає трансакцій",
  emptyBillingHistoryDescription:
    "Вони з’являться тут, коли ви почнете бронювати сеанси",
  addNewSkill: "Додати новий тег",
  confirmationAddSkill: "Ви впевнені, що хочете додати новий тег",
  addPaymentMethod: "Додати спосіб оплати",
  stripeDescription: "Для транзакцій з експертами з 50+ країн",
  fondyDescription: "Для транзакцій з експертами з України",
  fondyAddCardDescription:
    "Додайте спосіб оплати, щоб продовжити. Буде спроба підтвердити тестовий платіж.",
  timeZoneChangedHeader: "Часовий пояс змінився",
  timeZoneSChangedDescription:
    "Ми помітили, що ваш часовий пояс змінився. Оновити часовий пояс в налаштуваннях профілю?",
  updateButton: "Змінити",
  and: "і",
  moreFiles: "ще файлів",
  addPayout: "Додайте принаймні один спосіб отримання виплат",
  testChargeWarning: "Буде спроба підтвердити стягнення тестової плати.",
  payoutMethods: "Ваші способи отримання виплат",
  identifier: "Ідентифікатор",
  stopWar: "Зупиніть війну",
  yourAreOnlyOneHere: "До цієї сесії поки що більше ніхто не під'єднався",
  yourAreOnlyOneHereSingle:
    "До цієї сесії поки що більше ніхто не під'єднався\nПоділіться посиланням нижче з іншими",
  clientView: "Клієнт",
  businessView: "Бізнес",
  mainPage: "Головна сторінка",
  signUpCompleted: "Реєстрація завершена",
  selectPaymentInfo:
    "Доступні способи оплати залежать від платіжної системи вашого експерта",
  payment: "Платежі",
  paymentWasDeclined: "Платіж відхилено.",
  payoutWasDeclined: "Спосіб виплати відхилено.",
  primary: "Основна",
  notUsed: "Не використовується",
  releaseBannerTitle: "Waylight було оновлено до нової версії.",
  refresh: "Оновити",
  country: "Країна",
  viewAll: "Переглянути всі",
  videoPresentation: "Відеопрезентація",
  bookFor: "Забронювати за",
  bookForFree: "Бронювати безкоштовно",
  follow: "Слідкувати",
  followed: "Слідкую",
  emptyPortfolio: "Схоже, у цьому портфоліо немає файлів",
  media: "Медіа",
  other: "Файли",
  emptyReview: "Здається, немає відгуків",
  newest: "Найновіші",
  oldest: "Найстаріші",
  viewProfileInfo: "Відкрити деталі",
  emptyTransactions: "Схоже, транзакцій немає",
  emptyOffers: "На жаль, у цього експерта ще немає пропозицій.",
  searchExperts: "Шукати експертів",
  modifySchedule: "Редагувати розклад",
  editMode: "Редагувати",
  finishEditing: "Завершити редагування",
  editPersonalInfo: "Редагувати особисту інформацію",
  editPortfolio: "Редагувати портфоліо",
  editOffers: "Редагувати сесії",
  profileId: "ID особистого профілю",
  profileSettings: "Налаштування профілю",
  editPublicID: "Змінити загальнодоступний ID",
  removeTimeslot: "Видалити таймслот",
  areYouSureRemoveTimeslot: "Ви впевнені, що ви хочете видалити таймслот?",
  qrCode: "QR код",
  communication: "Засіб",
  editCoverDescription: "Налаштуйте зображення обкладинки",
  editProfileData: "Редагувати дані профілю",
  editProfileDataDescription: "Додайте інформацію про себе та свій досвід",
  editPublicIDDescription:
    "Оберіть унікальний публічний ідентифікатор для свого профілю",
  profilePhoto: "Фото профілю",
  publicIDDescription: "Це автоматично згенерований ідентифікатор!",
  publicIDWarning:
    "Не забудьте змінити його на щось круте в режимі редагування, як, наприклад 'supergirl'.",
  followWarning: "Кнопка “Підписатися” працюватиме для всіх користувачів.",
  openChatWarning:
    "Ви не зможете поговорити із собою, але ваші клієнти зможуть.",
  bookingOfferWarning:
    "Користувачі зможуть придбати пропозицію, натиснувши тут.",
  shareOffer: "Копіювати посилання",
  turnOffAccount: "Вимкнути акаунт",
  turnOnAccount: "Ввімкнути акаунт",
  areYouSureYouWantTurnOff:
    "Ви впевнені, що хочете повністю вимкнути ваш акаунт?",
  areYouSureYouWantTurnOn: "Ви впевнені, що хочете ввімкнути ваш акаунт?",
  disableFreeChat: "Вимкнути безкоштовний чат",
  enableFreeChat: "Ввімкнути безкоштовний чат",
  areYouSureYOuWantDisableFreeChat:
    "Ви впевнені, що хочете вимкнути безкоштовний чат?",
  areYouSureYOuWantEnableFreeChat:
    "Ви впевнені, що хочете ввімкнути безкоштовний чат?",
  cancelEditing: "Скасувати редагування",
  descriptionCancelEditing: "Ви впевнені, що хочете скасувати редагування?",
  takenPublicID: "Цей публічний ідентифікатор уже зайнятий",
  charactersMatch: "2-64 символа",
  charactersBetweenMatch: "Поле повинно містити від 1 до 64 символів",
  charactersMaxMatch: "Максимальна довжина поля - 64 символи",
  deletePhoto: "Видалити фото",
  descriptionDeletePhoto: "Ви впевнені, що хочете видалити свою фотографію?",
  accountOnDescription:
    "Коли ви вимикаєте акаунт, він стає недоступним в будь-який спосіб (пряме посилання, пошук і т.д.)",
  freechat: "Безкоштовний чат",
  freechatDescription:
    "Коли ви вимикаєте безкоштовний чат, користувачі не зможуть розпочати комунікацію з вами в цей спосіб. Однак, ви зможете зробити це з вашими клієнтами.",
  freeChatWarning:
    "У цьому обліковому записі вимкнено безкоштовний чат. Ви можете надіслати запит на сеанс для комунікації.",
  editMessage: "Редагувати повідомлення",
  replyTo: "Відповісти",
  file: "Файл",
  unableToJoinSession: "Неможливо приєднатися до сесії",
  backgroundWarning:
    "Попередження: фони не повністю підтримуються браузерами Safari та Firefox і можуть не працювати.",
  single: "Швидкі",
  scheduleSession: "Забронюйте сесію з експертом чи креатором.",
  startSessionImmediately:
    "Почніть безкоштовну сесію зараз. Запросіть інших за посиланням.",
  quickSessionWarningTittle: "Ви впевнені, що хочете почати сесію?",
  quickSessionWarningDescription:
    "В вашому розкладі є запланована сесія, що перетинається з швидкою сесію, яку ви намагаєтеся розпочати.",
  connectIn: "Під'єднатися через",
  connectOn: "Під'єднатися",
  sessionDecisionTittle: "Крім вас ніхто не приєднався",
  expertSessionDecision:
    "Хотіли б ви завершити сесію або почекати довше? Завершення сесії призведе до переказу платежу на ваш рахунок.",
  clientSessionDecision:
    "Хотіли б ви завершити сесію або почекати довше? Завершення сесії поверне вам ваш платіж.",
  cancelledSessionTittle: "Цю сесію скасовано",
  cancelledSessionExpertDescription:
    "Платіж перераховано на ваш рахунок. Просимо вибачення за незручності.",
  cancelledSessionClientDescription:
    "З вас не утримуватиметься плата, і кошти повернуться. Просимо вибачення за незручності.",
  areYouSureYouWantLeaveSession: "Ви впевнені, що бажаєте залишити сесію?",
  areYouSureYouWantLeaveSessionDescription:
    "Ви зможете повернутися до сесії, поки вона не закінчиться.",
  addExtraMinutes: "Додати 10 хвилин",
  addExtraMinutesDescription:
    "Ви впевнені, що хочете додати ще 10 хвилин до цієї сесії?",
  unableAddExtraMinutes: "Неможливо додати 10 хвилин",
  unableAddExtraMinutesDescription:
    "Нажаль, у вас запланована сесія менше ніж через 10 хвилин.",
  monday: "Понеділок",
  tuesday: "Вівторок",
  wednesday: "Середа",
  thursday: "Четвер",
  friday: "П'ятниця",
  saturday: "Субота",
  sunday: "Неділя",
  h: "г",
  sharingSetting: "Налаштування доступу",
  call: "Дзвінок",
  public: "Публічна",
  byLinkOnly: "Лише за посиланням",
  offerInfo: "Інформація про пропозицію",
  coverVideo: "Обкладинка / відео",
  imageVideo: "Зображення / відео",
  chooseCountry: "Оберіть країну",
  passwordChangedSuccessfully: "Пароль успішно змінено",
  connected: "Моє коло",
  passedALot: "Завершені",
  chooseCountryBank: "Оберіть країну вашого банку чи фінансового сервісу",
  createAccountContinue: "Створіть акаунт, щоб продовжити",
  metasignInTitle: "Вхід | Waylight",
  metasignUpTitle: "Реєстрація | Waylight",
  metapasswordForgotTitle: "Відновлення паролю | Waylight",
  metapasswordResetTitle: "Скинути пароль | Waylight",
  clientflowStep1Title: "Знайдіть посилання в БІО або пошуку Waylight",
  clientflowStep1:
    "Знайдіть посилання Waylight вашого креатора, інфлюенсера або експерта в соціальних мережах (Instagram, Facebook, YouTube, LinkedIn і т.д.) або введіть ім’я чи псевдонім у пошук Waylight.",
  clientflowStep2Title:
    "Забронюйте відеодзвінок або підпишіться на блог або чат",
  clientflowStep2:
    "Відкрийте та ознайомтеся з бізнес-профілем вашого креатора, інфлюенсера або експерта. Знайдіть цікаву пропозицію для відеодзвінка 1:1, забронюйте її та приєднайтеся в призначений час. Спробуйте поспілкуватися до або після, щоб отримати більше інформації в довгостроковій перспективі. Підпишіться на особистий блог з контентом тільки для підписників, навчальними туторіалами, статтями, відео-порадами, особистим життєвим досвідом, уроками, музикою та іншим.",
  clientflowStep3Title: "Перевірте децентралізовані рейтинги та відгуки",
  clientflowStep3:
    "Децентралізовані рейтинги створюють репутацію для авторів і експертів, їх не можна придбати або підробити, і вони створюють відчуття довіри, прозорості та доводять досвід і попит на контент для клієнтів і підписників. Залишайте свої реальні рейтинги після відеодзвінків, чатів і в постах. Поділіться своїми відгуками, щоб допомогти спільноті Waylight виділити найкращих.",
  confirmBooking: "Підтвердити вибір",
  free: "Безкоштовна",
  unableVerifyEmail: "Помилка при верифікації вашого email.",
  thankYouForVerifiedEmail: "Дякуємо за верифікацію вашого email!",
  reSend: "Повторити",
  signInToContinue: "Увійдіть, щоб продовжити",
  switchingToClientView: "Переключення в режим клієнта",
  switchingToExpertView: "Переключення в режим бізнесу",
  cookiePermission:
    'Ми цінуємо вашу конфіденційність. Ми використовуємо файли cookie для покращення вашого досвіду, надання персоналізованого контенту та аналізу нашого трафіку. Натискаючи "Прийняти", ви погоджуєтеся на використання нами файлів cookie.',
  readMore: "Докладніше",
  requestSupport: "НАТИСНІТЬ нижче, щоб звернутися до служби підтримки:",
  notReadableDevicesWarning:
    "Увага: Ваша камера та/або мікрофон на даний момент або використовуються, або до них немає доступу!",
  howPayoutsWork: "Як працюють виплати",
  payoutsOnboarding1: "Завершіть онбординг, щоб включити виплати.",
  payoutsOnboarding2:
    "Ми зберігаємо ваші гроші в безпеці до вашого успішного онбордингу.",
  payoutsOnboarding3:
    "Вам знадобляться ваші банківські реквізити або дані карти в залежності від вашої країни.",
  payoutsOnboarding4: "Виплати проходять кожен понеділок.",
  payoutsOnboarding5: "Ви можете перевірити статус виплат в Транзакціях.",
  payoutsOnboarding6: "Платіжний шлюз утримує 0.25% + $0.25 за виплату.",
  payoutsOnboardingEcommpay6:
    "Платіжний шлюз утримує 0,5% за картки та 0% з мерчантів, якщо увімкнені комісії клієнтів.",
  payoutsOnboarding7:
    "Якщо ви отримуєте виплату вперше, переказ може йти 7–14 робочих днів через обмеження платіжної системи.",
  payoutsOnboarding8: "Зазвичай виплата іде близько 3 робочих днів.",
  payoutsOnboarding9:
    "Змінити країну наразі неможливо. Зв’яжіться з нами, якщо є такий запит, і ми спробуємо вирішити питання в вашому конкретному випадку.",
  payoutsOnboarding10:
    "Waylight стягує змінну комісію в залежності від вашого плану.",
  payoutsOnboarding11: "Якщо у вас є питання, звертайтеся до",
  supportAlternative: "Підтримки",
  freeSessionDecision: "Ви бажаєте закінчити сесію чи почекати ще?",
  cancelledFreeSessionDescription:
    "Ви єдиний, хто з'явився. Приносимо вибачення за незручності",
  m: "хв",
  cityNotFound: "Варіантів не знайдено. Оберіть найближче велике місто.",
  notifications: "Сповіщення",
  telegramBot: "Телеграм-бот",
  notConnected: "не під'єднано",
  connectTelegramBot: "Під'єднати Телеграм-бот",
  disconnectTelegramBot: "Відключити Телеграм-бот",
  telegramConnected: "під'єднано",
  bgSettingsDescription:
    "Використовувати ці налаштування, щоб встановити бекграунд для ваших відео потоків",
  selectBackground: "Обрати бекграунд",
  bookingDelay: "Встановити затримку бронювання",
  bookingDelayDescription:
    "Вкажіть, наскільки заздалегідь вас можна бронювати.",
  processingFee: "Комісія платіжної системи",
  payoutsOnboardingEcommpay1:
    "Виберіть метод виплат особистої кредитної картки або ФОП.",
  payoutsOnboardingEcommpay3:
    "Ви повинні додати принаймні одну особисту кредитну картку + свій індивідуальний податковий номер АБО завершити реєстрацію ФОП мерчанта в Fondy, щоб транзакції працювали!",
  payoutsOnboardingEcommpay4: "Виплати здійснюються після кожної транзакції.",
  payoutsOnboardingEcommpay7:
    "Виплати здійснюються не пізніше наступного робочого дня.",
  tutorials: "Продукти",
  tutorialsAndCourses: "Придбати продукти",
  noOffers: "Схоже, тут ще немає пропозицій",
  newSessionOffer: "Нова пропозиція сесії",
  newSessionOfferDescription: "Створіть платну або безкоштовну відеосесію",
  newTutorialOffer: "Нова пропозиція продукту",
  newTutorialOfferDescription: "Створіть платний або безкоштовний продукт",
  sessionName: "Назва сесії",
  tutorialName: "Назва продукту",
  tutorialNameDescription:
    "Виберіть назву для вашого продукту, наприклад «Урок танців №1»",
  cover: "Обкладинка",
  tutorialCoverTooltip:
    "Завантажте зображення або відео для відображення в описі продукту.",
  getTutorialPaid: "Ви отримуєте гроші за кожну покупку цього продукту.",
  unlockedAfterPurchase: "Доступне після придбання",
  unlockedAfterPurchaseDescription:
    "Додайте контент, який буде доступний вашим клієнтам після транзакції.",
  tutorialDetailsDescription: "Додайте коментарі, деталі або інструкції тут",
  removeSection: "Видалити розділ",
  addAnotherSection: "Додати ще один розділ",
  details: "Деталі",
  toastEmptyUnlockedContent: "Заблокований контент не може бути порожнім",
  toastTutorialNotEdited: "Цей продукт не був відредагований",
  removeSectionDescription: "Ви впевнені, що хочете видалити розділ?",
  buyFor: "Купити за",
  buttonOpenChatAlt: "Відкрити чат",
  buttonShare: "Поділитися",
  tutorial: "Продукт",
  toastBuyingYourOwnTutorial: "Ви не можете купувати свій власний продукт!",
  purchasingInProgress: "Транзакція в процесі",
  byClickingConfirm: "Натискаючи,",
  iAgreeWith: "я погоджуюсь з",
  allowWaylightToCharge:
    "та дозволяю Waylight стягувати плату з моєї карти зараз і в майбутньому відповідно до умов підписки, допоки я її не скасую.",
  paymentProcessingFees: "Можливі комісійні за обробку платежів.",
  bookingTutorialWarning:
    "Користувачі зможуть купувати продукт, натиснувши тут.",
  editTutorials: "Редагувати продукти",
  topReview: "Найкращий відгук",
  addForFree: "Додати безкоштовно",
  tutorialPaymentFailed:
    "Ваш платіж не був здійснений, і ви не змогли придбати цей продукт.",
  buy: "Придбати",
  tutorialWith: "продукт від",
  purchased: "Придбано",
  noTutorialsFound: "Продукти не знайдено",
  noTutorialsFoundDescription:
    "Вони з'являться тут, коли ви придбаєте або додасте продукт",
  tags: "Мітки",
  createSessionOffer: "Створити пропозицію сесії",
  createSessionOfferDescription: "Додайте платну або безкоштовну відеосесію.",
  createTutorial: "Створити пропозицію продукту",
  createTutorialDescription: "Додайте платний або безкоштовний продукт.",
  requires_transfer: "Очікує на онбординг",
  pending: "Очікування виплати",
  requires_payout: "Очікування виплати",
  requires_capture: "На утриманні",
  reschedule: "Перепланувати",
  rescheduleDelay: "Встановити затримку перепланування",
  rescheduleDelayDescription:
    "Вкажіть, за скільки днів до сесії можна її перепланувати.",
  rescheduleSession: "Перепланувати сесію",
  rescheduleSessionDescription: "Ви впевнені, що хочете перепланувати сесію?",
  buttonChange: "Перепланувати",
  timeslots: "Часові інтервали",
  specifyReason: "Вкажіть причину зміни дати і часу.",
  cannotRescheduleSession: "Не можна перепланувати сесію",
  rescheduleReason: "Причина перепланування",
  optionLink: "Додати зовнішнє посилання",
  optionUpload: "Або завантажити файл",
  tutorialLinkDescription: "Додайте посилання на ваш продукт.",
  tutorialUploadDescription:
    "Завантажте будь-який файл сюди. Ви можете додати один файл на розділ.",
  bgNone: "Відсутній",
  bgLowBlur: "Слабке розмиття",
  bgMediumBlur: "Середнє розмиття",
  bgHighBlur: "Сильне розмиття",
  bgSolidColour: "Суцільний колір",
  bgCustomImage: "Власне зображення",
  telegramNotifications: "Сповіщення в Telegram",
  connectYourTelegramAccount:
    "Підключіть свій обліковий запис Telegram, щоб отримувати сповіщення від Waylight.",
  buttonConnectTelegram: "Підключити Telegram",
  notificationGoToMyProfile: "Перейти в мій профіль",
  setupSchedule: "Налаштувати розклад",
  aboutTheTutorial: "Про продукт",
  added: "Додано",
  orderingChangeInProgress: "Зміна порядку...",
  gloryToUkraine: "Слава Україні!",
  terroristStates:
    "Waylight не працює для користувачів з санкційних країн з диктаторськими та терористичними режимами: Росія, Іран, Північна Корея, Сирія, Куба, Венесуела, Афганістан.",
  never: "Без обмежень",
  expiration: "Експірація",
  expirationDescription:
    "Клієнти матимуть доступ до цього продукту протягом певної кількості днів",
  daysLeft: "днів залишилося",
  tutorialExpired: "Ваш доступ до цього продукту закінчився.",
  days: "днів",
  tips: "Подяки",
  newTipOffer: "Нова пропозиція подяки",
  newTipOfferDescription: "Створити подяку",
  tipName: "Назва подяки",
  tipNameDescription: "Оберіть назву для вашої подяки",
  tipCoverTooltip:
    "Завантажте зображення або відео, яке буде відображатися в описі подяки.",
  aboutTheTip: "Про подяку",
  describeYourTip:
    "Додайте опис подяки: збирання пожертв, проведення благодійності або просто кава?",
  tipAmounts: "Додайте три типових суми",
  tipAmountsDescription:
    "Ваші клієнти також зможуть ввести свою власну суму, якщо вони цього побажають.",
  bonusTipper: "(Необов'язково) Відправити бонус клієнту",
  bonusTipperDescription:
    "Додайте контент або повідомлення, які клієнти отримають як бонус після подяки.",
  tipLinkDescription: "Додайте посилання на ваш бонус.",
  tipMessageDescription: "Додайте ваше текстове повідомлення тут.",
  toastTipNotEdited: "Ця подяка не була відредагована",
  leaveATip: "Подякувати",
  bookingTipWarning: "Користувачі зможуть подякувати, натиснувши тут.",
  includesAReward: "Містить бонус",
  selectTip: "Оберіть, скільки ви хотіли б подякувати",
  selectTipAmount: "Оберіть суму подяки",
  toastBuyingYourOwnTip: "Ви не можете подякувати самі собі!",
  tipSuccessfullyBought: "Подяку успішно проведено",
  tip: "Подяка",
  tipPaymentFailed: "Ваш платіж не пройшов, і ви не змогли подякувати.",
  purchasedOffers: "Придбане",
  noTipsFound: "Подяк не знайдено",
  noTipsFoundDescription: "Вони з'являться тут, коли почнете дякувати",
  thankYouExclamation: "Дякую!",
  editTips: "Редагувати подяки",
  tipPriceLimit: "Ціна може бути від $2 до $10000.",
  tipPriceLimitForUA: "Ціна може бути від ₴83 до ₴412496.",
  cardAuthorization: "Авторизація картки",
  failed: "Помилка",
  communityName: "Назва спільноти",
  communityNameDescription:
    "Оберіть назву для вашої спільноти, наприклад, «Мій ексклюзивний канал в Telegram»",
  getCommunityPaid: "Ціна підписки",
  Period: "Період*",
  expirationCommunityDescription: "Встановіть тривалість підписки",
  aboutTheCommunityOffer: "Про пропозицію",
  TelegramChannelName: "Назва каналу/групи в Telegram*",
  communityTelegramNameDescription:
    "Оберіть назву вашого каналу або групи. Якщо поле порожнє, натисніть на позначку інформації для отримання інструкцій.",
  communities: "Спільноти",
  community: "Спільнота",
  subscribeFor: "Підписатися за",
  subscribed: "Підписано",
  unsubscribe: "Відписатися",
  unsubscribeDescription:
    "Ви впевнені, що хочете відписатися?\nВаш доступ закінчиться",
  subscribeDescription: "{{community}}.",
  usubscribed: "Відписано",
  unlimited: "Необмежено",
  subscribeAgain: "Підписатися знову",
  unsubscribeDescriptionModal:
    "Ви впевнені, що хочете підписатися знову?\nПлату за наступний період буде стягнуто",
  subscribe: "Підписатися",
  currentlyMember: "Ви наразі є учасником {{title}} в Telegram.",
  notCurrentlyMember: "Ви наразі не є учасником {{title}} в Telegram.",
  newCommunityOffer: "Нова пропозиція спільноти",
  newCommunityOfferDescription: "Створити або керувати спільнотою",
  editCommunities: "Редагувати спільноти",
  joinCommunities: "Приєднатися до спільнот",
  noCommunitiesAdded: "Спільноти не додано",
  TheyWillAppear: "Вони з'являться тут, коли ви створите пропозиції.",
  createCommunity: "Створити спільноту",
  noAccessToData: "Немає доступу до даних! Додайте",
  noAccessToDataAsAnAdministrator:
    "як адміністратора до вашої групи, щоб надати доступ.",
  retryPayment: "Повторити сплату",
  areYouSureYouWantToSetCard:
    "Ви впевнені, що хочете встановити картку *{{last4}} як основну?",
  thingsToTry: "Спробуйте наступне:",
  checkIfYouHaveSufficient:
    "Перевірте, чи у вас достатньо коштів на балансі або чи включені інтернет-платежі.",
  selectDifferentPaymentMethod: "Оберіть інший спосіб оплати.",
  set: "Встановити",
  primaryCard: "Основна",
  setPrimary: "Встановити основну",
  inviteMember: "Запросити учасника",
  copyLink: "Скопіювати посилання",
  members: "Учасники",
  cancel: "Скасувати",
  actions: "ДІЇ",
  subscribedUntil: "ПІДПИСАНО ДО",
  waylightClients: "Клієнт WAYLIGHT",
  nameMembers: "ІМ'Я",
  admin: "Адміністратор",
  external: "Не авторизовано",
  subscriber: "Підписник",
  unsubscribed: "Відписано",
  kicked: "Видалено",
  owner: "Власник",
  save: "Зберегти",
  kickAndBan: "Видалити з каналу",
  areYouSureYouWantToRemove:
    "Ви впевнені, що хочете видалити «{{name}}» з вашого каналу?",
  WeHaveEncounteredAnIssue:
    "Ми зіткнулися з проблемою з вашим регулярним платежем!",
  yourPaymentHasNotGone:
    "Ваш платіж не пройшов, і ми не змогли повторити сплату за вашу підписку.",
  gatewayFee: "Клієнти сплачують комісію платіжної системи",
  gatewayFeeDescription:
    "З цим налаштуванням комісія платіжної системи буде додана до рахунків клієнтів. Якщо воно вимкнено, комісія буде відраховуватися з виплат бізнесу.",
  enableGatewayFee: "Увімкнути комісію платіжної системи для клієнтів?",
  areYouSureYOuWantEnableGatewayFee:
    "Ви впевнені, що хочете увімкнути комісію платіжної системи для клієнтів?",
  disableGatewayFee: "Вимкнути комісію платіжної системи для клієнтів?",
  areYouSureYOuWantDisableGatewayFee:
    "Ви впевнені, що хочете вимкнути комісію платіжної системи для клієнтів? Комісія буде відраховуватися з ваших виплат!",
  communityTooltip:
    "Переконайтеся, що ви додали Waylight Bot до вашої групи/каналу як адміністратора, підключили свій обліковий запис Telegram до Waylight і це єдине пропозиція для цього каналу!",
  communityCoverTooltip:
    "Завантажте зображення або відео, яке буде відображатися в описі спільноти.",
  setCard: "Встановити",
  expirationDateMustBeInFuture:
    "Дата закінчення підписки має бути в майбутньому",
  accessGranted: "Доступ надано",
  noAccess: "Немає доступу",
  manageCommunity: "Управління спільнотою",
  manage: "Управління",
  January: "Січень",
  February: "Лютий",
  March: "Березень",
  April: "Квітень",
  May: "Травень",
  June: "Червень",
  July: "Липень",
  August: "Серпень",
  September: "Вересень",
  October: "Жовтень",
  November: "Листопад",
  December: "Грудень",
  toastBuyingYourOwnCommunity:
    "Ви не можете підписатися на свою власну спільноту!",
  priceLimitCommunity: "Ціна може бути від $5 до $10000.",
  priceLimitCommunityForUA: "Ціна може бути від ₴198 до ₴195000.",
  toastRetryPaymentSuccess: "Повторна спроба оплати була успішною.",
  toastCommunityLinkCopied: "Посилання на спільноту скопійовано",
  communityPaymentFailed: "Платіж за спільноту не вдався",
  AddExpirationDateManually: "Додати дату закінчення вручну:",
  SetTimeFormat: "Встановити формат часу",
  events: "Події",
  newEventOffer: "Нова пропозиція події",
  newEventOfferDescription: "Створити подію з обмеженим часом",
  eventName: "Назва події",
  eventNameDescription:
    "Виберіть назву для вашої події, наприклад, «Майстер-клас з SEO»",
  eventCoverTooltip: "Підказка щодо обкладинки події",
  getEventPaid: "Ви отримуєте гроші за кожну покупку місць на цій події",
  aboutTheEventOffer: "Про подію",
  dateAndTime: "Дата та час",
  clickToSetDateAndTime: "Натисніть, щоб встановити дату та час",
  DescribeYourEvent: "Коротко опишіть вашу подію.",
  onlineEvent: "Онлайн-подія",
  offlineEvent: "Офлайн-подія",
  eventLinkDescription:
    "Додайте інформацію про місце події, таке як Zoom чи Meet",
  uploadOptional: "Завантажити (опціонально)",
  uploadEventDetails:
    "Завантажте сюди будь-який файл із додатковими деталями про подію (за бажанням)",
  addInformationAboutLocation:
    "Додайте інформацію про місце проведення події або посилання на карту",
  location: "Місце проведення",
  address: "Адреса",
  addressOptional: "Адреса (опціонально)",
  addAddressEvent: "Додайте адресу події",
  AllDayEvent: "Це подія на весь день.",
  toastBuyingYourOwnEvent: "You can not buy your own event!",
  event: "Подія",
  timeToEvent: "Час до події",
  hoursEvent: "години",
  seatsLeft: "вільних місць",
  follower: "Підписник",
  priceLimitEventForUA: "Ціна може бути від ₴198 до ₴195000.",
  soldOut: "Розпродано!",
  joinEvents: "Відвідати події",
  noEventFound: "Подій не знайдено",
  noEventsFoundDescription:
    "Вони з'являться тут, коли ви відвідаєте принаймні одну подію",
  editEvents: "Редагувати події",
  attendForfree: "Відвідати безкоштовно",
  disabled: "Вимкнено",
  enabled: "Увімкнено",
  personalCreditcard: "Особистий (кредитна картка)",
  privateEntrepreneur: "Приватний підприємець (IBAN)",
  selectPayoutMethod: "Виберіть метод виплати",
  enterYourPersonalTaxCode: "Введіть свій особистий податковий код*",
  createFondyMerchantAccount:
    "Створіть обліковий запис для продавця Fondy та завершіть онбординг:",
  create: "Створити",
  participantsMin: "Мінімальна кількість учасників - 1",
  personalTaxCodeError:
    "Індивідуальний податковий номер (ІПН) повинен бути правильним числом!",
  participantsError: "Кількість учасників повинна бути валідним числом!",
  peName: "Назва ФОП",
  url: "Веб-сайт",
  okpo: "ЄДРПОУ",
  phone: "Телефон",
  peNameTooltip: "Офіційна назва вашого ФОП: 'Прізвище Ім'я По-батькові'.",
  urlTooltip:
    "URL вашого бізнесу. Це може бути ваш веб-сайт, Facebook, Instagram тощо.",
  okpoTooltip: "ЄДРПОУ вашого ФОП.",
  phonePayoutTooltip: "Ваш номер телефону.",
  paymentsTipStripe:
    "Платежі завжди активовані для користувачів Stripe. Виплати доступні після завершення проходження онбордингу.",
  paymentsTipFondy1:
    "Приватні підприємці: платежі активуються після створення мерчанту. Виплати доступні після завершення онбордингу.",
  paymentsTipFondy2:
    "Кредитні картки: платежі та виплати активуються після додавання комбінації приватної кредитної картки та індивідуального податкового номеру.",
  phoneError: "Номер телефону повинен бути валідним!",
  toastTaxCodeAdded: "Ваш індивідуальний податковий номер успішно доданий.",
  toastTaxCodeError:
    "Під час спроби додати ваш індивідуальний податковий номер сталася помилка!",
  toastPECodeAdded:
    "Ваш новий мерчант Fondy створений успішно. Перевірте свою електронну пошту, щоб розпочати онбординг!",
  toastPECodeError:
    "Під час спроби створити нового мерчанта Fondy сталася помилка!",
  onboarding: "Онбординг",
  notCompletedOnboarding: "Не завершено",
  completedOnboarding: "Завершено",
  completeOnboarding: "Завершити онбординг",
  onboardingTooltip:
    "Завершіть онбординг в панелі управління Fondy. Перевірте свою електронну пошту на запрошення створити пароль, увійдіть до панелі управління Fondy, завантажте необхідні документи та додайте цифровий підпис. Після схвалення ваш обліковий запис буде активовано.",
  fondyMerchantAccountDetails: "Деталі акаунту Fondy",
  eventIsLife: "Прямий ефір!",
  created: "Ініційовано",
  processing: "Обробляється",
  goToFondyDashboard: "Перейти в ваш кабінет Fondy",
  youMustCompleteFondy:
    "Ви повинні завершити реєстрацію в Fondy за допомогою електронного листа, який ви отримали на:",
  chooseEventType: "Виберіть тип події",
  addException: "Додати виняток",
  manageScheduleExceptions: "Керування винятками в розкладі",
  addRemoveSpecificTime:
    "Додайте або видаліть конкретний час, коли ви доступні або недоступні.",
  exceptionType: "Тип винятку:",
  available: "Доступність",
  notAvailable: "Недоступність",
  yes: "Так",
  no: "Ні",
  removeException: "Видалити виняток",
  removeExceptionDesc: "Ви впевнені, що хочете видалити виняток {{time}}?",
  NoExceptionsAdded: "Винятків не додано",
  availableAllDay: "Доступність на цілий день.",
  notAvailableAllDay: "Недоступність на цілий день.",
  exceptionDeleteSuccessfully: "Виняток успішно видалено",
  exceptionDeleteError: "Ваш виняток не може бути видалено",
  exceptionSavedSuccessfully: "Виняток успішно збережено",
  exceptionSavedError: "Ваш виняток не може бути збережено",
  addJoininstructions: "Додати інструкції для приєднання",
  addJoininstructionsDesc:
    "Додайте контент або повідомлення, яке ваші клієнти побачать після придбання, коли вони ще не приєдналися до каналу",
  joinChannel: "Приєднатися до каналу",
  communityUploadDescription: "Завантажити зображення або відео.",
  detailsOptional: "Деталі (Опціонально)",
  type: "Tип",
  youCannotAddPast:
    "Ви не можете додати часовий інтервал, який закінчується у минулому",
  Sun: "Нд",
  Mon: "Пн",
  Tue: "Вт",
  Wed: "Ср",
  Thu: "Чт",
  Fri: "Пт",
  Sat: "Сб",
  toastEventNotEdited: "Сталася помилка при спробі редагувати вашу подію!",
  refunded: "Повернута",
  declined: "Відхилена",
  verified: "Верифікована",
  transactionSuccessful: "Успішна транзакція",
  myTabs: "Мої таби",
  tabStatus: "{{tabName}} - {{status}}",
  controlOfferTabs:
    "Типи пропозицій, які клієнти можуть бачити на вашій головній сторінці:",
  onlyEnglishLetters: "Дозволені лише англійські літери та цифри",
  notificationCompleteOnboarding: "Завершити Онбординг",
  notificationYourPaidOffersAreUnavailable:
    "Ваші платні пропозиції недоступні для ваших клієнтів.",
  products: "Продукти",
  bookingAvailable: "Доступність бронювання",
  bookingAvailableDescription: "Вкажіть, чи доступне бронювання в цілому.",
  availableKey: "Доступне",
  notAvailableKey: "Не доступне",
  paymentOccurs: "Платіж стягується раз на",
  paymentOccursOneTime: "Одноразовий платіж.",
  eventIsOver: "Подія завершилася",
  integrations: "Інтеграції",
  externalAnalytics: "Зовнішня аналітика",
  externalAnalyticsDescription:
    "Встановіть ваші ідентифікатори облікових записів зовнішньої аналітики нижче.",
  participantsManage: "Учасники",
  manageEvent: "Управління подією",
  noEventsAdded: "Подій немає",
  createEvent: "Створити подію",
  shareEvent: "Поділитися подією",
  waylightProfile: "Waylight Профіль",
  tickedId: "ID квитка",
  checkIn: "Чек-ін",
  checkedIn: "Використано",
  notCheckedin: "Не використано",
  codeIsValid: "Код дійсний! Використано!",
  codeisInvalid: "Код недійсний! Будь ласка, спробуйте ще раз.",
  codeIsUsed: "Цей код вже використано!",
  buyMore: "Купити ще",
  noParticipantsYet: "Ще немає учасників",
  emptyDeteilsBuysTickets:
    "Вони з'являться тут, коли хтось придбає квитки на вашу подію.",
  connectYourTelegram:
    "Підключіть свій Telegram, щоб отримати доступ до каналу!",
  change: "Зміна",
  numberOfTickets: "Кількість квитків:",
  selectPaymentMethodTitle: "Оберіть спосіб оплати:",
  or: "- або -",
  addACard: "Додати картку",
  subscriptionLevels: "Рівні підписки",
  subscriptionLevelsTooltip:
    "Додайте до 3 рівнів до підписки вашої спільноти. Наприклад: щомісячно, щоквартально і щорічно.",
  levelName: "Назва рівня",
  starter: "Стартовий",
  pro: "Професійний",
  levelDiscription: "Назва рівня, яку бачать клієнти",
  period: "Період",
  periodDiscription: "Клієнти будуть оплачувати підписку з цим інтервалом",
  priceDiscription: "Ви отримуєте оплату через визначені інтервали",
  removeLevel: "Видалити рівень",
  addAnotherLevel: "Додати ще один рівень",
  noTrial: "Без пробного періоду",
  trialDiscription: "Кількість днів безкоштовного доступу для ваших клієнтів",
  trialPeriod: "Пробний період:",
  selectSubscription: "Обрати підписку:",
  subscription: "Підписка",
  removeLevelDescription: "Ви впевнені, що хочете видалити рівень?",
  tryForFree: "Спробувати безкоштовно",
  startTrial: "Розпочати trial",
  subscribedSuccessfully: "Підписка успішно оформлена",
  edit: "Редагувати",
  expirationColumName: "Експірація",
  kick: "Вилучити",
  trial: "Пробний період",
  notJoined: "Не приєднано",
  telegramProfile: "Профіль Telegram",
  notAuthorized: "Не авторизовано",
  noMembersFoundFilter: "Згідно з встановленим фільтром учасники не знайдені.",
  tryADifferentFilter: "Спробуйте інший фільтр або пошуковий запит.",
  "not joined": "Не приєднано",
  "not authorized": "Не авторизовано",
  membership: "Членство",
  priceDiscriptionEvents:
    "Ви отримуєте оплату за кожну покупку квитків на цій події",
  fanZone: "Фан-зона",
  vipZone: "VIP-зона",
  selectTickets: "Оберіть квитки:",
  attendance: "Участь",
  eventLevels: "Типи квитків",
  noTicketsSelected: "Квитки не обрано",
  SetMaximumNumberOfSeats:
    "Встановіть максимальну кількість місць для цього рівня",
  participants: "Кількість місць",
  import: "Імпорт",
  export: "Експорт",
  labelNameDescription: "Оберіть лейбл для ваших імпортованих даних",
  hidden: "приховано",
  upload: "Завантажити",
  labelName: "Назва лейблу",
  fileIsRequired: "Ви повинні завантажити файл!",
  code: "код",
  level: "Рівень",
  label: "Лейбл",
  exportFileError: "Експорт не вдалося!",
  exportFileSuccess: "Файл успішно експортовано.",
  importFileSuccess: "Файл успішно імпортовано.",
  importFileError: "Імпорт не вдався!",
  statsSuccess: "Статистика чек-іну завантажена успішно.",
  statsError: "Виникла помилка при завантаженні статистики чек-іну!",
  uploadAText: "Звичайний текст або файл csv до 50 МБ",
  onlyTextFilesAllowed: "Дозволені лише текстові файли або csv!",
  toastCommunityNotEdited: "Помилка при редагуванні спільноти",
  toastYouCanSelectOnlyImage:
    "Ви можете вибрати лише одне зображення для заголовка",
  sections: "Секції",
  logInOrSignup: "Увійдіть або зареєструйтеся в Waylight нижче",
  continue: "Продовжити",
  invalidEmail: "Невалідний email",
  enterCode: "Введіть код",
  haveSentCodeTo: "Ми відправили код на",
  paste: "Вставити",
  resendCodeIn: "Відправити код ще раз... (через {{time}} секунд)",
  resendCode: "Відправити код знову",
  waylight: "Waylight",
  pleaseChooseCountry:
    "Будь ласка, оберіть країну вашого банку або фінансової служби.",
  buyAccessFor: "Купити доступ за",
  thankYouForFollowing: "Дякуємо за вподобання!",
  incorrectCode: "Код невірний або застарів!",
  enterEmailToContinue: "Введіть електронну адресу для продовження",
  city: "Місто",
  social: "Cоцмережі",
  earned: "загалом",
  priceLimitARS:
    "Ціна може бути ARS$ 0 або від ARS$ 1879.31 до ARS $9396554.46.",
  priceLimitAUD: "Ціна може бути A$ 0 або від A$ 3.01 до A$ 15097.1 .",
  priceLimitEUR: "Ціна може бути €0 або від €1.81 до €9070.",
  priceLimitBOB: "Ціна може бути Bs. 0 або від Bs. 13.81 до Bs. 69119.48.",
  priceLimitBGN: "Ціна може бути лв 0 або від лв 3.55 до лв 17750.81.",
  priceLimitCAD: "Ціна може бути CA$ 0 або від CA$ 2.74 до CA$ 13718.98.",
  priceLimitCLP: "Ціна може бути CL$ 0 або від CL$ 1864.56 до CL$ 9321644.2.",
  priceLimitCOP:
    "Ціна може бути COL$ 0 або від COL$ 8072.11 до COL$ 40360576.84.",
  priceLimitCRC: "Ціна може бути ₡0 або від ₡1055.02 до ₡5277582.56.",
  priceLimitHRK: "Ціна може бути kn 0 або від kn 35.24 до kn 70472.",
  priceLimitCZK: "Ціна може бути Kč 0 або від Kč 45.71 до Kč 228558.",
  priceLimitDKK: "Ціна може бути kr 0 або від kr 13.54 до kr 67719.",
  priceLimitDOP: "Ціна може бути RD$ 0 або від RD$ 294.46 до RD$ 588928.",
  priceLimitHKD: "Ціна може бути HK$ 0 або від HK$ 15.58 до HK$ 77915.",
  priceLimitEGP: "Ціна може бути EGP 0 або від EGP 154.75 до EGP 309501.",
  priceLimitHUF: "Ціна може бути Ft 0 або від Ft 716 до Ft 3579217.",
  priceLimitISK: "Ціна може бути kr 0 або від kr 684 до kr 1369300.",
  priceLimitINR: "Ціна може бути ₹0 або від ₹167.89 до ₹839435.",
  priceLimitIDR: "Ціна може бути Rp 0 або від Rp 78852 до Rp 157703833.",
  priceLimitILS: "Ціна може бути ₪0 або від ₪19 до ₪36657.",
  priceLimitUYU: "Ціна може бути $U 0 або від $U 81 до $U 402925.",
  priceLimitGBP: "Ціна може бути £0 або від £1.56 до £7779.95.",
  priceLimitTRY: "Ціна може бути ₺0 або від ₺67.06 до ₺335289.19.",
  priceLimitTTD: "Ціна може бути TT$ 0 або від TT$ 13.58 до TT$ 67917.76.",
  priceLimitTHB: "Ціна може бути ฿0 або від ฿69.82 до ฿349109.6.",
  priceLimitCHF: "Ціна може бути CHF 0 або від CHF 1.73 до CHF 8647.",
  priceLimitSEK: "Ціна може бути kr 0 або від kr 20.82 до kr 104092.39.",
  priceLimitZAR: "Ціна може бути R 0 або від R 94 до R 186359.",
  priceLimitSGD: "Ціна може бути SGD$ 0 або від SGD$ 2.63 до SGD$ 13146.69.",
  priceLimitSAR: "Ціна може бути SAR 0 або від SAR 19 до SAR 37496.",
  priceLimitRON: "Ціна може бути lei 0 або від lei 9.04 до lei 45196.94.",
  priceLimitPLN: "Ціна може бути zł 0 або від zł 7.8 до zł 39008.41.",
  priceLimitPHP: "Ціна може бути ₱ 0 або від ₱ 281 до ₱ 560255.",
  priceLimitPEN: "Ціна може бути S/. 0 або від S/. 19 до S/. 38040.",
  priceLimitPYG: "Ціна може бути ₲ 0 або від ₲ 36387 до ₲ 72772680.",
  priceLimitNOK: "Ціна може бути kr 0 або від kr 21.38 до kr 106903.",
  priceLimitNZD: "Ціна може бути NZ$ 0 або від NZ$ 3.33 до NZ$ 16646.",
  priceLimitMXN: "Ціна може бути MX$ 0 або від MX$ 37.92 до MX$ 189595.",
  priceLimitKES: "Ціна може бути KSh 0 або від KSh 803 до KSh 1605000.",

  linkInBIOtool: "Інструмент Link in BIO",
  thatHelpsYouEarn: "що допомагає заробляти!",
  earnMore: "Заробляйте більше",
  withWaylight: "з Waylight:",
  descriptionEarn: "Заробляйте в 10 разів більше зі своїх соціальних мереж!",
  descriptionEarnPart: "Запускайтеся за дві хвилини!",
  earnOnPaidCommunities: "Заробляйте на платних спільнотах.",
  createAndManage:
    "Створюйте та керуйте своїми підписками на спільноти в Telegram.",
  EarnPaidDigital: "Заробляйте на платних цифрових продуктах.",
  hostAndManage:
    "Додавайте та керуйте своїми курсами, уроками, електронними книгами, посібниками, файлами тощо.",
  earnOnline: "Заробляйте на онлайн і офлайн подіях.",
  sellTicketsAndManage:
    "Продавайте квитки та керуйте своїми онлайн і офлайн груповими подіями.",
  EarnServices: "Заробляйте на платних послугах.",
  sellManage: "Продавайте та керуйте своїм потоком послуг.",
  earnOnlineVideoSessions: "Заробляйте на платних відеосесіях онлайн.",
  ScheduleBookingsOnlineVideo:
    "Створіть розклад та отримуйте бронювання на відеосесії з клієнтами.",
  EarnAppointments: "Заробляйте на платних особистих сесіях.",
  ScheduleSignups:
    "Створіть розклад та отримуйте заявки на свої особисті чи групові сесії.",
  earnTipping: "Заробляйте на подяках.",
  getTippingSupport:
    "Отримуйте подяки на підтримку своїх каналів або за розповсюдження контенту в соцмережах.",
  youWilGet: "Ви отримаєте",
  tools: "1. Інструменти",
  sales: "2. Продажі",
  clientsGet: "3. Клієнтів",
  howItWorks: "Як це працює",
  GetClients: "Отримуйте нові продажі і клієнтів",
  useWaylightGrowth:
    "Використовуйте інструменти для зростання і поради від Waylight, щоб продавати більше та здобувати нових клієнтів з соцмереж.",
  createWaylight: "Створити Waylight",
  creatorsExperts: "Кріейторів і експертів",
  interactionsMonthly: "Інтеракцій щомісяця",
  monthOverMonth: "MoM зростання",
  forWhom: "Для кого:",
  pricing: "Ціни:",
  chooseYourPlan: "Оберіть свій план",
  saveWhenChoosingYearlyPlan: "Економте більше на щорічному плані!",
  monthlyBilling: "Щомісячний",
  annuallyBilling: "Щорічний",
  freePlan: "Безкоштовний",
  startLowestCosts: "Почніть з найнижчих витрат та досліджуйте Waylight",
  mo: "/міс.",
  signUp: "Зареєструватися",
  impressionMo: "{{comission}}% комісії за транзакції.",
  basicToolsIncluded: "Включено основні інструменти!",
  basic: "Базовий",
  getEverythingBasic: "Отримайте все з безкоштовного плану з меншими комісіями",
  contactSales: "Звернутися в відділ продажів",
  transactionFees: "2% комісії за транзакції.",
  publishingMarketplace: "Публікація в пошуку маркетплейсу.",
  saveBadge: "Економія {{percent}}%",
  bestValue: "Найкраща ціна",
  GetMoreSales:
    "Отримуйте більше продажів з соцмереж без комісій за транзакції",
  ziroTransactionFees: "0% комісії за транзакції.",
  proToolsIncluded: "Включено професійні інструменти!",
  customizations: "Налаштування.",
  verifiedSign: "Бейдж 'перевірено'.",
  prioritySupport: "Пріоритетна підтримка.",
  launchExpert: "Допомога Експерта по запусках.",
  askTelegram: "Запитайте в Telegram",
  askWhatsApp: "Запитайте в WhatsApp",
  askEmail: "Запитайте в Email",
  MakeTenX: "Заробляйте в 10 разів більше",
  onYourSocialMedia: "зі своїх соціальних мереж! 🎉",
  revenue: "мереж! 🎉",
  launchJust: "Запускайтеся за",
  twoMinutes: "2 хвилини!",
  product: "Продукт:",
  customersDataManagement: "Керування даними клієнтів",
  reviewsProduct: "Відгуки",
  portfolioProduct: "Портфоліо",
  businessWebsite: "Бізнес-сайт",
  securePayments: "Безпечні платежі",
  tipping: "Подяки",
  paidAppointments: "Платні особисті сесії",
  paidVideoSessions: "Платні відеосесії",
  paidServices: "Платні послуги",
  ticketsOnlineEvents: "Квитки на онлайн-події",
  ticketsScanner: "Сканер квитків",
  ticketsPersonEvents: "Квитки на особисті події",
  paidAccessCourses: "Платний доступ до курсів",
  paidAccessDigitalProducts: "Платний доступ до цифрових продуктів",
  paidGroupChannelAccessTelegram:
    "Платний доступ до груп та каналів в Telegram",
  paidSubscriptionCommunitiesTelegram:
    "Платні підписки на спільноти в Телеграм",
  productMenuItem: "Продукт",
  PricingMenuItem: "Ціни",
  Creator: "Кріейтор",
  Freelancer: "Фрілансер",
  Tutor: "Репетитор",
  Coach: "Коуч",
  Influencer: "Інфлюєнсер",
  ask: "Задати",
  anyQuestions: "будь-які питання тут:",
  how: "Як",
  itWorks: "це працює",
  businessflowStep1new:
    "Додайте своє посилання Waylight в BIO соціальних мереж та почніть залучати аудиторію та клієнтів, ділитися історіями та постами про це.",
  businessflowStep2new:
    "Використовуйте інструменти Waylight та поради по зростанню, щоб продавати більше та залучати нових клієнтів з соціальних мереж.",
  businessflowStep3new:
    "Отримуйте оплату за свої спільноти, цифрові продукти, онлайн та офлайн заходи, подяки, особисті та відео-сесії тощо.",
  businessCalculatorNew: "Калькулятор доходу Waylight",
  howMuchMoneyNew:
    "Дізнайтеся, скільки грошей підписники принесуть вашому бізнесу.",
  estimateNumberNew: "1. Оцініть кількість клієнтів і підписників",
  howMuchYouChargeNew: "2. Скільки коштує пропозиція",
  for: "Для",
  whom: "кого",
  getEverythingBasicNew: "Отримайте все в базовому з меншою комісією",
  startLowestCostsNew: "Починайте без витрат і вивчайте Waylight",
  GetMoreSalesNew:
    "Отримайте більше продажів зі своїх соціальних мереж без комісій",
  onYourSocialMediaNew: "зі своїх соціальних мереж! 🎉",
  accordionv2label0: "В яких країнах ви працюєте?",
  accordionv2text0:
    "Waylight Business працює в 55+ країнах на 5 континентах. Ви можете звернутися до нашої підтримки щодо вашої країни або просто спробувати зареєструватися. Клієнти зможуть сплатити за ваші пропозиції з 150+ країн світу.",
  accordionv2label1: "Як я отримаю виплати?",
  accordionv2text1:
    "Ви отримуватиме виплати на свій банківський рахунок щопонеділка. Підключення вашого банківського рахунку до Waylight Business займає менше 1 хвилини.",
  accordionv2label2: "Які інструменти включені в ціну?",
  accordionv2text2:
    "Це залежить від плану, який ви оберете, але в будь-якому випадку ви отримаєте доступ до всіх бізнес-потоків монетизації Waylight, таких як платні спільноти в телеграмі, квитки, цифрові товари, курси, записи на прийом, відео сесії, подяки, бізнес-сайт, дані клієнта, двигуни продажів, академія монетизації і т.д.",
  accordionv2label3: "Чи є у вас Apple Pay, Google Pay, PayPal?",
  accordionv2text3:
    "Так, є. Ми називаємо це «Smooth Checkout». Waylight працює над автоматизацією потоків продажу з найвищими конверсійними показниками, тому у нас є 1-click-checkout (з PayPal в планах).",
  accordionv2label4: "Як Waylight допоможе мені заробити 10X?",
  accordionv2text4:
    "Ви отримаєте доступ до двигуна зростання продажів та академії монетизації, що допоможе вам продавати більше та залучати більше клієнтів з ваших соціальних мереж. Окрім того, Waylight вже створив автоматизовані потоки та інструменти, які дають вам вищі конверсійні показники.",
  accordionv2label5: "Що мені потрібно, щоб почати?",
  accordionv2text5:
    "Це легко 👌 Зареєструйтесь, додайте свій банківський рахунок, створіть пропозиції, приєднайтесь до академії монетизації, додайте посилання в соціальних мережах і почніть заробляти все більше і більше грошей.",
  connectWaylight: "Зв'язатися в Waylight!",
  toastTooLargeFileTwilio:
    "Цей файл занадто великий. Максимальний розмір файлу – 150 Мб.",
  enableSold: "Увімкнути індикатори продажу пропозицій?",
  areYouSureYOuWantEnableSold:
    "Ви впевнені, що хочете увімкнути індикатори продажів пропозицій? Значення продажів будуть видимі для всіх користувачів.",
  disableSold: "Вимкнути індикатори продажів пропозицій?",
  areYouSureYOuWantDisableSold:
    "Ви впевнені, що хочете вимкнути індикатори продажів пропозицій?",
  soldEnabled: "Індикатори продажів пропозицій увімкнені",
  soldEnabledDescription:
    "Коли це налаштування увімкнене, значення продажів будуть відображатися для всіх користувачів.",
  tipOrCommunitypriceLimitARS:
    "Ціна може бути від ARS$ 1879.31 до ARS$ 9396554.46.",
  tipOrCommunitypriceLimitAUD: "Ціна може бути від A$ 3.01 до A$ 15097.1 .",
  tipOrCommunitypriceLimitEUR: "Ціна може бути від €1.81 до €9070.",
  tipOrCommunitypriceLimitBOB: "Ціна може бути від Bs. 13.81 до Bs. 69119.48.",
  tipOrCommunitypriceLimitBGN: "Ціна може бути від лв 3.55 до лв 17750.81.",
  tipOrCommunitypriceLimitCAD: "Ціна може бути від CA$ 2.74 до CA$ 13718.98.",
  tipOrCommunitypriceLimitCLP:
    "Ціна може бути від CL$ 1864.56 до CL$ 9321644.2.",
  tipOrCommunitypriceLimitCOP:
    "Ціна може бути від COL$ 8072.11 до COL$ 40360576.84.",
  tipOrCommunitypriceLimitCRC: "Ціна може бути від ₡1055.02 до ₡5277582.56.",
  tipOrCommunitypriceLimitHRK: "Ціна може бути від kn 36 до kn 70472.",
  tipOrCommunitypriceLimitCZK: "Ціна може бути від Kč 45.71 до Kč 228558.",
  tipOrCommunitypriceLimitDKK: "Ціна може бути від kr 13.54 до kr 67719.",
  tipOrCommunitypriceLimitDOP: "Ціна може бути від RD$ 295 до RD$ 588928.",
  tipOrCommunitypriceLimitHKD: "Ціна може бути від HK$ 15.58 до HK$ 77915.",
  tipOrCommunitypriceLimitEGP: "Ціна може бути від EGP 154.75 до EGP 309501.",
  tipOrCommunitypriceLimitHUF: "Ціна може бути від Ft 716 до Ft 3579217.",
  tipOrCommunitypriceLimitISK: "Ціна може бути від kr 684 до kr 1369300.",
  tipOrCommunitypriceLimitINR: "Ціна може бути від ₹167.89 до ₹839435.",
  tipOrCommunitypriceLimitIDR: "Ціна може бути від Rp 78852 до Rp 157703833.",
  tipOrCommunitypriceLimitILS: "Ціна може бути від ₪19 до ₪36657.",
  tipOrCommunitypriceLimitUYU: "Ціна може бути від $U 81 до $U 402925.",
  tipOrCommunitypriceLimitGBP: "Ціна може бути від £1.56 до £7779.95.",
  tipOrCommunitypriceLimitTRY: "Ціна може бути від ₺67.06 до ₺335289.19.",
  tipOrCommunitypriceLimitTTD: "Ціна може бути від TT$ 13.58 до TT$ 67917.76.",
  tipOrCommunitypriceLimitTHB: "Ціна може бути від ฿69.82 до ฿349109.6.",
  tipOrCommunitypriceLimitCHF: "Ціна може бути від CHF 1.73 до CHF 8647.",
  tipOrCommunitypriceLimitSEK: "Ціна може бути від kr 20.82 до kr 104092.39.",
  tipOrCommunitypriceLimitZAR: "Ціна може бути від R 94 до R 186359.",
  tipOrCommunitypriceLimitSGD: "Ціна може бути від SGD$ 2.63 до SGD$ 13146.69.",
  tipOrCommunitypriceLimitSAR: "Ціна може бути від SAR 19 до SAR 37496.",
  tipOrCommunitypriceLimitRON: "Ціна може бути від lei 9.04 до lei 45196.94.",
  tipOrCommunitypriceLimitPLN: "Ціна може бути від zł 7.8 до zł 39008.41.",
  tipOrCommunitypriceLimitPHP: "Ціна може бути від ₱ 281 до ₱ 560255.",
  tipOrCommunitypriceLimitPEN: "Ціна може бути від S/. 19 до S/. 38040.",
  tipOrCommunitypriceLimitPYG: "Ціна може бути від ₲ 36387 до ₲ 72772680.",
  tipOrCommunitypriceLimitNOK: "Ціна може бути від kr 21.38 до kr 106903.",
  tipOrCommunitypriceLimitNZD: "Ціна може бути від NZ$ 3.33 до NZ$ 16646.",
  tipOrCommunitypriceLimitMXN: "Ціна може бути від MX$ 37.92 до MX$ 189595.",
  tipOrCommunitypriceLimitKES: "Ціна може бути від KSh 803 до KSh 1605000.",
  authorizeToAccess: "Авторизуйтеся, щоб отримати доступ до",
  noCommunitiesFound: "Спільнот не знайдено",
  noCommunitiesFoundDescription:
    "Вони з'являться тут, коли ви купите або додасте спільноту",
  following: "Слідкую",
  appointments: "Зустрічі",
  share: "Поділитися",
  addOffer: "Додати пропозицію",
  aboutMe: "Про мене",
  estimatedAmount:
    "Приблизна сума з урахуванням поточних курсів конвертації у валюті вашого облікового запису Stripe.",
  ConnectYourTelegram: "Підключіть свій Telegram:",
  ConnectYourTelegramAccount:
    "Підключіть свій обліковий запис Telegram, щоб отримувати сповіщення від творців, яких ви слідкуєте.",
  connectTelegram: "Підключити Telegram",
  buyAgain: "Купити знову",
  purchases: "Придбане",
  videoGuide: "Відеоінструкція",
  businessBilling: "Бізнес оплати",
  noPaymentMethodsAdded: "Способи оплати не додано",
  yourPaymentMethods: "Ваші способи оплати",
  yourPlan: "Ваш план",
  yearlyBilling: "Щорічний",
  selectFree: "Обрати Безкоштовний",
  whatIncluded: "Що включено",
  selectStarter: "Обрати Базовий",
  selectPro: "Обрати Професіональний",
  enterPromoCode: "Введіть промокод:",
  apply: "Застосувати",
  selected: "Вибрано",
  waylightSubscription: "Підписка на Waylight",
  cashbackRefferals: "Реферали",
  referrals: "Партнерська програма",
  shareLink: "Поділитися посиланням",
  howReferralsWork: "Як працюють реферали",
  cashback: "Кешбек",
  yourCashbackCode: "Ваш код кешбеку",
  yourCustomPartnerReferralCode: "Ваш індивідуальний партнерський код",
  howCashbackWork: "Як працює кешбек",
  toastPromoCodeSuccess: "Ваш промокод успішно застосовано!",
  toastPromoCodeError: "Під час застосування вашого промокоду виникла помилка!",
  enterPromoCodeSignUp: "Введіть промокод (необов’язково):",
  enterPromoCodeBtn: "+ Введіть промокод",
  billingBusinessTooltip: "Виберіть свій план нижче.",
  referralTooltip: "Це ваш унікальний код кешбеку.",
  cashbackTooltip: "Це ваш унікальний партнерський код.",
  currentPlan: "Поточний план:",
  neverExpires: "Без обмежень",
  expires: "Термін дії:",
  emptyBillingBusinessHistoryDescription:
    "emptyBillingBusinessHistoryDescription",
  notificationGoToBillingBusiness:
    "У нас виникла проблема з оплатою вашої підписки! ",
  WeHaveEncounteredAnIssueSubscription:
    "У нас виникла проблема з оплатою вашої підписки",
  checkIfYouHaveSufficientSubscription:
    "Перевірте, чи у вас є достатньо коштів на балансі або ввімкнені інтернет-платежі.",
  selectDifferentPaymentMethodSubscription: "Виберіть інший спосіб оплати.",
  referralsDescriptions1: "Дякуємо вам за те, що стали нашим цінним партнером!",
  referralsDescriptions2:
    "Завершіть онбординг, щоб отримувати виплати за партнерську програму. Ми зберігаємо ваші гроші у безпеці, поки ви не завершите онбординг.",
  referralsDescriptions3:
    "Вам знадобляться реквізити вашого банку або реквізити вашої картки залежно від вашої країни.",
  referralsDescriptions4:
    "Виплати здійснюються щопонеділка. Ви можете перевірити статуси виплат у розділі Транзакції.",
  referralsDescriptions5:
    "Ваш партнерський код надає додаткові знижки на підписки Waylight.",
  referralsDescriptions6:
    "Ваші знижки та ставки розділування доходу налаштовані на основі нашої угоди.",
  referralsDescriptions7:
    "Якщо це перший раз, коли ви отримуєте виплату, вона може зайняти від 7 до 14 робочих днів, щоб прибути через обмеження шлюзу. Зазвичай виплати займають близько 3 робочих днів.",
  cashbackDescriptions1: "Дякуємо вам за участь у нашій програмі кешбеку!",
  cashbackDescriptions2:
    "Завершіть онбординг, щоб отримувати виплати за партнерську програму. Ми зберігаємо ваші гроші у безпеці, поки ви не завершите онбординг.",
  cashbackDescriptions3:
    "Вам знадобляться реквізити вашого банку або реквізити вашої картки залежно від вашої країни.",
  cashbackDescriptions4:
    "Виплати здійснюються щопонеділка. Ви можете перевірити статуси виплат у розділі Транзакції.",
  cashbackDescriptions5:
    "Поширюйте своє посилання та отримуйте розділування доходу від нових клієнтів.",
  cashbackDescriptions6:
    "Ваш кешбек становить 20-30% від усіх комісій Waylight протягом 1 року (30% тільки від щорічних підписок Pro).",
  cashbackDescriptions7:
    "Якщо це перший раз, коли ви отримуєте виплату, вона може зайняти від 7 до 14 робочих днів, щоб прибути через обмеження шлюзу. Зазвичай виплати займають близько 3 робочих днів.",
  invalidGTMiD:
    "Недійсний ідентифікатор GTM. Введіть лише свій ідентифікатор, а не код, наприклад: GTM-AA11BB2.",
  invalidMetaPixelID:
    "Недійсний ідентифікатор Meta Pixel. Введіть лише свій ідентифікатор, а не код, наприклад: 123456789012345.",
  invalidHotjarID:
    "Недійсний ідентифікатор Hotjar. Введіть лише свій ідентифікатор, а не код, наприклад: 1112223.",
  accountCreated: "Обліковий запис створено",
  toastOUrlCopied: "Посилання рефералу успішно скопійовано!",
  YouAreOnFreePlan: "Ви вже скористалися безкоштовним планом",
  referralCodeCanNotBeUsed:
    "Цей код реферала не може бути використаний для вашої поточної країни облікового запису: {{country}}",
  createWaylightBusiness: "Створити Waylight Business",
  pleaseChooseCountryBank:
    "Виберіть країну вашого банку або фінансової служби:",
  referralsDescriptionsUA4:
    "Виплати здійснюються після кожної транзакції. Ви можете перевірити статуси виплат у розділі Транзакції.",
  referralsDescriptionsUA7:
    "Виплати здійснюються не пізніше наступного робочого дня.",
  cashbackDescriptionsUA4:
    "Виплати здійснюються після кожної транзакції. Ви можете перевірити статуси виплат у розділі Транзакції.",
  cashbackDescriptionsUA7:
    "Виплати здійснюються не пізніше наступного робочого дня.",
  issueSubscriptionPayment: "У нас виникла проблема з оплатою вашої підписки!",
  revenueSharhingSelectPE:
    "Реферальна програма доступна лише для активованих облікових записів ФОП в Україні.",
  youCanChangeYourAccount:
    "Ви можете змінити тип облікового запису в налаштуваннях.",
  yourReferralCodeWillAppear:
    "Ваш реферальний код з'явиться тут, як тільки ви завершите онбординг ФОП.",
  goToPayoutSettings: "Перейти до налаштувань виплат",
  commission: "Комісія",
  youAreOnSamePlan:
    "Ви вже знаходитесь на тому ж плані, який ви намагаєтеся вибрати! Будь ласка, оберіть інший план.",
  onlyCyrillicLettersAllowed: "Дозволені лише кириличні літери",
  dragToChangePosition: "Перетягніть, щоб змінити положення",
  eventsQrcode: "Короткі коди подій",
  eventsQrcodeDescription:
    "Якщо цей параметр увімкнено, ваші квитки на події будуть генеруватися з короткими 4-значними PIN-кодами та QR-кодами. Якщо вимкнено, будуть генеруватися більш безпечні 8-значні PIN-коди з штрих-кодами.",
  areYouSureYOuWantEnableQrcode:
    "Ви впевнені, що хочете увімкнути короткі PIN-коди подій з QR-кодами?",
  areYouSureYOuWantDisableQrcode:
    "Ви впевнені, що хочете вимкнути короткі PIN-коди подій та замість них генерувати штрих-коди?",
  enableEventsQrcode: "Увімкнути короткі коди подій",
  disableEventsQrcode: "Вимкнути короткі коди та перейти на штрих-коди",
  authorizeScanner: "Авторизувати сканер",
  toastScannerUrlCopied: "URL сканера скопійовано",
  total: "Всього:",
  gtmIdDescription: "Локальний GTM для цієї пропозиції.",
  metaPixelIdDescription: "Локальний Meta Pixel для цієї пропозиції.",
  tutorialBoughtPreview:
    "Це попередній перегляд придбаного стану вашого продукту.",
  tutorialNotBoughtPreview:
    "Так виглядає ваш продукт для клієнтів, коли він ще не придбаний.",
  authorizeToAccessPurchases:
    "Авторизуйтеся, щоб отримати доступ до ваших покупок!",
  myPromotions: "Мій промокод",
  describeTheDetailsPromotions:
    "Виберіть пропозицію, вкажіть назву, виберіть URL та додайте деталі промокоду нижче",
  promotionDropdownDescription:
    "Виберіть вашу пропозицію зі списку. Ви не зможете змінити її пізніше для цього промокоду.",
  offerNamePromotions: "Пропозиція",
  promotionsTooltip:
    "Виберіть пропозицію, до якої цей промокод буде застосовано. Ви можете створити стільки інших промокодів для цієї пропозиції, скільки забажаєте.",
  offerNameDescriptionPromotions:
    "Введіть назву акції. Ця назва для вашого внутрішнього обліку.",
  codePromotionsDescription:
    "Введіть або згенеруйте унікальний промокод, використовуючи англійські літери та цифри.",
  expirationPromotions: "Термін дії",
  name: "Назва",
  codePromotions: "Код",
  usersNumDescription:
    "Цей промокод може бути використаний вказану кількість разів.",
  max_uses: "Кількість використань",
  pricingPromotionsDiscription: "pricingPromotionsDiscription",
  discount: "Знижка",
  discountTitle: "Знижка(и):",
  selectOfferDiscounts:
    "Виберіть пропозицію вище, щоб почати керувати знижками для неї.",
  promoNameCanNotBeEmpty: "Назва акції не може бути порожньою",
  urlIsAlreadyTaken: "Цей URL вже зайнятий!",
  promoCodeValidation:
    "Промокод може містити тільки великі та малі англійські літери та цифри від 0 до 9!",
  promotions: "Промокоди",
  enterPromoCodePromotions: "Введіть промокод",
  promoCodeIncorrectExpired: "Цей промокод неправильний або прострочений!",
  selectedPromoCodeNotAvailable: "Вибраний промокод недоступний!",
  weWereUnableToProcessYourTransaction:
    "Нам не вдалося обробити вашу транзакцію з вибраним промокодом через закінчення терміну дії, обмеження на використання або недоступність. Будь ласка, почніть покупку знову.",
  promotionsEmptyStateHeader:
    "За встановленим фільтром не знайдено промокодів.",
  promotionsEmptyStateDescription:
    "Створіть промокод, спробуйте інший фільтр або пошуковий запит.",
  toastPromotionUrlCopied: "URL акції скопійовано!",
  toastPromotionCodeCopied: "Промокод скопійовано!",
  managePromotionsForYourOffers: "Керуйте промокодами для своїх пропозицій",
  offerPromotions: "Ваші промокоди",
  deletePromotionDescription: "Ви впевнені, що хочете видалити промокод?",
  deletePromotionTitle: "Видалити промокод",
  addPromotion: "Додати промокод",
  toastPromotionNotEditedEnabled: "Ми не змогли оновити ваш промокод!",
  toastPromotionNotEdited: "Ми не змогли оновити ваш промокод!",
  usage: "Використано",
  discountedPriceError: "Знижена ціна не може бути менше еквіваленту USD 1!",
  priceWithDiscount: "Ціна зі знижкою",
  promoCodeAddedToast: "Промо-код успішно застосовано!",
  toastPromotionSavedSuccessfully: "Промо-код успішно створено",
  toastPromotiomNotAdded: "Сталася помилка під час створення вашої промокоду!",
  myPromotionsMenu: "Мої промокоди",
  setSubscriptionlevelManually: "Встановити рівень підписки вручну:",
  settingLevelManuallyNotifyClientAutomatically:
    "Встановлення рівня вручну автоматично сповістить клієнта і буде застосовано з наступним платежем {{expiration}}",
  subscribeForFree: "Підписатися безкоштовно",
  toastSavedSuccessfullyUpdatedLevelCommunity:
    "Рівень спільноти успішно збережено та оновлено.",
  toastCommunityLevelNotUpdated: "Рівень спільноти не оновлено.",
  addNewCardStripe: "Додати нову картку (Stripe):",
  creditCard: "Кредитна карта",
  promocode: "Promocode",
  nonFollowers: "Не підписники",
  dueOn: "Термін до",
  exportMyClientsSuccess: "Експорт клієнтів успішно завершено.",
  exportMyClientsError: "Помилка під час експорту клієнтів.",
  eventTelegramDescription:
    "Виберіть назву вашого каналу або групи. Якщо тут нічого немає, переконайтеся, що ви додали Waylight Bot до вашої групи або каналу як адміністратора і це єдина пропозиція для цього каналу!",
  onlineTelegramEvent: "Онлайн телеграм подія",
  joinEvent: "Приєднатися до події",
  levels: "Рівні",
  paidAccess: "Платний доступ",
  clientWillPayOnceAccess: "Клієнт заплатить один раз за доступ",
  noOffersFound: "Пропозицій не знайдено",
  noOffersFoundDescription:
    "Вони з'являться тут, коли ви придбаєте або додасте пропозицію",
  youCanNotManageEnabled: "У вас немає жодної пропозиції, яку можна керувати.",
  enableOffersForManagement:
    "Увімкніть сеанси, події або спільноти, щоб керувати ними тут.",
  specifyQuickSessionPriceDuration: "Вкажіть ціну та тривалість швидкої сесії",
  youGetPaidSpecifiedPriceForFixedAmountTime:
    "Ви отримуєте вказану суму за фіксований час.",
  videoCallQuick: "Відеодзвінок",
  tabs: "Вкладки",
  pleaseEnterEmailAccessQuickSession:
    "Будь ласка, введіть свою електронну адресу, щоб отримати доступ до швидкої сесії",
  disconnectWhatsAppBot: "Відключити WhatsApp-бота",
  connectWhatsAppBot: "Підключити WhatsApp-бота",
  WhatsAppBot: "WhatsApp-бот",
  videoCallSession: "Відеодзвінок",
  copyProfileLink: "Скопіювати посилання на профіль",
  profileQRCode: "Мій QR-код профілю",
  copyCommunitiesLink: "Посилання на спільноти",
  copyEventsLink: "Посилання на події",
  copyProductsLink: "Посилання на продукти",
  copySessionsLink: "Посилання на сесії",
  copyTipsLink: "Посилання на поради",
  copyPortfolioLink: "Посилання на портфоліо",
  copyReviewsLink: "Посилання на відгуки",
  leaveReviewForMe: "Залиште відгук для мене",
  shareYourWaylight: "Поділіться своїм Waylight",
  myProfileQRCode: "Мій QR-код профілю",
  menu: "Меню",
  selectOfferExportData: "Виберіть пропозицію для експорту даних",
};
