import {
  utcToZonedTime,
  formatInTimeZone,
  getTimezoneOffset,
} from "date-fns-tz";
import {
  ITimeSlot,
  ISelectedSlots,
  IUtcSlots,
  IDateSlots,
} from "types/timeSlots";
import { format } from "date-fns-tz";
import ct from "countries-and-timezones";
import { getLocale } from "utils";

const countries12H = [
  "United States of America",
  "Canada",
  "Australia",
  "New Zealand",
  "Philippines",
];
import { addMinutes } from "date-fns";

export const utcToTimeZone = (
  isoDate: string | undefined,
  timeZone: string
): Date => {
  if (isoDate) {
    return utcToZonedTime(isoDate, timeZone);
  }
  return undefined;
};

export const addZoneFormateToDateString = (date: string): string => {
  if (date?.includes("+0000")) {
    return date.replace("+0000", ".000Z");
  }
  return date;
};

export const getFormattedTime = (
  date: string,
  tomeZone: string,
  format?: string
): string => {
  if (date) {
    return formatInTimeZone(
      addZoneFormateToDateString(date),
      tomeZone,
      format ? format : "HH:mm"
    );
  }
};

export const getFormattedDate = (date: string, timezone: string): string =>
  date
    ? formatInTimeZone(
        addZoneFormateToDateString(date),
        timezone,
        "dd MMMM yyyy",
        { locale: getLocale() }
      )
    : "";

export const getCurrentUtcDate = (): Date => {
  return utcToTimeZone(new Date().toISOString(), "Europe/London");
};

export const getTZDiffInHours = (localTZ, userTz) => {
  let selectedTz = getTimezoneOffset(localTZ) / 3600000;
  let targetTz = getTimezoneOffset(userTz) / 3600000;
  return selectedTz - targetTz;
};

export const isTimeZoneDifferenceMatches = (localTZ, userTz) => {
  return Math.abs(getTZDiffInHours(localTZ, userTz)) > 0.25;
};

export const getSelectedSlots = (
  timeslots?: IUtcSlots[],
  timeZone?: string,
  isPublicView?: boolean
) => {
  let selectedSlots: ISelectedSlots[] = [];
  let timezoneSlots: IDateSlots[] = [];
  let formattedTimeZoneSlot: string[];
  let dates: Set<string>;

  //handling on private view
  if (!isPublicView) {
    //setting slots in Date format
    timezoneSlots = timeslots.map((slot: { from: string; to: string }) => {
      const from = utcToZonedTime(slot.from, timeZone);
      const to = utcToZonedTime(slot.to, timeZone);
      return { from: from, to: to };
    });
    //setting slots in string format for displaying in Timeslots
    formattedTimeZoneSlot = timezoneSlots.map(
      (slot: any) =>
        `${format(slot.from, "HH:mm", { timeZone: timeZone })} - ${format(
          slot.to,
          "HH:mm",
          { timeZone: timeZone }
        )}`
    );
    //getting week dates for sorting slots
    dates = new Set();
    timezoneSlots.forEach((el) =>
      dates.add(format(el.from, "yyyy-M-d", { timeZone: timeZone }))
    );
    //handling on public view
  } else {
    timezoneSlots = timeslots.map((slot: { from: string; to: string }) => {
      const from = new Date(slot.from);
      const to = new Date(slot.to);
      return { from: from, to: to };
    });
    formattedTimeZoneSlot = timezoneSlots.map(
      (slot: any) =>
        `${format(slot.from, "HH:mm")} - ${format(slot.to, "HH:mm")}`
    );
    dates = new Set();
    timezoneSlots.forEach((el) => dates.add(format(el.from, "yyyy-M-d")));
  }
  const result: ITimeSlot[] = timeslots.map((slot, index) => {
    return {
      id: index,
      from: slot.from,
      to: slot.to,
      date: timezoneSlots[index],
      time: formattedTimeZoneSlot[index],
      selected: false,
    };
  });
  //sorting all the slots by dates
  for (let key of Array.from(dates)) {
    const slots = result.filter(
      (slot: ITimeSlot) =>
        format(slot.date.from, "yyyy-M-d", { timeZone: timeZone }) === key
    );
    const dateObject = { date: key, slots: slots };
    selectedSlots.push(dateObject);
  }

  return selectedSlots;
};

export const getFormattedInAmPmTimeSlots = (
  timeSlots: ISelectedSlots[],
  timeFormat: string
) => {
  if (timeFormat === "12h") {
    timeSlots.forEach((block) => {
      block.slots.forEach((slot) => {
        const from = slot.date.from
          .toLocaleString("en-US", {
            hour: "numeric",
            minute: "numeric",
            hour12: true,
          })
          .replace(" ", "");
        const to = slot.date.to
          .toLocaleString("en-US", {
            hour: "numeric",
            minute: "numeric",
            hour12: true,
          })
          .replace(" ", "");
        slot.time = `${from}-${to}`;
      });
    });
  } else {
    timeSlots.forEach((block) => {
      block.slots.forEach((slot) => {
        const from = slot.date.from
          .toLocaleString("en-US", {
            hour: "numeric",
            minute: "numeric",
            hour12: false,
          })
          .replace("24:", "00:");
        const to = slot.date.to
          .toLocaleString("en-US", {
            hour: "numeric",
            minute: "numeric",
            hour12: false,
          })
          .replace("24:", "00:");
        slot.time = `${from}-${to}`;
      });
    });
  }
  return timeSlots;
};

export const getDefaultTimeFormat = (timeZone: string): string => {
  const countries = ct.getCountriesForTimezone(timeZone);
  if (countries && countries[0]) {
    const country = countries[0].name;
    if (countries12H.includes(country)) {
      return "12h";
    }
  }
  return "24h";
};

export const getDefaultTimeFormatByIp = (country: string): string => {
  if (country) {
    if (countries12H.includes(country)) {
      return "12h";
    }
  }
  return "24h";
};

export const formatAMPM = (timeslots) => {
  if (timeslots && timeslots.length > 0) {
    timeslots.forEach((day) => {
      if (day.range.length > 0) {
        let startTime = getAmPtTime(day.range[0].startDate);
        let endTime = getAmPtTime(day.range[0].endDate);
        day.range[0].startDate = startTime;
        day.range[0].endDate = endTime;
      }
    });
    return timeslots;
  }
};

export const getAmPtTime = (date) => {
  let hours = date.substring(0, date.indexOf(":"));
  let minutes = date.substring(date.indexOf(":") + 1);
  let ampm = hours >= 12 ? "PM" : "AM";

  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  hours = hours < 10 ? `0${hours}` : hours;
  const strTime = hours + ":" + minutes + ampm;
  return strTime;
};

export const get24Time = (date) => {
  let hours = date.substring(0, date.indexOf(":"));
  let minutes = date.substring(date.indexOf(":") + 1, date.indexOf(":") + 3);
  let ampm = date.substring(date.indexOf(":") + 3);
  if (ampm === "PM") {
    hours = parseInt(hours) + 12;
  }
  const strTime = hours + ":" + minutes;
  return strTime;
};

export const getTimeFormat = (format: string): string => {
  return format && format === "12h" ? "hh:mma" : "HH:mm";
};

function convertOffset(milliseconds) {
  let minutes = Math.floor(milliseconds / 60000);
  let sign = minutes < 0 ? "-" : "+";
  let hours = Math.floor(Math.abs(minutes) / 60);
  let mins = Math.abs(minutes) % 60;
  return sign + pad(hours) + ":" + pad(mins);
}

function pad(number) {
  if (number < 10) {
    return "0" + number;
  }
  return number;
}

function extractDate(dateStr) {
  let year = dateStr.slice(0, 4);
  let month = dateStr.slice(5, 7);
  let day = dateStr.slice(8, 10);
  month = month - 1;

  let extractedDate = new Date(year, month, day);

  extractedDate.setHours(extractedDate.getHours() + 6);
  return extractedDate;
}

export const formatTimezoneString = (
  timeZone: string | undefined,
  date: string
): string => {
  if (timeZone) {
    const dateForOffes = extractDate(date);
    const offsetMilisec = getTimezoneOffset(timeZone, dateForOffes);
    const offset = convertOffset(offsetMilisec);
    return `(GMT${offset}) ${timeZone}`;
  }
  return "";
};

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const formattedDate = (date1, date2, timeFormat, timeZone) => {
  const locale = getLocale();
  const regex = /[+-]\d{2}:\d{2}/;
  const match = formatTimezoneString(timeZone, date1).match(regex);
  if (match) {
    const offsetStartDate = formatTimezoneString(timeZone, date1).match(
      regex
    )[0];
    const offsetEndDate = formatTimezoneString(timeZone, date2).match(regex)[0];

    const timeFormatterOptions = timeFormat === "12h" ? "hh:mm a" : "HH:mm";

    const start = new Date(date1);
    const end = new Date(date2);
    const regexTime = /^([+-])(\d{2}):(\d{2})$/;
    const matchTime = offsetStartDate.match(regexTime);
    const matchTimeEnd = offsetEndDate.match(regexTime);
    const [__, signEnd, hoursEnd, minutesEnd] = matchTimeEnd;
    const [_, sign, hours, minutes] = matchTime;

    const signMultiplier = sign === "-" ? -1 : 1;
    const parsedHours = parseInt(hours);
    const parsedMinutes = parseInt(minutes);

    const signMultiplierEnd = signEnd === "-" ? -1 : 1;
    const parsedHoursEnd = parseInt(hoursEnd);
    const parsedMinutesEnd = parseInt(minutesEnd);

    const totalMinutes = signMultiplier * (parsedHours * 60 + parsedMinutes);
    const totalMinutesEnd =
      signMultiplierEnd * (parsedHoursEnd * 60 + parsedMinutesEnd);

    const startTime = addMinutes(start, totalMinutes);
    const endTime = addMinutes(end, totalMinutesEnd);

    const formattedTimeStart = format(
      startTime,
      `LLLL d, yyyy, ${timeFormatterOptions}`,
      { timeZone: "UTC", locale: locale }
    );
    const formattedTimeEnd = format(
      endTime,
      `LLLL d, yyyy, ${timeFormatterOptions}`,
      { timeZone: "UTC", locale: locale }
    );

    const capitalizedMonth1 = capitalizeFirstLetter(formattedTimeStart);
    const capitalizedMonth2 = capitalizeFirstLetter(formattedTimeEnd);

    const [, startMonth, startDay, startYear] = formattedTimeStart.match(
      /(\p{L}+) (\d+), (\d+)/u
    );
    const [, endMonth, endDay, endYear] = formattedTimeEnd.match(
      /(\p{L}+) (\d+), (\d+)/u
    );

    if (
      startYear === endYear &&
      startMonth === endMonth &&
      startDay === endDay
    ) {
      return `${capitalizedMonth1} - ${capitalizedMonth2.split(", ")[2]}`;
    } else {
      return `${capitalizedMonth1} - ${capitalizedMonth2}`;
    }
  }
};
