export const de = {
  next: "Nächste",
  youWontBeCharged:
    "Sie werden erst belastet, wenn Sie die Sitzungsdetails bestätigt haben.",
  home: "Heim",
  chats: "Nachrichten",
  ratings: "Bewertungen",
  settings: "Einstellungen",
  moreSettings: "Weitere Einstellungen",
  settingsNotify: "Einstellungen | Waylight",
  logOut: "Ausloggen",
  changePassword: "Passwort ändern",
  receiveResetPassword:
    "Klicken Sie unten auf „Zurücksetzen“, um einen Link zum Zurücksetzen des Passworts zu erhalten.",
  enterEmailForResetPassword:
    "Geben Sie Ihre E-Mail-Adresse ein, um einen Link zum Zurücksetzen des Passworts zu erhalten.",
  successResetPassword: "E-Mail zum Zurücksetzen des Passworts wurde gesendet!",
  failResetPassword: "Kennwort konnte nicht zurückgesetzt werden",
  reset: "Zurücksetzen",
  signOut: "Ausloggen",
  availableBooking: "Buchbar",
  unavailableBooking: "Nicht buchbar",
  shareProfile: "Profil teilen",
  requests: "Anfragen",
  hours: "Stunden",
  totalSessions: "Sitzungen insgesamt",
  totalEarned: "Insgesamt verdient",
  totalHours: "Gesamtstunden",
  yourSession: "Ihre Sitzung",
  runningNow: "läuft jetzt",
  notHaveUpcomingSessions: "Sie haben noch keine anstehenden Sitzungen",
  notHavePastSessions: "Sie haben noch keine vergangenen Sitzungen",
  notHaveQuickSessions: "Sie haben noch keine Schnellsitzungen gestartet",
  upcomingSessions: "Anstehende Sitzungen",
  offer: "Angebot",
  gallery: "Galerie",
  photo: "Foto",
  session: "Sitzung",
  sessionWith: "Sitzung mit",
  occupation: "Beruf",
  skills: "Fähigkeiten",
  experts: "Experten",
  noInformation: "Keine Information",
  speaks: "Spricht",
  socialLinks: "Soziale Verbindungen",
  requestSession: "Sitzung anfordern",
  contacts: "Kontakte",
  noOffersYet: "Keine Angebote verfügbar",
  all: "Alle",
  requested: "Angefordert",
  confirmed: "Bestätigt",
  passed: "Bestanden",
  joined: "Beigetreten",
  ongoing: "Laufend",
  canceled: "Abgesagt",
  rejected: "Abgelehnt",
  approved: "Genehmigt",
  calendar: "Kalender",
  favorites: "Gefolgt",
  removeAll: "Alles entfernen",
  viewProfile: "Profil anzeigen",
  today: "Heute",
  upcoming: "Bevorstehende",
  attachments: "Anhänge",
  goToSession: "Zur Sitzung wechseln",
  prepare: "Vorbereiten",
  sessions: "Sitzungen",
  prerequisites: "Voraussetzungen",
  download: "Herunterladen",
  past: "Vergangene",
  decline: "Ablehnen",
  accept: "Annehmen",
  declineSession: "Sitzung ablehnen",
  wantDeclineSession: "Möchten Sie diese Sitzung wirklich ablehnen?",
  wantDeclinePaidSession:
    "Möchten Sie die Anfrage wirklich ablehnen? Die Zahlung Ihres Kunden wird zurückerstattet.",
  wantDeclineAfterApprove:
    "Sind Sie sicher, dass Sie abbrechen möchten? Die Zahlung Ihres Kunden wird zurückerstattet.",
  videoCall: "Sitzung beginnen",
  endSession: "Sitzung beenden",
  areYouSureYouWantEndSession: "Möchten Sie diese Sitzung wirklich beenden?",
  findExpert: "Experte finden",
  selectAnExpert: "Wählen Sie einen Experten aus",
  inviteClients: "Laden Sie Ihre Kunden ein",
  selectAChat: "Wählen Sie einen Chat aus, um eine Nachricht zu senden",
  noChatsYet: "Noch keine Chats",
  notHaveMessages: "Sie haben noch keine Nachrichten",
  shareProfileToClient: "Teilen Sie das Profil mit dem Kunden",
  remove: "Entfernen",
  time: "Zeit",
  timeSlots: "Zeitfenster",
  deleteOffer: "Angebot löschen",
  myOffers: "Angebote",
  aboutOffer: "Über die Sitzung",
  createNewOffer: "Neues Angebot erstellen",
  editOffer: "Angebot bearbeiten",
  successfulyBooked: "Sitzung erfolgreich gebucht",
  close: "Schließen",
  account: "Konto",
  price: "Preis",
  priceAndLength: "Preis und Länge",
  aboutTheOffer: "Über die Sitzung",
  uploadAFile: "Eine Datei hochladen",
  uploadAFileError: "Datei wurde nicht hochgeladen",
  confirm: "Bestätigen",
  IDLogin: "Deine E-Mail",
  myProfile: "Kundenprofil",
  transactions: "Transaktionen",
  transactionHistory: "Verlauf der Transaktionen",
  noTransactions: "Sie haben noch keine Transaktionen",
  noTransactionsDescription:
    "Sie werden angezeigt, sobald Sie erfolgreich bezahlte Sitzungen erhalten.",
  typicalSchedule: "Typischer Zeitplan",
  amount: "Menge",
  status: "Status",
  paid: "Ausbezahlt",
  action: "Aktion",
  description: "Beschreibung",
  billing: "Rechnung",
  card: "Karte",
  clients: "Kunden",
  sessionsPassed: "Sitzungen abgeschlossen",
  payout: "Auszahlungen",
  firstName: "Vorname",
  lastName: "Nachname",
  skip: "Überspringen",
  connect: "Verbinden",
  about: "Über",
  title: "Titel",
  languages: "Sprachen",
  currency: "Währung",
  addYourOffer: "Fügen Sie Ihr Angebot hinzu",
  join: "Verbinden",
  joinIn: "Beitreten",
  joinOn: "Beitreten",
  message: "Nachricht",
  client: "Kunde",
  expert: "Experte",
  Expert: "Experte",
  noMoreOptions: "Keine weiteren Optionen",
  male: "Männlich",
  female: "Weiblich",
  custom: "Spezifisch",
  copyYourLink: "Kopieren Sie Ihren Link",
  done: "Fertig",
  search: "Suche",
  searchWaylight: "Finden Sie Ihren Waylight",
  suggested: "Empfohlen",
  upcomingSession: "Anstehende Sitzung",
  startASession: "Schnelle Sitzung",
  quickSession: "Schnelle Sitzung",
  rate: "Bewertung",
  perHour: "pro Stunde",
  packages: "Pakete",
  timezone: "Zeitzone",
  book: "Buch",
  favorite: "Favorit",
  lastSession: "Letzte Interaktion",
  ago: "vor",
  gender: "Geschlecht",
  loading: "Wird geladen",
  min: "mindestens",
  hour: "Stunde",
  online: "Online",
  offline: "Offline",
  date: "Datum",
  displayName: "Anzeigename",
  closePanel: "Bedienfeld schließen",
  goToStripe: "Öffnen Sie Ihr Auszahlungs-Dashboard",
  completeStripeOnBoarding: "Schließen Sie das Stripe Express Onboarding ab",
  stripeOnBoarding: "Stripe Onboarding",
  completed: "abgeschlossen",
  notCompleted: "nicht vollständig",
  myClients: "Meine Kunden",
  findClients: "Wir können keine Kunden finden",
  clientDisplayedHere: "Wenn Sie Kunden finden, werden diese hier angezeigt",
  writeSomething:
    "Geben Sie etwas ein und drücken Sie die Eingabetaste, um ein Element zu erstellen",
  bookSession: "Sitzung buchen",
  registerAs: "Registrieren Sie sich als:",
  addedCards: "Ihre Karten",
  cardType: "Kartenart",
  billingHistory: "Rechnungsverlauf",
  deleteCard: "Karte löschen",
  wantToDeleteCard: "Möchten Sie die Karte wirklich löschen?",
  selectPaymentMethod: "Wählen Sie die Zahlungsmethode",
  addNewCard: "Neue Karte hinzufügen",
  emailAddress: "Öffentliche E-Mail",
  phoneNumber: "Telefonnummer",
  publicPhoneNumber: "Öffentliche Telefonnummer",
  username: "Nutzername",
  socialProfilesOrLinks: "Soziale Profile / Andere Links",
  newOffer: "Neues Angebot",
  myExperts: "Meine Experten",
  myClientProfile: "Mein Kundenprofil",
  noExpertsFound: "Keine Experten gefunden",
  openMenu: "Menü öffnen",
  becomeAnExpert: "Treten Sie Waylight Business bei",
  switchToExpert: "Wechseln Sie zur Business Anisicht",
  switchToClient: "Wechseln Sie in die Kundenansicht",
  notHaveExperts: "Sie haben noch keine Experten",
  endCall: "Stream stoppen",
  endSharing: "Teilen beenden",
  lastOnline: "Zuletzt online",
  clientSince: "Kunde seit",
  welcome: "Herzlich willkommen",
  messageError: "Pflichtfeld",
  support: "Hilfe",
  profile: "Profil",
  portfolio: "Portfolio",
  publicId: "Öffentlicher Ausweis",
  files: "Dateien",
  reason: "Grund",
  declineReason: "Lassen Sie Ihren Kunden wissen, warum Sie ablehnen müssen",
  rejectionReason: "Ablehnungsgrund",
  business: "Geschäft",
  passwordResetInvalidEmail: "E-Mail ist ungültig",
  logIn: "Einloggen",
  logInSubtitle:
    "Um Aktionen durchzuführen, melden Sie sich bitte an oder registrieren Sie sich.",
  enterEmail: "Email eingeben",
  enterPassword: "Passwort",
  capsLockIsOn: "Die Feststelltaste ist aktiviert",
  numLockIsOn: "Die Num-Lock-Taste ist aktiviert",
  forgotPassword: "Haben Sie Ihr Passwort vergessen?",
  forgotPasswordEmail:
    "Geben Sie Ihre E-Mail-Adresse ein, um einen Link zum Zurücksetzen des Passworts zu erhalten.",
  requestPasswordReset: "Zurücksetzen des Passworts anfordern",
  requestResetLink: "Link erneut senden",
  in: "in",
  sec: "Sekunden",
  passwordResetRequestReceived:
    "Anfrage zum Zurücksetzen des Passworts erhalten",
  passwordResetIfAccountExist:
    "Wenn ein Konto vorhanden ist, erhalten Sie in Kürze eine E-Mail mit einem Link.",
  resetPassword: "Passwort zurücksetzen",
  passwordChangeSuccessful: "Erfolgreiche Passwortänderung",
  passwordChangeSuccessfulSubtitle:
    "Sie können sich jetzt mit Ihrem neuen Passwort bei Ihrem Konto anmelden.",
  setNewPassword:
    "Legen Sie ein neues Passwort fest, um sich bei Ihrem Konto anzumelden",
  passwordChange: "Passwort ändern",
  passwordOld: "Altes Passwort",
  passwordNew: "Neues Passwort",
  dontHaveAccount: "Sie haben kein Konto?",
  alreadyRegistered: "Sie haben bereits ein Konto?",
  tokenExpired: "Token abgelaufen",
  tokenExpiredDescription:
    "Dieser Token ist entweder abgelaufen oder wurde bereits verwendet",
  validLength: "8 oder mehr Zeichen",
  upperCase: "Großbuchstaben (AZ)",
  lowerCase: "Kleinbuchstaben (az)",
  numbersOrSymbols: "Zahlen oder Symbole (0-9!@#$%^&*)",
  joinFree: "Kostenlos beitreten",
  businessTitle: "Waylight | Link in BIO-Tool, das Ihnen hilft zu verdienen!",
  businessDescription:
    "Verdienen Sie das 10-fache Ihres Umsatzes in sozialen Medien! Starten Sie in nur 2 Minuten!",

  spotlightYourExperience:
    "Umfassende Tools für Ihre Content-, Experten- und Community-Ertragsströme",
  monetizationPlatform: "Online Business Studio",
  createAccount: "Konto anlegen",
  watchVideo: "Video anschauen",
  bestToolsAvailable:
    "Wir möchten, dass Sie die besten Tools zur Verfügung haben und wir berechnen Ihnen keine Gebühren für deren Verwendung.",
  schedulingManagement: "Terminverwaltung.",
  chooseConvenientTimeSlots:
    "Wählen Sie bequeme Zeitfenster für die Arbeit und erhalten Sie Buchungen.",
  expertLandingPayouts: "Auszahlungen.",
  simpleOnboarding: "Einfaches Onboarding Ihrer Finanzkonten.",
  industryStandard: "Anrufqualität und Verfügbarkeit nach Branchenstandard.",
  lead: "Führen",
  engage: "Engagieren",
  frequentlyAskedQuestions: "Häufig gestellte Fragen",
  contactUs: "Kontaktiere uns:",
  accordionLabel0:
    "Wenn der Dienst kostenlos ist, wie wird Waylight betrieben?",
  accordionText0:
    "Wir nehmen eine volumenabhängige Provision für Expertengebühren und die Entdeckungs- und Wohltätigkeitsanrufe gehen zu unseren Lasten!",
  accordionLabel1: "Unterstützen Sie mehrere Angebotstypen?",
  accordionText1:
    "Natürlich — Sie können so viele Angebote erstellen wie Sie möchten und für jedes Angebot den Preis, die Dauer und die Einzelheiten angeben.",
  accordionLabel2: "Wie werde ich bezahlt?",
  accordionText2:
    "Wir verwenden Stripe und andere Gateways zur Abwicklung der Auszahlungen. Ein einfaches Onboarding ist erforderlich, um Ihr Bankkonto oder Ihre Zahlungsmethode zu verbinden, die in mehr als 55 Ländern funktioniert. Mehrere weitere Zahlungsmethoden und flexible Auszahlungseinstellungen sind in Vorbereitung.",
  accordionLabel3: "Kann ich Zoom, Meet oder Skype nutzen?",
  accordionText3:
    "Wir haben eine vollständig integrierte Videolösung, die entwickelt wurde, um einen sicheren Ablauf für beide Parteien zu gewährleisten. Der Einsatz anderer Videolösungen mit weniger Sicherheit, aber mehr Flexibilität wird in Zukunft eine Option sein.",
  accordionLabel4: "Kann ich Zapier, Calendly etc. verwenden?",
  accordionText4:
    "Wir arbeiten aktiv an einem Backlog für die von unseren Benutzern am meisten gewünschten Integrationen. Wir würden uns freuen Ihre Ideen zu diesem Thema zu hören. Senden Sie eine kurze E-Mail an support@waylight.me. Wir danken Ihnen!",
  freeAudience: "Wir entwickeln – Sie gestalten!",
  businessProfile: "Business Profil",
  onlineBusiness:
    "Erstellen Sie in wenigen Minuten ein Online-Geschäft und heben Sie hervor, was Sie am besten können.",
  paidCalls: "Bezahlte Audio- und Videoanrufe.",
  paidChatsAndBlog: "Bezahlte Chats und Blogs.",
  setUpSubscription:
    "Richten Sie ein Abonnement für Chats und persönliche Blogs ein.",
  web3: "Web3 Bewertungen und Rezensionen.",
  collectRealRatings:
    "Sammeln Sie echte Bewertungen und Rezensionen, um Ihre persönliche Marke aufzubauen.",
  getStartedToday: "Drei einfache Schritte zu einer neuen Einnahmequelle:",
  createProfile: "1. Erstellen Sie Ihr Profil",
  fillTemplate:
    "Füllen Sie eine vorgefertigte Vorlage mit Ihrem Profil, Zeitplan und Angeboten aus. Richten Sie Preise für kostenpflichtige Chats und Blogs ein und innerhalb von Minuten sind Sie bereit, Bestellungen und Abonnenten anzunehmen.",
  shareIt: "2. Teilen Sie es mit der Welt",
  linkYourProfile:
    "Verknüpfen Sie Ihr Profil mit all Ihren Kanälen – Ihren sozialen Medien, Anzeigen, E-Mails und mehr. Teilen Sie Informationen über Ihr Online-Geschäft in Beiträgen, Geschichten, Videos, persönlichen Nachrichten usw.",
  growYourBusiness: "3. Bauen Sie Ihr Geschäft aus",
  shareYourExpertise:
    "Teilen Sie Ihre Erfahrung und Ihr Wissen, aktualisieren Sie Ihren Blog, reagieren Sie auf Buchungen und Chats, führen Sie Online-Videoberatung durch und erhalten Sie Ihre öffentlichen Web3-Bewertungen und Rezensionen.",
  businessflow: "Flow",
  businessflowStep1Title: "Link in BIO hinzufügen",
  businessflowStep1:
    "Fügen Sie Ihren Waylight Business Profillink in sozialen Netzwerken BIO (Instagram, Facebook, YouTube, LinkedIn, Twitter, TikTok usw.) hinzu und teilen Sie Informationen durch Geschichten und Beiträge darüber.",
  businessflowStep2Title: "1:1-Videoanrufe, 1:1-Chats, Persönlicher Blog",
  businessflowStep2:
    "Monetarisieren Sie durch 1:1-Videoanrufe, 1:1-Chats mit Ihren Abonnenten und Kunden. Erstellen Sie Inhalte nur für Mitglieder in Ihrem persönlichen Blog mit Tutorials, Artikeln, Videoratschlägen, Backstage, persönlichen Lebenserfahrungen, Lektionen, Musik usw.",
  businessflowStep3Title: "Bezahlt werden",
  businessflowStep3:
    "Lassen Sie sich für Ihre Videoanrufe, Chats und exklusiven Mitgliederabonnements in Ihrem persönlichen Blog bezahlen. Steigern Sie Ihren Umsatz, indem Sie mehr Informationen über Ihr Waylight teilen!",
  bookOnboardingCall: "Buchen Sie ein kostenloses Onboarding-Gespräch",
  talkToFounders:
    "Sprechen Sie mit den Gründern darüber, wie Sie mit Waylight Geld verdienen können.",
  orScanQR: "oder QR scannen",
  yourFreeTools: "Ihre kostenlosen Tools",
  features: "Merkmale",
  personalLink: "Persönliche URL",
  personalQR: "Persönlicher QR",
  scheduling: "Planung",
  bookings: "Buchungen",
  paidChats: "Bezahlte 1:1 Chats",
  personalPaidBlog: "Persönlicher kostenpflichtiger Blog",
  soon: "demnächst",
  subscriptions: "Abonnements",
  payments: "Zahlungen in über 100 Ländern",
  payouts: "Auszahlungen in über 58 Ländern",
  cryptoPayments: "Krypto-Zahlungen",
  coinsAwarding: "Münzvergabe",
  web3Ratings: "Web3 Bewertungen, Rezensionen",
  clientsManagement: "Kundenverwaltung",
  sessionsManagement: "Sitzungsmanagement",
  dashboard: "Dashboard",
  businessCalculator: "Waylight Business Einkommensrechner",
  howMuchMoney:
    "Finden Sie heraus, wie viel Geld Abonnenten Ihrem Unternehmen bringen würden.",
  estimateNumber: "1. Schätzen Sie die Anzahl der Kunden und Abonnenten",
  howMuchYouCharge:
    "2. Wie viel können Sie für Videoanrufe oder Abonnements verlangen?",
  monthlyIncome: "Monatliches Einkommen",
  newToday: "heute neu",
  hadNoSessions: "Keine Sitzungen diese Woche",
  atThisWeek: "diese Woche",
  welcomeToWaylight: "Willkommen bei Waylight!",
  welcomeToWaylightBusiness: "Willkommen bei Waylight Business!",
  linkToVideo: "Link zum Einführungsvideo",
  writeSentences: "Schreibe ein paar Sätze über dich.",
  emptyExpertTitle: "Diese Seite scheint nicht zu existieren",
  emptyExpertText:
    "Der Link ist möglicherweise defekt, oder die Seite wurde deaktiviert oder entfernt.",
  profileData: "Profildaten",
  headerImageTooltip:
    "Die empfohlene Größe eines Header-Bildes ist etwa 10:1. Sie können etwas wie 1900x190px oder 1280x128px versuchen.",
  emailTooltip:
    "Dies ist eine E-Mail-Adresse, die öffentlich angezeigt wird (nicht Ihr Login). Sie können jede beliebige E-Mail-Adresse angeben oder sie ganz entfernen, dies ist optional!",
  timezoneTooltip:
    "Wenn Sie Ihre Stadt nicht finden können, suchen Sie nach der Zone, z. B. „Pacific Daylight Time“ oder „Eastern European Time“.",
  linkToVideoPresentation: "Externer Link hinzufügen",
  expertProfileVideoPresentation: "Oder Datei hochladen",
  linkToVideoPresentationTooltip:
    "Legen Sie einen Link zu einem externen Video fest, der Teil Ihrer Angaben sein soll. Die meisten bestehenden Video-Hosting-Dienste werden unterstützt. Wenn Sie ein Video hochladen, wird es gegenüber diesem Link bevorzugt behandelt.",
  linkToVideoOfferTooltip:
    "Legen Sie einen Link zu einem externen Video fest, der Teil der Angebotsdetails sein soll. Die meisten bestehenden Video-Hosting-Dienste werden unterstützt. Wenn Sie ein Video hochladen, wird es gegenüber diesem Link bevorzugt behandelt.",
  phoneTooltip:
    "Dies ist eine Telefonnummer zur öffentlichen Anzeige. Es ist optional!",
  socialProfilesTooltip:
    "Fügen Sie Links zu Ihren sozialen Profilen und Websiten wie Youtube, Facebook, Linkedin, Instagram oder Tiktok hinzu.",
  videoPresentationTooltip:
    "Laden Sie ein Video hoch, das in Ihren Details angezeigt werden soll. Es überschreibt den obigen Videolink.",
  videoOfferTooltip:
    "Laden Sie ein Video hoch, das in den Angebotsdetails angezeigt werden soll. Es überschreibt den obigen Videolink.",
  occupationTooltip:
    "Erzählen Sie der Welt von Ihrem Beruf, zB „AR Consultant“ oder „Top Tiktok Influencer“.",
  skillsTooltip:
    "Fügen Sie Ihre Fähigkeiten hinzu, damit Ihre Kunden wissen, worin Sie gut sind, z. B. „Unternehmertum“ oder „Elternschaft“.",
  aboutTooltip:
    "Fügen Sie eine persönliche Beschreibung hinzu, damit sich Ihre Kunden willkommen fühlen und Ihre persönliche Marke schätzen.",
  speaksTooltip: "Geben Sie die Sprachen an, die Sie sprechen.",
  cropPhoto: "Foto zuschneiden",
  updatePhoto: "Profilbild aktualisieren",
  crop: "Schnitt",
  bookASession: "Wenn Sie eine Sitzung buchen, wird diese hier angezeigt",
  videoAudioSettings: "Video- und Audioeinstellungen",
  videoAudioSettingsDescription:
    "Verwenden Sie diese Einstellungen, um Video und Audio anzupassen",
  cameras: "Kamera",
  microphones: "Mikrofon",
  output: "Lautsprecher",
  bgSettings: "Wählen Sie einen Videohintergrund aus",
  toStartAChat: "Um einen Chat zu starten,",
  startAChat: "und starten Sie einen Chat.",
  messageToAnExpert: "Senden Sie Ihre erste Nachricht an einen Experten",
  messageToAClient: "Senden Sie Ihre erste Nachricht an einen Kunden",
  chatWindowGetToKnow:
    "1. Lernen Sie Ihre potenziellen Kunden und ihre Bedürfnisse kennen",
  requestInformation: "2. Fordern Sie ggf. weitere Informationen an",
  notGettingAReply:
    "3. Wenn Sie keine Antwort erhalten, können Sie versuchen, höflich nachzufragen",
  knowEachOther: "4. Lernen Sie sich kennen und planen Sie Ihre Sitzungen",
  chatWindowProvideABrief: "1. Beschreiben Sie kurz, wonach Sie suchen",
  chatWindowAddAttachments: "2. Fügen Sie ggf. Anhänge hinzu",
  describeYourIssue:
    "Bitte beschreiben Sie Ihr Anliegen und was Sie lernen möchten oder wozu Sie Hilfe benötigen. Fügen Sie ggf. weitere Dateien hinzu.",
  getAConfirmation: "Sie erhalten in Kürze eine Bestätigung des Experten.",
  describeTheDetails:
    "Beschreiben Sie die Details, den Preis und die Voraussetzungen für Ihr Angebot.",
  areYouSure: "Möchten Sie dieses Angebot wirklich löschen?",
  offerAvailable: "Angebot verfügbar",
  offerUnavailable: "Angebot nicht verfügbar",
  notHaveOffers: "Sie haben noch keine Angebote",
  createOne: "Erstellen Sie eine, um mit Ihren Kunden zu arbeiten.",
  getPaid:
    "Sie erhalten den festgelegten Preis für einen festgelegten Zeitraum.",
  describeYourOffer: "Beschreiben Sie kurz Ihr Angebot.",
  describeRequirements:
    "Beschreiben Sie die Anforderungen für Kunden vor der Teilnahme an Sitzungen: Nachricht oder Anhänge.",
  priceLimit: "Der Preis kann $0 sein oder von $2 bis $10000.",
  priceLimitForUA: "Der Preis kann ₴0 sein oder von ₴83 bis ₴412496.",
  offerNameDescription:
    "Versuchen Sie etwas wie „Discovery Call“ oder „Basic Graphic Design“, wenn das Ihr Ding ist.",
  clickingConfirm:
    "Durch Klicken auf „Bestätigen“ erkläre ich mich damit einverstanden",
  termsAndConditions: "Bedingungen und Konditionen",
  preCharge: "Für die Anfrage Ihrer Sitzung wird eine Vorgebühr erhoben.",
  paymentFeesMayAlter: "Die Gebühren für das Zahlungssystem können variieren.",
  notBeCharged: "Sie werden niemals ohne zusätzliche Bestätigung belastet.",
  last: "Letzte 4",
  lastDigits: "Letzten 4 Ziffern",
  billingPageName: "Name",
  gateway: "Gateway",
  prepareForTheMeeting: "Bereiten Sie sich auf das Treffen vor",
  prepareForSession: "Bereiten Sie sich vor",
  fewRecomendations:
    "Einige Empfehlungen, wie Sie Ihre Sitzung komfortabel und produktiv gestalten können.",
  findAQuiet: "Suchen Sie sich einen ruhigen Ort, an dem Sie niemand stört",
  makeSure:
    "Stellen Sie sicher, dass Sie über eine gute Internetverbindung verfügen",
  weRecommendUsing: "Wir empfehlen die Verwendung von Kopfhörern",
  prepareYourQuestions: "Bereiten Sie Ihre Fragen vor",
  enjoyTheSession: "Genießen Sie die Sitzung!",
  initialization: "Initialisierung...",
  beginOnboardingAsExpert:
    "Beginnen Sie mit dem Onboarding als Experte für Waylight Business",
  connectStripeAccount:
    "Um Geld von Ihren Kunden zu erhalten, müssen Sie eine Auszahlungsmethode erstellen.",
  provideABrief: "1. Beschreiben Sie kurz, wonach Sie suchen",
  addAttachments: "2. Fügen Sie ggf. Anhänge hinzu",
  gettingAReply:
    "3. Wenn Sie keine Antwort erhalten, können Sie versuchen, höflich nachzufragen",
  getToKnow: "4. Lernen Sie sich kennen und planen Sie Ihre Sitzungen",
  fileType: "Dateityp wird nicht unterstützt",
  orDragAndDrop: "oder ziehen und ablegen",
  upTo: "bis zu 1 GB (kontaktieren Sie den Support, wenn Sie größere Dateigrößen haben)",
  headerImage: "Header-Bild",
  inviteClientsModalInviteClients: "Kunden einladen",
  shareYourLink:
    "Teilen Sie Ihren persönlichen Link, um Ihre Kunden einzuladen!",
  addDays: "Richten Sie Ihren regelmäßigen Zeitplan ein",
  publiclyInformation:
    "Es können mehrere Zeitfenster pro Wochentag hinzugefügt werden.",
  availableTimeslot: "Verfügbares Zeitfenster",
  AddTimeslot: "Zeitfenster hinzufügen",
  AddToSchedule: "Zum Zeitplan hinzufügen",
  signUpAs: "Registrieren Sie sich als",
  registerAsClient: "Ich möchte etwas Neues lernen",
  registerAsExpert: "Ich möchte meine Erfahrung teilen",
  approveSession: "Sitzung genehmigen",
  approveThisSession:
    "Möchten Sie diese Sitzung wirklich annehmen? Mit der Zustimmung stimmen Sie zu, zum angegebenen Zeitpunkt beizutreten.",
  noTimeslots:
    "Keine Zeitfenster verfügbar. Wählen Sie ein anderes Datum oder fragen Sie den Experten nach dem Zeitplan.",
  toastSavedSuccessfully: "Erfolgreich gespeichert",
  toastDeletedSuccessfully: "Erfolgreich gelöscht",
  toastEmailCopied: "E-Mail kopiert",
  toastProfileCopied: "Profil kopiert",
  toastBookingAvalable: "Sie können jetzt buchen",
  toastBookingUnavalable: "Sie sind nicht buchbar",
  toastQrCopied: "QR-Code kopiert",
  toastPaymentMethodAdded: "Zahlungsmethode erfolgreich hinzugefügt",
  toastPaymentMethodDeleted: "Zahlungsmethode erfolgreich gelöscht",
  toastUrlCopied: "Persönlicher Link kopiert",
  toastOfferUrlCopied: "Angebotslink kopiert",
  toastSessionCanceled: "Diese Sitzung wurde aus Zeitgründen abgesagt",
  toastSessionWithYourself: "Sie können keine Sitzung mit sich selbst buchen!",
  toastPayoutMethodAdded: "Auszahlungsmethode erfolgreich hinzugefügt",
  toastPayoutMethodDeleted: "Auszahlungsmethode erfolgreich gelöscht",
  toastPrimaryCardError: "Die primäre Karte konnte nicht festgelegt werden",
  toastPrimaryCardAdded: "Primäre Karte erfolgreich hinzugefügt",
  toastTooLargeFile: "Die Dateigröße ist zu groß",
  toastOfferNotAdded: "Das Angebot wurde nicht hinzugefügt",
  toastOfferNotEdited: "Das Angebot wurde nicht bearbeitet",
  toastSessionLinkCopied: "Sitzungslink kopiert",
  toastMaxParticipantsReached: "Maximale Teilnehmerzahl erreicht",
  toastNotMayJoin:
    "Leider können Sie dieser Sitzung nicht beitreten, da sie ihre Kapazität erreicht hat.",
  toastLinkExpired: "Dieser Link ist bereits abgelaufen.",
  toastCannotStartSession: "Schnellsitzung kann jetzt nicht gestartet werden",
  toastSessionExtendedSuccessfully: "Sitzung wurde erfolgreich verlängert",
  toastTimeslotStartAndEnd:
    "Sie können keinen Zeitschlitz hinzufügen, der zur gleichen Zeit beginnt und endet",
  toastTimeslotEndsInPast:
    "Sie können kein Zeitfenster hinzufügen, das in der Vergangenheit endet",
  toastTimeslotDuplicate: "Sie können kein doppeltes Zeitfenster hinzufügen",
  toastSkillNameTooLong:
    "Ihr Skill-Name ist zu lang, versuchen Sie es mit einem kürzeren Namen",
  buttonSave: "Speichern",
  buttonCancel: "Absagen",
  buttonApprove: "Genehmigen",
  buttonDelete: "Löschen",
  buttonChat: "Chatten",
  buttonOpenChat: "Chatten",
  buttonFiles: "Dateien",
  buttonNotNow: "Nicht jetzt",
  buttonSubmit: "Einreichen",
  buttonNo: "Nein",
  buttonEnd: "Ende",
  buttonSignUp: "Anmelden",
  buttonYes: "Ja",
  buttonEdit: "Bearbeiten",
  buttonCopy: "Kopieren",
  buttonReply: "Antwort",
  buttonAdd: "Hinzufügen",
  buttonSend: "Senden",
  buttonRequest: "Hilfe anfordern",
  buttonSet: "Einstellen",
  buttonEditPhoto: "Foto bearbeiten",
  buttonStartAnyway: "Trotzdem starten",
  buttonWait: "Warten",
  buttonCopyLink: "Link kopieren",
  buttonLeave: "Verlassen",
  buttonExtraMinutes: "Zusätzliche 10 Minuten",
  buttonViewOffer: "Einzelheiten anzeigen",
  buttonMoreDetails: "Mehr Details",
  buttonContinue: "Fortsetzen",
  buttonBookACall: "Buchen Sie einen Anruf",
  notificationSetup: "Konfiguration",
  notificationFillProfile: "Profil füllen",
  notificationHi: "Hi!",
  notificationAddYourName:
    "Fügen Sie Ihren Namen und Ihr Foto hinzu, um die Kommunikation zu beleben, wenn Sie Lust dazu haben. Kein Druck :)",
  notificationSetupBilling: "Rechnung einrichten",
  notificationAddPaymentMethod:
    "Fügen Sie eine Zahlungsmethode hinzu, um Sitzungen buchen zu können.",
  notificationGoToBilling: "Gehen Sie zu Abrechnung",
  notificationWeAreSureYouHaveALotToSay:
    "Wir sind sicher, dass Sie viel über sich selbst zu sagen haben. Füllen Sie Ihr Profil aus und beginnen Sie mit dem Teilen!",
  notificationAddAnOffer: "Angebot hinzufügen",
  notificationAddYourOffers: "Fügen Sie Ihre Angebote hinzu",
  notificationSetupVariousKindsOfSessions:
    "Richten Sie verschiedene Arten von Sitzungen für Ihre Kunden ein, die in Ihrem Profil gebucht werden können.",
  notificationGoToMyOffers: "Gehen Sie zu Angebote",
  notificationConnectStripe: "Auszahlungen einrichten",
  notificationSetupPayouts: "Onboarding der Auszahlungen",
  notificationInOrderToReceiveMoney:
    "Um Geld von Ihren Kunden zu erhalten, müssen Sie eine Auszahlungsmethode erstellen.",
  notificationGoToPayouts: "Gehen Sie zu Auszahlungen",
  notificationSetYourSchedule: "Legen Sie Ihren Zeitplan fest",
  notificationWorkAtConvenientHours: "Arbeiten Sie zu günstigen Zeiten",
  notificationSetupSchedule:
    "Richten Sie einen Zeitplan ein und Ihre Kunden können nur Sitzungen buchen, die diesem Zeitplan entsprechen.",
  accountOn: "Konto an",
  accountOff: "Konto aus",
  cancelSession: "Sitzung abbrechen",
  paymentFailed: "Bezahlung fehlgeschlagen",
  paymentFailedDescription:
    "Ihre Zahlung wurde nicht durchgeführt und wir konnten keine neue Sitzung anfordern.",
  areYouSureCancel: "Möchten Sie diese Sitzung wirklich abbrechen?",
  areYouSureCancelPaidSession:
    "Bist du sicher, dass du abbrechen möchtest? Ihre Zahlung wird zurückerstattet.",
  areYouSureCancelLessThanADay:
    "Möchten Sie wirklich stornieren, wenn weniger als 24 Stunden verbleiben? Ihre Zahlung wird NICHT zurückerstattet.",
  link: "Verknüpfung",
  view: "Ansicht",
  requires_action: "Erfordert eine Aktion",
  succeeded: "Erfolgreich",
  signUpTitle: "Anmelden",
  profileTitleNotify: "Profil | Waylight",
  dashboardTitleNotify: "Dashboard | Waylight",
  chatTitleNotify: "Chatten | Waylight",
  with: "mit",
  expertsTitle: "Experten",
  expertsTitleNotify: "Erkunden | Waylight",
  signUpTitleNotify: "Anmelden | Waylight",
  chatTitle: "Chatten",
  clientsTitle: "Kunden",
  clientsTitleNotify: "Kunden | Waylight",
  clientTitle: "Klient",
  fixedPrice: "Festpreis",
  internetConnectionLost: "Internetverbindung unterbrochen",
  fiveMinutesLeft: "noch 5 Minuten",
  summarizeMeeting:
    "Bitte fassen Sie Ihr Treffen zusammen und teilen Sie Ihre Eindrücke mit",
  oopsConnectionLost: "Hoppla... Partei Verbindung unterbrochen",
  partyTemporaryLostConnection:
    "Anscheinend hat Ihre Gruppe vorübergehend die Verbindung zum Meeting verloren. Entschuldigung für die Unannehmlichkeiten.",
  leaveAReviewFor: "Hinterlassen Sie eine Bewertung für",
  leaveAReview: "Hinterlassen Sie eine Bewertung",
  review: "Rezension",
  followers: "Follower",
  rating: "Bewertung",
  hitStarRate: "Bewerten Sie die Sitzung unten!",
  reviewsEmpty: "Es gibt noch keine Bewertungen",
  reviews: "Bewertungen",
  sessionEnded: "Sitzung erfolgreich beendet",
  thankYouForLighting: "Danke, dass Sie jemandem den Weg erhellen!",
  sessionNotStarted: "Die Sitzung hat nicht begonnen",
  devicePermissionTittle:
    "Erlauben Sie Waylight den Zugriff auf Ihr Mikrofon und Ihre Kamera. Aktualisieren Sie die Seite, wenn Sie kein Video haben.",
  networkConnection: "Die Qualität Ihrer Internetverbindung ist",
  unknown: "Unbekannt",
  low: "niedrig",
  average: "durchschnittlich",
  good: "gut",
  expertDidNotCome:
    "Leider ist Ihr Experte nicht erschienen. Sie werden nicht belastet und erhalten eine Rückerstattung. Wir entschuldigen uns für die Unannehmlichkeiten.",
  noFilesAdded: "Keine Dateien hinzugefügt.",
  enterYourEmail: "Geben Sie ihre E-Mailadresse ein",
  required: "Feld ist erforderlich",
  emailOrPassword: "Falsche Email oder Passwort",
  emailInUse: "Diese E-Mail-Adresse wird schon verwendet",
  businessIsNotAvailableTitle:
    "Waylight Business ist in Ihrem Land nicht verfügbar. Erfahre als Erster, wann es losgeht.",
  beInTouch: "In Kontakt sein",
  businessIsNotAvailableEmailAddress: "E-Mail-Addresse",
  pleaseTryAgain:
    "Wir konnten Ihre E-Mail-Adresse nicht speichern. Bitte versuche es erneut.",
  thankYou: "Vielen Dank, wir melden uns, sobald dies verfügbar ist.",
  wereNoChatMessages: "In dieser Sitzung gab es keine Chat-Nachrichten",
  yourCountry: "Dein Land",
  open: "Offen",
  offerName: "Name des Angebots",
  gatewayCountryWarning:
    "Warnung: Dies sollte das Land Ihres Bankinstituts sein und kann später NICHT geändert werden, was sich auf Ihre Möglichkeit auswirken kann, Auszahlungen zu erhalten.",
  addAttachment: "Anhang hinzufügen",
  you: "Du",
  explore: "Entdecken",
  stats: "Statistiken",
  more: "Mehr sehen",
  noPrice: "Kostenlose Angebote",
  theyWillAppear:
    "Sie werden hier angezeigt, wenn Sie mit der Interaktion beginnen",
  noExpertFoundUsingFilter:
    "Keine Experten gefunden, die diesen Filter verwenden.",
  tryAnotherInput:
    "Probieren Sie ein anderes Tag oder eine andere Eingabe aus.",
  noFavoriteExpertsFound: "Keine bevorzugten Experten gefunden.",
  tryAddingSomeone: "Versuchen Sie jemanden zu den Favoriten hinzuzufügen.",
  agoraDeniedError:
    "Bitte aktivieren Sie Ihren Zugriff auf Mikrofon und Kamera in den Einstellungen, um Waylight zu verwenden!",
  editCover: "Titelbild bearbeiten",
  language: "Sprache",
  invalid: "Ungültig",
  scrollPastHistory:
    "Scrollen Sie oben durch den Verlauf Ihrer vergangenen Sitzung",
  earnedFromLastSession: "aus der letzten Sitzung verdient",
  typeHere: "Geben Sie hier ein",
  schedule: "Zeitlicher Ablauf",
  offers: "Bietet an",
  qrSharing: "Laden Sie Ihren QR-Code herunter und teilen Sie ihn.",
  qrSharingMobile:
    "Drücken Sie lange auf den QR-Code, um ihn zu kopieren und zu teilen.",
  preview: "Vorschau",
  back: "Zurück",
  love: "Mit Liebe gemacht von Waylight",
  allRightsReserved: "© 2024 Waylight Inc. Alle Rechte vorbehalten",
  allRightsReservedSecond:
    "© 2022 Arceon Consulting OÜ. Alle Rechte vorbehalten",
  termsOfService: "Nutzungsbedingungen",
  privacyPolicy: "Datenschutz-Bestimmungen",
  sessionSharingpopupTitle: "Bildschirmfreigabe kann nicht gestartet werden!",
  sessionSharingpopupDescription:
    "Prüfen Sie, ob Sie eine aktuelle Version Ihres Browsers verwenden und ob dieser über Systemberechtigungen für die Bildschirmfreigabe verfügt. Schließlich können Sie versuchen, die Seite zu aktualisieren. Wenn das Problem weiterhin besteht, wenden Sie sich bitte an den Support!",
  sessionSharingpopupSafariDescription:
    "Die Bildschirmfreigabe wird in Ihrem Browser nicht unterstützt. Neueste Versionen gängiger Browser unterstützen diese Funktion.",
  copy: "Kopieren",
  expertWithoutOffers:
    "Dieser Experte hat noch keine kostenpflichtigen Angebote hinzugefügt.",
  errorViewHeader: "Ups! Irgendwas lief schief!",
  errorViewDescription:
    "Sie haben einen Fehler gefunden. Dieser Link ist möglicherweise defekt oder Sie haben keinen Zugriff auf ein Element.",
  returnToHome: "Nach Hause zurückkehren",
  invalidURL:
    "Ungültige URL. Stellen Sie sicher, dass es mit 'https://' oder 'http://' beginnt.",
  invalidVideoExternalURL:
    "Ungültige Video-URL. Fügen Sie entweder einen Youtube- oder einen Vimeo-Link hinzu.",
  repeatedURL: "Dieser Link wurde bereits hinzugefügt",
  tooLongUrl:
    "Dieser Link ist zu lang. Bitte verwenden Sie Links, die nicht länger als 128 Zeichen.",
  edited: "Bearbeitet",
  emptyCards: "Sie haben noch keine Karten hinzugefügt",
  emptyBillingHistory: "Sie haben noch keine Transaktionen",
  emptyBillingHistoryDescription:
    "Sie werden hier angezeigt, wenn Sie mit der Buchung von Sitzungen beginnen",
  addNewSkill: "Fügen Sie eine neue Fertigkeit hinzu",
  confirmationAddSkill:
    "Sind Sie sicher, dass Sie eine neue Fertigkeit hinzufügen möchten?",
  addPaymentMethod: "Zahlungsmethode hinzufügen",
  stripeDescription: "Für Transaktionen mit Experten aus über 50 Ländern",
  fondyDescription: "Für Transaktionen mit Experten aus der Ukraine",
  fondyAddCardDescription:
    "Fügen Sie eine Zahlungsmethode hinzu, um fortzufahren. Zur Bestätigung wird eine Testladung durchgeführt.",
  timeZoneChangedHeader: "Zeitzone geändert",
  timeZoneSChangedDescription:
    "Wir haben festgestellt, dass sich Ihre Zeitzone geändert hat. Möchten Sie die Zeitzone Ihres Profils aktualisieren?",
  updateButton: "Aktualisieren",
  and: "und",
  moreFiles: "weitere Dateien",
  addPayout:
    "Fügen Sie mindestens eine Methode hinzu, um Auszahlungen zu erhalten",
  testChargeWarning: "Zur Bestätigung wird eine Testladung versucht.",
  payoutMethods: "Ihre Auszahlungsmethoden",
  identifier: "Kennzeichnung",
  stopWar: "Krieg beenden",
  yourAreOnlyOneHere: "Du bist der einzige hier",
  yourAreOnlyOneHereSingle:
    "Du bist der Einzige hier. Link unten teilen, damit andere mitmachen können",
  clientView: "Kunde",
  businessView: "Business",
  mainPage: "Hauptseite",
  signUpCompleted: "Anmeldung abgeschlossen",
  selectPaymentInfo:
    "Die verfügbaren Zahlungsmethoden hängen vom Gateway Ihres Experten ab",
  payment: "Zahlungen",
  paymentWasDeclined: "Die Zahlung wurde abgelehnt.",
  payoutWasDeclined: "Auszahlung wurde abgelehnt.",
  primary: "Primär",
  notUsed: "Nicht benutzt",
  releaseBannerTitle: "Waylight wurde auf eine neue Version aktualisiert.",
  refresh: "Aktualisierung",
  country: "Land",
  viewAll: "Alle ansehen",
  videoPresentation: "Video Präsentation",
  bookFor: "Buchen für",
  bookForFree: "Kostenlos buchen",
  follow: "Folgen",
  followed: "Gefolgt",
  emptyPortfolio: "Anscheinend gibt es keine Dateien in diesem Portfolio",
  media: "Medien",
  other: "Dateien",
  emptyReview: "Anscheinend gibt es keine Bewertungen",
  newest: "Neueste",
  oldest: "Älteste",
  viewProfileInfo: "Profilinformationen anzeigen",
  emptyTransactions: "Sieht so aus, als gäbe es keine Transaktionen",
  emptyOffers: "Leider hat dieser Experte noch keine Angebote.",
  searchExperts: "Experten suchen",
  modifySchedule: "Zeitplan ändern",
  editMode: "Bearbeitungsmodus",
  finishEditing: "Beenden Sie die Bearbeitung",
  editPersonalInfo: "Persönliche Daten bearbeiten",
  editPortfolio: "Portfolio bearbeiten",
  editOffers: "Angebote bearbeiten",
  profileId: "Persönliche öffentliche ID",
  profileSettings: "Profileinstellungen",
  editPublicID: "Öffentliche ID bearbeiten",
  removeTimeslot: "Zeitfenster entfernen",
  areYouSureRemoveTimeslot: "Möchten Sie das Zeitfenster wirklich entfernen?",
  qrCode: "QR-Code",
  communication: "Kommunikation",
  editCoverDescription: "Passen Sie Ihr Titelbild an",
  editProfileData: "Profildaten bearbeiten",
  editProfileDataDescription:
    "Fügen Sie Informationen über sich und Ihren Hintergrund hinzu",
  editPublicIDDescription:
    "Wählen Sie eine eindeutige öffentliche ID für Ihr Profil",
  profilePhoto: "Profilfoto",
  publicIDDescription: "Dies ist eine automatisch generierte ID!",
  publicIDWarning:
    "Vergessen Sie nicht, den Namen im Bearbeitungsmodus in etwas Cooles zu ändern, z. B. 'Supergirl'.",
  followWarning: "Die Schaltfläche „Folgen“ funktioniert für alle Benutzer.",
  openChatWarning:
    "Sie werden nicht in der Lage sein, mit sich selbst zu chatten, aber Ihre Kunden werden es tun.",
  bookingOfferWarning:
    "Benutzer können ein Angebot erwerben, indem sie hier klicken.",
  shareOffer: "Link kopieren",
  turnOffAccount: "Konto deaktivieren",
  turnOnAccount: "Konto einschalten",
  areYouSureYouWantTurnOff:
    "Möchten Sie Ihr Konto wirklich vollständig deaktivieren?",
  areYouSureYouWantTurnOn: "Möchten Sie Ihr Konto wirklich wieder aktivieren?",
  disableFreeChat: "Deaktivieren Sie den kostenlosen Chat",
  enableFreeChat: "Aktivieren Sie den kostenlosen Chat",
  areYouSureYOuWantDisableFreeChat:
    "Möchten Sie den kostenlosen Chat wirklich deaktivieren?",
  areYouSureYOuWantEnableFreeChat:
    "Möchten Sie den kostenlosen Chat wirklich aktivieren?",
  cancelEditing: "Bearbeitung abbrechen",
  descriptionCancelEditing: "Möchten Sie die Bearbeitung wirklich abbrechen?",
  takenPublicID: "Diese öffentliche ID ist bereits vergeben",
  charactersMatch: "2-64 Zeichen",
  charactersBetweenMatch: "Das Feld muss zwischen 1 und 64 Zeichen lang sein",
  charactersMaxMatch: "Die maximale Feldlänge beträgt 64 Zeichen",
  deletePhoto: "Foto löschen",
  descriptionDeletePhoto: "Möchten Sie Ihr Foto wirklich löschen?",
  accountOnDescription:
    "Wenn das Konto deaktiviert ist, ist es nicht verfügbar und in keiner Weise sichtbar (direkte Links, Suchergebnisse usw.)",
  freechat: "Kostenloser Chat",
  freechatDescription:
    "Wenn der kostenlose Chat deaktiviert ist, können Benutzer auf diese Weise keine Kommunikation mit Ihnen initiieren. Sie können dies jedoch mit Ihren Kunden tun.",
  freeChatWarning:
    "Dieses Konto hat den kostenlosen Chat deaktiviert. Sie können eine Sitzung zur Kommunikation anfordern.",
  editMessage: "Nachricht bearbeiten",
  replyTo: "Antwort an",
  file: "Datei",
  unableToJoinSession: "Der Sitzung kann nicht beigetreten werden",
  backgroundWarning:
    "Achtung: Hintergründe werden von den Browsern Safari und Firefox nicht vollständig unterstützt und funktionieren möglicherweise nicht.",
  single: "Schnell",
  scheduleSession:
    "Planen Sie eine Sitzung mit einem Experten oder einem Ersteller.",
  startSessionImmediately:
    "Beginnen Sie sofort mit der kostenlosen Sitzung. Laden Sie andere direkt mit einem Link ein.",
  quickSessionWarningTittle:
    "Sind Sie sicher, dass Sie die Sitzung starten möchten?",
  quickSessionWarningDescription:
    "Sie haben eine geplante Sitzung, die sich mit der Schnellsitzung überschneidet, die Sie zu starten versuchen.",
  connectIn: "Verbinden ",
  connectOn: "Verbinden ",
  sessionDecisionTittle: "Ihre Partei ist nicht aufgetaucht.",
  expertSessionDecision:
    "Möchten Sie die Sitzung beenden oder länger warten? Das Beenden der Sitzung führt zu einer Überweisung auf Ihr Konto.",
  clientSessionDecision:
    "Möchten Sie die Sitzung beenden oder länger warten? Wenn Sie die Sitzung beenden, wird Ihre Zahlung zurückerstattet.",
  cancelledSessionTittle: "Diese Sitzung wurde abgesagt",
  cancelledSessionExpertDescription:
    "Die Zahlung wurde auf Ihr Konto überwiesen. Wir entschuldigen uns für die Unannehmlichkeiten.",
  cancelledSessionClientDescription:
    "Sie werden nicht belastet und erhalten eine Rückerstattung. Wir entschuldigen uns für die Unannehmlichkeiten.",
  areYouSureYouWantLeaveSession: "Möchten Sie die Sitzung wirklich verlassen?",
  areYouSureYouWantLeaveSessionDescription:
    "Sie können zur Sitzung zurückkehren, bis diese beendet ist.",
  addExtraMinutes: "Fügen Sie weitere 10 Minuten hinzu",
  addExtraMinutesDescription:
    "Sind Sie sicher, dass Sie dieser Sitzung weitere 10 Minuten hinzufügen möchten?",
  unableAddExtraMinutes:
    "Zusätzliche 10 Minuten können nicht hinzugefügt werden",
  unableAddExtraMinutesDescription:
    "Leider haben Sie in weniger als 10 Minuten eine geplante Sitzung.",
  monday: "Montag",
  tuesday: "Dienstag",
  wednesday: "Mittwoch",
  thursday: "Donnerstag",
  friday: "Freitag",
  saturday: "Samstag",
  sunday: "Sonntag",
  h: "h",
  sharingSetting: "Einstellungen teilen",
  call: "Anruf",
  public: "Öffentlich",
  byLinkOnly: "Nur per Link",
  offerInfo: "Angebotsinformationen",
  coverVideo: "Titelseite / Video",
  imageVideo: "Bild / Video",
  chooseCountry: "Land auswählen",
  passwordChangedSuccessfully: "Das Passwort wurde erfolgreich geändert",
  connected: "Verbunden",
  passedALot: "Bestanden",
  chooseCountryBank:
    "Wählen Sie das Land Ihrer Bank oder Ihres Finanzdienstleisters.",
  createAccountContinue: "Erstellen Sie ein Konto, um fortzufahren",
  metasignInTitle: "Anmelden | Waylight",
  metasignUpTitle: "Anmeldung | Waylight",
  metapasswordForgotTitle: "Passwort vergessen | Waylight",
  metapasswordResetTitle: "Passwort zurücksetzen | Waylight",
  clientflowStep1Title: "Finden Sie den Link in der BIO- oder Waylight-Suche",
  clientflowStep1:
    "Finden Sie den Waylight-Link Ihres Urhebers, Influencers, Experten oder Tutors in den sozialen Netzwerken (Instagram, Facebook, YouTube, LinkedIn, etc.) oder geben Sie den Namen oder Spitznamen in die Waylight-Suche ein.",
  clientflowStep2Title:
    "Buchen Sie einen Videoanruf oder abonnieren Sie einen Blog oder Chat",
  clientflowStep2:
    "Öffnen und erkunden Sie das Unternehmensprofil Ihres Erstellers, Influencers, Experten oder Tutors. Finden Sie ein interessantes Angebot für einen 1:1-Videoanruf, buchen Sie es und greifen Sie zum geplanten Zeitpunkt zu. Versuchen Sie, danach oder vorher zu chatten, um langfristig mehr Informationen zu erhalten. Abonnieren Sie den persönlichen Blog, der nur für Mitglieder zugänglich ist, mit Anleitungen, Artikeln, Videotipps, Hintergrundinformationen, persönlichen Lebenserfahrungen, Lektionen, Musik, usw.",
  clientflowStep3Title: "Überprüfen Sie dezentrale Bewertungen und Rezensionen",
  clientflowStep3:
    "Dezentralisierte Bewertungen bilden eine Erfolgsbilanz für Entwickler und Experten, können nicht verkauft oder gefälscht werden und schaffen ein Gefühl des Vertrauens, der Transparenz, des Fachwissens und der Nachfrage nach Inhalten für Kunden und Abonnenten. Hinterlassen Sie Ihre echten Bewertungen nach Videogesprächen, Chats und unter Beiträgen in Blogs, die nur für Mitglieder zugänglich sind. Teilen Sie Ihre Bewertungen, um der Waylight-Community zu helfen, die besten hervorzuheben.",
  confirmBooking: "Auswahl bestätigen",
  free: "Frei",
  unableVerifyEmail: "Wir konnten Ihre E-Mail-Adresse nicht verifizieren.",
  thankYouForVerifiedEmail: "Danke für das Verifizieren Ihrer E-Mail!",
  reSend: "Erneut senden",
  signInToContinue: "Melden Sie sich an, um fortzufahren",
  switchingToClientView: "Wechseln Sie in die Kundenansicht",
  switchingToExpertView: "Wechseln Sie zur Business Anisicht",
  cookiePermission:
    "Wir schätzen Ihre Privatsphäre. Wir verwenden Cookies, um Ihr Surferlebnis zu verbessern, personalisierte Inhalte anzubieten und unseren Datenverkehr zu analysieren. Indem Sie auf 'Akzeptieren' klicken, stimmen Sie unserer Verwendung von Cookies zu.",
  readMore: "Mehr lesen",
  notReadableDevicesWarning:
    "Warnung: Ihre Kamera und/oder Ihr Mikrofon sind entweder gerade in Benutzung oder nicht erreichbar!",
  howPayoutsWork: "Wie die Auszahlung funktioniert",
  payoutsOnboarding1:
    "Vervollständigen Sie das Onboarding, um Auszahlungen zu ermöglichen.",
  payoutsOnboarding2: "Wir bewahren Ihr Geld sicher auf vor Ihrem Onboarding.",
  payoutsOnboarding3:
    "Je nach Land benötigen Sie Ihre Bankverbindung oder Ihre Kartendaten.",
  payoutsOnboarding4: "Die Auszahlungen erfolgen jeden Montag.",
  payoutsOnboarding5:
    "Sie können den Auszahlungsstatus unter Transaktionen überprüfen.",
  payoutsOnboarding6:
    "Das Zahlungsgateway berechnet 0,25% + $0,25 pro Auszahlung.",
  payoutsOnboardingEcommpay6:
    "Das Zahlungsgateway berechnet 0,5% für Karten und 0% für Händler, wenn Kundengebühren aktiviert sind.",
  payoutsOnboarding7:
    "Wenn Sie eine Auszahlung zum ersten Mal erhalten, kann es aufgrund von Gateyway-Einschränkungen 7-14 Werktage dauern bis sie ankommt.",
  payoutsOnboarding8:
    "Normalerweise dauert es etwa 3 Werktage, bis eine Auszahlung erfolgt.",
  payoutsOnboarding9:
    "Eine Änderung des Landes ist derzeit nicht möglich. Kontaktieren Sie uns, wenn Sie dies benötigen und wir werden versuchen eine individuelle Lösung zu finden.",
  payoutsOnboarding10:
    "Waylight bere je nach Ihrem Plan eine variable Provision.",
  payoutsOnboarding11: "Bei Fragen kontaktieren Sie bitte",
  supportAlternative: "Hilfe",
  freeSessionDecision: "Möchten Sie die Sitzung beenden oder länger warten?",
  cancelledFreeSessionDescription:
    "Sie sind der Einzige, der aufgetaucht ist. Wir entschuldigen uns für die Unannehmlichkeiten.",
  m: "m",
  cityNotFound:
    "Keine Optionen gefunden. Wählen Sie die nächstgelegene Großstadt.",
  notifications: "Benachrichtigungen",
  telegramBot: "Telegram Bot",
  notConnected: "nicht verbunden",
  connectTelegramBot: "Telegram Bot verbinden",
  disconnectTelegramBot: "Telegram-Bot abmelden",
  telegramConnected: "verbunden",
  bgSettingsDescription:
    "Verwenden Sie diese Einstellungen, um den Hintergrund für Ihren Videostream anzupassen",
  selectBackground: "Hintergrund auswählen",
  bookingDelay: "Legen Sie eine Buchungsverzögerung fest",
  bookingDelayDescription:
    "Legen Sie fest, wie lange im Voraus Sie gebucht werden können.",
  processingFee: "Gebühr für das Zahlungssystem",
  payoutsOnboardingEcommpay1:
    "Wählen Sie die Methode zur Auszahlung von persönlichen Kreditkarten oder privatem Unternehmertum.",
  payoutsOnboardingEcommpay3:
    "Sie müssen entweder mindestens eine persönliche Kreditkarte + Ihren individuellen Steuercode hinzufügen ODER die Fondy-Händler-Onboarding abschließen, damit Transaktionen funktionieren!",
  payoutsOnboardingEcommpay4: "Auszahlungen erfolgen nach jeder Transaktion.",
  payoutsOnboardingEcommpay7:
    "Auszahlungen erfolgen spätestens am nächsten Werktag.",
  tutorials: "Produkte",
  tutorialsAndCourses: "Produkte kaufen",
  noOffers: "Es scheint, hier gibt es noch keine Angebote",
  newSessionOffer: "Neues Sitzungsangebot",
  newSessionOfferDescription:
    "Erstellen Sie ein kostenpflichtiges oder kostenloses Videositzungsangebot",
  newTutorialOffer: "Neues Produktangebot",
  newTutorialOfferDescription:
    "Erstellen Sie ein kostenpflichtiges oder kostenloses Produktangebot",
  sessionName: "Sitzungsname",
  tutorialName: "Produktname",
  tutorialNameDescription:
    "Wählen Sie einen Namen für Ihr Produkt, wie z.B. „Tanzklasse Nr. 1“",
  cover: "Abdeckung",
  tutorialCoverTooltip:
    "Laden Sie ein Bild oder Video hoch, das in der Produktbeschreibung angezeigt wird.",
  getTutorialPaid: "Sie erhalten für jeden Kauf dieses Produkts bezahlt.",
  unlockedAfterPurchase: "Nach dem Kauf freigeschaltet",
  unlockedAfterPurchaseDescription:
    "Fügen Sie Inhalte hinzu, die Ihre Kunden freischalten können.",
  tutorialDetailsDescription:
    "Fügen Sie hier Kommentare, Details oder Anweisungen hinzu",
  removeSection: "Abschnitt entfernen",
  addAnotherSection: "Weiteren Abschnitt hinzufügen",
  details: "Details",
  toastEmptyUnlockedContent: "Gesperrter Inhalt darf nicht leer sein",
  toastTutorialNotEdited: "Dieses Produkt wurde nicht bearbeitet",
  removeSectionDescription:
    "Sind Sie sicher, dass Sie den Abschnitt entfernen möchten?",
  buyFor: "Kaufen für",
  buttonOpenChatAlt: "Chat öffnen",
  buttonShare: "Teilen",
  tutorial: "Produkt",
  toastBuyingYourOwnTutorial: "Sie können Ihr eigenes Produkt nicht kaufen!",
  purchasingInProgress: "Transaktion läuft",
  byClickingConfirm: "Durch Klicken",
  iAgreeWith: "Ich stimme zu mit",
  allowWaylightToCharge:
    "und gestatten Sie Waylight, meine Karte jetzt und in Zukunft gemäß den Abonnementbedingungen zu belasten, bis ich kündige.",
  paymentProcessingFees:
    "Es können Gebühren für die Zahlungsabwicklung anfallen.",
  bookingTutorialWarning:
    "Benutzer können ein Produkt kaufen, indem sie hier klicken.",
  editTutorials: "Produkte bearbeiten",
  topReview: "Top-Bewertung",
  addForFree: "Kostenlos hinzufügen",
  tutorialPaymentFailed:
    "Ihre Zahlung wurde nicht durchgeführt, und Sie konnten dieses Produkt nicht kaufen.",
  buy: "Kaufen",
  tutorialWith: "Produkt von",
  purchased: "Gekauft",
  noTutorialsFound: "Keine Produkte gefunden",
  noTutorialsFoundDescription:
    "Sie werden hier angezeigt, wenn Sie ein Produkt kaufen oder hinzufügen",
  tags: "Tags",
  createSessionOffer: "Sitzungsangebot erstellen",
  createSessionOfferDescription:
    "Fügen Sie eine kostenpflichtige oder kostenlose Videositzung hinzu.",
  createTutorial: "Produktangebot erstellen",
  createTutorialDescription:
    "Fügen Sie ein kostenpflichtiges oder kostenloses Produkt hinzu.",
  requires_transfer: "Benötigt Schulung",
  pending: "Auszahlung ausstehend",
  requires_payout: "Auszahlung ausstehend",
  requires_capture: "In Wartestellung",
  reschedule: "Neu planen",
  rescheduleDelay: "Neu planen Verzögerung festlegen",
  rescheduleDelayDescription:
    "Geben Sie an, wie viele Tage im Voraus Sie Ihre Sitzungen neu planen können.",
  rescheduleSession: "Sitzung neu planen",
  rescheduleSessionDescription:
    "Sind Sie sicher, dass Sie die Sitzung neu planen möchten?",
  buttonChange: "Neu planen",
  timeslots: "Zeitschlitze",
  specifyReason:
    "Geben Sie einen Grund für die Änderung von Datum und Uhrzeit an.",
  cannotRescheduleSession: "Sitzung kann nicht neu geplant werden",
  rescheduleReason: "Grund für die Neuverplanung",
  optionLink: "Externe Verknüpfung hinzufügen",
  optionUpload: "Oder laden Sie eine Datei hoch",
  tutorialLinkDescription: "Fügen Sie den Link zu Ihrem Produkt hinzu.",
  tutorialUploadDescription:
    "Laden Sie hier eine beliebige Datei hoch. Sie können pro Abschnitt eine Datei hinzufügen.",
  bgNone: "Keine",
  bgLowBlur: "Geringe Unschärfe",
  bgMediumBlur: "Mittlere Unschärfe",
  bgHighBlur: "Hohe Unschärfe",
  bgSolidColour: "Einfarbige Farbe",
  bgCustomImage: "Benutzerdefiniertes Bild",
  telegramNotifications: "Telegram-Benachrichtigungen",
  connectYourTelegramAccount:
    "Verbinden Sie Ihr Telegram-Konto, um Benachrichtigungen von Waylight zu erhalten.",
  buttonConnectTelegram: "Telegram verbinden",
  notificationGoToMyProfile: "Zu meinem Profil gehen",
  setupSchedule: "Zeitplan einrichten",
  aboutTheTutorial: "Über das Produkt",
  added: "Hinzugefügt",
  orderingChangeInProgress: "Änderung der Reihenfolge...",
  gloryToUkraine: "Ruhm der Ukraine!",
  terroristStates:
    "Waylight funktioniert nicht für Benutzer aus sanktionierten Ländern mit diktatorischen und terroristischen Regimen: Russland, Iran, Nordkorea, Syrien, Kuba, Venezuela, Afghanistan.",
  never: "Keine Begrenzung",
  expiration: "Zeitbegrenzung",
  expirationDescription:
    "Kunden haben Zugang zu diesem Produkt für eine bestimmte Anzahl von Tagen",
  daysLeft: "Tage übrig",
  tutorialExpired: "Ihr Zugang zu diesem Produkt ist abgelaufen.",
  days: "Tage",
  tips: "Spenden",
  newTipOffer: "Neues Spendenangebot",
  newTipOfferDescription: "Erstellen Sie eine Spende",
  tipName: "Name der Spende",
  tipNameDescription: "Wählen Sie einen Namen für Ihre Spende",
  tipCoverTooltip:
    "Laden Sie ein Bild oder Video hoch, das in der Beschreibung der Spende angezeigt werden soll.",
  aboutTheTip: "Über die Spende",
  describeYourTip:
    "Fügen Sie eine Beschreibung der Spende hinzu: Sammeln von Spenden, Durchführen einer Wohltätigkeitsaktion oder Kauf eines Kaffees?",
  tipAmounts: "Fügen Sie drei Spendensummen hinzu",
  tipAmountsDescription:
    "Ihre Kunden können auch ihren eigenen Betrag eingeben, wenn sie dies wünschen.",
  bonusTipper: "(Optional) Senden Sie einen Bonus an den Kunden",
  bonusTipperDescription:
    "Fügen Sie Inhalte oder eine Nachricht hinzu, die Ihre Kunden als Bonus nach der Spende erhalten.",
  tipLinkDescription: "Fügen Sie den Link zu Ihrem Bonus hinzu.",
  tipMessageDescription: "Fügen Sie hier Ihre Textnachricht hinzu.",
  toastTipNotEdited: "Diese Spende wurde nicht bearbeitet",
  leaveATip: "Eine Spende hinterlassen",
  bookingTipWarning:
    "Benutzer können eine Spende hinterlassen, indem sie hier klicken.",
  includesAReward: "Enthält einen Bonus",
  selectTip: "Wählen Sie aus, wie viel Sie spenden möchten",
  selectTipAmount: "Wählen Sie einen Betrag für die Spende",
  toastBuyingYourOwnTip: "Sie können Ihre eigene Spende nicht kaufen!",
  tipSuccessfullyBought: "Spende erfolgreich gekauft",
  tip: "Spende",
  tipPaymentFailed:
    "Ihre Zahlung wurde nicht bearbeitet, und Sie konnten diese Spende nicht kaufen.",
  purchasedOffers: "Gekauft",
  noTipsFound: "Keine Spenden gefunden",
  noTipsFoundDescription:
    "Sie werden hier angezeigt, wenn Sie eine Spende kaufen",
  thankYouExclamation: "Danke!",
  editTips: "Spenden bearbeiten",
  tipPriceLimit: "Der Preis kann von $2 bis $10000 sein.",
  tipPriceLimitForUA: "Der Preis kann von ₴83 bis ₴412496 sein.",
  cardAuthorization: "Kreditkarten-Autorisierung",
  failed: "Fehlgeschlagen",
  communityName: "Community-Name",
  communityNameDescription:
    "Wählen Sie einen Namen für Ihre Community, wie zum Beispiel „Mein exklusiver Telegram-Kanal nur für Abonnenten“.",
  getCommunityPaid: "Abonnementpreis",
  Period: "Zeitraum*",
  expirationCommunityDescription: "Legen Sie die Abonnementdauer fest.",
  aboutTheCommunityOffer: "Über das Angebot",
  TelegramChannelName: "Telegram-Kanal-/Gruppenname*",
  communityTelegramNameDescription:
    "Wählen Sie Ihren Kanal- oder Gruppennamen aus. Wenn leer, klicken Sie bitte auf das Info-Symbol für Anweisungen.",
  communities: "Gemeinschaften",
  community: "Gemeinschaft",
  subscribeFor: "Abonnieren für",
  subscribed: "Abonniert",
  unsubscribe: "Abonnement kündigen",
  unsubscribeDescription:
    "Sind Sie sicher, dass Sie das Abonnement kündigen möchten?\nIhr Zugang läuft ab am",
  subscribeDescription: "{{community}}.",
  usubscribed: "Abonnement gekündigt",
  unlimited: "Unbegrenzt",
  subscribeAgain: "Erneut abonnieren",
  unsubscribeDescriptionModal:
    "Sind Sie sicher, dass Sie erneut abonnieren möchten?\nSie werden für den nächsten Zeitraum am",
  subscribe: "Abonnieren",
  currentlyMember: "Sie sind derzeit Mitglied von {{title}} in Telegram.",
  notCurrentlyMember:
    "Sie sind derzeit kein Mitglied von {{title}} in Telegram.",
  newCommunityOffer: "Neues Community-Angebot",
  newCommunityOfferDescription: "Erstellen oder verwalten Sie eine Community.",
  editCommunities: "Gemeinschaften bearbeiten",
  joinCommunities: "Gemeinschaften beitreten",
  noCommunitiesAdded: "Keine Gemeinschaften hinzugefügt",
  TheyWillAppear:
    "Sie werden hier erscheinen, wenn Sie Ihre Angebote erstellen.",
  createCommunity: "Community erstellen",
  noAccessToData: "Kein Zugriff auf Daten! Fügen Sie",
  noAccessToDataAsAnAdministrator:
    "als Administrator zu Ihrer Gruppe hinzu, um Zugriff zu gewähren.",
  retryPayment: "Zahlung erneut versuchen",
  areYouSureYouWantToSetCard:
    "Sind Sie sicher, dass Sie die Karte *{{last4}} als primär festlegen möchten?",
  thingsToTry: "Dinge, die Sie ausprobieren können:",
  checkIfYouHaveSufficient:
    "Überprüfen Sie, ob Sie ausreichend Guthaben auf Ihrem Konto haben oder ob Internetzahlungen aktiviert sind.",
  selectDifferentPaymentMethod: "Wählen Sie eine andere Zahlungsmethode aus.",
  set: "Festlegen",
  primaryCard: "Primär",
  setPrimary: "Als primär festlegen",
  inviteMember: "Mitglied einladen",
  copyLink: "Link kopieren",
  members: "Mitglieder",
  cancel: "Abbrechen",
  actions: "AKTIONEN",
  subscribedUntil: "ABONNIERT BIS",
  waylightClients: "WAYLIGHT-Kunde",
  nameMembers: "NAME",
  admin: "Admin",
  external: "Extern",
  subscriber: "Abonnent",
  unsubscribed: "Abonnement gekündigt",
  kicked: "Entfernt",
  owner: "Besitzer",
  save: "Speichern",
  kickAndBan: "Entfernen",
  areYouSureYouWantToRemove:
    "Sind Sie sicher, dass Sie „{{name}}“ aus Ihrem Kanal entfernen möchten?",
  WeHaveEncounteredAnIssue:
    "Wir haben ein Problem mit Ihrer wiederkehrenden Zahlung festgestellt!",
  yourPaymentHasNotGone:
    "Ihre Zahlung ist nicht durchgegangen und wir konnten die Gebühr für Ihr Abonnement nicht erneut versuchen.",
  gatewayFee: "Kunden zahlen Gateway-Gebühren",
  gatewayFeeDescription:
    "Mit dieser Einstellung wird die Gateway-Gebühr zur Rechnung des Kunden hinzugefügt. Wenn sie ausgeschaltet ist, wird die Gebühr von den Geschäftsauszahlungen abgezogen.",
  enableGatewayFee: "Gateway-Gebühr für Kunden aktivieren?",
  areYouSureYOuWantEnableGatewayFee:
    "Sind Sie sicher, dass Sie die Gateway-Gebühr für Kunden aktivieren möchten?",
  disableGatewayFee: "Gateway-Gebühr für Kunden deaktivieren?",
  areYouSureYOuWantDisableGatewayFee:
    "Sind Sie sicher, dass Sie die Gateway-Gebühr für Kunden deaktivieren möchten? Die Gebühr wird von Ihren Auszahlungen abgezogen!",
  communityTooltip:
    "Stellen Sie sicher, dass Sie Waylight Bot als Administrator zu Ihrer Gruppe/Kanal hinzugefügt, Ihr Telegram-Konto mit Waylight verbunden und dies das einzige Angebot für diesen Kanal ist!",
  communityCoverTooltip:
    "Laden Sie ein Bild oder Video hoch, das in der Community-Beschreibung angezeigt wird.",
  setCard: "Setzen",
  expirationDateMustBeInFuture: "Ablaufdatum muss in der Zukunft liegen",
  accessGranted: "Zugriff gewährt",
  noAccess: "Kein Zugriff",
  manageCommunity: "Community verwalten",
  manage: "Verwalten",
  January: "Januar",
  February: "Februar",
  March: "März",
  April: "April",
  May: "Mai",
  June: "Juni",
  July: "Juli",
  August: "August",
  September: "September",
  October: "Oktober",
  November: "November",
  December: "Dezember",
  toastBuyingYourOwnCommunity:
    "Sie können sich nicht für Ihre eigene Community anmelden!",
  priceLimitCommunity: "Der Preis kann von $5 bis $10000 sein.",
  priceLimitCommunityForUA: "Der Preis kann von ₴198 bis ₴195000 sein.",
  toastRetryPaymentSuccess: "Ihr erneuter Zahlungsversuch war erfolgreich.",
  toastCommunityLinkCopied: "Community-Link kopiert",
  communityPaymentFailed: "Community-Zahlung fehlgeschlagen",
  AddExpirationDateManually: "Ablaufdatum manuell hinzufügen:",
  SetTimeFormat: "Zeitformat festlegen",
  events: "Veranstaltungen",
  newEventOffer: "Neues Event-Angebot",
  newEventOfferDescription:
    "Erstellen Sie eine zeitlich begrenzte Veranstaltung",
  eventName: "Veranstaltungsname",
  eventNameDescription:
    "Wählen Sie einen Namen für Ihre Veranstaltung, wie z.B. „SEO-Meisterklasse“",
  eventCoverTooltip: "Event-Cover-Tooltip",
  getEventPaid:
    "Sie erhalten eine Bezahlung für jeden Kauf von Plätzen in dieser Veranstaltung",
  aboutTheEventOffer: "Über die Veranstaltung",
  dateAndTime: "Datum und Uhrzeit",
  clickToSetDateAndTime: "Klicken Sie hier, um Datum und Uhrzeit festzulegen",
  DescribeYourEvent: "Beschreiben Sie Ihre Veranstaltung kurz.",
  onlineEvent: "Online-Veranstaltung",
  offlineEvent: "Offline-Veranstaltung",
  eventLinkDescription:
    "Fügen Sie Informationen zum Veranstaltungsort hinzu, z.B. Zoom oder Meet",
  uploadOptional: "Hochladen (optional)",
  uploadEventDetails:
    "Laden Sie hier eine Datei mit optionalen Veranstaltungsdetails hoch",
  addInformationAboutLocation:
    "Fügen Sie Informationen zum Veranstaltungsort oder einen Kartenlink hinzu",
  location: "Ort",
  address: "Adresse",
  addressOptional: "Adresse (optional)",
  addAddressEvent: "Adresse der Veranstaltung hinzufügen",
  AllDayEvent: "Dies ist eine ganztägige Veranstaltung.",
  toastBuyingYourOwnEvent: "You can not buy your own event!",
  event: "Veranstaltung",
  timeToEvent: "Zeit bis zur Veranstaltung",
  hoursEvent: "Stunden",
  seatsLeft: "verbleibende Plätze",
  follower: "Follower",
  priceLimitEventForUA: "Preis kann von ₴198 bis ₴195000 sein.",
  soldOut: "Ausverkauft!",
  joinEvents: "An Veranstaltungen teilnehmen",
  noEventFound: "Keine Veranstaltungen gefunden",
  noEventsFoundDescription:
    "Sie werden hier angezeigt, wenn Sie mindestens an einer Veranstaltung teilnehmen",
  editEvents: "Veranstaltungen bearbeiten",
  attendForfree: "Kostenlos teilnehmen",
  disabled: "Deaktiviert",
  enabled: "Aktiviert",
  personalCreditcard: "Persönlich (Kreditkarte)",
  privateEntrepreneur: "Selbständiger Unternehmer (IBAN)",
  selectPayoutMethod: "Wählen Sie die Auszahlungsmethode",
  enterYourPersonalTaxCode: "Geben Sie Ihren persönlichen Steuercode ein*",
  createFondyMerchantAccount:
    "Erstellen Sie ein Fondy-Händlerkonto und schließen Sie die Registrierung ab:",
  create: "Erstellen",
  participantsMin: "Die Mindestanzahl der Teilnehmer beträgt 1",
  personalTaxCodeError:
    "Der individuelle Steuercode muss eine gültige Nummer sein!",
  participantsError: "Die Teilnehmeranzahl muss eine gültige Nummer sein!",
  peName: "Name PE",
  url: "Website",
  okpo: "OKPO",
  phone: "Telefon",
  peNameTooltip:
    "Der offizielle Name Ihres privaten Unternehmens: 'Nachname Vorname Patronym'.",
  urlTooltip:
    "Die URL Ihres Unternehmens. Dies kann Ihre Website, Facebook, Instagram usw. sein.",
  okpoTooltip: "OKPO-Code Ihres privaten Unternehmens.",
  phonePayoutTooltip: "Ihre Telefonnummer.",
  paymentsTipStripe:
    "Zahlungen sind für Stripe-Benutzer immer aktiviert. Auszahlungen sind nach Abschluss der Registrierung verfügbar.",
  paymentsTipFondy1:
    "Private Unternehmer: Zahlungen werden nach der Erstellung des Händlers aktiviert. Auszahlungen sind nach Abschluss der Registrierung verfügbar.",
  paymentsTipFondy2:
    "Kreditkarten: Zahlungen und Auszahlungen werden aktiviert, nachdem eine Kombination aus privater Kreditkarte und individuellem Steuercode hinzugefügt wurde.",
  phoneError: "Telefon muss eine gültige Nummer sein!",
  toastTaxCodeAdded:
    "Ihr individueller Steuercode wurde erfolgreich hinzugefügt.",
  toastTaxCodeError:
    "Es gab einen Fehler beim Versuch, Ihren individuellen Steuercode hinzuzufügen!",
  toastPECodeAdded:
    "Ihr neuer Fondy-Händler wurde erfolgreich erstellt. Überprüfen Sie Ihre E-Mail, um mit der Integration zu beginnen!",
  toastPECodeError:
    "Es gab einen Fehler beim Versuch, Ihren neuen Fondy-Händler zu erstellen!",
  onboarding: "Onboarding",
  notCompletedOnboarding: "Nicht abgeschlossen",
  completedOnboarding: "Abgeschlossen",
  completeOnboarding: "Onboarding abschließen",
  onboardingTooltip:
    "Schließen Sie das Onboarding im Fondy-Dashboard ab. Überprüfen Sie Ihre E-Mail-Einladung zur Erstellung eines Passworts, melden Sie sich im Fondy-Dashboard an, laden Sie erforderliche Dokumente hoch und fügen Sie eine digitale Signatur hinzu. Nach Genehmigung wird Ihr Konto aktiviert.",
  fondyMerchantAccountDetails: "Details zum Fondy-Händlerkonto",
  eventIsLife: "Veranstaltung ist live",
  created: "Initiiert",
  processing: "Verarbeitung",
  goToFondyDashboard: "Öffnen Sie Ihr Fondy-Dashboard",
  youMustCompleteFondy:
    "Sie müssen die Fondy-Onboarding über die Einladung per E-Mail abschließen, die Sie unter folgender Adresse erhalten haben:",
  chooseEventType: "Wählen Sie den Ereignistyp",
  addException: "Ausnahme hinzufügen",
  manageScheduleExceptions: "Verwalten von Ausnahmen im Zeitplan",
  addRemoveSpecificTime:
    "Fügen Sie eine bestimmte Zeit hinzu oder entfernen Sie sie, wenn Sie verfügbar oder nicht verfügbar sind.",
  exceptionType: "Ausnahmetyp:",
  available: "Verfügbar",
  notAvailable: "Nicht verfügbar",
  yes: "Ja",
  no: "Nein",
  removeException: "Ausnahme entfernen",
  removeExceptionDesc:
    "Sind Sie sicher, dass Sie die Ausnahme {{time}} entfernen möchten?",
  NoExceptionsAdded: "Es wurden keine Ausnahmen hinzugefügt",
  availableAllDay: "Ich werde den ganzen Tag verfügbar sein.",
  notAvailableAllDay: "Ich werde den ganzen Tag nicht verfügbar sein.",
  exceptionDeleteSuccessfully: "Ausnahme erfolgreich entfernt",
  exceptionDeleteError: "Ihre Ausnahme konnte nicht entfernt werden",
  exceptionSavedSuccessfully: "Ausnahme erfolgreich gespeichert",
  exceptionSavedError: "Ihre Ausnahme konnte nicht gespeichert werden",
  addJoininstructions: "Beitrittsanweisungen hinzufügen",
  addJoininstructionsDesc:
    "Fügen Sie Inhalte oder Nachrichten hinzu, die Ihre Kunden nach dem Kauf sehen, wenn sie dem Kanal noch nicht beigetreten sind",
  joinChannel: "Kanal beitreten",
  communityUploadDescription: "Bild oder Video hochladen.",
  detailsOptional: "Details (optional)",
  type: "Typ",
  youCannotAddPast:
    "Sie können keinen Zeitraum hinzufügen, der in der Vergangenheit endet",
  Sun: "So",
  Mon: "Mo",
  Tue: "Di",
  Wed: "Mi",
  Thu: "Do",
  Fri: "Fr",
  Sat: "Sa",
  toastEventNotEdited:
    "Beim Versuch, Ihre Veranstaltung zu bearbeiten, ist ein Fehler aufgetreten!",
  refunded: "zurückerstattet",
  declined: "abgelehnt",
  verified: "überprüft",
  transactionSuccessful: "Transaktion erfolgreich",
  myTabs: "Meine Tabs",
  tabStatus: "{{tabName}} sind {{status}}",
  controlOfferTabs:
    "Arten von Angeboten, die Kunden auf Ihrer Hauptseite sehen können:",
  onlyEnglishLetters: "Nur englische Buchstaben und Zahlen sind erlaubt",
  notificationCompleteOnboarding: "Onboarding abschließen",
  notificationYourPaidOffersAreUnavailable:
    "Ihre kostenpflichtigen Angebote stehen Ihren Kunden nicht zur Verfügung.",
  products: "Produkte",
  bookingAvailable: "Verfügbarkeit für Buchungen",
  bookingAvailableDescription:
    "Geben Sie an, ob Sie im Allgemeinen für Buchungen verfügbar sind.",
  availableKey: "Verfügbar",
  notAvailableKey: "Nicht verfügbar",
  paymentOccurs: "Zahlung fällig alle",
  paymentOccursOneTime: "Einmalige Zahlung.",
  eventIsOver: "Veranstaltung ist vorbei",
  integrations: "Integrationen",
  externalAnalytics: "Externe Analyse",
  externalAnalyticsDescription:
    "Setzen Sie Ihre externen Analyse-Konten-IDs unten.",
  participantsManage: "Teilnehmer",
  manageEvent: "Veranstaltung verwalten",
  noEventsAdded: "Keine Veranstaltungen hinzugefügt",
  createEvent: "Veranstaltung erstellen",
  shareEvent: "Veranstaltung teilen",
  waylightProfile: "Waylight-Profil",
  tickedId: "Ticket-ID",
  checkIn: "Check-in",
  checkedIn: "Eingecheckt",
  notCheckedin: "Nicht eingecheckt",
  codeIsValid: "Der Code ist gültig! Eingecheckt!",
  codeisInvalid: "Code ungültig! Bitte erneut versuchen.",
  codeIsUsed: "Dieser Code wurde bereits verwendet!",
  buyMore: "Mehr kaufen",
  noParticipantsYet: "Noch keine Teilnehmer",
  emptyDeteilsBuysTickets:
    "Sie werden hier angezeigt, wenn jemand Tickets für Ihre Veranstaltung kauft.",
  connectYourTelegram:
    "Verbinden Sie Ihr Telegram-Konto, um auf den Kanal zuzugreifen!",
  change: "Änderung",
  numberOfTickets: "Anzahl der Tickets:",
  selectPaymentMethodTitle: "Zahlungsmethode auswählen:",
  or: "- oder -",
  addACard: "Karte hinzufügen",
  subscriptionLevels: "Abonnementstufen",
  subscriptionLevelsTooltip:
    "Fügen Sie Ihrer Community-Abonnement bis zu 3 Stufen hinzu. Zum Beispiel: monatlich, vierteljährlich und jährlich.",
  levelName: "Stufenname",
  starter: "Einsteiger",
  pro: "Profi",
  levelDiscription: "Vom Kunden angezeigter Stufenname",
  period: "Zeitraum",
  periodDiscription:
    "Kunden werden in diesem Intervall belastet, um abonniert zu bleiben",
  priceDiscription: "Sie werden in bestimmten Intervallen bezahlt",
  removeLevel: "Stufe entfernen",
  addAnotherLevel: "Weitere Stufe hinzufügen",
  noTrial: "Keine Testphase",
  trialDiscription:
    "Anzahl der Tage, für die Ihre Kunden kostenlosen Zugang erhalten",
  trialPeriod: "Testzeitraum:",
  selectSubscription: "Abonnement auswählen:",
  subscription: "Abonnement",
  removeLevelDescription:
    "Sind Sie sicher, dass Sie die Stufe entfernen möchten?",
  tryForFree: "Kostenlos testen",
  startTrial: "Kostenlose Testversion starten",
  subscribedSuccessfully: "Erfolgreich abonniert",
  edit: "Bearbeiten",
  expirationColumName: "Ablauf",
  kick: "Entfernen",
  trial: "Probe",
  notJoined: "Nicht beigetreten",
  telegramProfile: "Telegram-Profil",
  notAuthorized: "Nicht autorisiert",
  noMembersFoundFilter:
    "Keine Mitglieder gemäß dem festgelegten Filter gefunden.",
  tryADifferentFilter:
    "Versuchen Sie einen anderen Filter oder eine andere Sucheingabe.",
  "not joined": "Nicht beigetreten",
  "not authorized": "Nicht autorisiert",
  membership: "Mitgliedschaft",
  priceDiscriptionEvents:
    "Sie erhalten eine Zahlung für jeden Kauf von Plätzen bei dieser Veranstaltung",
  fanZone: "Fanzone",
  vipZone: "VIP-Zone",
  selectTickets: "Wählen Sie Tickets aus:",
  attendance: "Teilnahme",
  eventLevels: "Veranstaltungsstufen",
  noTicketsSelected: "Keine Tickets ausgewählt",
  SetMaximumNumberOfSeats:
    "Legen Sie die maximale Anzahl der Plätze für dieses Level fest",
  participants: "Anzahl der Plätze",
  import: "Importieren",
  export: "Exportieren",
  labelNameDescription: "Wählen Sie ein Etikett für Ihre importierten Daten",
  hidden: "versteckt",
  upload: "Hochladen",
  labelName: "Etikettenname",
  fileIsRequired: "Sie müssen eine Datei hochladen!",
  code: "Code",
  level: "Stufe",
  label: "Etikett",
  exportFileError: "Export fehlgeschlagen!",
  exportFileSuccess: "Datei erfolgreich exportiert.",
  importFileSuccess: "Datei erfolgreich importiert.",
  importFileError: "Import fehlgeschlagen!",
  statsSuccess: "Check-in-Statistiken erfolgreich geladen.",
  statsError: "Beim Laden der Check-in-Statistiken ist ein Fehler aufgetreten!",
  uploadAText: "Einfacher Text oder CSV-Datei bis zu 50 MB",
  onlyTextFilesAllowed: "Nur Text- oder CSV-Dateien sind erlaubt!",
  toastCommunityNotEdited:
    "Beim Bearbeiten der Community ist ein Fehler aufgetreten",
  toastYouCanSelectOnlyImage:
    "Sie können nur ein Bild für die Überschrift auswählen",
  sections: "Abschnitte",
  logInOrSignup:
    "Melden Sie sich unten bei Waylight an oder registrieren Sie sich",
  continue: "Fortsetzen",
  invalidEmail: "Ungültige E-Mail",
  enterCode: "Code eingeben",
  haveSentCodeTo: "Wir haben einen Code an",
  paste: "Einfügen",
  resendCodeIn: "Code erneut senden... (in {{time}} Sekunden)",
  resendCode: "Code erneut senden",
  waylight: "Waylight",
  pleaseChooseCountry:
    "Bitte wählen Sie das Land Ihrer Bank oder Finanzdienstleistung.",
  buyAccessFor: "Zugang kaufen für",
  thankYouForFollowing: "Vielen Dank fürs Folgen!",
  incorrectCode: "Code ist falsch oder abgelaufen!",
  enterEmailToContinue: "Geben Sie die E-Mail-Adresse ein, um fortzufahren",
  city: "Stadt",
  social: "Sozial",
  earned: "gesamt",
  priceLimitARS:
    "Der Preis kann ARS$ 0 betragen oder von ARS$ 1879.31 bis ARS$ 9396554.46 reichen.",
  priceLimitAUD:
    "Der Preis kann A$ 0 betragen oder von A$ 3.01 bis A$ 15097.1 reichen.",
  priceLimitEUR: "Der Preis kann €0 betragen oder von €1.81 bis €9070 reichen.",
  priceLimitBOB:
    "Der Preis kann Bs. 0 betragen oder von Bs. 13.81 bis Bs. 69119.48 reichen.",
  priceLimitBGN:
    "Der Preis kann лв 0 betragen oder von лв 3.55 bis лв 17750.81 reichen.",
  priceLimitCAD:
    "Der Preis kann CA$ 0 betragen oder von CA$ 2.74 bis CA$ 13718.98 reichen.",
  priceLimitCLP:
    "Der Preis kann CL$ 0 betragen oder von CL$ 1864.56 bis CL$ 9321644.2 reichen.",
  priceLimitCOP:
    "Der Preis kann COL$ 0 betragen oder von COL$ 8072.11 bis COL$ 40360576.84 reichen.",
  priceLimitCRC:
    "Der Preis kann ₡0 betragen oder von ₡1055.02 bis ₡5277582.56 reichen.",
  priceLimitHRK:
    "Der Preis kann kn 0 betragen oder von kn 35.24 bis kn 70472 reichen.",
  priceLimitCZK:
    "Der Preis kann Kč 0 betragen oder von Kč 45.71 bis Kč 228558 reichen.",
  priceLimitDKK:
    "Der Preis kann kr 0 betragen oder von kr 13.54 bis kr 67719 reichen.",
  priceLimitDOP:
    "Der Preis kann RD$ 0 betragen oder von RD$ 294.46 bis RD$ 588928 reichen.",
  priceLimitHKD:
    "Der Preis kann HK$ 0 betragen oder von HK$ 15.58 bis HK$ 77915 reichen.",
  priceLimitEGP:
    "Der Preis kann EGP 0 betragen oder von EGP 154.75 bis EGP 309501 reichen.",
  priceLimitHUF:
    "Der Preis kann Ft 0 betragen oder von Ft 716 bis Ft 3579217 reichen.",
  priceLimitISK:
    "Der Preis kann kr 0 betragen oder von kr 684 bis kr 1369300 reichen.",
  priceLimitINR:
    "Der Preis kann ₹0 betragen oder von ₹167.89 bis ₹839435 reichen.",
  priceLimitIDR:
    "Der Preis kann Rp 0 betragen oder von Rp 78852 bis Rp 157703833 reichen.",
  priceLimitILS: "Der Preis kann ₪0 betragen oder von ₪19 bis ₪36657 reichen.",
  priceLimitUYU:
    "Der Preis kann $U 0 betragen oder von $U 81 bis $U 402925 reichen.",
  priceLimitGBP:
    "Der Preis kann £0 betragen oder von £1.56 bis £7779.95 reichen.",
  priceLimitTRY:
    "Der Preis kann ₺0 betragen oder von ₺67.06 bis ₺335289.19 reichen.",
  priceLimitTTD:
    "Der Preis kann TT$ 0 betragen oder von TT$ 13.58 bis TT$ 67917.76 reichen.",
  priceLimitTHB:
    "Der Preis kann ฿0 betragen oder von ฿69.82 bis ฿349109.6 reichen.",
  priceLimitCHF:
    "Der Preis kann CHF 0 betragen oder von CHF 1.73 bis CHF 8647 reichen.",
  priceLimitSEK:
    "Der Preis kann kr 0 betragen oder von kr 20.82 bis kr 104092.39 reichen.",
  priceLimitZAR:
    "Der Preis kann R 0 betragen oder von R 94 bis R 186359 reichen.",
  priceLimitSGD:
    "Der Preis kann SGD$ 0 betragen oder von SGD$ 2.63 bis SGD$ 13146.69 reichen.",
  priceLimitSAR:
    "Der Preis kann SAR 0 betragen oder von SAR 19 bis SAR 37496 reichen.",
  priceLimitRON:
    "Der Preis kann lei 0 betragen oder von lei 9.04 bis lei 45196.94 reichen.",
  priceLimitPLN:
    "Der Preis kann zł 0 betragen oder von zł 7.8 bis zł 39008.41 reichen.",
  priceLimitPHP:
    "Der Preis kann ₱ 0 betragen oder von ₱ 281 bis ₱ 560255 reichen.",
  priceLimitPEN:
    "Der Preis kann S/. 0 betragen oder von S/. 19 bis S/. 38040 reichen.",
  priceLimitPYG:
    "Der Preis kann ₲ 0 betragen oder von ₲ 36387 bis ₲ 72772680 reichen.",
  priceLimitNOK:
    "Der Preis kann kr 0 betragen oder von kr 21.38 bis kr 106903 reichen.",
  priceLimitNZD:
    "Der Preis kann NZ$ 0 betragen oder von NZ$ 3.33 bis NZ$ 16646 reichen.",
  priceLimitMXN:
    "Der Preis kann MX$ 0 betragen oder von MX$ 37.92 bis MX$ 189595 reichen.",
  priceLimitKES:
    "Der Preis kann KSh 0 betragen oder von KSh 803 bis KSh 1605000 reichen.",

  linkInBIOtool: "Link in BIO-Tool",
  thatHelpsYouEarn: "das Ihnen hilft zu verdienen!",
  earnMore: "Verdiene mehr",
  withWaylight: "mit Waylight:",
  descriptionEarn:
    "Verdienen Sie das 10-fache Ihres Umsatzes in sozialen Medien!",
  descriptionEarnPart: "Starten Sie in nur 2 Minuten!",
  earnOnPaidCommunities: "Verdienen Sie in bezahlten Communities.",
  createAndManage:
    "Erstellen und verwalten Sie Ihre Abonnement-Communities in Telegram.",
  EarnPaidDigital: "Verdienen Sie an digitalen Produkten.",
  hostAndManage:
    "Hosten und verwalten Sie Kurse, Tutorials, E-Books, Anleitungen, Dateien usw.",
  earnOnline: "Verdienen Sie bei Online- und Präsenzveranstaltungen.",
  sellTicketsAndManage:
    "Verkaufen Sie Tickets und verwalten Sie Ihre Online- und Präsenzgruppenveranstaltungen.",
  EarnServices: "Verdienen Sie an bezahlten Dienstleistungen.",
  sellManage: "Verkaufen und verwalten Sie Ihren Servicefluss.",
  earnOnlineVideoSessions: "Verdienen Sie an bezahlten Online-Video-Sessions.",
  ScheduleBookingsOnlineVideo:
    "Richten Sie einen Zeitplan ein und erhalten Sie Buchungen für Online-Video-Sessions mit Ihren Kunden.",
  EarnAppointments: "Verdienen Sie an bezahlten persönlichen Terminen.",
  ScheduleSignups:
    "Richten Sie einen Zeitplan ein und erhalten Sie Anmeldungen für Ihre 1-zu-1- oder Gruppentermine.",
  earnTipping: "Verdienen Sie durch Trinkgeld.",
  getTippingSupport:
    "Erhalten Sie Trinkgeld zur Unterstützung Ihrer Kanäle oder zum Teilen von Inhalten in sozialen Netzwerken.",
  youWilGet: "Sie erhalten",
  tools: "1. Werkzeuge",
  sales: "2. Verkäufe",
  clientsGet: "3. Kunden",
  howItWorks: "Wie es funktioniert",
  GetClients: "Gewinnen Sie neue Verkäufe und Kunden",
  useWaylightGrowth:
    "Nutzen Sie die Wachstumsmotoren und Ratschläge von Waylight, um mehr zu verkaufen und neue Kunden aus sozialen Netzwerken zu gewinnen.",
  createWaylight: "Erstellen Sie Waylight",
  creatorsExperts: "Kreative und Experten",
  interactionsMonthly: "Monatliche Interaktionen",
  monthOverMonth: " Monat für Monat Wachstum",
  forWhom: "Für wen:",
  pricing: "Preise:",
  chooseYourPlan: "Wählen Sie Ihren Plan",
  saveWhenChoosingYearlyPlan:
    "Sparen Sie mehr, wenn Sie einen Jahresplan wählen!",
  monthlyBilling: "Monatliche Abrechnung",
  annuallyBilling: "Jährliche Abrechnung",
  freePlan: "Kostenlos",
  startLowestCosts:
    "Starten Sie mit den niedrigsten Kosten und erkunden Sie Waylight",
  mo: "/Mon.",
  signUp: "Anmelden",
  impressionMo: "{{comission}}% Transaktionsgebühren.",
  basicToolsIncluded: "Grundlegende Tools sind enthalten!",
  basic: "Grundlegend",
  getEverythingBasic:
    "Erhalten Sie alles in Kostenlos mit niedrigeren Transaktionsgebühren",
  contactSales: "Vertrieb kontaktieren",
  transactionFees: "2 % Transaktionsgebühren.",
  publishingMarketplace: "Veröffentlichung in der Marktplatzsuche.",
  saveBadge: "Sparen Sie {{percent}}%",
  bestValue: "Bestes Preis-Leistungs-Verhältnis",
  GetMoreSales:
    "Erzielen Sie mehr Verkäufe aus Ihren sozialen Netzwerken ohne Transaktionsgebühren",
  ziroTransactionFees: "0 % Transaktionsgebühren.",
  proToolsIncluded: "Pro-Tools sind enthalten!",
  customizations: "Anpassungen.",
  verifiedSign: "Verifiziertes Zeichen.",
  prioritySupport: "Prioritätssupport.",
  launchExpert: "Startexperte ist beigefügt.",
  askTelegram: "Fragen Sie auf Telegram",
  askWhatsApp: "Fragen Sie auf WhatsApp",
  askEmail: "Fragen Sie per E-Mail",
  MakeTenX: "Verdienen Sie das 10-fache",
  onYourSocialMedia: "auf Ihren sozialen Medien",
  revenue: "Umsatz! 🎉",
  launchJust: "Starten Sie einfach",
  twoMinutes: "2 Minuten!",
  product: "Produkt:",
  customersDataManagement: "Kunden- und Datenmanagement",
  reviewsProduct: "Bewertungen",
  portfolioProduct: "Portfolio",
  businessWebsite: "Geschäftswebsite",
  securePayments: "Sichere Zahlungen",
  tipping: "Trinkgeld",
  paidAppointments: "Bezahlte persönliche Termine",
  paidVideoSessions: "Bezahlte Online-Video-Sitzungen",
  paidServices: "Bezahlte Dienstleistungen",
  ticketsOnlineEvents: "Tickets für Online-Veranstaltungen",
  ticketsScanner: "Ticketscanner",
  ticketsPersonEvents: "Tickets für persönliche Veranstaltungen",
  paidAccessCourses: "Bezahlter Zugang zu Kursen",
  paidAccessDigitalProducts: "Bezahlter Zugang zu digitalen Produkten",
  paidGroupChannelAccessTelegram:
    "Bezahlter Gruppen- und Kanalzugang auf Telegram",
  paidSubscriptionCommunitiesTelegram:
    "Bezahlte abonnementbasierte Communities auf Telegram",
  productMenuItem: "Produkt",
  PricingMenuItem: "Preisgestaltung",
  Creator: "Schöpfer",
  Freelancer: "Freiberufler",
  Tutor: "Lehrer",
  Coach: "Trainer",
  Influencer: "Influencer",
  ask: "Fragen",
  anyQuestions: "alle Fragen hier:",
  how: "Wie",
  itWorks: "es funktioniert",
  businessflowStep1new:
    "Fügen Sie Ihren Waylight-Link zum BIO Ihrer sozialen Medien hinzu und beginnen Sie, Ihr Publikum und Ihre Kunden durch das Teilen von Geschichten und Beiträgen darüber zu begeistern.",
  businessflowStep2new:
    "Verwenden Sie die Werkzeuge und Ratschläge des Waylight-Wachstumsmotors, um mehr zu verkaufen und neue Kunden aus den sozialen Medien zu gewinnen.",
  businessflowStep3new:
    "Bekommen Sie Bezahlung für Ihre Gemeinschaften, digitale Produkte, Online- und Offline-Veranstaltungen, Trinkgelder, persönliche und Video-Sessions usw.",
  businessCalculatorNew: "Waylight-Einkommensrechner",
  howMuchMoneyNew:
    "Erfahren Sie, wie viel Geld Abonnenten Ihrem Geschäft bringen würden.",
  estimateNumberNew: "1. Schätzen Sie die Anzahl der Kunden und Abonnenten",
  howMuchYouChargeNew: "2. Wie viel können Sie für ein Angebot verlangen",
  for: "Für",
  whom: "wem",
  getEverythingBasicNew:
    "Erhalten Sie alles im Basispaket mit einem niedrigeren Transaktionsgebührenniveau",
  startLowestCostsNew:
    "Starten Sie ohne Upfront-Kosten und erkunden Sie Waylight",
  GetMoreSalesNew:
    "Erzielen Sie mehr Verkäufe aus Ihren sozialen Medien ohne Transaktionsgebühren",
  onYourSocialMediaNew: "auf Ihren Einnahmen aus den sozialen Medien! 🎉",
  accordionv2label0: "In welchen Ländern arbeiten Sie?",
  accordionv2text0:
    "Waylight Business ist in mehr als 55 Ländern auf 5 Kontinenten tätig. Sie können sich bezüglich Ihres Landes an unseren Support wenden oder einfach versuchen, sich anzumelden und es auszuprobieren. Kunden können Ihre Angebote aus 150+ Ländern der Welt bezahlen.",
  accordionv2label1: "Wie werde ich bezahlt?",
  accordionv2text1:
    "Sie erhalten Auszahlungen auf Ihr Bankkonto jeden Montag. Es dauert weniger als 1 Minute, um Ihr Bankkonto mit Waylight Business zu verbinden.",
  accordionv2label2: "Welche Tools sind im Preis inbegriffen?",
  accordionv2text2:
    "Es hängt vom von Ihnen gewählten Plan ab, aber in jedem Fall erhalten Sie Zugang zu vollständigen Waylight Monetisierungsgeschäftsabläufen wie bezahlten Gemeinschaften in Telegramm, Tickets, digitalen Produkten, Kursen, Terminen, Videositzungen, Trinkgeld, Unternehmenswebsite, Kundendaten, Verkaufsmotoren, Monetisierungsakademie usw.",
  accordionv2label3: "Haben Sie Apple Pay, Google Pay, PayPal?",
  accordionv2text3:
    "Ja, haben wir. Wir nennen es „Smooth Checkout“. Waylight arbeitet daran, Verkaufsflüsse mit höheren Konversionsraten zu automatisieren, daher haben wir 1-Klick-Checkouts (mit PayPal in Arbeit).",
  accordionv2label4: "Wie wird mir Waylight helfen, das 10-fache zu verdienen?",
  accordionv2text4:
    "Sie erhalten Zugang zum Verkaufswachstumsmotor und zur Monetisierungsakademie, die Ihnen helfen werden, mehr zu verkaufen und mehr Kunden aus Ihren sozialen Medien zu gewinnen. Außerdem hat Waylight bereits automatisierte Abläufe und Tools erstellt, die Ihnen höhere Konversionsraten bieten.",
  accordionv2label5: "Was brauche ich, um anzufangen?",
  accordionv2text5:
    "Es ist einfach 👌 Melden Sie sich an, fügen Sie Ihr Bankkonto hinzu, erstellen Sie Angebote, treten Sie der Monetisierungsakademie bei, fügen Sie Links in sozialen Medien hinzu und beginnen Sie, immer mehr Geld zu verdienen.",
  connectWaylight: "Mit Waylight verbinden!",
  toastTooLargeFileTwilio:
    "Diese Datei ist zu groß. Die maximale Dateigröße beträgt 150 MB.",
  enableSold: "Angebotsverkaufsindikatoren aktivieren?",
  areYouSureYOuWantEnableSold:
    "Sind Sie sicher, dass Sie die Angebotsverkaufsindikatoren aktivieren möchten? Verkaufswerte sind für alle Benutzer sichtbar.",
  disableSold: "Angebotsverkaufsindikatoren deaktivieren?",
  areYouSureYOuWantDisableSold:
    "Sind Sie sicher, dass Sie die Angebotsverkaufsindikatoren deaktivieren möchten?",
  soldEnabled: "Angebotsverkaufsindikatoren sind aktiviert",
  soldEnabledDescription:
    "Wenn diese Einstellung aktiviert ist, sind Verkaufswerte für alle Benutzer sichtbar.",
  tipOrCommunitypriceLimitARS:
    "Der Preis kann ARS$ 4135 bis ARS$ 9396554.46 betragen.",
  tipOrCommunitypriceLimitAUD:
    "Der Preis kann A$ 3.01 bis A$ 15097.1 betragen.",
  tipOrCommunitypriceLimitEUR: "Der Preis kann €1.81 bis €9070 betragen.",
  tipOrCommunitypriceLimitBOB:
    "Der Preis kann Bs. 13.81 bis Bs. 69119.48 betragen.",
  tipOrCommunitypriceLimitBGN:
    "Der Preis kann лв 3.55 bis лв 17750.81 betragen.",
  tipOrCommunitypriceLimitCAD:
    "Der Preis kann CA$ 2.74 bis CA$ 13718.98 betragen.",
  tipOrCommunitypriceLimitCLP:
    "Der Preis kann CL$ 1864.56 bis CL$ 9321644.2 betragen.",
  tipOrCommunitypriceLimitCOP:
    "Der Preis kann COL$ 8072.11 bis COL$ 40360576.84 betragen.",
  tipOrCommunitypriceLimitCRC:
    "Der Preis kann ₡1055.02 bis ₡5277582.56 betragen.",
  tipOrCommunitypriceLimitHRK: "Der Preis kann kn 36 bis kn 70472 betragen.",
  tipOrCommunitypriceLimitCZK:
    "Der Preis kann Kč 45.71 bis Kč 228558 betragen.",
  tipOrCommunitypriceLimitDKK: "Der Preis kann kr 13.54 bis kr 67719 betragen.",
  tipOrCommunitypriceLimitDOP:
    "Der Preis kann RD$ 295 bis RD$ 588928 betragen.",
  tipOrCommunitypriceLimitHKD:
    "Der Preis kann HK$ 15.58 bis HK$ 77915 betragen.",
  tipOrCommunitypriceLimitEGP:
    "Der Preis kann EGP 154,75 bis EGP 309501 betragen.",
  tipOrCommunitypriceLimitHUF: "Der Preis kann Ft 716 bis Ft 3579217 betragen.",
  tipOrCommunitypriceLimitISK: "Der Preis kann kr 684 bis kr 1369300 betragen.",
  tipOrCommunitypriceLimitINR: "Der Preis kann ₹167.89 bis ₹839435 betragen.",
  tipOrCommunitypriceLimitIDR:
    "Der Preis kann Rp 78852 bis Rp 157703833 betragen.",
  tipOrCommunitypriceLimitILS: "Der Preis kann ₪19 bis ₪36657 betragen.",
  tipOrCommunitypriceLimitUYU: "Der Preis kann $U 81 bis $U 402925 betragen.",
  tipOrCommunitypriceLimitGBP: "Der Preis kann £1.56 bis £7779.95 betragen.",
  tipOrCommunitypriceLimitTRY: "Der Preis kann ₺67.06 bis ₺335289.19 betragen.",
  tipOrCommunitypriceLimitTTD:
    "Der Preis kann TT$ 13,58 bis TT$ 67917.76 betragen.",
  tipOrCommunitypriceLimitTHB: "Der Preis kann ฿69.82 bis ฿349109.6 betragen.",
  tipOrCommunitypriceLimitCHF: "Der Preis kann CHF 1.73 bis CHF 8647 betragen.",
  tipOrCommunitypriceLimitSEK:
    "Der Preis kann kr 20.82 bis kr 104092.39 betragen.",
  tipOrCommunitypriceLimitZAR: "Der Preis kann R 94 bis R 186359 betragen.",
  tipOrCommunitypriceLimitSGD:
    "Der Preis kann SGD$ 2.63 bis SGD$ 13146.69 betragen.",
  tipOrCommunitypriceLimitSAR: "Der Preis kann SAR 19 bis SAR 37496 betragen.",
  tipOrCommunitypriceLimitRON:
    "Der Preis kann lei 9.04 bis lei 45196.94 betragen.",
  tipOrCommunitypriceLimitPLN:
    "Der Preis kann zł 7.8 bis zł 39008.41 betragen.",
  tipOrCommunitypriceLimitPHP: "Der Preis kann ₱ 281 bis ₱ 560255 betragen.",
  tipOrCommunitypriceLimitPEN: "Der Preis kann S/. 19 bis S/. 38040 betragen.",
  tipOrCommunitypriceLimitPYG:
    "Der Preis kann ₲ 36387 bis ₲ 72772680 betragen.",
  tipOrCommunitypriceLimitNOK:
    "Der Preis kann kr 21.38 bis kr 106903 betragen.",
  tipOrCommunitypriceLimitNZD:
    "Der Preis kann NZ$ 3.33 bis NZ$ 16646 betragen.",
  tipOrCommunitypriceLimitMXN:
    "Der Preis kann MX$ 37.92 bis MX$ 189595 betragen.",
  tipOrCommunitypriceLimitKES:
    "Der Preis kann KSh 803 bis KSh 1605000 betragen.",
  authorizeToAccess: "Sie müssen sich autorisieren, um Zugriff zu erhalten",
  noCommunitiesFound: "Keine Gemeinschaften gefunden",
  noCommunitiesFoundDescription:
    "Sie erscheinen hier, wenn Sie eine Gemeinschaft kaufen oder hinzufügen",
  following: "Folgende",
  appointments: "Termine",
  share: "Teilen",
  addOffer: "Angebot hinzufügen",
  aboutMe: "Über mich",
  estimatedAmount:
    "Geschätzter Betrag gemäß den aktuellen Währungsumrechnungskursen in Ihrer Stripe-Konto-Standardwährung.",
  ConnectYourTelegram: "Verbinden Sie Ihr Telegram:",
  ConnectYourTelegramAccount:
    "Verbinden Sie Ihr Telegramm-Konto, um Benachrichtigungen von den Erstellern zu erhalten, denen Sie folgen.",
  connectTelegram: "Telegram verbinden",
  buyAgain: "Erneut kaufen",
  purchases: "Gekauft",
  videoGuide: "Videoanleitung",
  businessBilling: "Geschäftsfakturierung",
  noPaymentMethodsAdded: "Keine Zahlungsmethoden hinzugefügt",
  yourPaymentMethods: "Ihre Zahlungsmethoden",
  yourPlan: "Ihr Plan",
  yearlyBilling: "Jährliche Abrechnung",
  selectFree: "Kostenlos auswählen",
  whatIncluded: "Was ist inbegriffen",
  selectStarter: "Basic auswählen",
  selectPro: "Pro auswählen",
  enterPromoCode: "Promo-Code eingeben:",
  apply: "Anwenden",
  selected: "Ausgewählt",
  waylightSubscription: "Waylight-Abonnement",
  cashbackRefferals: "Empfehlungen",
  referrals: "Affiliate",
  shareLink: "Link teilen",
  howReferralsWork: "Wie Empfehlungen funktionieren",
  cashback: "Cashback",
  yourCashbackCode: "Ihr Cashback-Code",
  yourCustomPartnerReferralCode: "Ihr benutzerdefinierter Partner-Referenzcode",
  howCashbackWork: "Wie Cashback funktioniert",
  toastPromoCodeSuccess: "Ihr Promo-Code wurde erfolgreich angewendet!",
  toastPromoCodeError:
    "Beim Anwenden Ihres Promo-Codes ist ein Fehler aufgetreten!",
  enterPromoCodeSignUp: "Promo-Code eingeben (optional):",
  enterPromoCodeBtn: "+ Promo-Code eingeben",
  billingBusinessTooltip: "Wählen Sie unten Ihren Plan aus.",
  referralTooltip: "Dies ist Ihr einzigartiger Cashback-Code.",
  cashbackTooltip: "Dies ist Ihr einzigartiger Partnercode.",
  currentPlan: "Aktueller Plan:",
  neverExpires: "Nie",
  expires: "Läuft ab:",
  emptyBillingBusinessHistoryDescription:
    "Leere Beschreibung des Geschäftsfakturierungsverlaufs",
  notificationGoToBillingBusiness:
    "Wir haben ein Problem mit Ihrer Abonnementzahlung festgestellt! ",
  WeHaveEncounteredAnIssueSubscription:
    "Wir haben ein Problem mit Ihrer Abonnementzahlung festgestellt",
  checkIfYouHaveSufficientSubscription:
    "Überprüfen Sie, ob Sie ausreichend Guthaben auf Ihrem Konto haben oder Internetzahlungen aktiviert sind.",
  selectDifferentPaymentMethodSubscription:
    "Wählen Sie eine andere Zahlungsmethode aus.",
  referralsDescriptions1:
    "Vielen Dank, dass Sie unser geschätzter Partner sind!",
  referralsDescriptions2:
    "Schließen Sie die Onboarding ab, um Ihre Empfehlungsprovisionen zu erhalten. Ihr Geld ist bei uns sicher, bis Sie an Bord sind.",
  referralsDescriptions3:
    "Je nach Land benötigen Sie Ihre Bankdaten oder Kartendaten.",
  referralsDescriptions4:
    "Zahlungen erfolgen jeden Montag. Sie können die Zahlungsstatus in Transaktionen überprüfen.",
  referralsDescriptions5:
    "Ihr Partnercode bietet zusätzliche Rabatte für Waylight-Abonnements.",
  referralsDescriptions6:
    "Ihre Rabatte und Umsatzbeteiligungssätze werden individuell vereinbart.",
  referralsDescriptions7:
    "Wenn Sie zum ersten Mal eine Auszahlung erhalten, kann es aufgrund von Gateway-Einschränkungen 7-14 Werktage dauern, bis sie eintrifft. Normalerweise dauern Auszahlungen etwa 3 Werktage.",
  cashbackDescriptions1:
    "Vielen Dank, dass Sie Mitglied unseres Cashback-Programms sind!",
  cashbackDescriptions2:
    "Schließen Sie das Onboarding ab, um Ihre Empfehlungsprovisionen zu erhalten. Ihr Geld ist bei uns sicher, bis Sie an Bord sind.",
  cashbackDescriptions3:
    "Je nach Land benötigen Sie Ihre Bankdaten oder Kartendaten.",
  cashbackDescriptions4:
    "Zahlungen erfolgen jeden Montag. Sie können die Zahlungsstatus in Transaktionen überprüfen.",
  cashbackDescriptions5:
    "Teilen Sie Ihren Link und erhalten Sie Umsatzbeteiligungen von neuen Kunden.",
  cashbackDescriptions6:
    "Ihr Cashback beträgt 20-30% aller Gebühren von Waylight für 1 Jahr (nur 30% von jährlichen Pro-Abonnements).",
  cashbackDescriptions7:
    "Wenn Sie zum ersten Mal eine Auszahlung erhalten, kann es aufgrund von Gateway-Einschränkungen 7-14 Werktage dauern, bis sie eintrifft. Normalerweise dauern Auszahlungen etwa 3 Werktage.",
  invalidGTMiD:
    "Ungültige GTM-ID. Geben Sie nur Ihre ID ein, nicht den Code, zum Beispiel: GTM-AA11BB2.",
  invalidMetaPixelID:
    "Ungültige Meta Pixel-ID. Geben Sie nur Ihre ID ein, nicht den Code, zum Beispiel: 123456789012345.",
  invalidHotjarID:
    "Ungültige Hotjar-ID. Geben Sie nur Ihre ID ein, nicht den Code, zum Beispiel: 1112223.",
  accountCreated: "Konto erstellt",
  toastOUrlCopied: "Referral-Link erfolgreich kopiert!",
  YouAreOnFreePlan: "Sie befinden sich bereits auf dem kostenlosen Plan",
  referralCodeCanNotBeUsed:
    "Dieser Empfehlungscode kann für Ihr aktuelles Länderkonto nicht verwendet werden: {{country}}",
  createWaylightBusiness: "Waylight Business erstellen",
  pleaseChooseCountryBank:
    "Bitte wählen Sie das Land Ihrer Bank oder Finanzdienstleistung aus:",
  referralsDescriptionsUA4:
    "Zahlungen erfolgen nach jeder Transaktion. Sie können die Zahlungsstatus in Transaktionen überprüfen.",
  referralsDescriptionsUA7:
    "Zahlungen erfolgen spätestens am nächsten Werktag.",
  cashbackDescriptionsUA4:
    "Zahlungen erfolgen nach jeder Transaktion. Sie können die Zahlungsstatus in Transaktionen überprüfen.",
  cashbackDescriptionsUA7: "Zahlungen erfolgen spätestens am nächsten Werktag.",
  issueSubscriptionPayment:
    "Wir haben ein Problem mit Ihrer Abonnementzahlung festgestellt!",
  revenueSharhingSelectPE:
    "Das Empfehlungsprogramm ist nur für aktivierte PE-Konten in der Ukraine verfügbar.",
  youCanChangeYourAccount:
    "Sie können den Kontotyp in den Einstellungen ändern.",
  yourReferralCodeWillAppear:
    "Ihr Empfehlungscode wird hier angezeigt, sobald Sie das Onboarding für PE abgeschlossen haben.",
  goToPayoutSettings: "Zu den Auszahlungseinstellungen gehen",
  commission: "Gebühr",
  youAreOnSamePlan:
    "Sie befinden sich bereits auf dem gleichen Plan, den Sie zu wählen versuchen! Bitte wählen Sie einen anderen Plan.",
  onlyCyrillicLettersAllowed: "Es sind nur kyrillische Buchstaben erlaubt",
  dragToChangePosition: "Zum Ändern der Position ziehen",
  eventsQrcode: "Event-Kurzcodes",
  eventsQrcodeDescription:
    "Wenn diese Einstellung aktiviert ist, werden Ihre Veranstaltungstickets mit kurzen 4-stelligen PINs und QR-Codes generiert. Wenn deaktiviert, werden sicherere 8-stellige PINs mit Strichcodes generiert.",
  areYouSureYOuWantEnableQrcode:
    "Sind Sie sicher, dass Sie Event-Kurzcodes-PINs mit QR-Codes aktivieren möchten?",
  areYouSureYOuWantDisableQrcode:
    "Sind Sie sicher, dass Sie die kurzen PINs für Veranstaltungen deaktivieren und stattdessen Strichcodes generieren möchten?",
  enableEventsQrcode: "Kurze Eventcodes aktivieren",
  disableEventsQrcode: "Kurzcodes deaktivieren und auf Barcodes umschalten",
  authorizeScanner: "Scanner autorisieren",
  toastScannerUrlCopied: "Scanner-URL kopiert",
  total: "Gesamt:",
  gtmIdDescription: "Lokales GTM für dieses Angebot.",
  metaPixelIdDescription: "Lokaler Meta-Pixel für dieses Angebot.",
  tutorialBoughtPreview:
    "Dies ist die Vorschau des gekauften Zustands Ihres Produkts.",
  tutorialNotBoughtPreview:
    "So sehen Kunden Ihr Produkt, wenn es nicht gekauft wurde.",
  authorizeToAccessPurchases:
    "Bitte autorisieren Sie sich, um auf Ihre Einkäufe zuzugreifen!",
  myPromotions: "Mein Promo-Code",

  describeTheDetailsPromotions:
    "Wählen Sie ein Angebot, geben Sie den Namen an, wählen Sie eine URL und fügen Sie unten die Aktionsdetails hinzu",
  promotionDropdownDescription:
    "Wählen Sie Ihr Angebot aus der Liste. Sie können es später für diese Aktion nicht mehr ändern.",
  offerNamePromotions: "Angebot",
  promotionsTooltip:
    "Wählen Sie ein Angebot aus, für das diese Aktion gelten soll. Sie können so viele andere Aktionen für dieses Angebot erstellen, wie Sie möchten.",
  offerNameDescriptionPromotions:
    "Geben Sie den Aktionsnamen ein. Dies ist nur für Ihre internen Zwecke.",
  codePromotionsDescription:
    "Geben Sie einen eindeutigen Promo-Code mit englischen Buchstaben und Zahlen ein. Probieren Sie die magische Generierungstaste, wenn Sie möchten!",
  expirationPromotions: "Ablaufdatum",
  name: "Name",
  codePromotions: "Code",
  usersNumDescription:
    "Dieser Promo-Code kann eine bestimmte Anzahl von Malen verwendet werden.",
  max_uses: "Anzahl der Verwendungen",
  pricingPromotionsDiscription: "pricingPromotionsDiscription",
  discount: "Rabatt",
  discountTitle: "Rabatt(e):",
  selectOfferDiscounts:
    "Wählen Sie das Angebot oben aus, um die Rabatte dafür zu verwalten.",
  promoNameCanNotBeEmpty: "Promo-Name darf nicht leer sein",
  urlIsAlreadyTaken: "Diese URL ist bereits vergeben!",
  promoCodeValidation:
    "Der Promo-Code darf nur Groß- und Kleinbuchstaben sowie Zahlen von 0 bis 9 enthalten!",
  promotions: "Aktionen",
  enterPromoCodePromotions: "Promo-Code eingeben",
  promoCodeIncorrectExpired: "Dieser Promo-Code ist falsch oder abgelaufen!",
  selectedPromoCodeNotAvailable: "Ausgewählter Promo-Code ist nicht verfügbar!",
  weWereUnableToProcessYourTransaction:
    "Wir konnten Ihre Transaktion mit dem ausgewählten Promo-Code aufgrund von Ablauf, Nutzungslimit oder Nichtverfügbarkeit nicht verarbeiten. Bitte starten Sie Ihren Kauf erneut.",
  promotionsEmptyStateHeader:
    "Keine Aktionen nach dem eingestellten Filter gefunden.",
  promotionsEmptyStateDescription:
    "Erstellen Sie Aktionen, versuchen Sie einen anderen Filter oder eine andere Sucheingabe.",
  toastPromotionUrlCopied: "Aktions-URL kopiert!",
  toastPromotionCodeCopied: "Promo-Code kopiert!",
  managePromotionsForYourOffers: "Verwalten Sie Aktionen für Ihre Angebote",
  offerPromotions: "Angebotsaktionen",
  deletePromotionDescription:
    "Sind Sie sicher, dass Sie die Aktion löschen möchten?",
  deletePromotionTitle: "Aktion löschen",
  addPromotion: "Aktion hinzufügen",
  toastPromotionNotEditedEnabled: "Wir konnten Ihre Aktion nicht ändern!",
  toastPromotionNotEdited: "Wir konnten Ihre Aktion nicht ändern!",
  usage: "Verwendet",
  discountedPriceError:
    "Der ermäßigte Preis darf nicht weniger als 1 USD betragen!",

  priceWithDiscount: "Preis mit Rabatt",
  promoCodeAddedToast: "Promo-Code erfolgreich angewendet!",
  toastPromotionSavedSuccessfully: "Promo-Code erfolgreich erstellt",
  toastPromotiomNotAdded:
    "Beim Erstellen Ihrer Aktion ist ein Fehler aufgetreten!",
  myPromotionsMenu: "Meine Aktionen",
  setSubscriptionlevelManually: "Abonnementlevel manuell festlegen:",
  settingLevelManuallyNotifyClientAutomatically:
    "Das manuelle Festlegen eines Levels benachrichtigt den Kunden automatisch und wird mit der nächsten Abbuchung am {{expiration}} angewendet",
  subscribeForFree: "Kostenlos abonnieren",
  toastSavedSuccessfullyUpdatedLevelCommunity:
    "Community-Level erfolgreich gespeichert und aktualisiert.",
  toastCommunityLevelNotUpdated: "Community-Level nicht aktualisiert.",
  addNewCardStripe: "Neue Karte hinzufügen (Stripe):",
  creditCard: "Kreditkarte",
  promocode: "Promocode",
  nonFollowers: "Nicht-Follower",
  dueOn: "Fällig am",
  exportMyClientsSuccess: "Kundenexport erfolgreich abgeschlossen.",
  exportMyClientsError: "Fehler beim Exportieren der Kunden.",
  eventTelegramDescription:
    "Wählen Sie den Namen Ihres Kanals oder Ihrer Gruppe aus. Wenn Sie hier nichts sehen, stellen Sie sicher, dass Sie Waylight Bot als Administrator zu Ihrer Gruppe oder Ihrem Kanal hinzugefügt haben und dies das einzige Angebot für diesen Kanal ist!",
  onlineTelegramEvent: "Online-Telegramm-Event",
  joinEvent: "Veranstaltung beitreten",
  levels: "Stufen",
  paidAccess: "Bezahlter Zugang",
  clientWillPayOnceAccess: "Der Kunde zahlt einmal, um Zugang zu erhalten",
  noOffersFound: "Keine Angebote gefunden",
  noOffersFoundDescription:
    "Sie werden hier angezeigt, wenn Sie ein Angebot kaufen oder hinzufügen",
  youCanNotManageEnabled:
    "Sie haben keine Angebote aktiviert, die Sie verwalten können.",
  enableOffersForManagement:
    "Aktivieren Sie Sitzungen, Veranstaltungen oder Communities, um sie hier zu verwalten.",
  specifyQuickSessionPriceDuration:
    "Geben Sie den Preis und die Dauer der Schnell-Sitzung an",
  youGetPaidSpecifiedPriceForFixedAmountTime:
    "Sie erhalten den angegebenen Preis für eine feste Zeit.",
  videoCallQuick: "Videoanruf",
  tabs: "Registerkarten",
  pleaseEnterEmailAccessQuickSession:
    "Bitte geben Sie Ihre E-Mail ein, um auf die Schnell-Sitzung zuzugreifen",
  disconnectWhatsAppBot: "WhatsApp-Bot trennen",
  connectWhatsAppBot: "WhatsApp-Bot verbinden",
  WhatsAppBot: "WhatsApp-Bot",
  videoCallSession: "Videoanruf",
  copyProfileLink: "Profil-Link kopieren",
  profileQRCode: "Mein Profil-QR-Code",
  copyCommunitiesLink: "Gemeinschaften-Link",
  copyEventsLink: "Veranstaltungslink",
  copyProductsLink: "Produktlink",
  copySessionsLink: "Sitzungslink",
  copyTipsLink: "Tipp-Link",
  copyPortfolioLink: "Portfolio-Link",
  copyReviewsLink: "Bewertungslink",
  leaveReviewForMe: "Hinterlassen Sie eine Bewertung für mich",
  shareYourWaylight: "Teilen Sie Ihren Waylight",
  myProfileQRCode: "Mein Profil-QR-Code",
  menu: "Menü",
  selectOfferExportData: "Wählen Sie ein Angebot zum Exportieren von Daten aus",
};
